import { AxiosResponse } from "axios";

import { NestedData } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type Permission = {
  policy: {
    Resource: string[];
    Action: string;
    Effect: string;
    Sid: string;
  };
  ["sub_level_name"]: string;
  ["level_name"]: string;
};

type UsePermissionAPIType = {
  listAll: () => Promise<NestedData<Permission[]>>;
};

const usePermissionAPI = (): UsePermissionAPIType => {
  const API = useAxios();

  const listAll = async (): Promise<NestedData<Permission[]>> => {
    const response: AxiosResponse<NestedData<Permission[]>> =
      await API.instance.post(APIEndpoints.PERMISSION_LIST);
    return response.data;
  };

  return {
    listAll
  };
};

export default usePermissionAPI;
