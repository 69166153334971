import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  LineChart,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { FC } from "react";
import Watermark from "components/Watermark";
import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  MonitoringReasonFrequency,
  MonitoringReasonFrequencyHours
} from "api/MonitoredVehiclesDashboardAPI";
import { ChartStyle } from "components/VisualizationSwitcher";
import { monitoringVehiclesColors } from "styles/theme";

type MonitoredReasonsGraphByHourProps = {
  chartStyle: ChartStyle;
  isFetching: boolean;
  data: (MonitoringReasonFrequency & MonitoringReasonFrequencyHours)[];
};

const MonitoredReasonsGraphByHour: FC<MonitoredReasonsGraphByHourProps> = ({
  chartStyle,
  data,
  isFetching
}) => {
  const { t } = useTranslation();

  return (
    <Watermark>
      <Box sx={{ maxWidth: 900, margin: "0 auto" }}>
        {isFetching && <Skeleton variant="rectangular" height={350} />}
        {!isFetching && chartStyle === "bar" && (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hour" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="search_and_seizure"
                name={t("MonitoredVehiclesDashboardPage.search_and_seizure")}
                fill={monitoringVehiclesColors.seizure}
                stackId="a"
              />
              <Bar
                dataKey="documentation"
                name={t("MonitoredVehiclesDashboardPage.documentation")}
                fill={monitoringVehiclesColors.documentation}
                stackId="a"
              />
              <Bar
                dataKey="theft_and_robbery"
                name={t("MonitoredVehiclesDashboardPage.theft_and_robbery")}
                fill={monitoringVehiclesColors.steal}
                stackId="a"
              />
              <Bar
                dataKey="intelligence"
                name={t("MonitoredVehiclesDashboardPage.intelligence")}
                fill={monitoringVehiclesColors.intelligence}
                stackId="a"
              />
              <Bar
                dataKey="automatic_monitoring"
                name={t("MonitoredVehiclesDashboardPage.automatic_monitoring")}
                fill={monitoringVehiclesColors.rule}
                stackId="a"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {!isFetching && chartStyle === "line" && (
          <ResponsiveContainer width="100%" height={350}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hour" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="search_and_seizure"
                name={t("MonitoredVehiclesDashboardPage.search")}
                stroke={monitoringVehiclesColors.seizure}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="documentation"
                name={t("MonitoredVehiclesDashboardPage.documentation")}
                stroke={monitoringVehiclesColors.documentation}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="theft_and_robbery"
                name={t("MonitoredVehiclesDashboardPage.robbery")}
                stroke={monitoringVehiclesColors.steal}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="intelligence"
                name={t("MonitoredVehiclesDashboardPage.intelligence")}
                stroke={monitoringVehiclesColors.intelligence}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="automatic_monitoring"
                name={t("MonitoredVehiclesDashboardPage.automatic_monitoring")}
                stroke={monitoringVehiclesColors.rule}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Watermark>
  );
};

export default MonitoredReasonsGraphByHour;
