import { OutlinedInputProps } from "@mui/material";
import merge from "deepmerge";
import { FC } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { Error, Helper, Wrapper } from "./FormField";
import CustomInput from "./CustomInput";
import FormLabel from "./FormLabel";

const defaultProps = {
  label: "",
  gutter: true,
  mask: "",
  customProps: {
    name: "",
    type: "number",
    min: 0,
    max: 100,
    step: 1,
    fullWidth: true,
    variant: "outlined"
  },
  field: {},
  fieldState: {}
};

export interface InputNumberProps {
  mask?: string;
  label?: string;
  gutter?: boolean;
  fullWidth?: boolean;
  min?: number;
  max?: number;
  step?: number;
  field?: ControllerRenderProps;
  helperText?: string;
  customProps?: OutlinedInputProps;
  fieldState?: ControllerFieldState;
  onChange?: (value: number) => void;
}

const InputNumber: FC<InputNumberProps> = props => {
  const fullProps = merge(defaultProps, props);

  if (props.fieldState) {
    fullProps.fieldState = props.fieldState;
  }
  if (props.field) {
    fullProps.field = props.field;
  }

  const hasError = fullProps.fieldState.error && fullProps.fieldState.isTouched;
  const className = hasError ? "error" : "";

  return (
    <Wrapper
      className={fullProps.gutter ? "gutter" : ""}
      fullWidth={props.fullWidth}
    >
      {fullProps.label && (
        <FormLabel
          required={fullProps.customProps?.required}
          helperText={fullProps.helperText}
        >
          {fullProps.label}
        </FormLabel>
      )}
      <CustomInput
        {...fullProps.customProps}
        {...fullProps.field}
        inputProps={{
          min: props.min ?? defaultProps.customProps.min,
          max: props.max ?? defaultProps.customProps.max,
          step: props.step ?? defaultProps.customProps.step
        }}
        className={className}
        error={Boolean(hasError)}
      />
      {hasError && <Error>{fullProps.fieldState.error?.message}</Error>}
      {!hasError && fullProps.helperText && (
        <Helper>{fullProps.helperText}</Helper>
      )}
    </Wrapper>
  );
};

export default InputNumber;
