import { FC, useEffect, useRef } from "react";

type SimpleSoundPlayerParams = {
  sound: string | undefined;
};

const SimpleSoundPlayer: FC<SimpleSoundPlayerParams> = ({ sound }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (sound && audioRef.current) {
      audioRef.current.load();
    }
  }, [sound]);

  return (
    <audio controls ref={audioRef}>
      <source src={sound} type="audio/mp3" />
    </audio>
  );
};

export default SimpleSoundPlayer;
