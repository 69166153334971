import { createContext, FC, useContext } from "react";
import axios, { AxiosError } from "axios";
import snackNotification from "components/SnackNotification";
import { useTranslation } from "react-i18next";

type LinceError = {
  code: number;
  internal: number;
  message: string;
};

type ErrorProps = {
  error: Error | AxiosError | unknown;
};

interface ContextProps {
  errorHandler: (error: ErrorProps) => void;
}

const ErrorHandlerContext = createContext<ContextProps>({
  errorHandler() {
    throw new Error("errorHandler must be defined.");
  }
});

export const ErrorHandlerProvider: FC = ({ children }) => {
  const { t } = useTranslation();

  const showSnackNotificationError = (error: unknown) => {
    let errorMessage: string = `${error}` || t("error.generic");
    try {
      if (axios.isAxiosError(error)) {
        const axError: AxiosError = error;
        if (axError.response) {
          const lcError: LinceError = axError.response.data.error;
          if (lcError) {
            const errorTranslation =
              t(`error.code.${lcError.code}`) || t("error.generic");
            errorMessage = `${lcError.code}:${lcError.internal} - ${errorTranslation}`;
          } else {
            errorMessage = `${axError.response.status} - ${error}`;
          }
        } else {
          errorMessage = `${t("error.network")}`;
        }
      }
      snackNotification.error(errorMessage);
    } catch (_err) {
      if (typeof _err === "string") snackNotification.error(_err);
      else snackNotification.error(t("error.generic"));
    }
  };

  const contextValues: ContextProps = {
    errorHandler(error) {
      showSnackNotificationError(error.error);
    }
  };

  return (
    <ErrorHandlerContext.Provider value={contextValues}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

export const useErrorHandler = (): ContextProps => {
  const context = useContext(ErrorHandlerContext);
  if (context === undefined) {
    throw new Error(
      "useErrorHandler must be used within an ErrorHandlerProvider"
    );
  }
  return context;
};
