import { FC, ReactNode } from "react";
import { Box, styled } from "@mui/material";

export const BoxContainer = styled(Box)(({ theme }) => ({
  height: "40px",
  padding: "6px 8px",
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius
}));

export const IconBox = styled("strong")(() => ({
  margin: "6px 8px 0px 0px"
}));

export const TextBox = styled("strong")(() => ({
  color: "#000"
}));

type RestrictionTypeBoxProps = {
  color: string;
  icon?: ReactNode;
  align: string;
  text: string;
};

const TypeBox: FC<RestrictionTypeBoxProps> = ({ icon, color, align, text }) => (
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: align }}>
    <BoxContainer sx={{ backgroundColor: `${color}50` }}>
      {icon && <IconBox>{icon}</IconBox>}
      <TextBox>{text}</TextBox>
    </BoxContainer>
  </Box>
);

export default TypeBox;
