import { AxiosResponse } from "axios";
import { NestedData, Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";
import Crypto from "utils/Crypto";
import { isUrl } from "utils/String";

export type VmsIntegration = {
  ["customer_id"]: string;
  index: string;
  model: string;
  url: string;
  protocol: "RTSP" | "RTSPS";
  username: string;
  password: string;
  ["output_format"]: "AVI" | "MP4";
  timezone: string;
};

type CreateParams = {
  customerId: string;
  model: string;
  url: string;
  protocol: string;
  username: string;
  password: string;
  outputFormat: string;
  timezone: string;
};

type UpdateParams = {
  customerId: string;
  index: string;
  model: string;
  url: string;
  protocol: string;
  username: string;
  password: string;
  outputFormat: string;
  timezone: string;
};

type DeleteParams = {
  customerId: string;
  index: string;
};

type RequestDataParams = {
  customerId: string;
  registerId: string;
};

type RequestDataResponse = {
  ["request_id"]: string;
  url: string;
};

type GetDataParams = {
  customerId: string;
  requestId: string;
};

type GetDataResponse = {
  message: string;
  isUrl: boolean;
};

type UseVmsIntegrationAPIType = {
  listAll: (customerId: string) => Promise<NestedData<VmsIntegration[]>>;
  getByIndex: (customerId: string, index: string) => Promise<VmsIntegration>;
  deleteByIndex: (params: DeleteParams) => Promise<Success>;
  create: (data: CreateParams) => Promise<Success>;
  update: (data: UpdateParams) => Promise<Success>;
  requestData: (params: RequestDataParams) => Promise<RequestDataResponse>;
  getData: (params: GetDataParams) => Promise<GetDataResponse>;
};

const useVmsIntegrationAPI = (): UseVmsIntegrationAPIType => {
  const API = useAxios();

  const listAll = async (
    customerId: string
  ): Promise<NestedData<VmsIntegration[]>> => {
    const response: AxiosResponse<NestedData<VmsIntegration[]>> =
      await API.instance.post(APIEndpoints.VMS_INTEGRATION_LIST, {
        ["customer_id"]: customerId
      });
    return response.data;
  };

  const getByIndex = async (
    customerId: string,
    index: string
  ): Promise<VmsIntegration> => {
    const response: AxiosResponse<VmsIntegration> = await API.instance.get(
      APIEndpoints.VMS_INTEGRATION,
      {
        params: {
          ["customer_id"]: customerId,
          ["index"]: index
        }
      }
    );
    return response.data;
  };

  const deleteByIndex = async ({
    customerId,
    index
  }: DeleteParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VMS_INTEGRATION_DELETE,
      {
        ["customer_id"]: customerId,
        index
      }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VMS_INTEGRATION_CREATE,
      {
        ["customer_id"]: data.customerId,
        model: data.model,
        url: data.url,
        protocol: data.protocol,
        username: data.username,
        password: Crypto.encrypt(data.password),
        ["output_format"]: data.outputFormat,
        timezone: data.timezone
      }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VMS_INTEGRATION_UPDATE,
      {
        ["customer_id"]: data.customerId,
        index: data.index,
        model: data.model,
        url: data.url,
        protocol: data.protocol,
        username: data.username,
        password: data.password ? Crypto.encrypt(data.password) : undefined,
        ["output_format"]: data.outputFormat,
        timezone: data.timezone
      }
    );
    return response.data;
  };

  const requestData = async (
    data: RequestDataParams
  ): Promise<RequestDataResponse> => {
    const response: AxiosResponse<RequestDataResponse> =
      await API.instance.post(APIEndpoints.VMS_REQUEST_DATA, {
        ["customer_id"]: data.customerId,
        ["register_id"]: data.registerId
      });
    return response.data;
  };

  const getData = async (data: GetDataParams): Promise<GetDataResponse> => {
    const response: AxiosResponse<GetDataResponse> = await API.instance.get(
      APIEndpoints.VMS_GET_DATA,
      {
        params: {
          ["customer_id"]: data.customerId,
          ["request_id"]: data.requestId
        }
      }
    );
    return {
      ...response.data,
      isUrl: isUrl(response.data.message)
    };
  };

  return {
    listAll,
    getByIndex,
    create,
    update,
    deleteByIndex,
    requestData,
    getData
  };
};

export default useVmsIntegrationAPI;
