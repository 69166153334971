import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Box, Skeleton, styled } from "@mui/material";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { FC, MouseEvent, useState } from "react";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import PlateFrequencyDetailDialog from "pages/FlowDashboard/PlateFrequencyDetailDialog";
import VisualizationSwitcher, {
  ChartStyle
} from "components/VisualizationSwitcher";
import PageSection from "components/PageSection/PageSection";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

export type FrequencyPlateData = {
  name: string;
  total: number;
  plates: string[];
};

type PlateGraphByFrequencyProps = {
  title: string;
  isFetching: boolean;
  data: FrequencyPlateData[];
};

const colors = ["#F5634A"];

const PlateGraphByFrequency: FC<PlateGraphByFrequencyProps> = ({
  title,
  data,
  isFetching
}) => {
  const { t } = useTranslation();
  const [chartStyle, setChartStyle] = useState<ChartStyle>("bar");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBar, setSelectedBar] = useState<FrequencyPlateData>({
    name: "",
    total: 0,
    plates: []
  });

  const onChartStyleChange = (event: MouseEvent, value: ChartStyle) => {
    event.preventDefault();
    if (value) {
      setChartStyle(value);
    }
  };

  const handleBarClick = (event: CategoricalChartState) => {
    if (event?.activePayload) {
      setSelectedBar(event.activePayload[0].payload);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <PageSection
        title={title}
        variant="h5"
        actions={
          <VisualizationSwitcher
            chartStyle={chartStyle}
            onChartStyleChange={onChartStyleChange}
          />
        }
      >
        <Container>
          <Watermark>
            <Box sx={{ maxWidth: 900, margin: "0 auto" }}>
              {isFetching && <Skeleton variant="rectangular" height={350} />}
              {!isFetching && chartStyle === "bar" && (
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    onClick={handleBarClick}
                  >
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="total"
                      name={t("FlowDashboardPage.plateFrequencyHint")}
                      fill={colors[0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
              {!isFetching && chartStyle === "line" && (
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    onClick={handleBarClick}
                  >
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="total"
                      name={t("FlowDashboardPage.plateFrequencyHint")}
                      stroke={colors[0]}
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Watermark>
        </Container>
      </PageSection>
      <PlateFrequencyDetailDialog
        open={isModalOpen}
        setOpen={setIsModalOpen}
        data={selectedBar}
      />
    </>
  );
};

export default PlateGraphByFrequency;
