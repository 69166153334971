import { Box, Grid, styled, Typography, LinearProgress } from "@mui/material";
import Pages from "enums/Pages";
import Button from "components/Button";
import TabPanel from "components/TabPanel";
import { BookOpen, DownloadCloud } from "react-feather";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog";
import { usePageLocation } from "contexts/PageLocationContext";
import PaymentCardList from "pages/Settings/Billing/PaymentCardList";
import DataTableActions from "components/DataTableActions";

const mockInvoices = [
  {
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "opened"
  },
  {
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "pay"
  },
  {
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "pay"
  },
  {
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "late"
  }
];

const StatusIcon = styled("span")(({ theme }) => ({
  display: "inline-block",
  width: 6,
  height: 6,
  marginRight: theme.spacing(1),
  borderRadius: "100%",
  "&.pay": {
    backgroundColor: theme.palette.success.main
  },
  "&.late": {
    backgroundColor: theme.palette.error.main
  },
  "&.opened": {
    backgroundColor: theme.palette.secondary.main
  }
}));

const BoxSession = styled(Box)(({ theme }) => ({
  padding: "27px 24px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey["200"]}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3)
}));

const BillingPage: FC = () => {
  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();
  const [invoiceDetailsDialogOpen, setInvoiceDetailsDialogOpen] =
    useState(false);

  useEffect(() => {
    setPageTitle(t("windowTitle.billing"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.billing")
      },
      {
        label: t("BillingPage.title"),
        page: Pages.BILLING
      }
    ]);
  }, [t, Pages]);

  const [invoices, setInvoices] = useState(mockInvoices);

  const openInvoiceDetailsDialog = () => {
    setInvoiceDetailsDialogOpen(true);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2
          }}
        >
          <Typography variant="h4">{t("BillingPage.plan")}</Typography>
          <Button
            customProps={{
              color: "primary",
              variant: "text",
              disabled: true,
              size: "small"
            }}
          >
            {t("BillingPage.seeMorePlans")}
          </Button>
        </Box>
        <BoxSession>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="h5">
                {t("BillingPage.contractedPlan")}
              </Typography>
              <Typography sx={{ marginBottom: "24px" }} color="textSecondary">
                Profissional (Anual)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="h5">
                {t("BillingPage.dateRenewal")}
              </Typography>
              <Typography sx={{ marginBottom: "24px" }} color="textSecondary">
                Dezembro 2022
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">{t("BillingPage.cost")}</Typography>
              <Typography color="textSecondary">R$12,099</Typography>
            </Grid>
          </Grid>
        </BoxSession>
        <BoxSession>
          <Typography variant="h5" sx={{ mb: 5 }}>
            {t("BillingPage.usePlan")}
          </Typography>
          <LinearProgress
            sx={{ mb: 3 }}
            color="primary"
            value={25}
            variant="determinate"
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" color="textSecondary">
              {t("BillingPage.captures", { captures: "222,842" })}
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {t("BillingPage.expires", { expireDate: "Março de 2022" })}
            </Typography>
          </Box>
        </BoxSession>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography variant="h4">{t("BillingPage.payment")}</Typography>
        </Box>
        <Box>
          <TabPanel
            tabs={[
              {
                value: "invoices",
                label: t("BillingPage.invoices"),
                content: (
                  <DataTable
                    watermarked
                    headers={[
                      { key: "invoice", label: t("BillingPage.invoice") },
                      { key: "dueDate", label: t("BillingPage.dueDate") },
                      { key: "value", label: t("BillingPage.value") },
                      {
                        key: "status",
                        label: t("BillingPage.status"),
                        noSort: true
                      },
                      {
                        key: "actions",
                        label: t("BillingPage.actions"),
                        align: "right",
                        noSort: true
                      }
                    ]}
                    defaultSort={["invoice", "asc"]}
                    onHeaderSort={setInvoices}
                    data={invoices}
                    renderRow={row => [
                      <>{row.invoice}</>,
                      <>{row.dueDate}</>,
                      <>{row.value}</>,
                      <>
                        <StatusIcon className={row.status} />
                        {t(`BillingPage.${row.status}`)}
                      </>,
                      <>
                        <DataTableActions
                          actions={[
                            {
                              tooltip: t("action.download"),
                              icon: DownloadCloud,
                              onClick() {}
                            },
                            {
                              tooltip: t("action.open"),
                              icon: BookOpen,
                              onClick: openInvoiceDetailsDialog
                            }
                          ]}
                        />
                      </>
                    ]}
                    hideColumnsXs={[0, 2, 3]}
                    page={1}
                    onPageChange={_pageValue => {}}
                    pageSize={100}
                    onPageSizeChange={_itemsPerPage => {}}
                    totalPages={1}
                    totalItems={invoices.length}
                    isLoading={false}
                  />
                )
              },
              {
                value: "paymentDetails",
                label: t("BillingPage.paymentDetails"),
                content: <PaymentCardList />
              }
            ]}
          />
        </Box>
      </InnerPageLayout>
      <InvoiceDetailsDialog
        open={invoiceDetailsDialogOpen}
        setOpen={setInvoiceDetailsDialogOpen}
      />
    </DefaultPageLayout>
  );
};

export default BillingPage;
