import {
  Line,
  YAxis,
  XAxis,
  Legend,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { FC } from "react";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { FrequencyByDay } from "api/RestrictionDashboardAPI";
import { Box, styled, Skeleton } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

type TotalRestrictionDayGraphProps = {
  isFetching: boolean;
  data: FrequencyByDay[];
};

const TotalRestrictionDayGraph: FC<TotalRestrictionDayGraphProps> = ({
  data,
  isFetching
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Watermark>
        <Box sx={{ maxWidth: 900, margin: "0 auto" }}>
          {isFetching && <Skeleton variant="rectangular" height={350} />}
          {!isFetching && (
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  label={{
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                    offset: 1
                  }}
                  dataKey="weight"
                />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  strokeWidth={2}
                  fill="#4ACBAC"
                  dataKey="weight"
                  name={t("RestrictionDashboardPage.frequencyByDay") as string}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Watermark>
    </Container>
  );
};

export default TotalRestrictionDayGraph;
