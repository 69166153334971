import { Box, Tooltip } from "@mui/material";
import { Map } from "react-feather";
import Button from "components/Button";
import { Coords } from "google-map-react";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import useMonitoringAPI from "api/MonitoringAPI";
import CloneMap from "./CloneBIComponents/CloneMap";
import { dateTimeToString } from "utils/DateFunctions";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FormCloneDashboardFilter } from "./CloneDashboardFilter";
import CloneHistoryTable from "./CloneBIComponents/CloneHistoryTable";
import { useCloneDashboardFilter } from "./CloneDashboardFilterContext";
import useCloneDashboardAPI, { SuspectVehicle } from "api/CloneDashboardAPI";
import CloneImageViewerDialog from "./CloneBIComponents/CloneImageViewerDialog";
import { parsePlateCoordinate } from "components/ImageViewer";
import { CaptureImageDialog } from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

export type InfoCards = {
  label: string;
  value: number;
};

export const initialCloneImageDialogProps: CaptureImageDialog = {
  id: "",
  image: "",
  equipment: "",
  camera: "",
  dateTime: "",
  plate: "",
  city: "",
  state: "",
  model: "",
  color: "",
  make: ""
};

type Props = {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
};

const CloneBI: FC<Props> = ({ isLoading, setLoading }) => {
  const isMount = useIsMount();
  const {
    getFilterData,
    filterData,
    setTotalSuspectVehicles,
    setIsLoading: setIsTotalLoading
  } = useCloneDashboardFilter();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [page, setPage] = useState(1);
  const MonitoringAPI = useMonitoringAPI();
  const { errorHandler } = useErrorHandler();
  const [zoom, setZoom] = useState<number>(3);
  const [center, setCenter] = useState<Coords>({
    lat: -25.431040332176853,
    lng: -49.2434949242968
  });
  const CloneDashboardAPI = useCloneDashboardAPI();
  const [seeAll, setSeeAll] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);
  const [monitorings, setMonitorings] = useState<string[]>([]);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [suspectVehicles, setSuspectVehicles] = useState<SuspectVehicle[][]>(
    []
  );
  const [suspectVehiclesRow, setSuspectVehiclesRow] = useState<
    SuspectVehicle[][]
  >([]);
  const [isCloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);

  const [firstVehicleSelected, setFirstVehicleSelected] =
    useState<CaptureImageDialog>(initialCloneImageDialogProps);

  const [secondVehicleSelected, setSecondVehicleSelected] =
    useState<CaptureImageDialog>(initialCloneImageDialogProps);

  const requestData = useCallback(
    async (
      filter: FormCloneDashboardFilter,
      pageValue: number,
      pageSizeValue: number
    ) => {
      setLoading(true);
      setIsTotalLoading(true);
      if (!sessionUser?.["customer_id"]) return;
      try {
        const filterObject = {
          ["customer_id"]: sessionUser.customer_id,
          ["initial_date"]: dateTimeToString(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["final_date"]: dateTimeToString(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          plate: filter.plate === "" ? undefined : filter.plate,
          cameras: filter.cameras ?? undefined,
          equipments: filter.equipments ?? undefined,
          page: pageValue,
          ["page_size"]: pageSizeValue
        };
        setPage(pageValue);

        const suspectResponse =
          await CloneDashboardAPI.analyticsBiSuspectVehicles(filterObject);

        setTotalSuspectVehicles(Number(suspectResponse.total_items));
        setSuspectVehicles(suspectResponse.suspect_vehicles);

        const monitoringPlates: string[] = [];
        if (suspectResponse.suspect_vehicles.length > 0) {
          const monitoringResponse = await MonitoringAPI.listAll({
            customerId: sessionUser.customer_id
          });

          if (
            monitoringResponse.data.Items &&
            monitoringResponse.data.Items.length > 0
          ) {
            monitoringResponse.data.Items.forEach(item => {
              monitoringPlates.push(item.plate);
            });
          }
        }
        setMonitorings(monitoringPlates);

        setZoom(3);
        setSeeAll(true);
        setCenter({ lat: -25.431040332176853, lng: -49.2434949242968 });
        setSuspectVehiclesRow([]);

        setPaginator({
          totalPages: suspectResponse.total_pages || 0,
          totalItems: Number(suspectResponse.total_items) || 0
        });
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
        setIsTotalLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(getFilterData(), page, pageSize);
  }, [requestData, pageSize]);

  useEffect(() => {
    if (!isMount) {
      if (getFilterData() === null) {
        requestData(getFilterData(), page, pageSize);
      } else {
        requestData(getFilterData(), 1, pageSize);
      }
    }
  }, [filterData]);

  const openCloneDialog = (clone: SuspectVehicle[]) => {
    setFirstVehicleSelected({
      id: clone[0].id?.toString() ?? "",
      image: clone[0].images_path,
      equipment: clone[0].location_name ?? "",
      camera: clone[0].camera_name ?? "",
      dateTime: clone[0]["date_capture"] || "",
      plate: clone[0].plate || "",
      plateCoordinate: clone[0].plate_coordinate
        ? parsePlateCoordinate(clone[0].plate_coordinate)
        : undefined,
      city: clone[0].city ?? "",
      state: clone[0].state ?? "",
      model: clone[0].vehicle_model || "",
      color: clone[0].vehicle_color || "",
      make: clone[0].vehicle_make || "",
      longitude: clone[0].longitude.toString(),
      latitude: clone[0].latitude.toString()
    });
    setSecondVehicleSelected({
      id: clone[1].id?.toString() ?? "",
      image: clone[1].images_path,
      equipment: clone[1].location_name ?? "",
      camera: clone[1].camera_name ?? "",
      dateTime: clone[1]["date_capture"] || "",
      plate: clone[1].plate || "",
      plateCoordinate: clone[1].plate_coordinate
        ? parsePlateCoordinate(clone[1].plate_coordinate)
        : undefined,
      city: clone[1].city ?? "",
      state: clone[1].state ?? "",
      model: clone[1].vehicle_model || "",
      color: clone[1].vehicle_color || "",
      make: clone[1].vehicle_make || "",
      longitude: clone[1].longitude.toString(),
      latitude: clone[1].latitude.toString()
    });
    setCloneDialogOpen(true);
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <CloneMap
          center={center}
          zoom={zoom}
          isLoading={isLoading}
          suspectVehicles={seeAll ? suspectVehicles : suspectVehiclesRow}
        />
      </Box>
      <Box sx={{ mb: 1, textAlign: "end" }}>
        <Button
          customProps={{
            variant: "outlined",
            onClick() {
              setSeeAll(false);
              setZoom(3);
              setSuspectVehiclesRow(suspectVehicles);
              setCenter({ lat: -25.431040332176853, lng: -49.2434949242968 });
            }
          }}
        >
          <Tooltip title={t("CloneDashboardPage.seeAll")}>
            <Map size={16} />
          </Tooltip>
        </Button>
      </Box>
      <Box>
        <CloneHistoryTable
          isLoading={isLoading}
          monitoringPlates={monitorings}
          suspectVehicles={suspectVehicles}
          openCloneDialog={openCloneDialog}
          setMapContent={row => {
            if (row[0].latitude && row[0].longitude) {
              setSeeAll(false);
              setCenter({
                lat: Number(row[0].latitude),
                lng: Number(row[0].longitude)
              });
              setSuspectVehiclesRow([row]);
              setZoom(12);
            }
          }}
          page={page}
          paginator={paginator}
          onPageChange={pageValue =>
            requestData(getFilterData(), pageValue, pageSize)
          }
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </Box>
      <CloneImageViewerDialog
        open={isCloneDialogOpen}
        setOpen={setCloneDialogOpen}
        firstVehicle={firstVehicleSelected}
        secondVehicle={secondVehicleSelected}
      />
    </Box>
  );
};

export default CloneBI;
