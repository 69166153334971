import {
  Grid,
  TableRow,
  Skeleton,
  TableBody,
  TableCell,
  TableHead
} from "@mui/material";
import { FC } from "react";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { Table, TableContainer } from "components/Table";
import { VehicleFrequency } from "api/RestrictionDashboardAPI";
import { EMPTY_VALUE } from "utils/String";

type MostMonitoredReasonsProps = {
  isFetching: boolean;
  data: VehicleFrequency[];
};

const RestrictionVehicleFrequencyTable: FC<MostMonitoredReasonsProps> = ({
  isFetching,
  data
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container columnSpacing={2} data-testid="data-table-row">
        <Grid item xs={12}>
          <Watermark>
            <TableContainer sx={{ maxHeight: 320 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="h0" component="th">
                      #
                    </TableCell>
                    <TableCell key="h1" component="th">
                      {t("RestrictionDashboardPage.plate")}
                    </TableCell>
                    <TableCell key="h2" component="th">
                      {t("RestrictionDashboardPage.vehicleMake")}
                    </TableCell>
                    <TableCell key="h3" component="th">
                      {t("RestrictionDashboardPage.vehicleModel")}
                    </TableCell>
                    <TableCell key="h4" component="th">
                      {t("RestrictionDashboardPage.vehicleColor")}
                    </TableCell>
                    <TableCell key="h5" component="th">
                      {t("RestrictionDashboardPage.vehicleIncident")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching &&
                    [0, 1, 2, 3, 4].map(i => (
                      <TableRow key={i}>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                      </TableRow>
                    ))}
                  {!isFetching &&
                    data &&
                    data.map((row, idx) => (
                      <TableRow key={`r0${idx}`}>
                        <TableCell component="td">{idx + 1}</TableCell>
                        <TableCell component="td">
                          {row.plate || EMPTY_VALUE}
                        </TableCell>
                        <TableCell component="td">
                          {row.vehicle_make || EMPTY_VALUE}
                        </TableCell>
                        <TableCell component="td">
                          {row.vehicle_model || EMPTY_VALUE}
                        </TableCell>
                        <TableCell component="td">
                          {row.vehicle_color || EMPTY_VALUE}
                        </TableCell>
                        <TableCell component="td">{row.weight}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Watermark>
        </Grid>
      </Grid>
    </>
  );
};

export default RestrictionVehicleFrequencyTable;
