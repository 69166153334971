import { Box, Typography } from "@mui/material";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useUserGroupAPI, {
  PaginatedUserGroups,
  UserGroup
} from "api/UserGroupAPI";
import UserGroupFormDialog from "pages/Settings/UserGroups/UserGroupFormDialog";
import DataList from "components/DataList";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const UserGroupsPage: FC = () => {
  const isMount = useIsMount();
  const UserGroupAPI = useUserGroupAPI();
  const { showModal } = useModal();
  const [isFetchingUserGroups, setFetchingUsers] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);

  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [userGroupsFormOpen, setUserGroupsFormOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setFetchingUsers(true);
      try {
        let response;
        if (searchValue) {
          response = await UserGroupAPI.search({
            customerId: sessionUser["customer_id"],
            name: searchValue,
            page: pageValue
          });
        } else {
          response = await UserGroupAPI.listAll({
            customerId: sessionUser["customer_id"],
            page: pageValue,
            pageSize: pageSizeValue
          });
        }
        const responsePaginated = response.data as PaginatedUserGroups;
        setUserGroups(responsePaginated.user_groups || []);
        setPaginator({
          totalPages: responsePaginated.total_pages || 0,
          totalItems: responsePaginated.total_count || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setFetchingUsers(false);
      }
    },
    [sessionUser]
  );

  const openUserGroupFormDialog = (name = "") => {
    setSelectedGroup(name);
    setUserGroupsFormOpen(true);
  };

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, page, pageSize);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const handleRemoveUserGroup = (name: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeUserGroup(name),
      confirmText: t("action.remove"),
      title: t("EditUserGroupsPage.removeConfirmTitle", { name }),
      description: t("EditUserGroupsPage.removeConfirmDescription")
    });
  };

  const removeUserGroup = async (name: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      await UserGroupAPI.deleteByName({
        customerId: sessionUser["customer_id"],
        name
      });
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const { t } = useTranslation();
  const history = useHistory();

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.userGroups"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.accessControl")
      },
      {
        label: t("UserGroupsPage.title"),
        page: Pages.USER_GROUPS
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("UserGroupsPage.title")}
          isLoading={isFetchingUserGroups}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("EditUserGroupsPage.researchGroup")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openUserGroupFormDialog("")}
            >
              {t("EditUserGroupsPage.newGroup")}
            </PageSectionHeaderAction>
          }
        >
          <DataList
            watermarked
            data={userGroups}
            renderName={row => (
              <Box
                onClick={() =>
                  history.push(`${Pages.USER_GROUPS}/${row["group_name"]}`)
                }
              >
                <Typography variant="subtitle1">
                  <strong>{row["group_name"]}</strong>
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {`${row["group_users"].length} ${t(
                    "EditUserGroupPage.users"
                  )} - ${
                    row["locations"] === undefined ? 0 : row["locations"].length
                  } ${t("EditUserGroupPage.equipments")}
                  `}
                </Typography>
              </Box>
            )}
            actions={row => [
              {
                tooltip: t("action.edit"),
                icon: Edit,
                onClick: () => openUserGroupFormDialog(row["group_name"])
              },
              {
                tooltip: t("action.remove"),
                icon: MinusCircle,
                onClick: () => handleRemoveUserGroup(row["group_name"])
              }
            ]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isFetchingUserGroups}
          />
        </PageSection>
      </InnerPageLayout>
      <UserGroupFormDialog
        name={selectedGroup}
        open={userGroupsFormOpen}
        setOpen={setUserGroupsFormOpen}
        updateCallerContent={() => requestData(search, page, pageSize)}
      />
    </DefaultPageLayout>
  );
};

export default UserGroupsPage;
