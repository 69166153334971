import { SchedulerDate } from "api/MonitoringAPI";
import { format } from "date-fns";

export const fomatDateToTestCase = (date: Date): string =>
  format(date, "MM/dd/yyyy");
export const fomatTimeToTestCase = (date: Date): string =>
  format(date, "HH:mm");
export const fomatHour = (date: Date): string => format(date, "HH:mm:ss");

export const msToSeconds = (ms: string): string =>
  (parseFloat(ms) / 1000).toFixed(3);

export const dateToString = (
  date: Date | string,
  fmt = "yyyy-MM-dd HH:mm:ss"
): string => {
  const dateTime = typeof date === "string" ? stringToDate(date) : date;
  return format(dateTime, fmt);
};
export const dateTimeToString = (
  date: Date,
  time: Date | null = null
): string => {
  const dateTime = typeof date === "string" ? stringToDate(date) : date;
  if (time) {
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    dateTime.setSeconds(time.getSeconds());
  }
  return format(dateTime, "yyyy-MM-dd HH:mm:ss");
};

export const stringToDate = (dateString: string): Date => {
  const dateWithoutTimezone = dateString
    .replace("T", " ")
    .replace(/\.\d{3}Z$/, "");
  return new Date(dateWithoutTimezone);
};

export const formatSchedulerDateTime = (
  schedulerDates: SchedulerDate[]
): SchedulerDate[] => {
  const newData = schedulerDates;

  newData.forEach((scheduler, indexS) => {
    scheduler.hours_intervals.forEach((hour, indexH) => {
      const timezoneHour = {
        ["start_hour"]:
          typeof hour.start_hour === "string"
            ? hour.start_hour
            : format(hour.start_hour as Date, "HH:mm"),
        ["end_hour"]:
          typeof hour.end_hour === "string"
            ? hour.end_hour
            : format(hour.end_hour as Date, "HH:mm")
      };
      if (newData) newData[indexS].hours_intervals[indexH] = timezoneHour;
    });
  });

  return newData;
};
