import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "./API";
import useAxios from "hooks/useAxios";
import { User } from "./UserAPI";

export type UserGroup = {
  ["group_name"]: string;
  ["group_description"]?: string;
  ["group_users"]: string[];
  ["customer_id"]: string;
  ["locations"]: string[];
};

export type PaginatedUserGroups = {
  ["page"]: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["user_groups"]: UserGroup[];
  ["users"]: User[];
};

type ListAllParams = {
  customerId: string;
  page: number;
  pageSize?: number;
};

type ListGroupUsersParams = {
  customerId: string;
  userGroup: string;
  exist: boolean;
  page: number;
  pageSize: number;
};

type SearchParams = {
  customerId: string;
  name: string;
  page?: number;
};

type GetByNameParams = {
  customerId: string;
  groupName: string;
};

type CreateParams = {
  customerId: string;
  name: string;
  description?: string;
  users: string[];
};

type UpdateParams = {
  customerId: string;
  data: {
    // Form data
    name: string;
    description?: string;
    users: string[];
    locations?: string[];
  };
  userGroup: {
    // Values loaded previously from get request
    name: string;
    description?: string;
    users: string[];
    locations?: string[];
  };
};

type DeleteByNameParams = {
  customerId: string;
  name: string;
};

type UseUserGroupAPIType = {
  listAll: (
    param: ListAllParams
  ) => Promise<NestedData<PaginatedUserGroups | UserGroup[]>>;
  search: (
    param: SearchParams
  ) => Promise<NestedData<PaginatedUserGroups | UserGroup[]>>;
  getByName: (param: GetByNameParams) => Promise<UserGroup>;
  create: (data: CreateParams) => Promise<Success>;
  update: (param: UpdateParams) => Promise<Success>;
  deleteByName: (param: DeleteByNameParams) => Promise<Success>;
  listGroupUsers: (
    param: ListGroupUsersParams
  ) => Promise<NestedData<PaginatedUserGroups | UserGroup[]>>;
};

const useUserGroupAPI = (): UseUserGroupAPIType => {
  const API = useAxios();

  const listAll = async ({
    customerId,
    page = -1,
    pageSize
  }: ListAllParams): Promise<NestedData<PaginatedUserGroups | UserGroup[]>> => {
    const response: AxiosResponse<
      NestedData<PaginatedUserGroups | UserGroup[]>
    > = await API.instance.post(APIEndpoints.USER_GROUP_LIST, {
      ["customer_id"]: customerId,
      page,
      ["page_size"]: pageSize
    });
    return response.data;
  };

  const listGroupUsers = async ({
    customerId,
    userGroup,
    exist,
    page,
    pageSize
  }: ListGroupUsersParams): Promise<
    NestedData<PaginatedUserGroups | UserGroup[]>
  > => {
    const response: AxiosResponse<
      NestedData<PaginatedUserGroups | UserGroup[]>
    > = await API.instance.post(APIEndpoints.USER_LIST_GROUP, {
      ["customer_id"]: customerId,
      ["user_group"]: userGroup,
      exist,
      page,
      ["page_size"]: pageSize
    });
    return response.data;
  };

  const search = async ({
    customerId,
    name,
    page = -1
  }: SearchParams): Promise<NestedData<PaginatedUserGroups | UserGroup[]>> => {
    const response: AxiosResponse<
      NestedData<PaginatedUserGroups | UserGroup[]>
    > = await API.instance.get(APIEndpoints.USER_GROUP_PARTIAL, {
      params: {
        ["customer_id"]: customerId,
        name,
        page
      }
    });
    return response.data;
  };

  const getByName = async ({
    customerId,
    groupName
  }: GetByNameParams): Promise<UserGroup> => {
    const response: AxiosResponse<UserGroup> = await API.instance.get(
      APIEndpoints.USER_GROUP,
      { params: { ["customer_id"]: customerId, ["group_name"]: groupName } }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_GROUP_CREATE,
      {
        ["customer_id"]: data.customerId,
        ["group_name"]: data.name,
        ["group_description"]: data.description,
        users: data.users
      }
    );
    return response.data;
  };

  const update = async ({
    customerId,
    data,
    userGroup
  }: UpdateParams): Promise<Success> => {
    const isChangingName = data.name !== userGroup.name;

    // Change name and/or description
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_GROUP_UPDATE,
      {
        ["customer_id"]: customerId,
        ["old_group_name"]: isChangingName ? userGroup.name : undefined,
        ["new_group_name"]: isChangingName ? data.name : undefined,
        ["group_name"]: isChangingName ? undefined : data.name,
        ["group_description"]: data.description
      }
    );

    const locationsToUpdate =
      data.locations === userGroup.locations ? undefined : data.locations;
    const usersToUpdate =
      data.users === userGroup.users ? undefined : data.users;

    if (locationsToUpdate || usersToUpdate) {
      await API.instance.post(APIEndpoints.USER_GROUP_UPDATE, {
        ["customer_id"]: customerId,
        ["group_name"]: isChangingName ? undefined : data.name,
        locations: locationsToUpdate,
        users: usersToUpdate
      });
    }
    return response.data;
  };

  const deleteByName = async ({
    customerId,
    name
  }: DeleteByNameParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_GROUP_DELETE,
      {
        ["customer_id"]: customerId,
        ["group_name"]: name
      }
    );
    return response.data;
  };

  return {
    create,
    update,
    search,
    listAll,
    getByName,
    deleteByName,
    listGroupUsers
  };
};

export default useUserGroupAPI;
