import { FC, useState, useRef, ChangeEvent, KeyboardEvent } from "react";
import { InputBase, styled, Box } from "@mui/material";

const Input = styled(InputBase)(({ theme }) => ({
  input: {
    color: theme.palette.primary.light,
    textAlign: "center"
  },
  fontSize: "42px",
  padding: "10px",
  width: "50px",
  height: "70px",
  border: `1px solid ${theme.palette.grey["300"]}`,
  borderRadius: "10px"
}));

type InputCodeProps = {
  length: number;
  onComplete: (code: string) => void;
};

const InputCode: FC<InputCodeProps> = ({ length, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef<HTMLInputElement[]>([]);

  const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;
    if (/\D/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every(n => n !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.key === "Backspace" && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      {code.map((num, idx) => (
        <Input
          key={idx}
          type="text"
          inputMode="numeric"
          inputProps={{
            maxLength: 1
          }}
          value={num}
          autoFocus={!code[0].length && idx === 0}
          onChange={(e: ChangeEvent<HTMLInputElement>) => processInput(e, idx)}
          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyUp(e, idx)}
          inputRef={ref => inputs.current.push(ref)}
        />
      ))}
    </Box>
  );
};

export default InputCode;
