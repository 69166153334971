import Button from "components/Button";
import { Grid } from "@mui/material";
import TabPanel from "components/TabPanel";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import InputNumber from "components/InputNumber";
import useIntegrationsAPI from "api/IntegrationsAPI";
import { Controller, useForm } from "react-hook-form";
import { validateInteger } from "utils/FormValidation";
import PageSectionCard from "components/PageSection/PageSectionCard";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import TelegramIntegrationTable from "pages/Settings/Integrations/TelegramIntegrationTable";
import VmsIntegrationTable from "pages/Settings/Integrations/VmsIntegration/VmsIntegrationTable";
import PageSection from "components/PageSection/PageSection";

type TelegramGroup = {
  enabled: boolean;
  index?: string;
  name: string;
  chatId: string;
};

type SystemIntegrationsForm = {
  detectaSp: {
    url: string;
    connectionTimeout: number;
    readTimeout: number;
    reconnectionAttempts: number;
  };
  spiaPrf: {
    url: string;
    connectionTimeout: number;
    readTimeout: number;
    reconnectionAttempts: number;
  };
  telegram: {
    groups: {
      createList: TelegramGroup[];
      updateList: TelegramGroup[];
      deleteList: TelegramGroup[];
    };
  };
};

const defaultValues: SystemIntegrationsForm = {
  detectaSp: {
    url: "",
    connectionTimeout: 3000,
    readTimeout: 3000,
    reconnectionAttempts: 3
  },
  spiaPrf: {
    url: "",
    connectionTimeout: 3000,
    readTimeout: 3000,
    reconnectionAttempts: 3
  },
  telegram: {
    groups: {
      createList: [],
      updateList: [],
      deleteList: []
    }
  }
};

const groupToGroupDb = (group: TelegramGroup) => ({
  enabled: group.enabled,
  index: group.index,
  name: group.name,
  ["chat_id"]: group.chatId
});

const SystemIntegrations: FC = () => {
  const { control, formState, handleSubmit, reset, watch, setValue } =
    useForm<SystemIntegrationsForm>({ defaultValues, mode: "onChange" });

  const IntegrationsAPI = useIntegrationsAPI();
  const { sessionUser } = useAuth();
  const [isFetchingData, setFetchingData] = useState(false);
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setFetchingData(true);
    try {
      const responses = await Promise.all([
        //IntegrationsAPI.getDetectaSpIntegration(),
        //IntegrationsAPI.getSpiaPrfIntegration(),
        IntegrationsAPI.listTelegramCustomerIntegration(sessionUser.customer_id)
      ]);
      if (responses) {
        const [telegramData] = responses;
        reset({
          /*detectaSp: {
            url: detectaSpData.url,
            connectionTimeout: detectaSpData["connection_timeout"],
            readTimeout: detectaSpData["read_timeout"],
            reconnectionAttempts: detectaSpData["number_retries"]
          },
          spiaPrf: {
            url: spiaPrfData.url,
            connectionTimeout: spiaPrfData["connection_timeout"],
            readTimeout: spiaPrfData["read_timeout"],
            reconnectionAttempts: spiaPrfData["number_retries"]
          },*/
          telegram: {
            groups: {
              createList: [],
              updateList: telegramData.data.map(group => ({
                enabled: group.enabled,
                index: group.index,
                name: group.name,
                chatId: group["chat_id"]
              })),
              deleteList: []
            }
          }
        });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setFetchingData(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const [isSaving, setSaving] = useState(false);

  const telegramGroups = watch().telegram.groups;

  const { t } = useTranslation();

  const onSubmit = async (data: SystemIntegrationsForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setSaving(true);
    try {
      const { groups } = data.telegram;
      const responses = await Promise.all([
        /*IntegrationsAPI.updateDetectaSpIntegration({
          data: {
            url: data.detectaSp.url,
            ["connection_timeout"]: data.detectaSp.connectionTimeout,
            ["read_timeout"]: data.detectaSp.readTimeout,
            ["number_retries"]: data.detectaSp.reconnectionAttempts
          }
        }),
        IntegrationsAPI.updateSpiaPrfIntegration({
          data: {
            url: data.spiaPrf.url,
            ["connection_timeout"]: data.spiaPrf.connectionTimeout,
            ["read_timeout"]: data.spiaPrf.readTimeout,
            ["number_retries"]: data.spiaPrf.reconnectionAttempts
          }
        }),*/
        IntegrationsAPI.saveTelegramCustomerIntegration({
          customerId: sessionUser.customer_id,
          data: {
            createList: groups.createList.map(groupToGroupDb),
            updateList: groups.updateList.map(groupToGroupDb),
            deleteList: groups.deleteList.map(groupToGroupDb)
          }
        })
      ]);
      if (!responses) return;
      const [telegramResponse] = responses;

      if (telegramResponse?.[0]?.success) {
        /*
          Successful response from create request means items were created,
          so handle their returned index (index is their id)
        */

        const indexes = telegramResponse[0].index;

        setValue("telegram.groups", {
          createList: [],
          updateList: [
            ...telegramGroups.updateList,
            ...telegramGroups.createList.map((group, i) => ({
              ...group,
              index: indexes[i]
            }))
          ],
          deleteList: []
        });
      }
      snackNotification.success(t("IntegrationsPage.integrationsSaved"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSaving(false);
    }
  };

  const isSaveDisabled = !formState.isValid || isFetchingData || isSaving;

  return (
    <PageSection title={t("IntegrationsPage.title")} isLoading={isFetchingData}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanel
          tabs={[
            {
              value: "detectaSp",
              label: t("IntegrationsPage.detectaSp.label"),
              content: (
                <PageSectionCard>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name="detectaSp.url"
                        control={control}
                        rules={{ required: t("form.requiredField").toString() }}
                        render={({ field, fieldState }) => (
                          <InputField
                            label={t("IntegrationsPage.detectaSp.url")}
                            customProps={{
                              autoFocus: true,
                              disabled: isFetchingData,
                              required: true
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="detectaSp.connectionTimeout"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 60000,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={60000}
                            label={t(
                              "IntegrationsPage.detectaSp.connectionTimeout"
                            )}
                            helperText="0 - 60000"
                            customProps={{
                              disabled: isFetchingData,
                              required: true,
                              endAdornment: (
                                <div style={{ paddingRight: "0.5rem" }}>ms</div>
                              )
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="detectaSp.readTimeout"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 60000,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={60000}
                            label={t("IntegrationsPage.detectaSp.readTimeout")}
                            helperText="0 - 60000"
                            customProps={{
                              disabled: isFetchingData,
                              required: true,
                              endAdornment: (
                                <div style={{ paddingRight: "0.5rem" }}>ms</div>
                              )
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="detectaSp.reconnectionAttempts"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 10,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={10}
                            label={t(
                              "IntegrationsPage.detectaSp.reconnectionAttempts"
                            )}
                            helperText="0 - 10"
                            customProps={{
                              disabled: isFetchingData,
                              required: true
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </PageSectionCard>
              )
            },
            {
              value: "spiaPrf",
              label: t("IntegrationsPage.spiaPrf.label"),
              content: (
                <PageSectionCard>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name="spiaPrf.url"
                        control={control}
                        rules={{ required: t("form.requiredField").toString() }}
                        render={({ field, fieldState }) => (
                          <InputField
                            label={t("IntegrationsPage.spiaPrf.url")}
                            customProps={{
                              autoFocus: true,
                              disabled: isFetchingData,
                              required: true
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="spiaPrf.connectionTimeout"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 60000,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={60000}
                            label={t(
                              "IntegrationsPage.spiaPrf.connectionTimeout"
                            )}
                            helperText="0 - 60000"
                            customProps={{
                              disabled: isFetchingData,
                              required: true,
                              endAdornment: (
                                <div style={{ paddingRight: "0.5rem" }}>ms</div>
                              )
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="spiaPrf.readTimeout"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 60000,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={60000}
                            label={t("IntegrationsPage.spiaPrf.readTimeout")}
                            helperText="0 - 60000"
                            customProps={{
                              disabled: isFetchingData,
                              required: true,
                              endAdornment: (
                                <div style={{ paddingRight: "0.5rem" }}>ms</div>
                              )
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} minWidth="206px">
                      <Controller
                        name="spiaPrf.reconnectionAttempts"
                        control={control}
                        rules={{
                          required: t("form.requiredField").toString(),
                          min: {
                            value: 0,
                            message: t("form.valueOutsideRange")
                          },
                          max: {
                            value: 10,
                            message: t("form.valueOutsideRange")
                          },
                          validate: value =>
                            validateInteger(
                              value,
                              t("form.invalidFormat").toString()
                            )
                        }}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            max={10}
                            label={t(
                              "IntegrationsPage.spiaPrf.reconnectionAttempts"
                            )}
                            helperText="0 - 10"
                            customProps={{
                              disabled: isFetchingData,
                              required: true
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </PageSectionCard>
              )
            },
            {
              value: "telegram",
              label: t("IntegrationsPage.telegram.label"),
              content: (
                <PageSectionCard>
                  <Controller
                    name="telegram.groups"
                    control={control}
                    render={({ field }) => (
                      <TelegramIntegrationTable
                        field={{ ...field }}
                        isLoading={isFetchingData}
                      />
                    )}
                  />
                </PageSectionCard>
              )
            },
            {
              value: "vms",
              label: t("IntegrationsPage.vms.label"),
              content: (
                <PageSectionCard>
                  <VmsIntegrationTable />
                </PageSectionCard>
              )
            }
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            customProps={{
              disabled: isSaveDisabled,
              color: "secondary",
              type: "submit"
            }}
          >
            {t("IntegrationsPage.save")}
          </Button>
        </div>
      </form>
    </PageSection>
  );
};

export default SystemIntegrations;
