import { ThemeOptions } from "@mui/material";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface Theme {
    extraColors?: {
      backgroundLight?: CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    extraColors?: {
      backgroundLight?: CSSProperties["color"];
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    sideBar?: Palette["primary"];
  }

  interface PaletteOptions {
    sideBar?: PaletteOptions["primary"];
  }
}

export const primaryDefaultColors = {
  main: "#323E54",
  dark: "#0E223F",
  light: "#768299",
  contrastText: "#ffffff"
};

export const secondaryDefaultColors = {
  main: "#0B2BFC",
  dark: "#0000B5",
  light: "#E5EEF8"
};

export const monitoringVehiclesColors = {
  seizure: "#C98500",
  rule: "#0272E2",
  documentation: "#119F0C",
  steal: "#D12502",
  intelligence: "#DA02AA",
  total: "#0E223F"
};

export const baseTheme: ThemeOptions = {
  extraColors: {
    backgroundLight: "#f7f9fb"
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    h1: {
      fontSize: "1.8rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: 700
    },
    h3: {
      fontSize: "1.6rem",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.4rem",
      fontWeight: 700
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 500,
      marginBottom: 7
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400
    },
    body2: {
      fontSize: "18px",
      fontWeight: 400
    },
    overline: {
      fontSize: "20px",
      fontWeight: 400,
      textTransform: "none"
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400
    },
    subtitle1: {
      fontSize: "14px"
    },
    button: {
      textTransform: "none",
      fontWeight: 500
    }
  },
  palette: {
    sideBar: primaryDefaultColors,
    primary: primaryDefaultColors,
    secondary: secondaryDefaultColors,
    text: {
      primary: "#2B2E32",
      secondary: "#6b6d70",
      disabled: "#959799"
    },
    success: {
      main: "#2E7D32",
      light: "#4CAF50",
      dark: "#1B5E20"
    },
    warning: {
      main: "#ED6C02",
      light: "#FF9800",
      dark: "#E65100"
    },
    error: {
      main: "#D32F2F",
      light: "#EF5350",
      dark: "#B52424"
    },
    background: {
      default: "#FFFFFF"
    },
    grey: {}
  },
  components: {
    MuiSelect: {
      defaultProps: {
        size: "small"
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "secondary"
      },
      styleOverrides: {
        root: {
          lineHeight: "1.1rem",
          "&.MuiButton-outlined": {
            background: primaryDefaultColors.contrastText
          },
          "&.Mui-disabled": {
            background: "#F5F5F5",
            color: "#909294"
          },
          "&.MuiButton-sizeLarge": {
            height: "48px"
          },
          "&.MuiButton-sizeMedium": {
            height: "40px"
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          textDecoration: "none",
          fontWeight: 500,
          borderBottom: "1px solid"
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiDialogContent-root": {
            padding: "0 40px"
          },
          "&.alertDialog .MuiDialogContent-root": {
            padding: "35px 40px 24px 40px"
          },
          "&.alertDialog .MuiDialogActions-root": {
            padding: "16px 24px 24px"
          },
          "& .MuiPaper-root:not(.MuiDialog-paperFullScreen)": {
            borderRadius: "8px"
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "24px 40px",
            fontWeight: 700,
            fontSize: "20px"
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "&": {
            padding: "16px 40px"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          backgroundColor: "#fff",
          input: {
            height: "23px",
            padding: "0 8px"
          },
          fieldset: {
            display: "none"
          },
          border: "1px solid #e0e0e0",
          svg: {
            color: "#768299"
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          height: 8
        },
        bar1Determinate: {
          borderRadius: "100px"
        }
      }
    }
  }
};

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "#dfe0e0",
      disabled: "#959698"
    },
    background: {
      default: "#2B2E32"
    }
  }
};

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light"
  }
};
