import VisualizationSwitcher, {
  ChartStyle
} from "components/VisualizationSwitcher";
import { useTranslation } from "react-i18next";
import SelectField from "components/SelectField";
import { FC, MouseEvent, useState } from "react";
import { Box, MenuItem, styled } from "@mui/material";
import PageSection from "components/PageSection/PageSection";
import { MonitoringReason } from "api/MonitoredVehiclesDashboardAPI";
import MonitoredReasonsGraphByHour from "pages/MonitoredVehiclesDashboard/MonitoredReasonsGraphByHour";
import MonitoredReasonsGraphByDayOfWeek from "pages/MonitoredVehiclesDashboard/MonitoredReasonsGraphByDayOfWeek";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

type Props = {
  data: MonitoringReason;
  isFetching: boolean;
};

const MonitoredReasonsGraphs: FC<Props> = ({ data, isFetching }) => {
  const { t } = useTranslation();
  const [isByHour, setIsByHour] = useState<string>("hour");
  const [chartStyle, setChartStyle] = useState<ChartStyle>("bar");

  const onChartStyleChange = (event: MouseEvent, value: ChartStyle) => {
    event.preventDefault();
    if (value) {
      setChartStyle(value);
    }
  };

  return (
    <>
      <PageSection
        title={
          isByHour === "hour"
            ? t(
                "MonitoredVehiclesDashboardPage.monitoringReasonsByHoursOfTheDay"
              )
            : t(
                "MonitoredVehiclesDashboardPage.monitoringReasonsByDaysOfTheWeek"
              )
        }
        variant="h5"
        actions={
          <>
            <VisualizationSwitcher
              chartStyle={chartStyle}
              onChartStyleChange={onChartStyleChange}
            />
            <SelectField
              size="small"
              style={{
                marginBottom: 0
              }}
              value={isByHour}
              disabled={isFetching}
              onChange={event => {
                setIsByHour(event.target.value as string);
              }}
            >
              <MenuItem value="hour">
                {t("MonitoredVehiclesDashboardPage.byHour")}
              </MenuItem>
              <MenuItem value="day">
                {t("MonitoredVehiclesDashboardPage.byDay")}
              </MenuItem>
            </SelectField>
          </>
        }
      >
        <Container>
          {isByHour === "hour" ? (
            <MonitoredReasonsGraphByHour
              chartStyle={chartStyle}
              data={data.frequency_by_hour}
              isFetching={isFetching}
            />
          ) : (
            <MonitoredReasonsGraphByDayOfWeek
              chartStyle={chartStyle}
              data={data.frequency_by_weekday}
              isFetching={isFetching}
            />
          )}
        </Container>
      </PageSection>
    </>
  );
};

export default MonitoredReasonsGraphs;
