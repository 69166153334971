import { Grid } from "@mui/material";
import Pages from "enums/Pages";
import { format } from "date-fns";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import { MapPin, Video } from "react-feather";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import { SuspectVehicle } from "api/CloneDashboardAPI";
import DataTable from "components/DataTable";
import CaptureImgButton from "components/CaptureImgButton";
import MonitoringStatus from "components/MonitoringStatus";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import DataTableActions from "components/DataTableActions";

type SuspectVehicleRow = {
  a: SuspectVehicle;
  b: SuspectVehicle;
};

interface CloneHistoryTableProps {
  isLoading: boolean;
  monitoringPlates: string[];
  suspectVehicles?: SuspectVehicle[][];
  setMapContent: (clone: SuspectVehicle[]) => void;
  openCloneDialog: (clone: SuspectVehicle[]) => void;
  page?: number;
  paginator?: PaginatorModel;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (itemsPerPage: number) => void;
}

const CloneHistoryTable: FC<CloneHistoryTableProps> = ({
  isLoading,
  suspectVehicles = [],
  monitoringPlates,
  openCloneDialog,
  setMapContent,
  page = 1,
  paginator,
  onPageChange,
  pageSize = 10,
  onPageSizeChange
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [cloneResults, setCloneResults] = useState<SuspectVehicleRow[]>([]);

  useEffect(() => {
    setCloneResults(
      suspectVehicles.map(suspect => ({
        a: suspect[0],
        b: suspect[1]
      }))
    );
  }, [suspectVehicles]);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <DataTable
            watermarked
            headers={[
              {
                key: "a.plate",
                label: t("CloneDashboardPage.plate")
              },
              {
                key: "a.date_capture",
                label: t("CloneDashboardPage.captureDate")
              },
              {
                key: "a.location_name",
                label: t("CloneDashboardPage.capturePoint")
              },
              {
                key: "a.camera_name",
                label: t("CloneDashboardPage.cameraId")
              },
              {
                key: "status",
                label: t("CloneDashboardPage.status"),
                noSort: true
              },
              {
                key: "images_path",
                label: t("CloneDashboardPage.vehicles"),
                noSort: true
              },
              {
                key: "actions",
                label: t("CamerasPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["a.date_capture", "desc"]}
            onHeaderSort={setCloneResults}
            data={cloneResults}
            renderRow={row => [
              <>
                <LinkPlate
                  plate={row.a.plate || EMPTY_VALUE}
                  path={Pages.VEHICLE_OVERVIEW}
                />
              </>,
              <>
                <div>
                  {format(
                    new Date(row.a["date_capture"]),
                    "dd/MM/yyyy HH:mm:ss"
                  ) || EMPTY_VALUE}
                </div>
                <div>
                  {format(
                    new Date(row.b["date_capture"]),
                    "dd/MM/yyyy HH:mm:ss"
                  ) || EMPTY_VALUE}
                </div>
              </>,
              <>
                <div>{row.a["location_name"] || EMPTY_VALUE}</div>
                <div>{row.b["location_name"] || EMPTY_VALUE}</div>
              </>,
              <>
                <div>{row.a["camera_name"] || EMPTY_VALUE}</div>
                <div>{row.b["camera_name"] || EMPTY_VALUE}</div>
              </>,
              <>
                <MonitoringStatus
                  monitoringPlates={monitoringPlates}
                  plate={row.a.plate}
                />
              </>,
              <>
                <div style={{ display: "inline-flex", gap: "1em" }}>
                  <CaptureImgButton
                    image_link={row.a["images_path"] || ""}
                    onClick={() => openCloneDialog([row.a, row.b])}
                  />
                  <CaptureImgButton
                    image_link={row.b["images_path"] || ""}
                    onClick={() => openCloneDialog([row.a, row.b])}
                  />
                </div>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("CloneDashboardPage.focus"),
                      icon: MapPin,
                      onClick() {
                        setMapContent([row.a, row.b]);
                      }
                    },
                    {
                      tooltip: t("CloneDashboardPage.monitoring"),
                      icon: Video,
                      onClick() {
                        if (monitoringPlates.includes(row.a.plate || "")) {
                          history.push(
                            `${Pages.MONITORING_VEHICLES}/${row.a.plate}`
                          );
                        } else if (
                          !row.a["vehicle_make"] &&
                          !row.a["vehicle_model"] &&
                          !row.a["vehicle_color"]
                        ) {
                          history.push(
                            `${Pages.MONITORING_VEHICLES}/${row.a.plate}/${row.a["vehicle_class"]}`
                          );
                        } else {
                          history.push(
                            `${Pages.MONITORING_VEHICLES}/${row.a.plate}/${row.a["vehicle_class"]}/${row.a["vehicle_make"]}/${row.a["vehicle_model"]}/${row.a["vehicle_color"]}`
                          );
                        }
                      }
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[2, 3]}
            hideColumnsXs={[1, 2, 3, 4, 6]}
            page={page}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            totalPages={
              paginator?.totalPages ?? defaultPaginatorModelValues.totalPages
            }
            totalItems={
              paginator?.totalItems ?? defaultPaginatorModelValues.totalItems
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CloneHistoryTable;
