import { Grid, InputAdornment, MenuItem } from "@mui/material";
import { Camera } from "api/CameraAPI";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { Controller, useForm } from "react-hook-form";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FC, useEffect, useState } from "react";
import useMonitoredRouteAPI, { MonitoredRoute } from "api/MonitoredRouteAPI";
import SelectField from "components/SelectField";
import InputNumber from "components/InputNumber";
import FormDialog from "components/FormDialog";

export type MonitoredRouteForm = {
  routeName: string;
  initialCamera: string;
  finalCamera: string;
  distance: string;
  legalSpeed: string;
};

const defaultValues: MonitoredRouteForm = {
  routeName: "",
  initialCamera: "",
  finalCamera: "",
  distance: "",
  legalSpeed: ""
};

type Props = {
  cameras: Camera[];
  selectedMonitoredRoute: MonitoredRoute | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: () => void;
};

const MonitoredRoutesCreateFormDialog: FC<Props> = ({
  selectedMonitoredRoute,
  open,
  setOpen,
  onSave,
  cameras
}) => {
  const MonitoredRouteAPI = useMonitoredRouteAPI();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<MonitoredRouteForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  useEffect(() => {
    if (selectedMonitoredRoute) {
      reset({
        routeName: selectedMonitoredRoute.route_name,
        distance: `${selectedMonitoredRoute.distance}`,
        finalCamera: selectedMonitoredRoute.final_camera,
        initialCamera: selectedMonitoredRoute.initial_camera,
        legalSpeed: `${selectedMonitoredRoute.legal_speed}`
      });
    }
  }, [setValue, selectedMonitoredRoute]);

  const onSubmit = async (data: MonitoredRouteForm) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      if (!selectedMonitoredRoute) return;
      setLoading(true);
      await MonitoredRouteAPI.update({
        customerId: sessionUser.customer_id,
        routeName: data.routeName,
        oldRouteName:
          selectedMonitoredRoute.route_name === data.routeName
            ? undefined
            : selectedMonitoredRoute.route_name,
        initialCamera: data.initialCamera,
        oldInitialCamera:
          selectedMonitoredRoute.initial_camera === data.initialCamera
            ? undefined
            : selectedMonitoredRoute.initial_camera,
        finalCamera: data.finalCamera,
        oldFinalCamera:
          selectedMonitoredRoute.final_camera === data.finalCamera
            ? undefined
            : selectedMonitoredRoute.final_camera,
        distance: data.distance ? Number(data.distance) : undefined,
        legalSpeed: Number(data.legalSpeed)
      });
      snackNotification.success(t("CamerasPage.cameraUpdated"));
      onSave();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.confirm")}
      title={t("MonitoredRoutesPage.editRoute")}
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Controller
            name="routeName"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("MonitoredRoutesPage.routeName")}
                customProps={{
                  inputProps: {
                    maxLength: 100
                  },
                  placeholder: t("MonitoredRoutesPage.routeNameHint"),
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ padding: "0 !important" }} />
        <Grid item xs={6} sm={6} md={4}>
          <Controller
            name="initialCamera"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("MonitoredRoutesPage.initialCamera")}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
                disabled={isLoading}
              >
                <MenuItem value="">
                  {t("MonitoredRoutesPage.initialCameraHint")}
                </MenuItem>
                {cameras.map(camera => (
                  <MenuItem key={camera.camera_name} value={camera.camera_name}>
                    {camera.camera_name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Controller
            name="finalCamera"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("MonitoredRoutesPage.finalCamera")}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
                disabled={isLoading}
              >
                <MenuItem value="">
                  {t("MonitoredRoutesPage.finalCameraHint")}
                </MenuItem>
                {cameras.map(camera => (
                  <MenuItem key={camera.camera_name} value={camera.camera_name}>
                    {camera.camera_name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        <Grid item md={12} sm={12} sx={{ padding: "0 !important" }} />
        <Grid item xs={6} sm={5} md={4}>
          <Controller
            name="distance"
            control={control}
            render={({ field, fieldState }) => (
              <InputNumber
                label={t("MonitoredRoutesPage.distanceBetweenCameras")}
                customProps={{
                  disabled: isLoading,
                  endAdornment: (
                    <InputAdornment sx={{ marginRight: "10px" }} position="end">
                      m
                    </InputAdornment>
                  )
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={5} md={4}>
          <Controller
            name="legalSpeed"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputNumber
                label={t("MonitoredRoutesPage.routeSpeed")}
                customProps={{
                  disabled: isLoading,
                  required: true,
                  endAdornment: (
                    <InputAdornment sx={{ marginRight: "10px" }} position="end">
                      km/h
                    </InputAdornment>
                  )
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default MonitoredRoutesCreateFormDialog;
