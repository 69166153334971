import { FC } from "react";
import { styled, Button as MButton, ButtonProps } from "@mui/material";

type CustomButtonProps = {
  color?: string;
  customProps?: ButtonProps;
};

const Button: FC<CustomButtonProps> = ({ children, color, customProps }) => {
  const StyledButton = styled(MButton)(() => ({
    color,
    boxShadow: "none"
  }));

  return <StyledButton {...customProps}>{children}</StyledButton>;
};

export default Button;
