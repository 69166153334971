import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";

import enUS from "./languages/en-US.json";
import esES from "./languages/es-ES.json";
import ptBR from "./languages/pt-BR.json";

const defaultNS = "translations";

const instance = {
  async init(language: string, fallbackLanguage: string): Promise<void> {
    await i18n
      .use(intervalPlural)
      .use(initReactI18next)
      .init({
        resources: {
          "en-US": { [defaultNS]: enUS },
          "es-ES": { [defaultNS]: esES },
          "pt-BR": { [defaultNS]: ptBR }
        },
        lng: language,
        fallbackLng: fallbackLanguage,
        debug: false,
        ns: [defaultNS],
        defaultNS,
        keySeparator: ".",
        pluralSeparator: "_",
        interpolation: {
          escapeValue: false
        }
      });
  }
};

export default instance;
