import { FC, useEffect } from "react";
import { CLEAR_EDITOR_COMMAND } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

interface ClearPluginProps {
  isClear: boolean;
  isComment: boolean;
}

const ClearPlugin: FC<ClearPluginProps> = ({ isClear, isComment }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (isClear && isComment) {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor);

        if (htmlString !== "") {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
      });
    }
  }, [isClear]);

  return null;
};

export default ClearPlugin;
