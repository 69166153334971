import { FC, useState } from "react";
import { Clock } from "react-feather";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { GlobalStyles, SxProps, Theme, Typography } from "@mui/material";

type InputFieldProps = {
  label?: string;
  gutter?: boolean;
  disabled?: boolean;
  required?: boolean;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  sx?: SxProps<Theme>;
};

const TimePickerField: FC<InputFieldProps> = ({
  label,
  field,
  disabled,
  required
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const openPicker = () => {
    setPickerOpen(true);
  };

  const handleTimeChange = (newValue: Date | null) => {
    field.onChange(newValue);
  };

  return (
    <>
      <GlobalStyles
        styles={{
          ".MuiMultiSectionDigitalClock-root": {
            maxHeight: "900px !important",
            height: "510px !important"
          },
          ".MuiMultiSectionDigitalClockSection-root": {
            maxHeight: "900px !important"
          },
          ".MuiMultiSectionDigitalClockSection-root:after": {
            height: "0 !important"
          },
          ".MuiInputBase-inputAdornedStart": {
            paddingRight: "0 !important"
          }
        }}
      />
      <Typography sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
        {label || "\u00A0"}
      </Typography>
      <DesktopTimePicker
        open={pickerOpen}
        onOpen={openPicker}
        onClose={() => setPickerOpen(false)}
        disabled={disabled}
        value={field.value}
        onChange={handleTimeChange}
        timeSteps={{ hours: 1, minutes: 5 }}
        ampm={pickerOpen}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: <Clock />,
              endAdornment: null
            },
            onClick: openPicker,
            disabled,
            required,
            value: field.value,
            sx: {
              padding: "8px",
              "& .MuiOutlinedInput-root": {
                padding: "0"
              }
            }
          }
        }}
        sx={{
          marginTop: "10px",
          padding: "0"
        }}
        componentsProps={{
          actionBar: {
            actions: ["clear", "accept"]
          }
        }}
      />
    </>
  );
};

export default TimePickerField;
