import { FC, useState } from "react";
import InputField, { InputFieldProps } from "./InputField";
import { IconButton, InputAdornment, OutlinedInputProps } from "@mui/material";
import { Eye, EyeOff } from "react-feather";
import merge from "deepmerge";

const PasswordField: FC<InputFieldProps> = (props: InputFieldProps) => {
  const [typeInput, setTypeInput] = useState("password");

  const viewPasswordIcon = (
    <InputAdornment position="end">
      {typeInput === "password" ? (
        <IconButton onClick={() => setTypeInput("text")}>
          <Eye />
        </IconButton>
      ) : (
        <IconButton onClick={() => setTypeInput("password")}>
          <EyeOff />
        </IconButton>
      )}
    </InputAdornment>
  );

  return (
    <InputField
      {...props}
      customProps={
        merge(props.customProps as OutlinedInputProps, {
          type: typeInput,
          endAdornment: viewPasswordIcon
        }) as OutlinedInputProps
      }
    />
  );
};

export default PasswordField;
