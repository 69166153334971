import {
  $insertNodes,
  DROP_COMMAND,
  $getSelection,
  createCommand,
  $setSelection,
  LexicalEditor,
  LexicalCommand,
  DRAGOVER_COMMAND,
  $isNodeSelection,
  DRAGSTART_COMMAND,
  $isRootOrShadowRoot,
  $createParagraphNode,
  COMMAND_PRIORITY_LOW,
  $createRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_EDITOR
} from "lexical";
import {
  ImageNode,
  $isImageNode,
  ImagePayload,
  $createImageNode
} from "./ImageNode/ImageNode";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from "react";
import getSelection from "../../../utils/getDOMSelection";
import {
  RichTextEditorDialogActions,
  RichTextEditorDialogButtonsList
} from "../RichTextEditorDialog/RichTextEditorDialogActions";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import RichTextEditorButton from "../RichTextEditorButton/RichTextEditorButton";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import RichTextEditorTextInput from "../RichTextEditorTextInput/RichTextEditorTextInput";
import RichTextEditorFileInput from "../RichTextEditorFileInput/RichTextEditorFileInput";

export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand();

export const InsertImageUriDialogBody: FC<{
  onClick: (payload: InsertImagePayload) => void;
}> = ({ onClick }) => {
  const { t } = useTranslation();
  const [src, setSrc] = useState("");
  const [altText, setAltText] = useState("");

  const isDisabled = src === "";

  return (
    <>
      <RichTextEditorTextInput
        label={t("RichTextEditor.imageURL")}
        placeholder="i.e. https://source.unsplash.com/random"
        onChange={setSrc}
        value={src}
        data-test-id="image-modal-url-input"
      />
      <RichTextEditorTextInput
        label={t("RichTextEditor.imageAltText")}
        placeholder={t("RichTextEditor.imageAltTextHint")}
        onChange={setAltText}
        value={altText}
        data-test-id="image-modal-alt-text-input"
      />
      <RichTextEditorDialogActions>
        <RichTextEditorButton
          data-test-id="image-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText, src })}
        >
          {t("action.confirm")}
        </RichTextEditorButton>
      </RichTextEditorDialogActions>
    </>
  );
};

export const InsertImageUploadedDialogBody: FC<{
  onClick: (payload: InsertImagePayload) => void;
}> = ({ onClick }) => {
  const { t } = useTranslation();
  const [src, setSrc] = useState("");
  const [altText, setAltText] = useState("");

  const isDisabled = src === "";

  const loadImage = (files: FileList | null) => {
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === "string") {
        setSrc(reader.result);
      }
      return "";
    };
    if (files !== null) {
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <RichTextEditorFileInput
        label={t("RichTextEditor.imageFile")}
        onChange={loadImage}
        accept="image/*"
        data-test-id="image-modal-file-upload"
      />
      <RichTextEditorTextInput
        label={t("RichTextEditor.imageAltText")}
        placeholder={t("RichTextEditor.imageAltTextHint")}
        onChange={setAltText}
        value={altText}
        data-test-id="image-modal-alt-text-input"
      />
      <RichTextEditorDialogActions>
        <RichTextEditorButton
          data-test-id="image-modal-file-upload-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText, src })}
        >
          {t("action.confirm")}
        </RichTextEditorButton>
      </RichTextEditorDialogActions>
    </>
  );
};

export function InsertImageDialog({
  activeEditor,
  onClose
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [mode, setMode] = useState<null | "url" | "file">(null);
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const onClick = (payload: InsertImagePayload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <>
      {!mode && (
        <RichTextEditorDialogButtonsList>
          <RichTextEditorButton
            data-test-id="image-modal-option-url"
            onClick={() => setMode("url")}
          >
            {t("RichTextEditor.imageURL")}
          </RichTextEditorButton>
          <RichTextEditorButton
            data-test-id="image-modal-option-file"
            onClick={() => setMode("file")}
          >
            {t("RichTextEditor.imageFile")}
          </RichTextEditorButton>
        </RichTextEditorDialogButtonsList>
      )}
      {mode === "url" && <InsertImageUriDialogBody onClick={onClick} />}
      {mode === "file" && <InsertImageUploadedDialogBody onClick={onClick} />}
    </>
  );
}

export default function ImagesPlugin({
  captionsEnabled
}: {
  captionsEnabled?: boolean;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        payload => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<DragEvent>(
        DRAGSTART_COMMAND,
        event => onDragStart(event),
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand<DragEvent>(
        DRAGOVER_COMMAND,
        event => onDragover(event),
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<DragEvent>(
        DROP_COMMAND,
        event => onDrop(event, editor),
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}

const TRANSPARENT_IMAGE =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
const img = document.createElement("img");
img.src = TRANSPARENT_IMAGE;

function onDragStart(event: DragEvent): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const { dataTransfer } = event;
  if (!dataTransfer) {
    return false;
  }
  dataTransfer.setData("text/plain", "_");
  dataTransfer.setDragImage(img, 0, 0);
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        altText: node.__altText,
        caption: node.__caption,
        height: node.__height,
        key: node.getKey(),
        maxWidth: node.__maxWidth,
        showCaption: node.__showCaption,
        src: node.__src,
        width: node.__width
      },
      type: "image"
    })
  );

  return true;
}

function onDragover(event: DragEvent): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  if (!canDropImage(event)) {
    event.preventDefault();
  }
  return true;
}

function onDrop(event: DragEvent, editor: LexicalEditor): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const data = getDragImageData(event);
  if (!data) {
    return false;
  }
  event.preventDefault();
  if (canDropImage(event)) {
    const range = getDragSelection(event);
    node.remove();
    const rangeSelection = $createRangeSelection();
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range);
    }
    $setSelection(rangeSelection);
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
  }
  return true;
}

function getImageNodeInSelection(): ImageNode | null {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isImageNode(node) ? node : null;
}

function getDragImageData(event: DragEvent): null | InsertImagePayload {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag");
  if (!dragData) {
    return null;
  }
  const { type, data } = JSON.parse(dragData);
  if (type !== "image") {
    return null;
  }

  return data;
}

declare global {
  interface DragEvent {
    rangeOffset?: number;
    rangeParent?: Node;
  }
}

function canDropImage(event: DragEvent): boolean {
  const { target } = event;
  return Boolean(
    target &&
      target instanceof HTMLElement &&
      !target.closest("code, span.editor-image") &&
      target.parentElement &&
      target.parentElement.closest("div.ContentEditable__root")
  );
}

function getDragSelection(event: DragEvent): Range | null | undefined {
  let range;
  const domSelection = getSelection();
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error("Cannot get the selection when dragging");
  }

  return range;
}
