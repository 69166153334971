import { FC, useEffect } from "react";
import { useWebSocket } from "contexts/WebSocketContext";
import useRouteFlowManager, {
  getLatLngFromPosition,
  RouteDirections
} from "pages/FlowDashboard/RouteFlowMap/useRouteFlowManager";
import { RouteMarkerProps } from "pages/FlowDashboard/RouteFlowMap/RouteMarker";
import { speedColors } from "pages/FlowDashboard/RouteFlowMap/RouteFlowMapLegend";
import { RouteData } from "api/MonitoredRouteAPI";

const routeDataKey = "routesData";

type ParsedWebSocketRoutes = {
  key: string;
  data: RouteData[];
};

const getSpeedAveragePercentage = (averageSpeed: number, legalSpeed: number) =>
  Math.floor((averageSpeed / legalSpeed) * 100);

const getRouteColor = (averageSpeed: number, legalSpeed: number): string => {
  const percentage = getSpeedAveragePercentage(averageSpeed, legalSpeed);
  if (percentage <= 50) {
    return speedColors[4];
  }
  if (percentage > 50 && percentage <= 80) {
    return speedColors[3];
  }
  if (percentage > 80 && percentage <= 90) {
    return speedColors[2];
  }
  if (percentage > 90 && percentage <= 110) {
    return speedColors[1];
  }
  return speedColors[0];
};

const getRendererOptions = (
  route: RouteData,
  map: google.maps.Map
): google.maps.DirectionsRendererOptions => ({
  map,
  suppressMarkers: true,
  preserveViewport: true,
  polylineOptions: {
    strokeWeight: 12,
    strokeColor: getRouteColor(route.average_speed, route.legal_speed)
  }
});

type RouteFlowMarkersParams = {
  map: google.maps.Map | undefined;
  setMarkers: (markers: RouteMarkerProps[]) => void;
  setIsCalculateBounds: (calculate: boolean) => void;
};

const RouteFlowMarkers: FC<RouteFlowMarkersParams> = ({
  map,
  setMarkers,
  setIsCalculateBounds
}) => {
  const { lastMessage } = useWebSocket();
  const [isBusy, getInitialDirections, buildDirections, updateDirectionsState] =
    useRouteFlowManager();

  const renderRoutes = (routeDirections: RouteDirections) => {
    if (!map) return;
    const markersToAdd: RouteMarkerProps[] = [];
    for (const routeDirection of routeDirections) {
      if (routeDirection.renderer) {
        routeDirection.renderer.setMap(null);
        routeDirection.renderer = undefined;
      }
      routeDirection.renderer = new google.maps.DirectionsRenderer(
        getRendererOptions(routeDirection.route, map)
      );
      routeDirection.renderer.setDirections(routeDirection.direction);
      const latLng = getLatLngFromPosition(
        routeDirection.route.initial_position
      );

      markersToAdd.push({
        routeName: routeDirection.route.route_name,
        initialCamera: routeDirection.route.initial_camera,
        finalCamera: routeDirection.route.final_camera,
        speedAveragePercentage: getSpeedAveragePercentage(
          routeDirection.route.average_speed,
          routeDirection.route.legal_speed
        ),
        lat: latLng.lat(),
        lng: latLng.lng(),
        averageSpeed: routeDirection.route.average_speed.toString(),
        legalSpeed: routeDirection.route.legal_speed.toString()
      });
    }
    setMarkers(markersToAdd);
    updateDirectionsState(routeDirections);
  };

  useEffect(() => {
    if (!map) return;

    (async () => {
      const routeDirections = await getInitialDirections();
      if (routeDirections && routeDirections.length > 0) {
        renderRoutes(routeDirections);
        setIsCalculateBounds(true);
      }
    })();
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (!lastMessage?.data) return;
    if (isBusy) return;
    const parsedRouteMessage: ParsedWebSocketRoutes = JSON.parse(
      lastMessage.data
    );
    if (parsedRouteMessage.key !== routeDataKey) return;
    (async () => {
      const routeDirections = await buildDirections(parsedRouteMessage.data);
      if (routeDirections) {
        renderRoutes(routeDirections);
      }
    })();
  }, [lastMessage, map]);

  return <></>;
};
export default RouteFlowMarkers;
