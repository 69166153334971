import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent
} from "@mui/material";
import Pages from "enums/Pages";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { Controller, useForm } from "react-hook-form";
import InnerPageLayout from "layouts/InnerPageLayout";
import PageSectionCard from "components/PageSection/PageSectionCard";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { LoadingButton } from "@mui/lab";
import Cookies from "utils/Cookies";
import SelectField from "components/SelectField";
import useCustomerAPI, { Customer } from "api/CustomerAPI";
import useWatermarkAPI from "api/WatermarkAPI";
import PageSection from "components/PageSection/PageSection";
import FormLabel from "components/FormLabel";

const TextTypeValues = ["E-mail", "CNPJ + E-mail"] as const;

type InformationProtectionForm = {
  enabled: boolean;
};

const defaultValues: InformationProtectionForm = {
  enabled: true
};

const PUMATRONIX_ADM = "Pumatronix";

const InformationProtectionPage: FC = () => {
  const CustomerAPI = useCustomerAPI();
  const WatermarkAPI = useWatermarkAPI();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [textType, setTextType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState<boolean>(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>(
    sessionUser?.customer_id ?? ""
  );
  const { setPageTitle, setLocation } = usePageLocation();
  const { control, handleSubmit, setValue } =
    useForm<InformationProtectionForm>({
      defaultValues
    });

  const isPumatronixUser = sessionUser?.level_name === PUMATRONIX_ADM;

  useEffect(() => {
    (async () => {
      try {
        if (isPumatronixUser) {
          setIsLoadingCustomers(true);
          const customerResponse = await CustomerAPI.listAll(-1, 100);
          setCustomers((customerResponse.data as Customer[]) || []);
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoadingCustomers(false);
      }
    })();
  }, [isPumatronixUser]);

  useEffect(() => {
    setPageTitle(t("windowTitle.informationProtection"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.system")
      },
      {
        label: t("InformationProtectionPage.title"),
        page: Pages.INFORMATION_PROTECTION
      }
    ]);
  }, [t, Pages]);

  const requestData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await WatermarkAPI.watermarkData(selectedCustomerId);
      setValue("enabled", response.enabled);
      let responseTextType = "";
      if (response.email) {
        responseTextType = "E-mail";
      } else if (response.cnpj) {
        responseTextType = "CNPJ + E-mail";
      }
      setTextType(responseTextType);
    } catch (error) {
      setValue("enabled", false);
      setTextType("");
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const onSubmit = async (data: InformationProtectionForm) => {
    try {
      setIsSaving(true);
      const watermarkData = {
        enabled: data.enabled,
        email: textType === "E-mail",
        cnpj: textType === "CNPJ + E-mail"
      };
      const response = await WatermarkAPI.watermarkUpdate(
        selectedCustomerId,
        watermarkData
      );
      Cookies.set(Cookies.WATERMARK, watermarkData);
      if (response.success) {
        snackNotification.success(t("InformationProtectionPage.updateMsg"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("InformationProtectionPage.title")}
          isLoading={isLoading}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <PageSectionCard>
              <Grid container columnSpacing={2} rowSpacing={2}>
                {isPumatronixUser && (
                  <Grid item xs={12} md={6} sm={12}>
                    <SelectField
                      disabled={isLoadingCustomers}
                      sx={{ mb: 0 }}
                      onChange={e =>
                        setSelectedCustomerId(
                          (e as SelectChangeEvent).target.value
                        )
                      }
                      value={selectedCustomerId}
                    >
                      {customers.map((customer, idx) => (
                        <MenuItem key={idx} value={customer.customer_id}>
                          {customer.customer_name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                )}
                <Grid item sm={12}>
                  <Controller
                    name="enabled"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={isLoading || isSaving}
                            color="secondary"
                            onChange={field.onChange}
                            checked={Boolean(field.value)}
                          />
                        }
                        label={t(
                          "InformationProtectionPage.watermark"
                        ).toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormLabel>
                    {t("InformationProtectionPage.textType")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="gridSize"
                    name="row-radio-buttons-group"
                  >
                    {TextTypeValues.map(value => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={
                          <Radio
                            disabled={isLoading || isSaving}
                            color="secondary"
                            size="small"
                            checked={value === textType}
                            onChange={event => {
                              setTextType(event.target.value);
                            }}
                          />
                        }
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            </PageSectionCard>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                disabled={isLoading}
                loading={isSaving}
                color="secondary"
                variant="contained"
                type="submit"
              >
                {t("action.save")}
              </LoadingButton>
            </div>
          </form>
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};
export default InformationProtectionPage;
