import { styled } from "@mui/material";
import Pages from "enums/Pages";
import InvoiceFilter, {
  FormFilterInvoices
} from "pages/Settings/Invoice/InvoiceFilter";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import InvoiceFormDialog from "./InvoiceFormDialog";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { BookOpen, DownloadCloud, Plus, Sliders } from "react-feather";
import { usePageLocation } from "contexts/PageLocationContext";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const mockInvoices = [
  {
    customer: "Lorem 1",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "opened"
  },
  {
    customer: "Lorem 2",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "late"
  },
  {
    customer: "Lorem 3",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "pay"
  },
  {
    customer: "Lorem 4",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "pay"
  },
  {
    customer: "Lorem 5",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "late"
  },
  {
    customer: "Lorem 6",
    invoice: "Lorem ipsum",
    dueDate: "05/06/2022",
    value: "R$ 1138,52",
    status: "opened"
  },
  {
    customer: "Lorem 7",
    invoice: "Lorem ipsum",
    dueDate: "14/02/2022",
    value: "R$ 1448,52",
    status: "pay"
  },
  {
    customer: "Lorem 8",
    invoice: "Lorem ipsum",
    dueDate: "26/01/2022",
    value: "R$ 1208,52",
    status: "late"
  },
  {
    customer: "Lorem 9",
    invoice: "Lorem ipsum",
    dueDate: "05/06/2022",
    value: "R$ 1138,52",
    status: "opened"
  },
  {
    customer: "Lorem 10",
    invoice: "Lorem ipsum",
    dueDate: "14/02/2022",
    value: "R$ 1448,52",
    status: "pay"
  }
];

const StatusIcon = styled("span")(({ theme }) => ({
  display: "inline-block",
  width: 6,
  height: 6,
  marginRight: theme.spacing(1),
  borderRadius: "100%",
  "&.pay": {
    backgroundColor: theme.palette.success.main
  },
  "&.late": {
    backgroundColor: theme.palette.error.main
  },
  "&.opened": {
    backgroundColor: theme.palette.secondary.main
  }
}));

const InvoicePage: FC = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(false);
  const [filterData, setFilterData] = useState<FormFilterInvoices>();
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");

  const openInvoiceFormDialog = (customerId = "") => {
    setSelectedCustomer(customerId);
    setInvoiceFormOpen(true);
  };

  const editInvoice = (customer: string) => {
    openInvoiceFormDialog(customer);
  };

  const onFilter = (data: FormFilterInvoices) => {
    setFilterData(data);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.invoices"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.billing")
      },
      {
        label: t("InvoicePage.title"),
        page: Pages.INVOICE
      }
    ]);
  }, [t, Pages]);

  useEffect(() => {
    /* stub */
  }, [filterData]);

  const [invoices, setInvoices] = useState(mockInvoices);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("InvoicePage.title")}
          actions={
            <PageSectionHeaderAction
              variant="outlined"
              color="primary"
              startIcon={<Sliders />}
              onClick={() => setIsFilterOpen(true)}
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openInvoiceFormDialog()}
            >
              {t("InvoicePage.newInvoice")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "customer", label: t("InvoicePage.customer") },
              { key: "invoice", label: t("InvoicePage.invoice") },
              { key: "dueDate", label: t("InvoicePage.dueDate") },
              { key: "value", label: t("InvoicePage.value") },
              { key: "status", label: t("InvoicePage.status") },
              {
                key: "actions",
                label: t("InvoicePage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["invoice", "asc"]}
            onHeaderSort={setInvoices}
            data={invoices}
            renderRow={row => [
              <>{row.customer}</>,
              <>{row.invoice}</>,
              <>{row.dueDate}</>,
              <>{row.value}</>,
              <>
                <StatusIcon className={row.status} />
                {t(`BillingPage.${row.status}`)}
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.download"),
                      icon: DownloadCloud,
                      onClick() {}
                    },
                    {
                      tooltip: t("InvoicePage.open"),
                      icon: BookOpen,
                      onClick: () => editInvoice(row.customer)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsXs={[0, 1, 3, 4]}
            page={1}
            onPageChange={_pageValue => {}}
            pageSize={100}
            onPageSizeChange={_itemsPerPage => {}}
            totalPages={1}
            totalItems={invoices.length}
            isLoading={false}
          />
        </PageSection>
      </InnerPageLayout>
      <InvoiceFilter
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        onFilter={data => onFilter(data)}
      />
      <InvoiceFormDialog
        customerId={selectedCustomer}
        open={invoiceFormOpen}
        setOpen={setInvoiceFormOpen}
      />
    </DefaultPageLayout>
  );
};

export default InvoicePage;
