import {
  Box,
  Grid,
  Radio,
  styled,
  useTheme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { CreditCard } from "react-feather";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";

const CardRadio = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  border: `1px solid ${theme.palette.grey["200"]}`,
  display: "flex",
  alignItems: "center",
  "& .option": {
    flexGrow: 1
  },
  "&.active": {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main
  }
}));

type CardForm = {
  cpfCnpj: string;
  dueDate: string;
  cardName: string;
  cardNumber: string;
  securityCode: string;
};

const defaultValues: CardForm = {
  cpfCnpj: "",
  dueDate: "",
  cardName: "",
  cardNumber: "",
  securityCode: ""
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const BillingFormDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [cardType, setCardType] = useState<string>("");
  const [card, setCard] = useState<CardForm>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCNPJ, setIsCNPJ] = useState<boolean>(false);
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm<CardForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  useEffect(() => {
    reset(card);
  }, [setValue, card]);

  useEffect(() => {
    if (!open) {
      setCardType("");
      setCard(defaultValues);
      reset(defaultValues);
    }
  }, [open]);

  const onSubmit = async (_data: CardForm) => {
    setIsLoading(true);
    try {
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid || !cardType}
      confirmText={t("action.apply")}
      title={t("BillingPage.addPayment")}
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        {[
          t("BillingPage.debitCard") as string,
          t("BillingPage.creditCard") as string
        ].map(option => (
          <Grid
            item
            xs={6}
            key={option}
            sx={{
              mb: 2
            }}
          >
            <CardRadio
              className={option === cardType ? "active" : ""}
              sx={{
                padding: fullScreen
                  ? "16px 1px 16px 1px"
                  : "20px 16px 20px 16px",
                flexDirection: fullScreen ? "column" : "row"
              }}
            >
              <CreditCard />
              <div
                className="option"
                style={{ marginLeft: fullScreen ? "0px" : "16px" }}
              >
                <strong>{option}</strong>
              </div>
              <div>
                <Radio
                  color="secondary"
                  value={option}
                  onChange={e => setCardType(e.target.value)}
                  checked={option === cardType}
                />
              </div>
            </CardRadio>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" align="left" color="textPrimary">
              <strong>{t("BillingPage.cardInfo")}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Controller
            name="cardName"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("BillingPage.cardName")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="cpfCnpj"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                onChange={() => {
                  if (getValues("cpfCnpj").length > 10) {
                    setIsCNPJ(true);
                  } else {
                    setIsCNPJ(false);
                  }
                }}
                mask={isCNPJ ? "99.999.999/9999-99" : "999.999.999-99"}
                label={t("BillingPage.cpfCnpj")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("BillingPage.cardNumber")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="dueDate"
            control={control}
            render={({ field, fieldState }) => (
              <DatePickerField
                label={t("BillingPage.dueDate")}
                disabled={isLoading}
                gutter
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="securityCode"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("BillingPage.securityCode")}
                customProps={{
                  placeholder: t("BillingPage.securityCodeHint"),
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default BillingFormDialog;
