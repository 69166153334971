import {
  Bar,
  YAxis,
  XAxis,
  Legend,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { FC } from "react";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { FrequencyByClass } from "api/RestrictionDashboardAPI";
import { Box, styled, Skeleton } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

type TotalRestrictionClassGraphProps = {
  isFetching: boolean;
  data: FrequencyByClass[];
};

const TotalRestrictionClassGraph: FC<TotalRestrictionClassGraphProps> = ({
  data,
  isFetching
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Watermark>
        <Box sx={{ maxWidth: 900, margin: "0 auto" }}>
          {isFetching && <Skeleton variant="rectangular" height={350} />}
          {!isFetching && (
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={data}
                barSize={5}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vehicle_class" />
                <YAxis
                  label={{
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                    offset: 1
                  }}
                  dataKey="weight"
                />
                <Tooltip />
                <Bar
                  dataKey="weight"
                  name={
                    t("RestrictionDashboardPage.frequencyByClass") as string
                  }
                  fill="#4a9af5"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Watermark>
    </Container>
  );
};

export default TotalRestrictionClassGraph;
