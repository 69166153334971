import { Box, Grid, styled, Slider, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import Button from "components/Button";
import SelectField from "components/SelectField";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterInvoices = {
  status: string;
  dueDate: string;
  customer: string;
  invoiceValue: number;
  startDateTime: string;
};
const defaultValues: FormFilterInvoices = {
  status: "",
  dueDate: "",
  customer: "",
  invoiceValue: 0,
  startDateTime: ""
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterInvoices) => void;
};

const InvoiceFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const { t } = useTranslation();
  const [invoiceValue, setInvoiceValue] = useState<number>(
    defaultValues.invoiceValue
  );

  const { control, handleSubmit, reset } = useForm<FormFilterInvoices>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const clearForm = () => {
    reset();
  };

  const onSubmit = async (data: FormFilterInvoices) => {
    onFilter({ ...data, invoiceValue });
    setOpen(false);
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setInvoiceValue(newValue as number);
  };

  return (
    <Drawer open={open} setOpen={setOpen} title={t("CaptureReportPage.filter")}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="customer"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("InvoicePage.customer")}
                    gutter={false}
                    customProps={{
                      placeholder: t("InvoicePage.customer")
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("InvoicePage.invoiceValue")}: R${invoiceValue},00
              </FormLabel>
              <Slider
                min={10}
                max={15000}
                size="small"
                color="secondary"
                value={invoiceValue}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("InvoicePage.status")}</FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    onChange={event => {
                      field.onChange(event.target.value as string);
                    }}
                    style={{ marginBottom: 0 }}
                  >
                    <MenuItem value="opened">
                      {t("InvoicePage.opened")}
                    </MenuItem>
                    <MenuItem value="late">{t("InvoicePage.late")}</MenuItem>
                    <MenuItem value="pay">{t("InvoicePage.pay")}</MenuItem>
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="startDateTime"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    gutter
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("InvoicePage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    gutter
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("InvoicePage.dueDate")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default InvoiceFilter;
