import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

export type Period = "daily" | "monthly";

type ConvoysParams = {
  ["customer_id"]: string;
  ["initial_date"]: string | undefined;
  ["final_date"]: string | undefined;
  frequency: number | undefined;
  period: Period;
  plate: string | undefined;
  page: number;
  ["page_size"]: number;
};

export type ConvoyVehicles = {
  ["id_convoy"]: string;
  date?: string;
  ["convoy_count"]: string;
  customer: string;
  month?: string;
  year?: string;
};

type ConvoysResponse = {
  registers: {
    items: ConvoyVehicles[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type ConvoyLocation = {
  latitude: string;
  longitude: string;
};

type ConvoysMapResponse = {
  ["convoy_locations"]: ConvoyLocation[];
};

export type VehicleInfos = {
  id: string;
  ["date_capture"]: string;
  ["location_name"]: string;
  ["camera_name"]: string;
  ["plate_coordinate"]: string;
  ["vehicle_class"]: string;
  ["vehicle_color"]: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  plate: string;
  image: string;
};

export type ConvoyInfo = {
  ["vehicle_1"]: VehicleInfos;
  ["vehicle_2"]: VehicleInfos;
};

export type ConvoyInfoResponse = {
  response: { ["convoy_registers"]: ConvoyInfo[][] };
};

type UseConvoyDashboardAPIType = {
  requestConvoys: (filter: ConvoysParams) => Promise<ConvoysResponse>;
  requestMapConvoy: (
    customerId: string,
    initialDate: string,
    finalDate: string,
    convoys: string[][]
  ) => Promise<ConvoysMapResponse>;
  requestInfoConvoy: (
    customerId: string,
    idConvoy: string
  ) => Promise<ConvoyInfoResponse>;
};

const useConvoyDashboardAPI = (): UseConvoyDashboardAPIType => {
  const API = useAxios();

  const requestConvoys = async (
    params: ConvoysParams
  ): Promise<ConvoysResponse> => {
    const response: AxiosResponse<ConvoysResponse> = await API.instance.post(
      APIEndpoints.CONVOY_LIST,
      params
    );

    return response.data;
  };

  const requestMapConvoy = async (
    customerId: string,
    initialDate: string,
    finalDate: string,
    convoy: string[][]
  ): Promise<ConvoysMapResponse> => {
    const response: AxiosResponse<ConvoysMapResponse> = await API.instance.post(
      APIEndpoints.CONVOY_LOCATION_LIST,
      {
        ["customer_id"]: customerId,
        ["initial_date"]: initialDate,
        ["final_date"]: finalDate,
        convoy
      }
    );

    return response.data;
  };

  const requestInfoConvoy = async (
    customerId: string,
    idConvoy: string
  ): Promise<ConvoyInfoResponse> => {
    const response: AxiosResponse<ConvoyInfoResponse> = await API.instance.post(
      APIEndpoints.CONVOY_GET_INFO,
      {
        ["customer_id"]: customerId,
        ["id_convoy"]: idConvoy
      }
    );

    return response.data;
  };

  return {
    requestConvoys,
    requestMapConvoy,
    requestInfoConvoy
  };
};

export default useConvoyDashboardAPI;
