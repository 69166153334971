import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type LogReport = {
  user: string;
  message: string;
  ["date_time"]: string;
};

export type LogReportPaginated = {
  page: number;
  logs: LogReport[];
  ["total_count"]: number;
  ["total_pages"]: number;
};

type ListParams = {
  ["customer_id"]: string;
  page: number;
  user?: string;
  message?: string;
  ["final_date"]: string;
  ["initial_date"]: string;
  ["page_size"]?: number;
};

type UseLogReportAPIType = {
  list: (filter: ListParams) => Promise<LogReportPaginated>;
};

const useLogReportAPI = (): UseLogReportAPIType => {
  const API = useAxios();

  const list = async (filter: ListParams): Promise<LogReportPaginated> => {
    const newFilter = { ...filter };

    if (!newFilter.user) delete newFilter.user;

    if (!newFilter.message) delete newFilter.message;

    const response: AxiosResponse<LogReportPaginated> = await API.instance.get(
      APIEndpoints.LOGS_REPORT_LIST,
      { params: newFilter }
    );
    return response.data;
  };

  return {
    list
  };
};

export default useLogReportAPI;
