import Grid from "@mui/material/Grid";
import throttle from "lodash/throttle";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import parse from "autosuggest-highlight/parse";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { FC, useEffect, useMemo, useState } from "react";

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  ["main_text"]: string;
  ["secondary_text"]: string;
  ["main_text_matched_substrings"]: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  ["structured_formatting"]: StructuredFormatting;
}

type SearchPlaceProps = {
  onChange?: (value: string) => void;
};

const SearchPlace: FC<SearchPlaceProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<PlaceType | null>(null);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (
            results?: google.maps.places.AutocompletePrediction[] | null
          ) => void
        ) => {
          const service = new google.maps.places.AutocompleteService();
          service.getPlacePredictions(request, callback);
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue },
      (results?: google.maps.places.AutocompletePrediction[] | null) => {
        if (active) {
          setOptions(results ?? []);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      noOptionsText=""
      openText={t("action.open")}
      closeText={t("action.close")}
      clearText={t("action.clear")}
      filterSelectedOptions
      size="small"
      value={value}
      onChange={(event, newValue: PlaceType | null) => {
        event.preventDefault();
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (onChange && newValue) onChange(newValue.description);
      }}
      onInputChange={(event, newInputValue) => {
        event.preventDefault();
        setInputValue(newInputValue);
      }}
      renderInput={params => {
        const newParams = { ...params };
        newParams.InputProps.startAdornment = <Search />;
        return (
          <TextField
            placeholder={t("EquipmentsPage.searchPlaceHint")}
            {...newParams}
            fullWidth
          />
        );
      }}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: MainTextMatchedSubstrings) => [
            match.offset,
            match.offset + match.length
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body1" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default SearchPlace;
