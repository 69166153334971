import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";

import useAuthAPI from "api/AuthAPI";

import BackButton from "./BackButton";
import InputCode from "./InputCode";
import { useErrorHandler } from "contexts/ErrorHandlerContext";

type StepCodeProps = {
  setStep: (step: number) => void;
  setCode: (code: string) => void;
  email: string;
};

const StepCode: FC<StepCodeProps> = (props: StepCodeProps) => {
  const defaultTimer = 60;

  const [loading, setLoading] = useState(false);
  const { sendPasswordResetCode } = useAuthAPI();
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const saveCode = (code: string) => {
    props.setCode(code);
    props.setStep(3);
  };

  const resendCode = async () => {
    setLoading(true);
    resetTimer();
    try {
      await sendPasswordResetCode({
        username: props.email
      });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const [seconds, setSeconds] = useState(defaultTimer);
  const [timerOn, setTimerOn] = useState(true);
  const timer = useRef<ReturnType<typeof setInterval>>(null);

  useEffect(() => {
    if (seconds > 0 && timerOn) {
      (timer.current as NodeJS.Timeout) = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else {
      setTimerOn(false);
    }
    return () => clearInterval(timer.current as NodeJS.Timeout);
  }, [timerOn, seconds]);

  const resetTimer = () => {
    setTimerOn(true);
    setSeconds(defaultTimer);
  };

  return (
    <>
      <BackButton goBack={() => props.setStep(1)} />
      <Box mb={2}>
        <Typography variant="h3">
          {t("ResetPasswordPage.changePassword")}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="body1" color="textSecondary">
          {t("ResetPasswordPage.step2")}
        </Typography>
      </Box>
      <Box mb={4}>
        <InputCode length={6} onComplete={(code: string) => saveCode(code)} />
      </Box>
      <Box sx={{ height: 30 }}>
        <Typography color="textSecondary">
          {t("ResetPasswordPage.noCode")}{" "}
          {seconds > 0 && t("ResetPasswordPage.waitForNewCode", { seconds })}
          {seconds < 1 && (
            <Link
              component="button"
              onClick={() => resendCode()}
              color="secondary"
              disabled={loading}
              variant="body1"
            >
              {t("action.resend")}
            </Link>
          )}
        </Typography>
      </Box>
    </>
  );
};

export default StepCode;
