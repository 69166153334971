import {
  Box,
  List,
  styled,
  Divider,
  Collapse,
  ListItem,
  Typography,
  ListItemButton,
  FormControlLabel
} from "@mui/material";
import Button from "components/Button";
import Switch from "components/Switch";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { ChevronDown, ChevronUp } from "react-feather";
import { useNotificationPanel } from "contexts/NotificationPanelContext";
import Drawer from "components/Drawer";

const ListItemSwitch = styled(ListItem)(() => ({
  padding: 7,
  display: "flex",
  "& label": {
    marginLeft: "4px",
    padding: "5px 0 5px"
  },
  flexDirection: "column",
  alignItems: "flex-start"
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormNotificationConfig = {
  time: string;
  sms: boolean;
  alert: boolean;
  error: boolean;
  email: boolean;
  whatsapp: boolean;
  telegram: boolean;
  information: boolean;
  panel: boolean;
};

const defaultValues: FormNotificationConfig = {
  time: "",
  sms: false,
  alert: false,
  error: false,
  email: false,
  whatsapp: false,
  telegram: false,
  information: false,
  panel: false
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setNotification: (isNotificationOpen: boolean) => void;
};

const NotificationConfig: FC<Props> = ({ open, setOpen, setNotification }) => {
  const [expandedSelect, setExpandedSelect] = useState(true);
  const [expandedReceive, setExpandedReceive] = useState(true);
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const { control, handleSubmit, setValue } = useForm<FormNotificationConfig>({
    mode: "onChange",
    defaultValues
  });

  const { notificationsConfig, isLoadingConfig, saveConfig, isSavingConfig } =
    useNotificationPanel();

  useEffect(() => {
    if (notificationsConfig && open) {
      setValue("error", notificationsConfig.notification_type.error || false);
      setValue("alert", notificationsConfig.notification_type.alert || false);
      setValue(
        "information",
        notificationsConfig.notification_type.information || false
      );
      setValue(
        "panel",
        notificationsConfig.notification_channel.panel || false
      );
      setValue(
        "email",
        notificationsConfig.notification_channel.email || false
      );
    }
  }, [notificationsConfig, open]);

  const handleSelect = () => {
    setExpandedSelect(!expandedSelect);
  };

  const handleExpandedReceive = () => {
    setExpandedReceive(!expandedReceive);
  };

  // const handleExpandedTime = () => {
  //   setExpandedTime(!expandedTime);
  // };

  const onSubmit = async (data: FormNotificationConfig) => {
    try {
      await saveConfig({
        ["notification_type"]: {
          error: data.error,
          alert: data.alert,
          information: data.information
        },
        ["notification_channel"]: {
          panel: data.panel,
          email: data.email
        },
        ["panel_status"]: false
      });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("NotificationPanel.configurations")}
      onBackClick={() => {
        setNotification(true);
        setOpen(false);
      }}
    >
      <div>
        <Divider />
        <List
          component="nav"
          aria-labelledby="list"
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px"
          }}
        >
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <ListItemButton onClick={handleSelect}>
              {expandedSelect ? <ChevronUp /> : <ChevronDown />}
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ marginLeft: "12px", fontWeight: 700 }}
              >
                {t("NotificationPanel.selectNotification")}
              </Typography>
            </ListItemButton>
            <Collapse in={expandedSelect} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider />
                <ListItemSwitch>
                  {/* <Controller
                      name="information"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!!field.value}
                              onChange={field.onChange}
                              name={t("NotificationPanel.informationBtn")}
                              disabled={isLoadingConfig || isSavingConfig}
                            />
                          }
                          label={
                            t("NotificationPanel.informationBtn") as string
                          }
                        />
                      )}
                    /> */}
                  <Controller
                    name="alert"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={Boolean(field.value)}
                            onChange={field.onChange}
                            name={t("NotificationPanel.alert")}
                            disabled={isLoadingConfig || isSavingConfig}
                          />
                        }
                        label={t("NotificationPanel.alert")}
                      />
                    )}
                  />
                  {/* <Controller
                      name="error"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!!field.value}
                              onChange={field.onChange}
                              name={t("NotificationPanel.error")}
                              disabled={isLoadingConfig || isSavingConfig}
                            />
                          }
                          label={t("NotificationPanel.error") as string}
                        />
                      )}
                    /> */}
                </ListItemSwitch>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={handleExpandedReceive}>
              {expandedReceive ? <ChevronUp /> : <ChevronDown />}
              <Typography
                align="left"
                variant="subtitle1"
                sx={{ marginLeft: "12px", fontWeight: 700 }}
              >
                {t("NotificationPanel.receiveNotification")}
              </Typography>
            </ListItemButton>
            <Collapse in={expandedReceive} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider />
                <ListItemSwitch>
                  <Controller
                    name="panel"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={Boolean(field.value)}
                            onChange={field.onChange}
                            name={t("NotificationPanel.panelNotification")}
                            disabled={isLoadingConfig || isSavingConfig}
                          />
                        }
                        label={t("NotificationPanel.panelNotification")}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={Boolean(field.value)}
                            onChange={field.onChange}
                            name={t("NotificationPanel.email")}
                            disabled={isLoadingConfig || isSavingConfig}
                          />
                        }
                        label={t("NotificationPanel.email")}
                      />
                    )}
                  />
                  {/* <Controller
                      name="whatsapp"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!!field.value}
                              onChange={field.onChange}
                              name={t("NotificationPanel.whatsapp")}
                              disabled
                            />
                          }
                          label={t("NotificationPanel.whatsapp") as string}
                        />
                      )}
                    />
                    <Controller
                      name="telegram"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!!field.value}
                              onChange={field.onChange}
                              name={t("NotificationPanel.telegram")}
                              disabled
                            />
                          }
                          label={t("NotificationPanel.telegram") as string}
                        />
                      )}
                    />
                    <Controller
                      name="sms"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!!field.value}
                              onChange={field.onChange}
                              name={t("NotificationPanel.sms")}
                              disabled
                            />
                          }
                          label={t("NotificationPanel.sms") as string}
                        />
                      )}
                    /> */}
                </ListItemSwitch>
              </List>
            </Collapse>
            {/*
              <Divider />
              <ListItemButton onClick={handleExpandedTime}>
                {expandedTime ? <ChevronUp /> : <ChevronDown />}
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ marginLeft: "12px", fontWeight: 700 }}
                >
                  {t("NotificationPanel.time")}
                </Typography>
              </ListItemButton>
              <Collapse in={expandedTime} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Divider />
                  <ListItem
                    sx={{
                      padding: "22px 52px 22px 52px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      "& div": { width: "88px" }
                    }}
                  >
                    <Controller
                      name="time"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputField
                          field={field}
                          gutter={false}
                          customProps={{
                            startAdornment: (
                              <Icon>
                                <Clock />
                              </Icon>
                            )
                          }}
                          fieldState={fieldState}
                        />
                      )}
                    />
                    <Button
                      customProps={{
                        sx: { backgroundColor: theme.palette.primary.main }
                      }}
                    >
                      {t("NotificationPanel.hour")}
                    </Button>
                    <Button
                      customProps={{
                        color: "primary",
                        variant: "outlined"
                      }}
                    >
                      {t("NotificationPanel.minutes")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>*/}
            <Footer>
              <Button
                customProps={{
                  color: "primary",
                  variant: "outlined",
                  onClick() {
                    setOpen(false);
                  }
                }}
              >
                {t("action.cancel")}
              </Button>
              <Button
                customProps={{
                  type: "submit"
                }}
              >
                {t("action.confirm")}
              </Button>
            </Footer>
          </form>
        </List>
      </div>
    </Drawer>
  );
};

export default NotificationConfig;
