import { createContext, FC, useContext, useEffect, useState } from "react";
import { useTheme } from "contexts/ThemeContext";
import useCustomizationAPI, { Customization } from "api/CustomizationAPI";
import { Backdrop, CircularProgress } from "@mui/material";
import RegexPatterns from "utils/RegexPatterns";
import { useErrorHandler } from "./ErrorHandlerContext";

type CustomizationContextValue = {
  appTitle: string;
  imgLogo: string;
  imgHeader: string;
  primaryColor: string;
  secondaryColor: string;
  isLoading: boolean;
  setAppTitle: (title: string) => void;
  setImgLogo: (img: string) => void;
  setImgHeader: (img: string) => void;
  setPrimaryColor: (color: string) => void;
  setSecondaryColor: (color: string) => void;
  setCustomTheme: (data: Customization) => void;
};

const CustomizationContext = createContext<CustomizationContextValue>({
  appTitle: "",
  imgLogo: "",
  imgHeader: "",
  primaryColor: "",
  secondaryColor: "",
  isLoading: false,
  setAppTitle() {
    throw new Error("setAppTitle must be defined.");
  },
  setImgLogo() {
    throw new Error("setImgLogo must be defined.");
  },
  setImgHeader() {
    throw new Error("setImgHeader must be defined.");
  },
  setPrimaryColor() {
    throw new Error("setPrimaryColor must be defined.");
  },
  setSecondaryColor() {
    throw new Error("setSecondaryColor must be defined.");
  },
  setCustomTheme() {
    throw new Error("setCustomTheme must be defined.");
  }
});

const getSubDomain = (): string => {
  const host = window.location.origin;
  // const host = "http://prf.lince.app.br";
  // const host = "https://lince.app.br/system/mosaic";
  const domain = /:\/\/([^/]+)/.exec(host);
  if (domain) {
    const subDomain = domain[1].toString().split(".")[0];
    if (
      subDomain.includes("puma") ||
      subDomain.includes("lince") ||
      subDomain.includes("local")
    ) {
      return "";
    }
    return subDomain;
  }
  return "";
};

const getStorageTheme = (): Customization | null => {
  const data = localStorage.getItem("customTheme");
  if (data) {
    return JSON.parse(data) as Customization;
  }
  return null;
};

export const setStorageTheme = (data: Customization | null): void => {
  if (data) {
    localStorage.setItem("customTheme", JSON.stringify(data));
  }
};

export const CustomizationProvider: FC = ({ children }) => {
  const { setPrimaryColor, setSecondaryColor, primaryColor, secondaryColor } =
    useTheme();
  const [appTitle, setAppTitle] = useState<string>(
    "Pumatronix Equipamentos Eletronicos Ltda"
  );
  const [imgLogo, setImgLogo] = useState<string>("");
  const [imgHeader, setImgHeader] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const CustomizationAPI = useCustomizationAPI();

  const setCustomTheme = (data: Customization) => {
    const primary = data.primary_color;
    const secondary = data.secondary_color;
    if (RegexPatterns.HEX_COLOR.test(primary)) {
      setPrimaryColor(primary);
    }
    if (RegexPatterns.HEX_COLOR.test(secondary)) {
      setSecondaryColor(secondary);
    }
    setAppTitle(data.title);
    setImgLogo(data.login_image);
    setImgHeader(data.header_image);
  };

  const { errorHandler } = useErrorHandler();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const storageTheme = getStorageTheme();
        if (storageTheme) {
          setCustomTheme(storageTheme);
          return;
        }
        const domain = getSubDomain();
        if (domain) {
          const response = await CustomizationAPI.getCustomization({
            domain
          });
          setCustomTheme(response.customization_data);
          setStorageTheme(response.customization_data);
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <CustomizationContext.Provider
      value={{
        setImgLogo,
        setImgHeader,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        setAppTitle,
        appTitle,
        imgLogo,
        imgHeader,
        setCustomTheme,
        isLoading
      }}
    >
      <Backdrop
        sx={{
          color: theme => theme.palette.primary.light,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </CustomizationContext.Provider>
  );
};

export const useCustomization = (): CustomizationContextValue => {
  const context = useContext(CustomizationContext);
  if (context === undefined) {
    throw new Error(
      "useCustomization must be used within an CustomizationProvider"
    );
  }
  return context;
};
