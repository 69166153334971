import {
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import { FC } from "react";
import { Icon } from "react-feather";

const ICON_SIZE = 16;

export type DataTableAction = {
  tooltip: string;
  onClick: () => void;
  icon: Icon;
  disabled?: boolean;
  loading?: boolean;
};

type DataTableActionsProps = {
  actions: DataTableAction[];
};

const DataTableActions: FC<DataTableActionsProps> = ({ actions }) => (
  <Stack
    direction="row"
    divider={<Divider orientation="vertical" flexItem />}
    spacing={1}
    sx={{ justifyContent: "end" }}
  >
    {actions.map(
      ({ tooltip, onClick, disabled, loading, icon: ActionIcon }) =>
        !disabled && (
          <Tooltip key={tooltip} title={loading ? "" : tooltip}>
            <IconButton onClick={onClick} disabled={loading}>
              {loading ? (
                <CircularProgress size={ICON_SIZE} />
              ) : (
                <ActionIcon size={ICON_SIZE} />
              )}
            </IconButton>
          </Tooltip>
        )
    )}
  </Stack>
);

export default DataTableActions;
