import {
  styled,
  Table as MTable,
  TableContainer as MTableContainer
} from "@mui/material";

export const TableContainer = styled(MTableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default
}));

export const Table = styled(MTable)(({ theme }) => ({
  "& .MuiTableHead-root .MuiTableCell-root": {
    color: theme.palette.primary.light,
    fontSize: "14px",
    fontWeight: 600,
    padding: "6px 16px"
  },
  "& .MuiTableBody-root .MuiTableCell-root": {
    fontSize: "12px"
  }
}));
