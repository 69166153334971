import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import CameraView from "components/CameraView";
import { Users } from "react-feather";

type Item = {
  id: string;
  location: string;
  camera: string;
  image?: string;
  owner: string;
};

type Props = {
  item: Item;
  onMoveItem?: (draggedId: string, id: string) => void;
  onDropItem?: (draggedId: string, id: string) => void;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  style?: CSSProperties;
};

const DraggableItem: FC<Props> = ({
  item,
  onMoveItem,
  onDropItem,
  setIsDragging,
  style
}) => {
  const cameraViewRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(); // Função de tradução

  const [{ isDragging }, connectDrag] = useDrag({
    type: "img",
    item: { id: item.id },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging()
      };
    }
  });

  useEffect(() => {
    setIsDragging(isDragging);
  }, [isDragging]);

  const [, connectDrop] = useDrop({
    accept: "img",
    hover({ id: draggedId }: { id: string; type: string }) {
      if (onMoveItem && draggedId !== item.id) {
        onMoveItem(draggedId, item.id);
      }
    },
    drop({ id: draggedId }: { id: string; type: string }) {
      if (onDropItem) {
        onDropItem(draggedId, item.id);
      }
    }
  });

  connectDrag(cameraViewRef);
  connectDrop(cameraViewRef);

  return (
    <Tooltip
      title={
        <div>
          {`${t("CamerasMosaicPage.camera")}: ${item.camera}`}
          <br />
          {`${t("CamerasMosaicPage.location")}: ${item.location}`}
          {item.owner !== "" && (
            <>
              <br />
              <Users size={16} /> {item.owner}
            </>
          )}
        </div>
      }
      placement="top"
    >
      <div
        style={{
          opacity: isDragging ? 0.5 : 1
        }}
      >
        <CameraView
          containerRef={cameraViewRef}
          image={item.image}
          style={style}
        />
      </div>
    </Tooltip>
  );
};

export default DraggableItem;
