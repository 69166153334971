import crypto from "crypto";
import { Buffer } from "buffer";

const ALGORITHM = "aes-256-cbc";

const key =
  process.env.REACT_APP_LINCE_CRYPTO_KEY ?? "__ENV_VARIABLE_MISSING__";
const iv = process.env.REACT_APP_LINCE_CRYPTO_IV ?? "__ENV_VARIABLE_MISSING__";

const hexKey = Buffer.from(key, "hex");
const hexIv = Buffer.from(iv, "hex");

const encrypt = (text: string): string => {
  const cipher = crypto.createCipheriv(ALGORITHM, hexKey, hexIv);
  const encryptedBuffer = Buffer.concat([cipher.update(text), cipher.final()]);
  return encryptedBuffer.toString("hex");
};

const decrypt = (text: string): string => {
  const encryptedText = Buffer.from(text, "hex");
  const decipher = crypto.createDecipheriv(
    ALGORITHM,
    Buffer.from(hexKey),
    hexIv
  );
  const decrypted = decipher.update(encryptedText);
  const decryptedBuffer = Buffer.concat([decrypted, decipher.final()]);
  return decryptedBuffer.toString();
};

const Crypto = { encrypt, decrypt };

export default Crypto;
