import { Tooltip, styled, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import { AlertCircle } from "react-feather";
import { primaryDefaultColors } from "styles/theme";

const Label = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: 14
}));

type Props = {
  helperText?: ReactNode;
  required?: boolean;
};

const FormLabel: FC<Props> = ({ children, helperText, required = false }) => {
  const theme = useTheme();
  return (
    <Label>
      {children}
      {required && (
        <span style={{ color: theme.palette.error.main }}>&nbsp;*</span>
      )}
      {helperText && (
        <Tooltip title={helperText}>
          <AlertCircle
            size={16}
            style={{ marginLeft: theme.spacing(0.5) }}
            color={primaryDefaultColors.light}
          />
        </Tooltip>
      )}
    </Label>
  );
};

export default FormLabel;
