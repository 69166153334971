import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  LineChart,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { Box, styled, Skeleton } from "@mui/material";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { FC, MouseEvent, useState } from "react";
import { WeekRegister } from "api/FlowDashboardAPI";
import PageSection from "components/PageSection/PageSection";
import VisualizationSwitcher, {
  ChartStyle
} from "components/VisualizationSwitcher";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

type CapturedVehicleGraphByDayOfWeekProps = {
  title: string;
  isFetching: boolean;
  data: WeekRegister[];
};

const colors = ["#dead37", "#768299", "#4ACBAC", "#F5634A", "#4a9af5"];

const CapturedVehicleGraphByDayOfWeek: FC<
  CapturedVehicleGraphByDayOfWeekProps
> = ({ title, data, isFetching }) => {
  const { t } = useTranslation();
  const [chartStyle, setChartStyle] = useState<ChartStyle>("bar");

  const onChartStyleChange = (event: MouseEvent, value: ChartStyle) => {
    event.preventDefault();
    if (value) {
      setChartStyle(value);
    }
  };

  return (
    <PageSection
      title={title}
      variant="h5"
      actions={
        <VisualizationSwitcher
          chartStyle={chartStyle}
          onChartStyleChange={onChartStyleChange}
        />
      }
    >
      <Container>
        <Watermark>
          <Box sx={{ maxWidth: 900, margin: "0 auto" }}>
            {isFetching && <Skeleton variant="rectangular" height={350} />}
            {!isFetching && chartStyle === "bar" && (
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="weekDayName" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="car"
                    name={t("MonitoredVehiclesDashboardPage.car")}
                    fill={colors[3]}
                  />
                  <Bar
                    dataKey="truck"
                    name={t("MonitoredVehiclesDashboardPage.truck")}
                    fill={colors[1]}
                  />
                  <Bar
                    dataKey="unknown"
                    name={t("MonitoredVehiclesDashboardPage.unknown")}
                    fill={colors[0]}
                  />
                  <Bar
                    dataKey="motorcycle"
                    name={t("MonitoredVehiclesDashboardPage.motorcycle")}
                    fill={colors[2]}
                  />
                  <Bar
                    dataKey="bus"
                    name={t("MonitoredVehiclesDashboardPage.bus")}
                    fill={colors[4]}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
            {!isFetching && chartStyle === "line" && (
              <ResponsiveContainer width="100%" height={350}>
                <LineChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="weekDayName" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="car"
                    name={t("MonitoredVehiclesDashboardPage.car")}
                    stroke={colors[3]}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="truck"
                    name={t("MonitoredVehiclesDashboardPage.truck")}
                    stroke={colors[1]}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="unknown"
                    name={t("MonitoredVehiclesDashboardPage.unknown")}
                    stroke={colors[0]}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="motorcycle"
                    name={t("MonitoredVehiclesDashboardPage.motorcycle")}
                    stroke={colors[2]}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="bus"
                    name={t("MonitoredVehiclesDashboardPage.bus")}
                    stroke={colors[4]}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Box>
        </Watermark>
      </Container>
    </PageSection>
  );
};

export default CapturedVehicleGraphByDayOfWeek;
