import { FC } from "react";
import { Box } from "@mui/material";

import logo from "assets/logo_h.png";
import { useCustomization } from "contexts/CustomizationContext";

const Logo: FC = () => {
  const { imgLogo } = useCustomization();

  return (
    <Box sx={{ mb: 5.625, display: "flex", justifyContent: "center" }}>
      {imgLogo && <img src={imgLogo} alt="Lince" height={150} />}
      {!imgLogo && <img src={logo} alt="Lince" height={50} />}
    </Box>
  );
};

export default Logo;
