import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  styled,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { FC } from "react";
import Button from "components/Button";
import SelectField from "components/SelectField";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { Clock, Plus, XCircle } from "react-feather";
import DatePickerField from "components/DatePickerField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TimePickerField from "components/TimePickerField";
import FormLabel from "components/FormLabel";

const Container = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  zIndex: 1,
  width: "100%",
  maxWidth: 790,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[16],
  borderRadius: theme.shape.borderRadius,
  "& > form": {
    width: "100%"
  }
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterNoInterval = {
  fields: {
    equipment: string;
    dateTime: string;
    tolerance: string;
    inMinutes: boolean;
  }[];
};

const defaultValues: FormFilterNoInterval = {
  fields: [
    {
      equipment: "",
      dateTime: "",
      tolerance: "60",
      inMinutes: true
    },
    {
      equipment: "",
      dateTime: "",
      tolerance: "60",
      inMinutes: true
    }
  ]
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterNoInterval) => void;
};

const IntervalFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "fields"
  });

  const clearForm = () => {
    reset(defaultValues);
  };

  const onSubmit = async (data: FormFilterNoInterval) => {
    onFilter(data);
    setOpen(false);
  };

  const addEquipment = () => {
    append(defaultValues.fields[0]);
  };

  return (
    <>
      {open && (
        <Container>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Content>
              {fields.map((equipment, idx) => (
                <Grid
                  key={equipment.id}
                  container
                  rowSpacing={1}
                  columnSpacing={2}
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={4}>
                    <FormLabel>
                      {t("CorrelationReportPage.equipment")}
                    </FormLabel>
                    <Controller
                      name={`fields.${idx}.equipment`}
                      control={control}
                      render={({ field }) => (
                        <SelectField
                          {...field}
                          onChange={event => {
                            field.onChange(event.target.value as string);
                          }}
                          style={{ marginBottom: 0 }}
                        >
                          <MenuItem value="">&nbsp;</MenuItem>
                          <MenuItem value="1">Seleção 1</MenuItem>
                          <MenuItem value="2">Seleção 2</MenuItem>
                          <MenuItem value="3">Seleção 3</MenuItem>
                        </SelectField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`fields.${idx}.dateTime`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DatePickerField
                          label={t("CorrelationReportPage.dateTime")}
                          gutter={false}
                          field={{ ...field }}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ marginTop: "25px" }}>
                    <Controller
                      name={`fields.${idx}.dateTime`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TimePickerField
                          gutter={false}
                          field={{ ...field }}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name={`fields.${idx}.tolerance`}
                      control={control}
                      render={({ field }) => (
                        <InputField
                          field={{ ...field }}
                          label={t("CorrelationReportPage.tolerance")}
                          gutter={false}
                          customProps={{
                            startAdornment: <Clock />
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <ToggleButtonGroup
                      value={fields[idx].inMinutes ? "minutes" : "hours"}
                      color="primary"
                      size="small"
                      exclusive
                      onChange={(e, value) => {
                        e.preventDefault();
                        if (value) {
                          const ob = fields[idx];
                          ob.inMinutes = value === "minutes";
                          update(idx, ob);
                        }
                      }}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="minutes" aria-label="left aligned">
                        {t("CorrelationReportPage.minute")}
                      </ToggleButton>
                      <ToggleButton value="hours" aria-label="centered">
                        {t("CorrelationReportPage.hour")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    {idx > 1 && (
                      <IconButton sx={{ mb: 1 }} onClick={() => remove(idx)}>
                        <XCircle size={16} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}
              <Box sx={{ pt: 1 }}>
                <Button
                  customProps={{
                    variant: "text",
                    startIcon: <Plus size={16} />,
                    size: "small",
                    onClick: addEquipment
                  }}
                >
                  {t("CorrelationReportPage.addEquipment")}
                </Button>
              </Box>
            </Content>
            <Footer>
              <Button
                customProps={{
                  color: "primary",
                  variant: "outlined",
                  onClick: clearForm
                }}
              >
                {t("action.clear")}
              </Button>
              <Button
                customProps={{
                  type: "submit"
                }}
              >
                {t("action.filter")}
              </Button>
            </Footer>
          </form>
        </Container>
      )}
    </>
  );
};

export default IntervalFilter;
