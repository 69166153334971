import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { FC, useEffect, useState } from "react";
import useCameraAPI, { Camera } from "api/CameraAPI";
import { Controller, useForm } from "react-hook-form";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import useLocationAPI, { CameraItem, Location } from "api/LocationAPI";
import { defaultCameraValues } from "pages/Settings/Cameras/CamerasPage";
import { CameraSelected } from "pages/Settings/Equipments/EquipmentsFormDialog";
import { defaultEquipmentValues } from "pages/Settings/Equipments/EquipmentsPage";
import FormDialog from "./FormDialog";

type AliasForm = {
  alias: string;
};

const defaultValues: AliasForm = {
  alias: ""
};

type Props = {
  open: boolean;
  currentAlias: string;
  item: Location | Camera;
  setOpen: (open: boolean) => void;
  aliasType: "equipment" | "camera";
  onRequestCompleted: (newalias: string) => void;
};

const AliasFormDialog: FC<Props> = ({
  open,
  setOpen,
  item,
  aliasType,
  currentAlias,
  onRequestCompleted
}) => {
  const { t } = useTranslation();
  const CameraAPI = useCameraAPI();
  const { sessionUser } = useAuth();
  const LocationAPI = useLocationAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<AliasForm>(defaultValues);

  const [camera, setCamera] = useState<Camera>(defaultCameraValues);
  const [equipment, setEquipment] = useState<Location>(defaultEquipmentValues);

  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<AliasForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  useEffect(() => {
    if (open) {
      setValue("alias", currentAlias);
      if (aliasType === "equipment") {
        setEquipment(item as Location);
      } else {
        setCamera(item as Camera);
      }
    }
  }, [open]);

  useEffect(() => {
    reset(data);
  }, [setValue, data]);

  useEffect(() => {
    if (!open) {
      setData(defaultValues);
      reset(defaultValues);
      setEquipment(defaultEquipmentValues);
      setCamera(defaultCameraValues);
    }
  }, [open]);

  const onSubmit = async (data: AliasForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    const newData = { ...data };
    try {
      if (data.alias !== currentAlias) {
        if (aliasType === "equipment") {
          const camerasSelected: CameraSelected[] = equipment.cameras || [];
          const cameras = camerasSelected.filter(
            camera => typeof camera !== "string"
          ) as CameraItem[];
          await LocationAPI.update(
            {
              customerId: sessionUser?.["customer_id"],
              locationName: `${equipment.shared_data.owner}#${equipment.location_name}`,
              comment: equipment.location_data.comment,
              type: equipment.location_data.type,
              zipCode: equipment.location_data.zip_code,
              latitude: equipment.location_data.latitude,
              longitude: equipment.location_data.longitude,
              ["shared_data"]: {
                ["owner_name"]: equipment.shared_data.owner_name,
                owner: equipment.shared_data.owner,
                alias: data.alias
              }
            },
            equipment.location_data.city,
            equipment.location_data.state,
            equipment.location_data.street,
            equipment.location_data.number,
            cameras
          );
          snackNotification.success(t("EquipmentsPage.equipmentUpdated"));
        } else {
          await CameraAPI.update({
            customerId: sessionUser?.["customer_id"],
            locationName: `${camera.shared_data.owner}#${camera.location_name}`,
            cameraName: camera.camera_name,
            active: camera.camera_data.active,
            side: camera.camera_data.side,
            serialNumber: camera.serial_number,
            requestImageRecognized: camera.camera_data.request_image_recognized,
            requestImageUnrecognized:
              camera.camera_data.request_image_unrecognized,
            vmsCameraName: camera.camera_data.vms?.name ?? "",
            vmsIndex: camera.camera_data.vms?.index ?? "",
            cortex: {
              lane: camera.camera_data.cortex.lane
            },
            ["detecta_sp"]: camera.camera_data.detecta_sp,
            ["spia_prf"]: camera.camera_data.spia_prf,
            oldLocationName: undefined,
            oldCameraName: undefined,
            ["shared_data"]: {
              ["owner_name"]: camera.shared_data.owner_name,
              owner: camera.shared_data.owner,
              alias: data.alias
            }
          });
          snackNotification.success(t("CamerasPage.cameraUpdated"));
        }
        onRequestCompleted(newData.alias);
      }
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        aliasType === "equipment"
          ? t("EquipmentsPage.editEquipment")
          : t("CamerasPage.editCamera")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Controller
            name="alias"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("ShareDataPage.alias")}
                customProps={{
                  disabled: isLoading
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default AliasFormDialog;
