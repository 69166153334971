import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import Languages from "enums/Languages";
import i18nLib from "i18n/i18n";
import Cookies from "utils/Cookies";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptBrLocale from "date-fns/locale/pt-BR";

const INITIAL_LANGUAGE = Languages.EN;

export const localeMap = {
  "en-US": enLocale,
  "es-ES": esLocale,
  "pt-BR": ptBrLocale
};

type LocaleContextValue = {
  language: Languages;
  setLanguage: (() => void) | Dispatch<SetStateAction<Languages>>;
};

const LocaleContext = createContext<LocaleContextValue>({
  language: INITIAL_LANGUAGE,
  setLanguage() {
    throw new Error("setLanguage must be defined.");
  }
});

export const getLanguageByValue = (search: string): Languages => {
  const formattedSearch = search.substr(0, 2).toUpperCase();
  const languageEntries = Object.entries(Languages);
  const found = languageEntries.find(
    ([_key, value]) => value.substr(0, 2).toUpperCase() === formattedSearch
  );
  return found?.[1] ?? INITIAL_LANGUAGE;
};

const defaultLanguage =
  (Cookies.get(Cookies.LANGUAGE) as Languages) ??
  getLanguageByValue(navigator.language) ??
  INITIAL_LANGUAGE;

i18nLib.init(defaultLanguage as string, INITIAL_LANGUAGE);

export const LocaleProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState<Languages>(defaultLanguage);

  const { i18n } = useTranslation();

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) html.setAttribute("lang", language);
    if (i18n && i18n.changeLanguage) i18n.changeLanguage(language);
    Cookies.set(Cookies.LANGUAGE, language);
  }, [language, i18n]);

  return (
    <LocaleContext.Provider
      value={{
        language,
        setLanguage
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[language]}
      >
        {children}
      </LocalizationProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = (): LocaleContextValue => {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw new Error("useLocale must be used within an LocaleProvider");
  }
  return context;
};
