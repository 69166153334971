import { FC, MouseEvent, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";
import ListCustomFilterDialog from "components/CustomFilterMenu/ListCustomFilterDialog";
import SaveCustomFilterDialog from "components/CustomFilterMenu/SaveCustomFilterDialog";
import { useCustomFilter } from "components/CustomFilterMenu/CustomFilterContext";

const CustomFilterMenu: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setIsListFilterDialogOpen, setIsSaveFilterDialogOpen } =
    useCustomFilter();
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenListFilterDialog = () => {
    setIsListFilterDialogOpen(true);
    handleClose();
  };

  const handleOpenSaveFilterDialog = () => {
    setIsSaveFilterDialogOpen(true);
    handleClose();
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        id="filterButton"
        aria-controls={open ? "filterMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ChevronDown />}
      >
        {t("CustomFilterMenu.manageFilters")}
      </Button>
      <Menu
        id="filterMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={handleOpenListFilterDialog}>
          {t("CustomFilterMenu.listFilters")}
        </MenuItem>
        <MenuItem onClick={handleOpenSaveFilterDialog}>
          {t("CustomFilterMenu.saveCurrentFilters")}
        </MenuItem>
      </Menu>
      <ListCustomFilterDialog />
      <SaveCustomFilterDialog />
    </>
  );
};

export default CustomFilterMenu;
