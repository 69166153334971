import Pages from "enums/Pages";
import Logo from "components/Logo";
import { Box } from "@mui/material";
import { Mail } from "react-feather";
import useAuthAPI from "api/AuthAPI";
import Timer from "components/Timer";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import RegexPatterns from "utils/RegexPatterns";
import AuthPageLayout from "layouts/AuthPageLayout";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";

type FormFields = {
  username: string;
  confirmationCode: string;
};

const ConfirmUserPage: FC = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);

  const { search } = useLocation<{ from?: { pathname: string } } | undefined>();

  const username = search.replace("?username=", "");
  const { errorHandler } = useErrorHandler();
  const { confirmUser, resendAccessCode } = useAuthAPI();

  const { control, formState, handleSubmit, getValues } = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      username,
      confirmationCode: ""
    }
  });

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const { setPageTitle } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.confirmUser"));
  }, [t]);

  const onSubmit = async (data: FormFields) => {
    try {
      setIsLoading(true);
      await confirmUser({
        username: data.username,
        code: data.confirmationCode
      });
      history.push(Pages.SIGN_IN);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    setIsLoading(true);
    try {
      const username: string = getValues("username");
      if (username) {
        await resendAccessCode({ username });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthPageLayout>
      <Box sx={{ px: 2, py: 3 }}>
        <Logo />
        <Box mb={1}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              control={control}
              rules={{
                required: t("form.requiredField").toString(),
                pattern: {
                  value: RegexPatterns.EMAIL_ADDRESS,
                  message: t("form.invalidFormat")
                }
              }}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("ConfirmUserPage.username")}
                  customProps={{
                    readOnly: true,
                    type: "email",
                    startAdornment: <Mail />
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
            <Controller
              name="confirmationCode"
              control={control}
              rules={{ required: t("form.requiredField").toString() }}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("ConfirmUserPage.confirmationCode")}
                  customProps={{
                    autoFocus: true
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
            <Box mb={2}>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                disabled={!formState.isValid}
                loading={isLoading}
              >
                {t("action.confirm")}
              </LoadingButton>
            </Box>
            <Box
              sx={{
                mb: 7
              }}
            >
              <Timer expiryTimestamp={time} resendCode={() => resendCode()} />
            </Box>
          </form>
        </Box>
      </Box>
    </AuthPageLayout>
  );
};

export default ConfirmUserPage;
