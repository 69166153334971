export const validateInteger = (
  value: number,
  message: string
): string | boolean => {
  if (value % 1 !== 0) return message;
  return true;
};

const FormValidation = { validateInteger };

export default FormValidation;
