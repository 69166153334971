export const captureReportTableColumns = [
  { key: "id", label: "id" },
  { key: "date_capture", label: "datetime" },
  { key: "equipment", label: "equipment" },
  { key: "camera", label: "camera" },
  { key: "side", label: "direction" },
  { key: "address", label: "address" },
  { key: "plate", label: "plate" },
  { key: "vehicle_make", label: "characteristics" },
  { key: "vehicle_class", label: "vehicle_class" }
];
