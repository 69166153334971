import { createContext, FC, useContext, useEffect, useState } from "react";
import useNotificationAPI, {
  Notification,
  NotificationConfig,
  UpdateNotification
} from "api/NotificationAPI";
import Cookies from "utils/Cookies";
import { User } from "api/UserAPI";
import NotificationType from "enums/NotificationType";
import { keyBy, merge, reverse, values } from "lodash";
import { differenceInHours, parseISO } from "date-fns";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useAuth } from "contexts/AuthContext";

type PushNotificationProps = {
  id: string;
  message: string;
  type: NotificationType;
  date: string;
};

interface ContextProps {
  pushNotification: (notification: PushNotificationProps) => void;
  loadNotifications: (customerId: string, username: string) => Promise<void>;
  saveConfig: (config: NotificationConfig) => Promise<void>;
  loadConfig: (customerId: string, username: string) => Promise<void>;
  updateNotifications: (updates: UpdateNotification[]) => Promise<void>;
  loadMoreNotifications: (
    customerId: string,
    username: string
  ) => Promise<void>;
  notificationsConfig?: NotificationConfig;
  notifications?: Notification[];
  latestNotifications: Notification[];
  oldestNotifications: Notification[];
  isLoadingConfig: boolean;
  isSavingConfig: boolean;
  isLoadingNotifications: boolean;
  hasMessageToRead: boolean;
  canLoadMore: boolean;
  isLoadingMore: boolean;
}

const NotificationPanelContext = createContext<ContextProps>({
  pushNotification() {
    throw new Error("pushNotification must be defined.");
  },
  loadNotifications() {
    throw new Error("loadNotifications must be defined.");
  },
  saveConfig() {
    throw new Error("saveConfig must be defined.");
  },
  loadConfig() {
    throw new Error("loadConfig must be defined.");
  },
  updateNotifications() {
    throw new Error("updateNotifications must be defined.");
  },
  loadMoreNotifications() {
    throw new Error("loadMoreNotifications must be defined.");
  },
  notificationsConfig: undefined,
  notifications: [],
  latestNotifications: [],
  oldestNotifications: [],
  isLoadingConfig: false,
  isSavingConfig: false,
  isLoadingNotifications: false,
  hasMessageToRead: false,
  canLoadMore: false,
  isLoadingMore: false
});

export const NotificationPanelProvider: FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [latestNotifications, setLatestNotifications] = useState<
    Notification[]
  >([]);
  const [oldestNotifications, setOldestNotifications] = useState<
    Notification[]
  >([]);
  const [notificationsConfig, setNotificationsConfig] =
    useState<NotificationConfig>();
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [isSavingConfig, setIsSavingConfig] = useState(false);
  const [hasMessageToRead, setHasMessageToRead] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const NotificationAPI = useNotificationAPI();
  const { sessionUser } = useAuth();

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        await Promise.all([
          loadConfig(sessionUser.customer_id, sessionUser.username),
          loadNotifications(sessionUser.customer_id, sessionUser.username)
        ]);
      }
    })();
  }, [sessionUser]);

  useEffect(() => {
    setHasMessageToRead(Boolean(notifications.find(n => !n.read)));
    setLatestNotifications([
      ...notifications.filter(
        n => differenceInHours(new Date(), parseISO(n.date)) < 1
      )
    ]);
    setOldestNotifications([
      ...notifications.filter(
        n => differenceInHours(new Date(), parseISO(n.date)) >= 1
      )
    ]);
  }, [notifications]);

  useEffect(() => {
    setCanLoadMore(lastPage < totalPages);
  }, [lastPage, totalPages]);

  const { errorHandler } = useErrorHandler();

  const loadMoreNotifications = async (
    customerId: string,
    username: string
  ) => {
    try {
      if (canLoadMore) {
        setIsLoadingMore(true);
        const page = lastPage + 1;
        const response = await NotificationAPI.getNotifications({
          customerId,
          username,
          page
        });
        setNotifications([...notifications, ...response.data.notifications]);
        setLastPage(page);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingMore(false);
    }
  };

  const pushNotification = (newNotification: PushNotificationProps) => {
    setNotifications([
      {
        id: newNotification.id,
        message: newNotification.message,
        type: newNotification.type,
        date: newNotification.date,
        read: false,
        deleted: false
      },
      ...notifications
    ]);
  };

  const loadNotifications = async (customerId: string, username: string) => {
    setIsLoadingNotifications(true);
    try {
      const response = await NotificationAPI.getNotifications({
        customerId,
        username,
        page: 1
      });
      setNotifications(response.data.notifications);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingNotifications(false);
    }
  };

  const loadConfig = async (customerId: string, username: string) => {
    setIsLoadingConfig(true);
    try {
      const response = await NotificationAPI.getConfig({
        customerId,
        username
      });
      setNotificationsConfig(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingConfig(false);
    }
  };

  const saveConfig = async (config: NotificationConfig) => {
    try {
      const sessionUser = Cookies.get(Cookies.SESSION_USER) as User;
      if (sessionUser) {
        setIsSavingConfig(true);
        await NotificationAPI.setConfig({
          ["customer_id"]: sessionUser.customer_id,
          username: sessionUser.username,
          ...config
        });
        setNotificationsConfig(config);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsSavingConfig(false);
    }
  };

  const updateNotifications = async (updates: UpdateNotification[]) => {
    try {
      const sessionUser = Cookies.get(Cookies.SESSION_USER) as User;
      if (sessionUser) {
        await NotificationAPI.updateNotifications({
          customerId: sessionUser.customer_id,
          username: sessionUser.username,
          notifications: updates
        });
        const updatedNotifications = reverse(
          values(merge(keyBy(notifications, "id"), keyBy(updates, "id")))
        );
        setNotifications([...updatedNotifications.filter(n => !n.deleted)]);
      }
    } catch (error) {
      errorHandler({ error });
    }
  };

  return (
    <NotificationPanelContext.Provider
      value={{
        loadNotifications,
        notifications,
        latestNotifications,
        oldestNotifications,
        pushNotification,
        saveConfig,
        loadConfig,
        updateNotifications,
        loadMoreNotifications,
        notificationsConfig,
        isLoadingConfig,
        isSavingConfig,
        isLoadingNotifications,
        hasMessageToRead,
        canLoadMore,
        isLoadingMore
      }}
    >
      {children}
    </NotificationPanelContext.Provider>
  );
};

export const useNotificationPanel = (): ContextProps => {
  const context = useContext(NotificationPanelContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationPanel must be used within an NotificationPanelProvider"
    );
  }
  return context;
};
