import { OutlinedInputProps } from "@mui/material";
import merge from "deepmerge";
import InputMask from "react-input-mask";
import { FC, ReactNode } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { Error, Wrapper } from "./FormField";
import CustomInput from "./CustomInput";
import FormLabel from "./FormLabel";

const defaultProps = {
  label: "",
  gutter: true,
  mask: "",
  uppercase: false,
  customProps: {
    name: "",
    type: "text",
    fullWidth: true,
    variant: "outlined"
  },
  field: {},
  fieldState: {}
};

export interface InputFieldProps {
  mask?: string;
  label?: ReactNode;
  gutter?: boolean;
  uppercase?: boolean;
  field?: ControllerRenderProps;
  customProps?: OutlinedInputProps;
  helperText?: string;
  fieldState?: ControllerFieldState;
  onChange?: (value: string) => void;
  filter?: RegExp;
}

const InputField: FC<InputFieldProps> = props => {
  const fullProps = merge(defaultProps, props);

  if (props.fieldState) {
    fullProps.fieldState = props.fieldState;
  }
  if (props.field) {
    fullProps.field = props.field;
  }

  const hasError =
    fullProps.fieldState.error &&
    (fullProps.fieldState.isTouched ||
      fullProps.fieldState.isDirty ||
      fullProps.fieldState.invalid);
  const className = hasError ? "error" : "";

  return (
    <Wrapper className={fullProps.gutter ? "gutter" : ""}>
      {fullProps.label && (
        <FormLabel
          required={fullProps.customProps?.required}
          helperText={fullProps.helperText}
        >
          {fullProps.label}
        </FormLabel>
      )}
      {fullProps.mask ? (
        <InputMask
          {...fullProps.field}
          onChange={event => {
            const newEvent = { ...event };

            newEvent.target.value = newEvent.target.value.replace(
              /[^\d]/gi,
              ""
            );

            if (fullProps.onChange) {
              fullProps.onChange(newEvent.target.value);
            }

            fullProps.field.onChange(newEvent);
          }}
          mask={fullProps.mask}
          disabled={fullProps.customProps.disabled}
        >
          {() => {
            delete fullProps.customProps.disabled;
            return (
              <CustomInput
                {...fullProps.customProps}
                uppercase={fullProps.uppercase}
                inputRef={fullProps.field.ref}
                className={className}
                error={Boolean(hasError)}
              />
            );
          }}
        </InputMask>
      ) : (
        <CustomInput
          {...fullProps.customProps}
          {...fullProps.field}
          uppercase={fullProps.uppercase}
          className={className}
          error={Boolean(hasError)}
          onChange={event => {
            const newEvent = { ...event };
            if (fullProps.filter) {
              newEvent.target.value = newEvent.target.value.replace(
                fullProps.filter,
                ""
              );
            }
            if (fullProps.field.onChange) {
              fullProps.field.onChange(newEvent);
            }
            if (fullProps.customProps.onChange) {
              fullProps.customProps.onChange(newEvent);
            }
          }}
        />
      )}
      {hasError && <Error>{fullProps.fieldState.error?.message}</Error>}
    </Wrapper>
  );
};

export default InputField;
