import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import { NestedData, Success } from "./API";
import APIEndpoints from "enums/APIEndpoints";

export type Forensic = {
  id: string;
  name: string;
  report: string;
  ["user_created"]: string;
  ["name_user_created"]: string;
  ["date_insert"]: string;
  ["date_update"]: string;
  ["editor_users"]: string[];
  index: string;
};

export type ForensicPaginated = {
  page: number;
  forensicReports: Forensic[];
  ["total_count"]: number;
  ["total_pages"]: number;
  ["page_size"]?: number;
};

type UseForensicAPIType = {
  listPaginated: (
    customerId: string,
    page: number,
    pageSize: number,
    search: string | undefined
  ) => Promise<NestedData<ForensicPaginated>>;
  deleteByIndex: (customerId: string, index: string) => Promise<Success>;
  downloadPdf: (customerId: string, index: string) => Promise<string>;
  getForensic: (customerId: string, index: string) => Promise<Forensic>;
  create: (
    customerId: string,
    name: string,
    report: string,
    editorUsers: string[]
  ) => Promise<Success>;
  update: (
    customerId: string,
    name: string,
    report: string,
    index: string,
    editorUsers: string[]
  ) => Promise<Success>;
};

const useForensicAPI = (): UseForensicAPIType => {
  const API = useAxios();

  const listPaginated = async (
    customerId: string,
    page: number,
    pageSize: number,
    search: string | undefined
  ): Promise<NestedData<ForensicPaginated>> => {
    const response: AxiosResponse<NestedData<ForensicPaginated>> =
      await API.instance.post(APIEndpoints.FORENSIC_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize,
        search
      });
    return response.data;
  };

  const downloadPdf = async (
    customerId: string,
    index: string,
    language: string
  ): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.FORENSIC_REPORT_DOWNLOAD,
      {
        ["customer_id"]: customerId,
        ["forensic_report_index"]: index,
        ["language"]: language
      }
    );
    return response.data;
  };

  const deleteByIndex = async (
    customerId: string,
    index: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_DELETE,
      {
        ["customer_id"]: customerId,
        index
      }
    );
    return response.data;
  };

  const getForensic = async (
    customerId: string,
    index: string
  ): Promise<Forensic> => {
    const response: AxiosResponse<Forensic> = await API.instance.get(
      APIEndpoints.FORENSIC_GET,
      { params: { ["customer_id"]: customerId, index } }
    );
    return response.data;
  };

  const create = async (
    customerId: string,
    name: string,
    report: string,
    editorUsers: string[]
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_CREATE,
      {
        ["forensic_report_data"]: {
          ["customer_id"]: customerId,
          ["editor_users"]: editorUsers,
          name,
          report
        }
      }
    );
    return response.data;
  };

  const update = async (
    customerId: string,
    name: string,
    report: string,
    index: string,
    editorUsers: string[]
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_UPDATE,
      {
        ["forensic_report_data"]: {
          ["customer_id"]: customerId,
          ["editor_users"]: editorUsers,
          name,
          report,
          index
        }
      }
    );
    return response.data;
  };

  return {
    update,
    create,
    getForensic,
    downloadPdf,
    listPaginated,
    deleteByIndex
  };
};

export default useForensicAPI;
