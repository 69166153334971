import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

export type State = {
  name: string;
  ibge: string;
  id: string;
};

export type Country = {
  data: {
    country: string;
    states: State[];
  }[];
};

export type City = {
  name: string;
  ibge: string;
};

export type Cities = {
  data: {
    cities: City[];
    country: string;
    state: string;
  }[];
};

export type SpiaPrfRestriction = {
  name: string;
  code: string;
};

export type SpiaPrfRestrictions = {
  data: {
    category: string;
    data: SpiaPrfRestriction[];
  }[];
};

export type Timezone = {
  name: string;
  value: string;
};

export type Name = { name: string; lite: boolean };

export type MakesResponse = {
  make: Name[];
  source: string;
};

export type ModelsResponse = {
  make: string;
  model: Name[];
};

type UseToolsAPIType = {
  getCountry: (country: string) => Promise<Country>;
  getCities: (country: string, state: string) => Promise<Cities>;
  getSpiaPrfRestrictions: () => Promise<SpiaPrfRestrictions>;
  listAllMakes: () => Promise<MakesResponse>;
  getSpecificModels: (make: string) => Promise<ModelsResponse>;
  getGenericModels: (make: string) => Promise<ModelsResponse>;
};

const useToolsAPI = (): UseToolsAPIType => {
  const API = useAxios();

  const getCountry = async (country: string): Promise<Country> => {
    const response: AxiosResponse<Country> = await API.instance.get(
      APIEndpoints.COUNTRY,
      {
        params: { country }
      }
    );
    return response.data;
  };

  const getCities = async (country: string, state: string): Promise<Cities> => {
    const response: AxiosResponse<Cities> = await API.instance.get(
      APIEndpoints.CITIES,
      {
        params: {
          country,
          state
        }
      }
    );
    return response.data;
  };

  const getSpiaPrfRestrictions = async (): Promise<SpiaPrfRestrictions> => {
    const response: AxiosResponse<SpiaPrfRestrictions> = await API.instance.get(
      APIEndpoints.SPIA,
      {
        params: { category: "restrictions" }
      }
    );
    return response.data;
  };

  const listAllMakes = async (): Promise<MakesResponse> => {
    const response: AxiosResponse<MakesResponse> = await API.instance.get(
      APIEndpoints.MAKE_LIST,
      {
        params: {
          source: "RENAVAM"
        }
      }
    );
    return response.data;
  };

  const getSpecificModels = async (make: string): Promise<ModelsResponse> => {
    const response: AxiosResponse<ModelsResponse> = await API.instance.get(
      APIEndpoints.MODEL_LIST,
      {
        params: {
          make
        }
      }
    );
    return response.data;
  };

  const getGenericModels = async (make: string): Promise<ModelsResponse> => {
    const response: AxiosResponse<ModelsResponse> = await API.instance.get(
      APIEndpoints.MODEL_LITE_LIST,
      {
        params: {
          make
        }
      }
    );
    return response.data;
  };

  return {
    getCities,
    getCountry,
    getSpiaPrfRestrictions,
    listAllMakes,
    getGenericModels,
    getSpecificModels
  };
};

export default useToolsAPI;
