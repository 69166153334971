import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import CustomerFormDialog from "./CustomerFormDialog";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import { usePageLocation } from "contexts/PageLocationContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useCustomerAPI, { Customer, DataCustomerList } from "api/CustomerAPI";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import DataTable from "components/DataTable";
import DataTableActions from "components/DataTableActions";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const CustomerPage: FC = () => {
  const CustomerAPI = useCustomerAPI();
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [customerFormOpen, setCustomerFormOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const { errorHandler } = useErrorHandler();

  const [search, setSearch] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const openCustomerFormDialog = (customerId = "") => {
    setSelectedCustomer(customerId);
    setCustomerFormOpen(true);
  };

  const [isFetchingClients, setFetchingClients] = useState<boolean>(true);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const { sessionUser } = useAuth();

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setFetchingClients(true);
      try {
        if (searchValue) {
          const customerResponse = await CustomerAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue
          );
          setPaginator({
            totalPages: customerResponse.data.total_pages || 0,
            totalItems: customerResponse.data.total_count || 0
          });
          setCustomers(customerResponse.data.customers || []);
        } else {
          const customerResponse = await CustomerAPI.listAll(
            pageValue,
            pageSizeValue
          );
          const apiCustomers = customerResponse.data as DataCustomerList;
          setPaginator({
            totalPages: apiCustomers.total_pages,
            totalItems: apiCustomers.total_count
          });
          setCustomers(apiCustomers.customers);
        }
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setFetchingClients(false);
        setIsFirstLoad(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, page, pageSize);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const { t } = useTranslation();

  const editCustomer = (customer: Customer) => {
    openCustomerFormDialog(customer.customer_id);
  };

  const handleRemoveCustomer = (customer: Customer) => {
    showModal({
      type: "error",
      modalType: "confirm",
      onConfirm: () => removeCustomer(customer.customer_id),
      confirmText: t("action.remove"),
      title: t("CustomerPage.removeConfirmTitle", {
        name: customer.customer_name
      }),
      description: t("CustomerPage.removeConfirmDescription")
    });
  };

  const removeCustomer = async (customerId: string) => {
    try {
      await CustomerAPI.deleteById(customerId);
      snackNotification.success(t("CustomerPage.customerDeleted"));
      requestData(search, page, pageSize);
    } catch (_error) {
      showModal({
        type: "error",
        title: t("CustomerPage.cantRemoveCustomer"),
        description: ""
      });
    }
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.customers"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.accessControl")
      },
      {
        label: t("CustomerPage.title"),
        page: Pages.CUSTOMERS
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout isLoadingContent={isFirstLoad && isFetchingClients}>
        <PageSection
          title={t("CustomerPage.title")}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("DefaultPageLayout.researchCustomers")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openCustomerFormDialog()}
            >
              {t("CustomerPage.newCustomers")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "customer_name", label: t("UsersPage.name") },
              {
                key: "actions",
                label: t("UsersPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["customer_name", "asc"]}
            onHeaderSort={setCustomers}
            data={customers}
            renderRow={row => [
              <>
                <strong>{row["customer_name"]}</strong>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => editCustomer(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemoveCustomer(row)
                    }
                  ]}
                />
              </>
            ]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isFetchingClients}
          />
        </PageSection>
      </InnerPageLayout>
      <CustomerFormDialog
        customerId={selectedCustomer}
        open={customerFormOpen}
        setOpen={setCustomerFormOpen}
        updateCallerContent={() => requestData(search, page, pageSize)}
      />
    </DefaultPageLayout>
  );
};

export default CustomerPage;
