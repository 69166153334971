import AxiosLib, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import Cookies from "utils/Cookies";
import { Auth } from "api/AuthAPI";
import APIEndpoints from "enums/APIEndpoints";
import HTTPStatus from "enums/HTTPStatus";
import history from "utils/history";
import Pages from "enums/Pages";
import { useAuth } from "contexts/AuthContext";

const host = process.env.REACT_APP_LINCE_HOST ?? "localhost";
const baseURL = `https://${host}`;
const wsHost = process.env.REACT_APP_LINCE_WS_HOST ?? "localhost";
const wsURL = `wss://${wsHost}`;

export type NestedData<T> = { data: T };

export type Success = { success: string };

type UseAxiosType = {
  instance: AxiosInstance;
  baseURL: string;
  wsURL: string;
};

const useAxios = (): UseAxiosType => {
  const { setAccessToken, accessToken, signOut } = useAuth();

  const axiosConfig = {
    baseURL,
    headers: {
      authorizationToken: accessToken
    }
  };
  const instance = AxiosLib.create(axiosConfig);

  const axios = {
    instance,
    baseURL,
    wsURL
    // CancelToken: AxiosLib.CancelToken,
    // isCancel: AxiosLib.isCancel
    // setAccessToken: (token: string): void => {
    //   instance.defaults.headers.common = {
    //     ...instance.defaults.headers.common,
    //     authorizationToken: token
    //   };
    // },
    // removeAccessToken: (): void => {
    //   const copy = { ...instance.defaults.headers.common };
    //   delete copy.authorizationToken;
    //   instance.defaults.headers.common = { ...copy };
    // }
  };

  const refreshAccessToken = async (config: AxiosRequestConfig) => {
    const refreshTokenCookie = Cookies.get(Cookies.REFRESH_TOKEN) as string;
    const sessionUserCookie = Cookies.get(Cookies.SESSION_USER) as Auth["user"];
    if (!refreshTokenCookie || !sessionUserCookie?.username) return;
    try {
      const refreshTokenResponse = await instance.post(
        APIEndpoints.REFRESH_TOKEN,
        {
          username: sessionUserCookie?.username,
          ["refresh_token"]: refreshTokenCookie
        }
      );
      const accessToken = refreshTokenResponse?.data?.["access_token"];
      if (!accessToken) return;
      setAccessToken(accessToken);
      Cookies.set(Cookies.ACCESS_TOKEN, accessToken);
      axios.instance.defaults.headers.common = {
        ...axios.instance.defaults.headers.common,
        authorizationToken: accessToken
      };

      const newConfig = { ...config };
      newConfig.headers.authorizationToken = accessToken;
      return axios.instance(newConfig);
    } catch (e) {
      revokeSession();
      window.location.reload();
      return Promise.reject(e);
    }
  };

  const revokeSession = () => {
    if (accessToken) setAccessToken(undefined);
    localStorage.clear();
    Cookies.remove(Cookies.ACCESS_TOKEN);
    Cookies.remove(Cookies.REFRESH_TOKEN);
    Cookies.remove(Cookies.SESSION_USER);
  };

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (
        response.config.url === APIEndpoints.LOGIN &&
        response.data.accessToken
      ) {
        axios.instance.defaults.headers.common = {
          ...axios.instance.defaults.headers.common,
          authorizationToken: response.data.accessToken
        };
      }
      return response;
    },
    async (error: AxiosError) => {
      const { response, config } = error;
      if (
        config.url !== APIEndpoints.REFRESH_TOKEN &&
        Cookies.get(Cookies.ACCESS_TOKEN) &&
        response?.status === HTTPStatus.ERROR_401_UNAUTHORIZED
      ) {
        // Request new access token with refresh token;
        return refreshAccessToken(config);
      }
      if (
        config.url === APIEndpoints.REFRESH_TOKEN &&
        response?.status === HTTPStatus.ERROR_403_FORBIDDEN
      ) {
        // New access token request failed, return user to sign in screen;
        revokeSession();
        window.location.reload();
      } else if (
        config.url !== APIEndpoints.LOGOUT &&
        response?.status === HTTPStatus.ERROR_403_FORBIDDEN
      ) {
        signOut();
        history.push(Pages.ERROR_403);
      }
      return Promise.reject(error);
    }
  );

  return axios;
};

export default useAxios;
