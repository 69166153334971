import { FC, useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";
import { ImageCoordinate } from "components/ImageViewer";

const Wrapper = styled(Box)(() => ({
  position: "absolute",
  bottom: -6,
  zIndex: 11,
  "&.left": {
    left: 0
  },
  "&.right": {
    right: 0
  }
}));

type PicInPictureParams = {
  imageSize: { width: number; height: number };
  background: HTMLImageElement;
  platePosition: ImageCoordinate;
};

const PicInPicture: FC<PicInPictureParams> = ({
  background,
  imageSize,
  platePosition
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [boxSize] = useState({ width: 200, height: 120 });
  const [pipPosition, setPipPosition] = useState<"left" | "right">("right");

  const draw = () => {
    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    const posX = -(platePosition.x - (boxSize.width - platePosition.width) / 2);
    const posY = -(
      platePosition.y -
      (boxSize.height - platePosition.height) / 2
    );

    if (background.width / 2 < platePosition.x + platePosition.width) {
      setPipPosition("left");
    } else {
      setPipPosition("right");
    }

    context.drawImage(background, posX, posY);
  };

  useEffect(() => {
    draw();
  }, [imageSize.width, imageSize.height]);

  return (
    <Wrapper className={pipPosition}>
      <canvas ref={canvasRef} width={boxSize.width} height={boxSize.height} />
    </Wrapper>
  );
};

export default PicInPicture;
