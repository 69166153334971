import {
  Box,
  Grid,
  MenuItem,
  styled,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { FC, useState } from "react";
import { Clock } from "react-feather";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import SelectField from "components/SelectField";
import FormLabel from "components/FormLabel";

const Container = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  zIndex: 1,
  width: "100%",
  maxWidth: 725,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[16],
  borderRadius: theme.shape.borderRadius,
  "& > form": {
    width: "100%"
  }
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterInterval = {
  startEquipment: string;
  endEquipment: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  interval: string;
  inMinutes: boolean;
};

const defaultValues: FormFilterInterval = {
  startEquipment: "",
  endEquipment: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  interval: "60",
  inMinutes: true
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterInterval) => void;
};

const IntervalFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const [inMinutes, setInMinutes] = useState(true);

  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<FormFilterInterval>({
    defaultValues
  });

  const clearForm = () => {
    setInMinutes(true);
    reset();
  };

  const onSubmit = async (data: FormFilterInterval) => {
    onFilter({ ...data, inMinutes });
    setOpen(false);
  };

  return (
    <>
      {open && (
        <Container>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={6}>
                  <FormLabel>
                    {t("CorrelationReportPage.initialEquipment")}
                  </FormLabel>
                  <Controller
                    name="startEquipment"
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        style={{ marginBottom: 0 }}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="1">Seleção S1</MenuItem>
                        <MenuItem value="2">Seleção S2</MenuItem>
                        <MenuItem value="3">Seleção S3</MenuItem>
                      </SelectField>
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        label={t("CaptureReportPage.startDateTime")}
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "25px" }}>
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <TimePickerField
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>
                    {t("CorrelationReportPage.finalEquipment")}
                  </FormLabel>
                  <Controller
                    name="endEquipment"
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        style={{ marginBottom: 0 }}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="1">Seleção E1</MenuItem>
                        <MenuItem value="2">Seleção E2</MenuItem>
                        <MenuItem value="3">Seleção E3</MenuItem>
                      </SelectField>
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        label={t("CorrelationReportPage.endDateTime")}
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "25px" }}>
                  <Controller
                    name="endTime"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <TimePickerField
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="interval"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        field={{ ...field }}
                        label={t("CorrelationReportPage.interval")}
                        gutter={false}
                        customProps={{
                          startAdornment: <Clock />
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ display: "flex", alignItems: "flex-end" }}
                >
                  <ToggleButtonGroup
                    value={inMinutes ? "minutes" : "hours"}
                    color="primary"
                    size="small"
                    exclusive
                    onChange={(e, value) => {
                      e.preventDefault();
                      if (value) {
                        setInMinutes(value === "minutes");
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="minutes" aria-label="left aligned">
                      {t("CorrelationReportPage.minute")}
                    </ToggleButton>
                    <ToggleButton value="hours" aria-label="centered">
                      {t("CorrelationReportPage.hour")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Content>
            <Footer>
              <Button
                customProps={{
                  color: "primary",
                  variant: "outlined",
                  onClick: clearForm
                }}
              >
                {t("action.clear")}
              </Button>
              <Button
                customProps={{
                  type: "submit"
                }}
              >
                {t("action.filter")}
              </Button>
            </Footer>
          </form>
        </Container>
      )}
    </>
  );
};

export default IntervalFilter;
