import {
  Box,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableSortLabel
} from "@mui/material";
import Pages from "enums/Pages";
import { orderBy } from "lodash";
import Order from "enums/OrderType";
import { FC, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { format, isValid, parseISO } from "date-fns";
import { Table, TableContainer } from "components/Table";
import { MonitoringVehiclesReport } from "api/MonitoringVehiclesReportAPI";
import LinkPlate from "components/LinkPlate";
import { EMPTY_VALUE } from "utils/String";

type Props = {
  rows: MonitoringVehiclesReport[];
  setRows: (value: MonitoringVehiclesReport[]) => void;
};

const MonitoringTable: FC<Props> = ({ rows, setRows }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<Order>("asc");
  const [orderProperty, setOrderProperty] = useState<string>("id");

  const handleRequestSort = (property: string) => {
    const isAsc = orderProperty === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderProperty(property);

    const sortReportResults = orderBy(
      rows,
      ["", property],
      isAsc ? [false, "asc"] : [false, "desc"]
    );

    setRows(sortReportResults || []);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                sortDirection={orderProperty === "id" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "id"}
                  direction={orderProperty === "id" ? order : "asc"}
                  onClick={() => handleRequestSort("id")}
                >
                  {t("MonitoringTable.id")}
                  {orderProperty === "id" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="th"
                sortDirection={orderProperty === "date_capture" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "date_capture"}
                  direction={orderProperty === "date_capture" ? order : "asc"}
                  onClick={() => handleRequestSort("date_capture")}
                >
                  {t("MonitoringTable.dateTime")}
                  {orderProperty === "date_capture" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="th"
                sortDirection={orderProperty === "plate" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "plate"}
                  direction={orderProperty === "plate" ? order : "asc"}
                  onClick={() => handleRequestSort("plate")}
                >
                  {t("MonitoringTable.plate")}
                  {orderProperty === "plate" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="th"
                sortDirection={orderProperty === "equipment" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "equipment"}
                  direction={orderProperty === "equipment" ? order : "asc"}
                  onClick={() => handleRequestSort("equipment")}
                >
                  {t("MonitoringTable.equipment")}
                  {orderProperty === "equipment" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="th"
                sortDirection={orderProperty === "camera" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "camera"}
                  direction={orderProperty === "camera" ? order : "asc"}
                  onClick={() => handleRequestSort("camera")}
                >
                  {t("MonitoringTable.camera")}
                  {orderProperty === "camera" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                component="th"
                sortDirection={
                  orderProperty === "descriptiondescription" ? order : false
                }
              >
                <TableSortLabel
                  active={orderProperty === "description"}
                  direction={orderProperty === "description" ? order : "asc"}
                  onClick={() => handleRequestSort("description")}
                >
                  {t("MonitoringTable.description")}
                  {orderProperty === "description" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(monitoring => (
              <TableRow
                key={monitoring.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 }
                }}
              >
                <TableCell component="td" scope="row">
                  {monitoring.id}
                </TableCell>
                <TableCell component="td" scope="row">
                  {isValid(parseISO(monitoring["date_capture"]))
                    ? format(
                        parseISO(monitoring["date_capture"]),
                        t("MonitoringTable.dateFormat")
                      )
                    : monitoring["date_capture"]}
                </TableCell>
                <TableCell component="td" scope="row">
                  <LinkPlate
                    plate={monitoring.plate}
                    path={Pages.VEHICLE_OVERVIEW}
                  />
                </TableCell>
                <TableCell component="td" scope="row">
                  <strong>{monitoring.equipment}</strong>
                </TableCell>
                <TableCell component="td" scope="row">
                  {monitoring.camera}
                </TableCell>
                <TableCell component="td" scope="row">
                  {monitoring?.description || EMPTY_VALUE}
                </TableCell>
              </TableRow>
            ))}
            {rows.length < 1 && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 }
                }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  colSpan={7}
                  align="center"
                >
                  <Typography sx={{ opacity: 0.5 }}>
                    {t("MonitoringTable.noItems")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MonitoringTable;
