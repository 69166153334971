import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type SpiaPrfRestriction = {
  ["tipoRestricao"]: string;
  ["codigoTipoRestricao"]: string;
  ["dataHoraRegistroRenavam"]: string;
};

export type SpiaPrfReport = {
  plate: string;
  ["date_insert"]: string;
  ["spia_date_update"]: string;
  ["make_model"]: string;
  color: string;
  ["make_year"]: string;
  ["model_year"]: string;
  city: string;
  ["federal_state"]: string;
  ["restrictions"]: SpiaPrfRestriction[];
};

export type SpiaPrfReportPaginated = {
  registers: {
    items: SpiaPrfReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type ListSpiaPrfReportParams = {
  ["customer_id"]: string;
  ["page_size"]?: number;
  plate?: string;
  state?: string;
  city?: string[];
  page?: number;
};

type UseSpiaPrfReportAPIType = {
  list: (param: ListSpiaPrfReportParams) => Promise<SpiaPrfReportPaginated>;
};

const useSpiaPrfReportAPI = (): UseSpiaPrfReportAPIType => {
  const API = useAxios();

  const list = async (
    filter: ListSpiaPrfReportParams
  ): Promise<SpiaPrfReportPaginated> => {
    const response: AxiosResponse<SpiaPrfReportPaginated> =
      await API.instance.post(APIEndpoints.SPIA_REPORT_LIST, filter);
    return response.data;
  };

  return {
    list
  };
};

export default useSpiaPrfReportAPI;
