import { styled, ToggleButton as MToggleButton } from "@mui/material";

export const ToggleButton = styled(MToggleButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "2px 16px",
  height: 32,
  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderLeft: "1px solid #e0e0e0",
    marginLeft: "initial !important"
  },
  "&.Mui-selected:hover, &.Mui-selected": {
    backgroundColor: theme.palette.background.default
  },
  "&.Mui-selected": {
    border: `1px solid ${theme.palette.secondary.main} !important`
  }
}));
