import {
  Button,
  Icon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import {
  Edit,
  Mail,
  Send,
  Sliders,
  Volume1,
  Smartphone,
  MinusCircle,
  MessageCircle,
  MessageSquare
} from "react-feather";
import Pages from "enums/Pages";
import { EMPTY_VALUE } from "utils/String";
import { useParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import useMonitoringAPI, { Monitoring } from "api/MonitoringAPI";
import useMonitoringRuleAPI, { Rule } from "api/MonitoringRuleAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import MonitoredVehicleFilter, {
  FormMonitoredVehicleFilter
} from "pages/Settings/MonitoredVehicles/MonitoredVehicleFilter";
import MonitoringVehiclesFormDialog from "./MonitoringVehiclesFormDialog";
import MonitoringVehiclesUploadDialog from "./MonitoringVehiclesUploadDialog";
import LinkPlate from "components/LinkPlate";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import MonitoringRuleFormDialog from "./MonitoringRuleFormDialog";

export type MonitoringClone = {
  plate: string;
  make: string;
  model: string;
  color: string;
  class: string;
};

export const defaultRule = {
  ["customer_id"]: "",
  ["rule_information"]: {
    ["allowed_plates"]: [],
    ["start_interval"]: "",
    ["end_interval"]: "",
    ["num_passes"]: 0,
    ["monitoring_until"]: "",
    notifications: {
      email: false,
      whatsapp: false,
      telegram: false,
      ["telegram_groups"]: [],
      sms: false
    }
  },
  ["sound_alert"]: false,
  ["popup_alert"]: false,
  owners: [],
  ["owners_group"]: []
};

const MonitoringVehiclesPage: FC = () => {
  const MonitoringAPI = useMonitoringAPI();
  const MonitoringRuleAPI = useMonitoringRuleAPI();
  const { showModal } = useModal();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [isRuleDialogOpen, setIsRuleDialogOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<string>("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [monitorings, setMonitorings] = useState<Monitoring[]>([]);
  const { sessionUser } = useAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [monitoringRule, setMonitoringRule] = useState<Rule>(defaultRule);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [filterData, setFilterData] =
    useState<FormMonitoredVehicleFilter | null>(null);
  const { errorHandler } = useErrorHandler();

  const params = useParams<MonitoringClone>();

  const monitoringClone: MonitoringClone = {
    plate: params.plate ? decodeURIComponent(params.plate) : "",
    make: params.make ? decodeURIComponent(params.make) : "",
    model: params.model ? decodeURIComponent(params.model) : "",
    color: params.color ? decodeURIComponent(params.color) : "",
    class: params.class ? decodeURIComponent(params.class) : ""
  };

  const openFormDialog = (customer_id = "") => {
    setItemSelected(customer_id);
    setIsFormDialogOpen(true);
  };

  const openUploadDialog = () => {
    setIsUploadDialogOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestData = useCallback(
    async (currentPage, filter, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setIsLoading(true);
      try {
        const [responseRule, monitoringResponse] = await Promise.all([
          MonitoringRuleAPI.getByCustomerId(sessionUser.customer_id),
          MonitoringAPI.listFiltered(
            customerId,
            {
              page: currentPage,
              soundAlert: filter ? filter.soundAlert : "",
              plate: filter ? filter.plate : "",
              type: filter ? filter.type : "",
              endDate: filter ? filter.endDate : "",
              startDate: filter ? filter.startDate : "",
              equipment: filter ? filter.equipment : ""
            },
            pageSizeValue
          )
        ]);

        if (responseRule !== null) {
          setMonitoringRule(responseRule);
        }
        setPaginator({
          totalPages: monitoringResponse.data["total_pages"],
          totalItems: monitoringResponse.data["total_count"]
        });
        setMonitorings(monitoringResponse.data.monitorings || []);
        setPage(currentPage);

        if (
          monitoringResponse.data.monitorings.length === 0 &&
          monitoringClone.plate !== ""
        ) {
          openFormDialog();
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    if (monitoringClone.plate === "") {
      requestData(page, filterData, pageSize);
    } else {
      requestData(
        page,
        { ...filterData, plate: monitoringClone.plate },
        pageSize
      );
    }
  }, [requestData, pageSize]);

  useEffect(() => {
    if (filterData !== null) {
      requestData(1, filterData, pageSize);
    }
  }, [filterData]);

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.monitoredVehicles"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("MonitoringVehicles.title"),
        page: Pages.MONITORING_VEHICLES
      }
    ]);
  }, [t, Pages]);

  const editItem = (item: Monitoring) => {
    openFormDialog(item.index);
  };

  const handleRemove = (item: Monitoring) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(item),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name: item.plate }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async (item: Monitoring) => {
    try {
      if (sessionUser) {
        await MonitoringAPI.deleteByIndex({
          customerId: sessionUser["customer_id"],
          index: item.index
        });

        snackNotification.success(
          t("MonitoringVehicles.monitoredVehicleRemoved")
        );
        requestData(page, filterData, pageSize);
      }
    } catch (error) {
      errorHandler({ error });
    }
  };

  const onCreateOrUpdate = () => {
    requestData(page, filterData, pageSize);
  };

  const onFilter = (data: FormMonitoredVehicleFilter) => {
    setFilterData(data);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("MonitoringVehicles.title")}
          isLoading={isLoading}
          actions={
            <>
              <PageSectionHeaderAction
                variant="text"
                color="primary"
                onClick={() => setIsRuleDialogOpen(true)}
              >
                {monitoringRule.customer_id
                  ? t("MonitoringVehicles.editMonitoredRule")
                  : t("MonitoringVehicles.addRule")}
              </PageSectionHeaderAction>
              <PageSectionHeaderAction
                variant="outlined"
                color="primary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            </>
          }
          primaryActions={
            <>
              <Button
                id="add-vehicle-menu"
                aria-haspopup="true"
                aria-controls="add-vehicle-menu"
                color="secondary"
                variant="contained"
                endIcon={<Icon sx={{ mt: 0.5 }}>▾</Icon>}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {t("MonitoringVehicles.addVehicle")}
              </Button>
              <Menu
                id="add-vehicle-menu"
                aria-labelledby="add-vehicle-menu"
                open={isMenuOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <MenuItem onClick={() => openFormDialog()}>
                  <ListItemText>
                    {t("MonitoringVehicles.newVehicle")}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => openUploadDialog()}>
                  <ListItemText>{t("MonitoringVehicles.upload")}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
        >
          <DataTable
            watermarked
            headers={[
              {
                key: "initial_date",
                label: t("MonitoringVehicles.expirationDate")
              },
              { key: "plate", label: t("MonitoringVehicles.plate") },
              { key: "type", label: t("MonitoringVehicles.type.label") },
              {
                key: "monitoring_data.description",
                label: t("MonitoringVehicles.description")
              },
              {
                key: "monitoring_data.characteristics",
                label: t("MonitoringVehicles.characteristics")
              },
              {
                key: "alert",
                label: t("MonitoringVehicles.alert"),
                noSort: true
              },
              {
                key: "notification",
                label: t("MonitoringVehicles.notification"),
                noSort: true
              },
              { key: "owners", label: t("MonitoringVehicles.responsible") },
              {
                key: "actions",
                label: t("MonitoringVehicles.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["date", "asc"]}
            onHeaderSort={setMonitorings}
            data={monitorings}
            renderRow={row => [
              <>
                <div>{row["initial_date"] || EMPTY_VALUE}</div>
                <div>{row["final_date"] || EMPTY_VALUE}</div>
              </>,
              <>
                {row.plate ? (
                  <LinkPlate plate={row.plate} path={Pages.VEHICLE_OVERVIEW} />
                ) : (
                  EMPTY_VALUE
                )}
              </>,
              <>
                <strong>
                  {row.type
                    ? t(`MonitoredVehiclesDashboardPage.${row.type}`)
                    : EMPTY_VALUE}
                </strong>
              </>,
              <>
                {row["monitoring_data"]?.description === ""
                  ? EMPTY_VALUE
                  : row["monitoring_data"]?.description}
              </>,
              <>
                {row["monitoring_data"]?.characteristics === ""
                  ? EMPTY_VALUE
                  : row["monitoring_data"]?.characteristics}
              </>,
              <>
                {row["sound_alert"] ? (
                  <Tooltip
                    title={t("MonitoringVehicles.soundAlert").toString()}
                  >
                    <Volume1 size={16} style={{ marginRight: "4px" }} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
                {row["popup_alert"] ? (
                  <Tooltip
                    title={t("MonitoringVehicles.popupAlert").toString()}
                  >
                    <MessageSquare size={16} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
              </>,
              <>
                {row["monitoring_data"].notifications.email ? (
                  <Tooltip title={t("MonitoringVehicles.email").toString()}>
                    <Mail size={16} style={{ marginRight: "4px" }} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
                {row["monitoring_data"].notifications.whatsapp ? (
                  <Tooltip title={t("MonitoringVehicles.whatsapp").toString()}>
                    <MessageCircle size={16} style={{ marginRight: "4px" }} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
                {row["monitoring_data"].notifications.telegram ? (
                  <Tooltip title={t("MonitoringVehicles.telegram").toString()}>
                    <Send size={16} style={{ marginRight: "4px" }} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
                {row["monitoring_data"].notifications.sms ? (
                  <Tooltip title={t("MonitoringVehicles.sms").toString()}>
                    <Smartphone size={16} />
                  </Tooltip>
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
              </>,
              <>
                {row.owners.length > 0 ? (
                  row.owners.join(", ")
                ) : (
                  <div style={{ textAlign: "center" }}>{EMPTY_VALUE}</div>
                )}
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => editItem(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemove(row)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[4, 5, 6, 7]}
            hideColumnsXs={[0, 3, 4, 5, 6, 7]}
            page={page}
            onPageChange={pageValue =>
              requestData(pageValue, filterData, pageSize)
            }
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <MonitoredVehicleFilter
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        onFilter={onFilter}
      />
      <MonitoringVehiclesUploadDialog
        open={isUploadDialogOpen}
        setOpen={setIsUploadDialogOpen}
        onUploadComplete={() => requestData(page, filterData, pageSize)}
      />
      <MonitoringVehiclesFormDialog
        monitoredIndex={itemSelected}
        open={isFormDialogOpen}
        setOpen={setIsFormDialogOpen}
        onRequestFinish={onCreateOrUpdate}
      />
      <MonitoringRuleFormDialog
        monitoringRule={monitoringRule}
        setMonitoringRule={setMonitoringRule}
        open={isRuleDialogOpen}
        setOpen={setIsRuleDialogOpen}
        onRequestFinish={onCreateOrUpdate}
      />
    </DefaultPageLayout>
  );
};

export default MonitoringVehiclesPage;
