import { createContext, FC, ReactNode, useContext, useState } from "react";
import { CustomFilter } from "api/CustomFilterAPI";

type ProviderProps = {
  filterKey: string;
  children: ReactNode;
};

interface ContextProps {
  currentFilterKey: string;
  filterData: string;
  appliedCustomFilter: CustomFilter | undefined;
  setFilterData: (data: string) => void;
  setAppliedCustomFilter: (filter: CustomFilter | undefined) => void;
  setIsListFilterDialogOpen: (open: boolean) => void;
  isListFilterDialogOpen: boolean;
  setIsSaveFilterDialogOpen: (open: boolean) => void;
  isSaveFilterDialogOpen: boolean;
}

const CustomFilterContext = createContext<ContextProps>({
  currentFilterKey: "",
  filterData: "",
  isListFilterDialogOpen: false,
  isSaveFilterDialogOpen: false,
  appliedCustomFilter: undefined,
  setFilterData(): void {
    throw new Error("setFilterData not implemented");
  },
  setIsListFilterDialogOpen(): void {
    throw new Error("setIsListFilterDialogOpen not implemented");
  },
  setIsSaveFilterDialogOpen(): void {
    throw new Error("setIsSaveFilterDialogOpen not implemented");
  },
  setAppliedCustomFilter(): void {
    throw new Error("setAppliedCustomFilter not implemented");
  }
});

export const CustomFilterProvider: FC<ProviderProps> = ({
  children,
  filterKey
}) => {
  const [currentFilterKey] = useState<string>(filterKey);
  const [filterData, setFilterData] = useState<string>("");
  const [isListFilterDialogOpen, setIsListFilterDialogOpen] = useState(false);
  const [isSaveFilterDialogOpen, setIsSaveFilterDialogOpen] = useState(false);
  const [appliedCustomFilter, setAppliedCustomFilter] = useState<
    CustomFilter | undefined
  >();

  return (
    <CustomFilterContext.Provider
      value={{
        currentFilterKey,
        filterData,
        setFilterData,
        isListFilterDialogOpen,
        setIsListFilterDialogOpen,
        isSaveFilterDialogOpen,
        setIsSaveFilterDialogOpen,
        appliedCustomFilter,
        setAppliedCustomFilter
      }}
    >
      {children}
    </CustomFilterContext.Provider>
  );
};

export const useCustomFilter = (): ContextProps => {
  const context = useContext(CustomFilterContext);
  if (context === undefined) {
    throw new Error(
      "useCustomFilter must be used within an CustomFilterProvider"
    );
  }
  return context;
};
