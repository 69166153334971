import { AxiosResponse } from "axios";

import { Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

type UpdatePlateParams = {
  ["customer_id"]: string;
  ["old_plate"]: string;
  ["new_plate"]: string;
  ["register_id"]: string;
};

type UseUpdatePlateAPIType = {
  updatePlate: (params: UpdatePlateParams) => Promise<Success>;
};

const useUpdatePlateAPI = (): UseUpdatePlateAPIType => {
  const API = useAxios();

  const updatePlate = async (params: UpdatePlateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.UPDATE_PLATE,
      params
    );
    return response.data;
  };

  return {
    updatePlate
  };
};

export default useUpdatePlateAPI;
