import {
  Box,
  Grid,
  Slider,
  styled,
  Divider,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import { AxiosError } from "axios";
import Button from "components/Button";
import Drawer from "components/Drawer";
import useToolsAPI from "api/ToolsAPI";
import { CameraFilter } from "api/CameraAPI";
import FormLabel from "components/FormLabel";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import CustomFilterMenu from "components/CustomFilterMenu/CustomFilterMenu";
import { VehicleColor, sliderMarks, vehicleColors } from "utils/VehicleConsts";
import { useCustomFilter } from "components/CustomFilterMenu/CustomFilterContext";
import { VehiclesMake } from "api/CaptureReportAPI";

const ContentContainer = styled(Box)(() => ({
  height: "calc(100vh - 200px)",
  overflow: "auto"
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`,
  maxHeight: "100%"
}));

const MSlider = styled(Slider)(() => ({
  height: 4,
  "& .MuiSlider-markLabel": {
    fontSize: 12
  }
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type VehicleCharacteristics = {
  make: string;
  color: string;
  model: string;
};

export type FormFilterCaptures = {
  id: string;
  equipments?: string[];
  cameras?: string[];
  direction?: string;
  street?: string;
  number?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  vehicleClasses?: string[];
  plate?: string;
  startTime?: string | Date | null;
  endTime?: string | Date | null;
  startDate?: string | Date | null;
  endDate?: string | Date | null;
  make?: string;
  model?: string;
  color?: string;
  numberOccurrences?: number[];
};

type Props = {
  open: boolean;
  cameras: CameraFilter[];
  equipments: string[];
  makes: VehiclesMake[];
  setOpen: (isOpen: boolean) => void;
  filter: FormFilterCaptures;
  defaultValues: FormFilterCaptures;
  onFilter: (data: FormFilterCaptures) => void;
  onClear?: () => void;
};

const valueLabelFormat = (value: number) => {
  if (value === 0) {
    return "1";
  }
  if (value > 100) {
    return "100+";
  }
  return value.toString();
};

const CaptureReportFilter: FC<Props> = ({
  open,
  setOpen,
  filter,
  defaultValues,
  onFilter,
  onClear,
  cameras,
  equipments,
  makes
}) => {
  const ToolsAPI = useToolsAPI();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [cameraSelected, setCameraSelected] = useState<CameraFilter[]>([
    {
      ["location_name"]: t("form.all"),
      ["camera_name"]: t("form.all")
    }
  ]);
  const [equipmentSelected, setEquipmentSelected] = useState<string[]>([
    t("form.all")
  ]);
  const [makeSelected, setMakeSelected] = useState<VehiclesMake>({
    name: "",
    isDivider: false
  });
  const [colorSelected, setColorSelected] = useState<VehicleColor>({
    color: ""
  });
  const [specificModels, setSpecificModels] = useState<string[]>([]);
  const [genericModels, setGenericModels] = useState<string[]>([]);
  const [specificModelSelected, setSpecificModelSelected] =
    useState<string>("");
  const [genericModelSelected, setGenericModelSelected] = useState<string>("");
  const [isSpecificModelLoading, setSpecificModelLoading] =
    useState<boolean>(false);
  const [isGenericModelLoading, setGenericModelLoading] =
    useState<boolean>(false);
  const vehicleClasses = ["car", "motorcycle", "truck", "bus", "unknown"];
  const [vehicleClassSelected, setVehicleClassSelected] = useState<string[]>(
    []
  );
  const [sliderDisabled, setSliderDisabled] = useState<boolean>(true);

  const [numberOccurrenceValue, setNumberOccurrenceValue] = useState<number[]>([
    1, 120
  ]);

  const setSpecificModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setSpecificModelLoading(true);
    try {
      const response = await ToolsAPI.getSpecificModels(makeSelected.name);
      setSpecificModels(response.model.map(item => item.name) || []);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSpecificModelLoading(false);
    }
  };

  const setGenericModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setGenericModelLoading(true);
    try {
      const response = await ToolsAPI.getGenericModels(makeSelected.name);
      setGenericModels(response.model.map(item => item.name) || []);
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.data.error.code === 4054) {
        setGenericModels([]);
      } else {
        errorHandler({ error });
      }
    } finally {
      setGenericModelLoading(false);
    }
  };

  const handleNumberOccurrenceChange = (
    _event: Event,
    newValue: number | number[]
  ) => {
    setNumberOccurrenceValue(newValue as number[]);
  };

  const { control, handleSubmit, reset, watch, getValues } =
    useForm<FormFilterCaptures>({
      mode: "onChange",
      defaultValues: filter
    });

  const { appliedCustomFilter, isSaveFilterDialogOpen, setFilterData } =
    useCustomFilter();
  const watchForm = watch();

  useEffect(() => {
    reset(filter);
  }, [filter]);

  useEffect(() => {
    if (makeSelected.name === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    } else {
      setSpecificModelSelected("");
      setSpecificModelOptions();
      setGenericModelSelected("");
      setGenericModelOptions();
    }
  }, [makeSelected]);

  useEffect(() => {
    if (genericModelSelected === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    }
  }, [genericModelSelected]);

  useEffect(() => {
    if (specificModelSelected === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    }
  }, [specificModelSelected]);

  useEffect(() => {
    if (!appliedCustomFilter) return;
    const filterData = JSON.parse(
      appliedCustomFilter.filter_data
    ) as FormFilterCaptures;
    reset(filterData);
    setEquipmentSelected(filterData.equipments ?? []);
    setVehicleClassSelected(filterData.vehicleClasses ?? []);

    if (filterData.numberOccurrences) {
      const max =
        filterData.numberOccurrences[1] === -1
          ? 120
          : filterData.numberOccurrences[1];
      setNumberOccurrenceValue([filterData.numberOccurrences[0], max]);
    } else {
      setNumberOccurrenceValue([1, 120]);
    }
  }, [appliedCustomFilter]);

  useEffect(() => {
    if (
      getValues("plate") ||
      getValues("make") ||
      getValues("model") ||
      getValues("color")
    ) {
      setSliderDisabled(false);
    } else {
      setSliderDisabled(true);
    }
  }, [watchForm]);

  const clearForm = () => {
    setCameraSelected([
      {
        ["location_name"]: t("form.all"),
        ["camera_name"]: t("form.all")
      }
    ]);
    setEquipmentSelected([t("form.all")]);
    setMakeSelected({ name: "", isDivider: false });
    setGenericModelSelected("");
    setSpecificModelSelected("");
    setColorSelected({ color: "" });
    setVehicleClassSelected([]);
    setNumberOccurrenceValue([1, 120]);
    reset(defaultValues);
    if (onClear) onClear();
  };

  const getFilterData = (data: FormFilterCaptures): FormFilterCaptures => {
    const newData = { ...data };

    if (equipmentSelected.includes(t("form.all"))) {
      newData.equipments = [];
    } else {
      newData.equipments = equipmentSelected;
    }

    const IsAll = cameraSelected.filter(
      c => c["location_name"] === t("form.all")
    );

    if (IsAll.length > 0) {
      newData.cameras = [];
    } else {
      newData.cameras = cameraSelected.map(camera => camera.camera_name);
    }

    newData.vehicleClasses = vehicleClassSelected;

    newData.make = makeSelected.name;

    if (specificModelSelected === "" && genericModelSelected !== "") {
      newData.model = genericModelSelected;
    } else if (genericModelSelected === "" && specificModelSelected !== "") {
      newData.model = specificModelSelected;
    } else {
      newData.model = undefined;
    }

    newData.color = colorSelected.color;

    newData.startTime = newData.startTime ? new Date(newData.startTime) : null;
    newData.endTime = newData.endTime ? new Date(newData.endTime) : null;
    newData.startDate = newData.startDate ? new Date(newData.startDate) : null;
    newData.endDate = newData.endDate ? new Date(newData.endDate) : null;
    const max = numberOccurrenceValue[1] > 100 ? -1 : numberOccurrenceValue[1];
    newData.numberOccurrences = [numberOccurrenceValue[0], max];

    return newData;
  };

  const onSubmit = async (data: FormFilterCaptures) => {
    const filterData = getFilterData(data);
    onFilter(filterData);
    setOpen(false);
  };

  useEffect(() => {
    if (isSaveFilterDialogOpen) {
      setFilterData(JSON.stringify(getFilterData(getValues())));
    }
  }, [isSaveFilterDialogOpen]);

  return (
    <Drawer open={open} setOpen={setOpen} title={t("CaptureReportPage.filter")}>
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ContentContainer>
          <Content sx={{ maxHeight: "70%" }}>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={8}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      label={t("CaptureReportPage.startDateTime")}
                      required
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="startTime"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  render={({ field, fieldState }) => (
                    <TimePickerField
                      required
                      field={{ ...field }}
                      fieldState={fieldState}
                      sx={{ mt: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      label={t("CaptureReportPage.endDateTime")}
                      required
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="endTime"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  render={({ field, fieldState }) => (
                    <TimePickerField
                      required
                      field={{ ...field }}
                      fieldState={fieldState}
                      sx={{ mt: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.equipment")}</FormLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  noOptionsText={t("form.noOptions")}
                  options={equipments}
                  getOptionLabel={option => option}
                  value={equipmentSelected}
                  onChange={(event, values) => {
                    event.preventDefault();
                    const noIsAll = !values.includes(t("form.all"));
                    if (
                      equipmentSelected.includes(t("form.all")) &&
                      equipmentSelected.length === 1
                    ) {
                      const idx = values.indexOf(t("form.all"));
                      values.splice(idx, 1);
                      setEquipmentSelected(values);
                    } else if (noIsAll) {
                      setEquipmentSelected(values);
                    } else {
                      setEquipmentSelected([t("form.all")]);
                      setCameraSelected([
                        {
                          ["location_name"]: t("form.all"),
                          ["camera_name"]: t("form.all")
                        }
                      ]);
                    }
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.cameras")}</FormLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={[
                    {
                      ["location_name"]: t("form.all"),
                      ["camera_name"]: t("form.all")
                    },
                    ...cameras.filter(c =>
                      equipmentSelected.includes(c["location_name"])
                    )
                  ]}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.camera_name}
                  value={cameraSelected}
                  onChange={(event, values) => {
                    event.preventDefault();
                    const noIsAll = values.findIndex(
                      c => c["location_name"] === t("form.all")
                    );
                    const allArray = cameraSelected.filter(
                      c => c["location_name"] === t("form.all")
                    );

                    if (allArray.length > 0 && cameraSelected.length === 1) {
                      values.splice(noIsAll, 1);
                      setCameraSelected(values);
                    } else if (noIsAll === -1) {
                      setCameraSelected(values);
                    } else {
                      setCameraSelected([
                        {
                          ["location_name"]: t("form.all"),
                          ["camera_name"]: t("form.all")
                        }
                      ]);
                    }
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={t("CaptureReportPage.id")}
                      gutter={false}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="plate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      uppercase
                      label={t("CaptureReportPage.plate")}
                      gutter={false}
                      field={{ ...field }}
                      fieldState={fieldState}
                      customProps={{
                        inputProps: {
                          maxLength: 7
                        }
                      }}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  align="left"
                  color="primary.light"
                >
                  {t("DefaultPageLayout.tooltipPlate")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="h5" color="primary.light">
                    {t("DefaultPageLayout.characteristicsSearch")}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.make")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={makes}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.name}
                  value={makeSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setMakeSelected({
                      name: value?.name || "",
                      isDivider: false
                    });
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                  renderOption={(props, value) => (
                    <>
                      <li {...props}>{value.name}</li>
                      {value.isDivider === true ? <Divider /> : null}
                    </>
                  )}
                />
                <Typography
                  align="left"
                  variant="caption"
                  color="primary.light"
                >
                  {t("CaptureReportPage.tooltipModel")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.genericModel")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={
                    makeSelected.name === "" ||
                    isGenericModelLoading ||
                    specificModelSelected !== ""
                  }
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={genericModels}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option}
                  value={genericModelSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setGenericModelSelected(value || "");
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                      {isGenericModelLoading && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
                <Divider>
                  <Typography variant="subtitle1" color="primary.light">
                    {t("DefaultPageLayout.or")}
                  </Typography>
                </Divider>
                <FormLabel>{t("CaptureReportPage.specificModel")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={
                    makeSelected.name === "" ||
                    isSpecificModelLoading ||
                    genericModelSelected !== ""
                  }
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={specificModels}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option}
                  value={specificModelSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setSpecificModelSelected(value || "");
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                      {isSpecificModelLoading && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.color")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={vehicleColors}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.color}
                  value={colorSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setColorSelected({ color: value?.color || "" });
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("CaptureReportPage.vehicle_class")}</FormLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={vehicleClasses}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option =>
                    t(`MonitoredVehiclesDashboardPage.${option}`)
                  }
                  value={vehicleClassSelected}
                  onChange={(event, values) => {
                    event.preventDefault();
                    setVehicleClassSelected(values);
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>
                  {t("CaptureReportPage.numberOccurrences")}
                </FormLabel>
                <MSlider
                  color="secondary"
                  min={0}
                  max={120}
                  disabled={sliderDisabled}
                  size="small"
                  value={numberOccurrenceValue}
                  onChange={handleNumberOccurrenceChange}
                  valueLabelFormat={valueLabelFormat}
                  step={null}
                  valueLabelDisplay="auto"
                  marks={sliderMarks}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomFilterMenu />
              </Grid>
            </Grid>
          </Content>
        </ContentContainer>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm,
              type: "button"
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default CaptureReportFilter;
