import { FC, MouseEvent } from "react";
import { ChevronRight } from "react-feather";
import { Breadcrumbs, Link, useMediaQuery, useTheme } from "@mui/material";
import { Link as RLink, useHistory } from "react-router-dom";
import { usePageLocation } from "contexts/PageLocationContext";
import { useTranslation } from "react-i18next";

const Breadcrumb: FC = () => {
  const { location } = usePageLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const handleLinkClick = (
    event: MouseEvent<HTMLAnchorElement>,
    page: string
  ) => {
    event.preventDefault();
    if (history.location.pathname === page) {
      window.location.reload();
    } else {
      history.push(page);
    }
  };

  return (
    <Breadcrumbs
      maxItems={isMobile || isTablet ? 2 : 4}
      itemsAfterCollapse={isMobile || isTablet ? 1 : 2}
      aria-label="breadcrumb"
      separator={<ChevronRight />}
      sx={{
        displayPrint: "none"
      }}
    >
      <Link
        component={RLink}
        to="/"
        color={theme.palette.secondary.main}
        onClick={event => handleLinkClick(event, "/")}
      >
        {t("menu.home")}
      </Link>
      {location.map(({ label, page }, index) =>
        page ? (
          <Link
            key={`${label}${page}${index}`}
            component={RLink}
            to={page}
            color={theme.palette.secondary.main}
            onClick={event => handleLinkClick(event, page)}
          >
            {label}
          </Link>
        ) : (
          <span key={`${label}${index}`}>{label}</span>
        )
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
