import {
  Box,
  Card,
  styled,
  Checkbox,
  Collapse,
  FormGroup,
  CardHeader,
  IconButton,
  Typography,
  CardActions,
  CardContent,
  FormControlLabel
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { FC, useState } from "react";
import { ChevronUp, ChevronDown, RefreshCw } from "react-feather";
import TabPanel from "components/TabPanel";

const StyledGroupCard = styled(Card)(({ theme }) => ({
  width: "306px",
  boxShadow: "none",
  borderRadius: "10px",
  padding: theme.spacing(2),
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.common.white,

  "& .MuiCardContent-root": {
    height: "310px"
  },

  "& .MuiCardActions-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: theme.palette.secondary.main,

    h5: {
      textDecoration: "underline",
      marginRight: theme.spacing(1)
    },

    "& .h5-svg": {
      opacity: 1,
      display: "flex",
      cursor: "pointer",
      marginBottom: theme.spacing(0),
      "&:hover": {
        opacity: 0.7
      }
    }
  }
}));

interface GroupCardProps {
  title: string;
  list: string[];
}

const GroupCard = (props: GroupCardProps) => {
  const { t } = useTranslation();
  const { title, list } = props;
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledGroupCard key={title} sx={{ height: expanded ? "430px" : "80px" }}>
      <CardHeader
        action={
          expanded ? (
            <IconButton onClick={handleExpanded}>
              <ChevronUp size={16} />
            </IconButton>
          ) : (
            <IconButton onClick={handleExpanded}>
              <ChevronDown size={16} />
            </IconButton>
          )
        }
        title={
          <Typography variant="subtitle1">
            <strong>{title}</strong>
          </Typography>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {list.map(elementList => (
            <FormGroup
              key={elementList}
              sx={{
                marginLeft: list.indexOf(elementList) === 0 ? "0px" : "16px"
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    defaultChecked={false}
                  />
                }
                label={elementList}
              />
            </FormGroup>
          ))}
        </CardContent>
        <CardActions>
          <div className="h5-svg">
            <Typography variant="h5">
              <strong>
                {t("EditUserGroupsPermissionPage.cleanSelection")}
              </strong>
            </Typography>
            <RefreshCw size={16} />
          </div>
        </CardActions>
      </Collapse>
    </StyledGroupCard>
  );
};

const EditUserGroupsPermissionPage: FC = () => {
  const { t } = useTranslation();
  const { name } = useParams<{ name: string }>();

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <Box>
          <Typography variant="h4">{name}</Typography>
          <TabPanel
            tabs={[
              {
                value: "permissions",
                label: t("EditUserGroupsPage.permissions"),
                content: (
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      {[
                        {
                          title: "Grupos",
                          list: [
                            "Lista de grupos",
                            "Quantidade de grupos",
                            "Adiconar grupos",
                            "Editar grupos",
                            "Excliur grupos"
                          ]
                        },
                        {
                          title: "Usuários",
                          list: [
                            "Lista de usuários",
                            "Quantidade de usuários",
                            "Perfil",
                            "Status",
                            "Adicionar usuário",
                            "Editar usuário",
                            "Excliur usuário"
                          ]
                        }
                      ].map((element, key) => (
                        <GroupCard
                          key={key}
                          title={element.title}
                          list={element.list}
                        />
                      ))}
                    </Box>
                  </Box>
                )
              }
            ]}
          />
        </Box>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default EditUserGroupsPermissionPage;
