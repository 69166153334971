import { AxiosResponse } from "axios";
import { NestedData /*, Success*/ } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type Vertical = {
  ["vertical_name"]: string;
  functionality: string[];
};

/*type CreateParams = {
  verticalName: string;
  functionality: string[];
};

type UpdateParams = {
  verticalName: string;
  functionality: string[];
};*/

type UseVerticalAPIType = {
  listAll: () => Promise<NestedData<Vertical[]>>;
  getByName: (name: string) => Promise<Vertical>;
  /*  deleteByName: (name: string) => Promise<Success>;
    create: (data: CreateParams) => Promise<Success>;
    update: (data: UpdateParams) => Promise<Success>;*/
};

const useVerticalAPI = (): UseVerticalAPIType => {
  const API = useAxios();

  const listAll = async (): Promise<NestedData<Vertical[]>> => {
    const response: AxiosResponse<NestedData<Vertical[]>> =
      await API.instance.post(APIEndpoints.VERTICAL_LIST);
    return response.data;
  };

  const getByName = async (name: string): Promise<Vertical> => {
    const response: AxiosResponse<Vertical> = await API.instance.get(
      APIEndpoints.VERTICAL,
      {
        params: { ["vertical_name"]: name }
      }
    );
    return response.data;
  };

  /*const deleteByName = async (name: string): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VERTICAL_DELETE,
      { ["vertical_name"]: name }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VERTICAL_CREATE,
      {
        ["vertical_name"]: data.verticalName,
        functionality: data.functionality
      }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.VERTICAL_UPDATE,
      {
        ["vertical_name"]: data.verticalName,
        functionality: data.functionality
      }
    );
    return response.data;
  };*/

  return {
    listAll,
    getByName /*,
    create,
    update,
    deleteByName*/
  };
};

export default useVerticalAPI;
