import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Typography
} from "@mui/material";
import Pages from "enums/Pages";
import useWelcomeAPI from "api/WelcomeAPI";
import Languages from "enums/Languages";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { useIsMount } from "hooks/useIsMount";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import RegexPatterns from "utils/RegexPatterns";
import SelectField from "components/SelectField";
import { useLocale } from "contexts/LocaleContext";
import AuthPageLayout from "layouts/AuthPageLayout";
import termsPtBr from "../../assets/terms_pt-BR.pdf";
import PasswordField from "components/PasswordField";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import useToolsAPI, { City, State } from "api/ToolsAPI";
import snackNotification from "components/SnackNotification";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";

type FormFields = {
  identification: string; // Identification code, eg. CPF, CNPJ, ...
  name: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  contact: string;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
};

const CreateAccountPage: FC = () => {
  const WelcomeAPI = useWelcomeAPI();
  const ToolsAPI = useToolsAPI();

  const { control, formState, getValues, handleSubmit, watch } =
    useForm<FormFields>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        identification: "",
        name: "",
        address: "",
        addressNumber: "",
        addressComplement: "",
        zipcode: "",
        city: "",
        state: "",
        country: "Brasil",
        phone: "",
        contact: "",
        username: "",
        password: "",
        confirmPassword: "",
        termsAccepted: false
      }
    });

  const watchCountry = watch("country");
  const [isTypeCountry, setIsTypeCountry] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [newCity, setNewCity] = useState<string>("");
  const [newState, setNewState] = useState<string>("");
  const [isCNPJ, setIsCNPJ] = useState<boolean>(false);
  const [isCreatingAccount, setCreatingAccount] = useState<boolean>(false);

  const history = useHistory();
  const { errorHandler } = useErrorHandler();

  const { t } = useTranslation();

  const onSubmit = async (data: FormFields) => {
    const newData = { ...data };

    if (newState && newCity) {
      newData.state = newState;
      newData.city = newCity;
    }

    setCreatingAccount(true);
    snackNotification.close();

    try {
      await WelcomeAPI.createAccount(newData);
      history.push(Pages.SIGN_IN);
      snackNotification.success(t("CreateAccountPage.accountCreated"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setCreatingAccount(false);
      setIsTypeCountry(false);
    }
  };

  const getCities = async (country: string, state: string) => {
    setIsLoading(true);
    try {
      const citiesResponse = await ToolsAPI.getCities(country, state);
      setCities(citiesResponse.data[0].cities);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getCountry = async (country: string) => {
    setIsLoading(true);
    try {
      if (country) {
        const countryResponse = await ToolsAPI.getCountry(country);
        setStates(countryResponse.data[0].states);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const goToSignInPage = () => {
    setCities([]);
    setStates([]);
    setNewCity("");
    setNewState("");
    setIsTypeCountry(false);
    history.push(Pages.SIGN_IN);
  };

  const isMount = useIsMount();

  const { setPageTitle } = usePageLocation();

  useEffect(() => {
    if (!getValues("country")) {
      setIsTypeCountry(true);
      setNewCity("");
      setNewState("");
      getCountry(getValues("country"));
    }
  }, [watchCountry]);

  useEffect(() => {
    setPageTitle(t("windowTitle.createAccount"));
  }, [t]);

  useEffect(() => {
    getCountry(getValues("country"));
  }, []);

  useEffect(() => {
    const currentCountry = getValues("country");
    if (!isMount && newState && currentCountry) {
      getCities(currentCountry, newState);
    }
  }, [newState]);

  const { language } = useLocale();

  const termsByLanguage = {
    [Languages.EN]: termsPtBr,
    [Languages.ES]: termsPtBr,
    [Languages.PT]: termsPtBr
  };

  const terms = termsByLanguage[language];

  return (
    <AuthPageLayout large closeable noBg onClose={goToSignInPage}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Typography variant="h3">
            {t("CreateAccountPage.createNewAccount")}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1" color="textSecondary">
            {t("CreateAccountPage.step1")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="identification"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    onChange={() => {
                      if (getValues("identification").length > 10) {
                        setIsCNPJ(true);
                      } else {
                        setIsCNPJ(false);
                      }
                    }}
                    mask={isCNPJ ? "99.999.999/9999-99" : "999.999.999-99"}
                    label={t("CreateAccountPage.identification")}
                    customProps={{
                      autoFocus: true,
                      required: true
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.name")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.address")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name="addressNumber"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.addressNumber")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="addressComplement"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.addressComplement")}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name="zipcode"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    mask="99999-999"
                    label={t("CreateAccountPage.zipcode")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.country")}
                    customProps={{
                      required: true
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            {isTypeCountry ? (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CreateAccountPage.state")}
                        customProps={{ required: true }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CreateAccountPage.city")}
                        customProps={{ required: true }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    required
                    label={t("CreateAccountPage.state")}
                    value={newState}
                    onChange={event => {
                      setNewState(event.target.value as string);
                    }}
                  >
                    {states.map(option => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SelectField
                    required
                    disabled={isLoading}
                    label={t("CreateAccountPage.city")}
                    value={newCity}
                    onChange={event => {
                      setNewCity(event.target.value as string);
                    }}
                  >
                    {cities.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="contact"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.contact")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: t("form.requiredField").toString(),
                  pattern: {
                    value: RegexPatterns.PHONE_NUMBER,
                    message: t("form.invalidFormat")
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    mask="(99) 99999-9999"
                    label={t("CreateAccountPage.phone")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: t("form.requiredField").toString(),
                  pattern: {
                    value: RegexPatterns.EMAIL_ADDRESS,
                    message: t("form.invalidFormat")
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CreateAccountPage.username")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: t("form.requiredField").toString(),
                  pattern: {
                    value: RegexPatterns.PASSWORD,
                    message: t("form.invalidFormat")
                  }
                }}
                render={({ field, fieldState }) => (
                  <PasswordField
                    label={t("CreateAccountPage.password")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  validate: value =>
                    value === getValues("password") ||
                    t("form.passwordsMustMatch").toString()
                }}
                render={({ field, fieldState }) => (
                  <PasswordField
                    label={t("CreateAccountPage.confirmPassword")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="termsAccepted"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={field.onChange}
                        checked={Boolean(field.value)}
                      />
                    }
                    label={
                      <Trans
                        i18nKey="CreateAccountPage.readTerms"
                        t={t}
                        components={[
                          <Link
                            key={0}
                            href={terms}
                            target="_blank"
                            rel="noreferrer"
                            color="secondary"
                          />
                        ]}
                      />
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            disabled={!formState.isValid || isLoading}
            loading={isCreatingAccount}
          >
            {t("action.confirm")}
          </LoadingButton>
        </Box>
      </form>
    </AuthPageLayout>
  );
};

export default CreateAccountPage;
