import useAuthAPI from "api/AuthAPI";
import { LoadingButton } from "@mui/lab";
import InputField from "components/InputField";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import RegexPatterns from "utils/RegexPatterns";
import { Controller, useForm } from "react-hook-form";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useLocation } from "react-router";

type StepEmailProps = {
  setStep: (step: number) => void;
  setEmail: (email: string) => void;
};

type EmailForm = {
  email: string;
};

const StepEmail: FC<StepEmailProps> = (props: StepEmailProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const { sendPasswordResetCode } = useAuthAPI();

  const { search } = useLocation<{ from?: { pathname: string } } | undefined>();

  const username = search.replace("?username=", "");

  const { control, handleSubmit, clearErrors, formState, getValues } =
    useForm<EmailForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        email: username
      }
    });

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const onSubmit = async (data: EmailForm) => {
    setLoading(true);
    try {
      await sendPasswordResetCode({
        username: data.email
      });
      props.setEmail(data.email);
      props.setStep(2);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Typography variant="h3">
            {t("ResetPasswordPage.changePassword")}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1" color="textSecondary">
            {t("ResetPasswordPage.step1")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("form.requiredField").toString(),
              pattern: {
                value: RegexPatterns.EMAIL_ADDRESS,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("ResetPasswordPage.emailAddress")}
                customProps={{
                  autoFocus: getValues("email") === "",
                  disabled: loading,
                  type: "email"
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Box>
        <Box>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!formState.isValid}
            loading={loading}
          >
            {t("action.continue")}
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default StepEmail;
