import Pages from "enums/Pages";
import useRestrictionDashboardAPI, {
  FrequencyByClass,
  FrequencyByDay,
  FrequencyByType,
  RestrictionParams,
  VehicleFrequency
} from "api/RestrictionDashboardAPI";
import RestrictionDashboardFilter, {
  defaultValues,
  FormRestrictionDashboardFilter
} from "./RestrictionDashboardFilter";
import { Sliders } from "react-feather";
import { Position } from "google-map-react";
import GoogleHeatmap from "./GoogleHeatmap";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import Heatmaps, { HeatmapData } from "./Heatmaps";
import { useLocale } from "contexts/LocaleContext";
import { dateTimeToString } from "utils/DateFunctions";
import { RestrictionType } from "api/RestrictionAPI";
import DashboardCard from "components/DashboardCard";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { FC, useEffect, useState, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import TotalRestrictionDayGraph from "./TotalRestrictionDayGraph";
import TotalRestrictionTypeGraph from "./TotalRestrictionTypeGraph";
import TotalRestrictionClassGraph from "./TotalRestrictionClassGraph";
import RestrictionVehicleFrequencyTable from "./RestrictionVehicleFrequencyTable";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const RestrictionDashboardPage: FC = () => {
  const { t } = useTranslation();
  const { language } = useLocale();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [isFetching, setIsFetching] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setPageTitle, setLocation } = usePageLocation();
  const [filterData, setFilterData] =
    useState<FormRestrictionDashboardFilter>(defaultValues);
  const [geolocation, setGeolocation] = useState<Position[]>([]);
  const [restrictionTotalWeekData, setRestrictionTotalWeekData] =
    useState<HeatmapData>([]);
  const [restrictionTotalHourData, setRestrictionTotalHourData] =
    useState<HeatmapData>([]);
  const RestrictionDashboardAPI = useRestrictionDashboardAPI();
  const [restrictionTotalTypeData, setRestrictionTotalTypeData] = useState<
    FrequencyByType[]
  >([]);
  const [restrictionTotal, setRestrictionTotal] = useState<number>(0);
  const [cardsData, setCardsData] = useState<{
    blockedRoad: number;
    borderDivision: number;
    exclusiveRoad: number;
    speeding: number;
    vehicleRotation: number;
  }>({
    blockedRoad: 0,
    borderDivision: 0,
    exclusiveRoad: 0,
    speeding: 0,
    vehicleRotation: 0
  });
  const [restrictionVehicleFrequencyData, setRestrictionVehicleFrequencyData] =
    useState<VehicleFrequency[]>([]);
  const [restrictionTotalDayData, setRestrictionTotalDayData] = useState<
    FrequencyByDay[]
  >([]);
  const [restrictionTotalClassData, setRestrictionTotalClassData] = useState<
    FrequencyByClass[]
  >([]);

  useEffect(() => {
    setPageTitle(t("windowTitle.restrictionDashboard"));
    setLocation([
      {
        label: t("DefaultPageLayout.restrictionDashboard"),
        page: Pages.RESTRICTION_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  const requestData = useCallback(
    async (filter: FormRestrictionDashboardFilter) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsFetching(true);
      try {
        const params: RestrictionParams = {
          ["customer_id"]: sessionUser.customer_id,
          ["initial_date"]: dateTimeToString(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["final_date"]: dateTimeToString(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          ["restriction_type"]:
            filter.type === undefined || filter.type.length === 0
              ? undefined
              : (filter.type as RestrictionType[]),
          equipments: filter.equipments ?? undefined,
          cameras: filter.cameras ?? undefined,
          ["grouping_by_type"]:
            filter.type === undefined || filter.type.length === 0
              ? "all"
              : "type"
        };

        const responseBiRestriction =
          await RestrictionDashboardAPI.biRestriction({
            ...params
          });

        let chartWeekData: HeatmapData = [];
        let chartHourData: HeatmapData = [];
        if (responseBiRestriction.response_by_time) {
          if (
            responseBiRestriction.response_by_time.frequency_by_week &&
            responseBiRestriction.response_by_time.frequency_by_week.length > 0
          ) {
            chartWeekData =
              responseBiRestriction.response_by_time.frequency_by_week.map(
                item => ({
                  name: item.location,
                  data: item.day_week_frequency.map(cData => ({
                    x: t(`form.weekDays.${cData.day_week.toString()}`).slice(
                      0,
                      3
                    ),
                    y: cData.weight ? Number(cData.weight.toFixed(1)) : 0
                  }))
                })
              );
          }

          if (
            responseBiRestriction.response_by_time.frequency_by_hour &&
            responseBiRestriction.response_by_time.frequency_by_hour.length > 0
          ) {
            chartHourData =
              responseBiRestriction.response_by_time.frequency_by_hour.map(
                item => ({
                  name: item.location,
                  data: item.hour_frequency.map(cData => ({
                    x: cData.hour.toString(),
                    y: cData.weight ? Number(cData.weight.toFixed(1)) : 0
                  }))
                })
              );
          }
        }

        const configGeographic: Position[] = [];
        responseBiRestriction.geographic_coordinates.forEach(coord => {
          configGeographic.push({
            lat: Number(coord.latitude),
            lng: Number(coord.longitude),
            weight: coord.weight
          });
        });

        setGeolocation(configGeographic || []);
        setRestrictionTotalWeekData(chartWeekData);
        setRestrictionTotalHourData(chartHourData);

        setRestrictionTotalTypeData(
          setFormatTypeDatas(
            responseBiRestriction.response_by_type?.frequency_by_type || []
          )
        );
        setRestrictionTotal(
          responseBiRestriction.response_by_type?.total_frequency?.value ?? 0
        );
        setRestrictionTotalDayData(
          setFormatDayDatas(
            responseBiRestriction.response_by_time?.frequency_by_day || []
          )
        );
        setRestrictionTotalClassData(
          setFormatClassDatas(
            responseBiRestriction.vehicle_class_frequency || []
          )
        );
        setRestrictionVehicleFrequencyData(
          responseBiRestriction.vehicle_violations || []
        );
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetching(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(filterData);
  }, [requestData, filterData, language]);

  const setFormatClassDatas = (
    arrayToFormat: FrequencyByClass[]
  ): FrequencyByClass[] => {
    const formatClass: FrequencyByClass[] = [];
    arrayToFormat.forEach(item =>
      formatClass.push({
        ["vehicle_class"]: t(
          `MonitoredVehiclesDashboardPage.${item.vehicle_class}`
        ),
        weight: item.weight || 0
      })
    );
    return formatClass;
  };

  const setFormatDayDatas = (
    arrayToFormat: FrequencyByDay[]
  ): FrequencyByDay[] => {
    const formatDay: FrequencyByDay[] = [];
    arrayToFormat.forEach(item =>
      formatDay.push({
        date: item.date,
        weight: item.weight || 0
      })
    );
    return formatDay;
  };

  const setFormatTypeDatas = (
    arrayToFormat: FrequencyByType[]
  ): FrequencyByType[] => {
    const formatDay: FrequencyByType[] = [];
    const formatCards = { ...cardsData };
    arrayToFormat.forEach(item => {
      formatDay.push({
        type: t(`RestrictionDashboardPage.type.${item.type}`),
        weight: item.weight || 0
      });
      if (item.type === "blocked_road") {
        formatCards.blockedRoad = item.weight;
      } else if (item.type === "border_division") {
        formatCards.borderDivision = item.weight;
      } else if (item.type === "exclusive_road") {
        formatCards.exclusiveRoad = item.weight;
      } else if (item.type === "speeding") {
        formatCards.speeding = item.weight;
      } else {
        formatCards.vehicleRotation = item.weight;
      }
    });
    setCardsData(formatCards);

    return formatDay;
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          testId="restriction-dashboard-content"
          title={t("menu.restrictionDashboard")}
          isLoading={isFetching}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Sliders />}
              onClick={() => setIsFilterOpen(true)}
              testId="open-filter-btn"
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
        >
          <RestrictionDashboardFilter
            data-testid="filter"
            open={isFilterOpen}
            setOpen={setIsFilterOpen}
            setFilterData={setFilterData}
          />
          <PageSection
            title={t("RestrictionDashboardPage.googleHeatmap")}
            variant="h5"
          >
            <GoogleHeatmap
              isFetching={isFetching}
              geolocationDatas={geolocation}
            />
            <Box mt={2}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.type.speeding")}
                    qtd={cardsData.speeding}
                    isLoading={isFetching}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.type.exclusive_road")}
                    qtd={cardsData.exclusiveRoad}
                    isLoading={isFetching}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.type.blocked_road")}
                    qtd={cardsData.blockedRoad}
                    isLoading={isFetching}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.type.vehicle_rotation")}
                    qtd={cardsData.vehicleRotation}
                    isLoading={isFetching}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.type.border_division")}
                    qtd={cardsData.borderDivision}
                    isLoading={isFetching}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                  <DashboardCard
                    title={t("RestrictionDashboardPage.total")}
                    qtd={restrictionTotal}
                    isLoading={isFetching}
                  />
                </Grid>
              </Grid>
            </Box>
          </PageSection>
          <PageSection
            title={t("RestrictionDashboardPage.frequencyByType")}
            variant="h5"
          >
            <TotalRestrictionTypeGraph
              data={restrictionTotalTypeData}
              isFetching={isFetching}
            />
          </PageSection>
          <PageSection
            title={t("RestrictionDashboardPage.frequencyByDay")}
            variant="h5"
          >
            <TotalRestrictionDayGraph
              data={restrictionTotalDayData}
              isFetching={isFetching}
            />
          </PageSection>
          <PageSection
            title={t("RestrictionDashboardPage.frequencyByClass")}
            variant="h5"
          >
            <TotalRestrictionClassGraph
              data={restrictionTotalClassData}
              isFetching={isFetching}
            />
          </PageSection>
          <PageSection
            title={t("RestrictionDashboardPage.vehicleFrequency")}
            variant="h5"
          >
            <RestrictionVehicleFrequencyTable
              data={restrictionVehicleFrequencyData}
              isFetching={isFetching}
            />
          </PageSection>
          <Heatmaps
            isFetching={isFetching}
            weekData={restrictionTotalWeekData}
            hourData={restrictionTotalHourData}
          />
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default RestrictionDashboardPage;
