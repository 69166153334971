import Pages from "enums/Pages";
import parse from "html-react-parser";
import { EMPTY_VALUE } from "utils/String";
import { format, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useForensicAPI, { Forensic } from "api/ForensicAPI";
import { FC, useEffect, useState, useCallback } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import "../../components/RichTextEditor/RichTextEditorStyle.css";
import { Grid, styled, Typography, CircularProgress } from "@mui/material";
import { LocationSection, usePageLocation } from "contexts/PageLocationContext";

const ReportContainer = styled("div")(() => ({
  img: {
    margin: "8px 0px",
    borderRadius: "4px",
    width: "548px",
    height: "411px"
  }
}));

const ForensicsReportViewer: FC = () => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const ForensicAPI = useForensicAPI();
  const { errorHandler } = useErrorHandler();
  const params = useParams<{ report: string }>();
  const { setPageTitle, setLocation } = usePageLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reportParam = params.report ? params.report.replace(/[\\"]/g, "") : "";
  const [reportSelected, setReportSelected] = useState<Forensic>({
    id: "",
    name: "",
    report: "",
    ["user_created"]: "",
    ["name_user_created"]: "",
    ["date_insert"]: "",
    ["date_update"]: "",
    index: "",
    ["editor_users"]: []
  });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    const customerId = sessionUser["customer_id"];
    setIsLoading(true);
    try {
      if (reportParam) {
        const forensicResponse = await ForensicAPI.getForensic(
          customerId,
          reportParam
        );
        setReportSelected(forensicResponse);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [reportParam, t]);

  useEffect(() => {
    requestData();
  }, [reportParam]);

  useEffect(() => {
    const location: LocationSection[] = [
      {
        label: t("menu.reports")
      },
      {
        label: t("ForensicsReportPage.title"),
        page: Pages.FORENSIC_REPORT
      }
    ];

    if (reportSelected.index) {
      const reportTitle = reportSelected.name;
      setPageTitle(t("windowTitle.forensicReportViewer", { reportTitle }));

      location.push({
        label: reportTitle
      });
    } else {
      setPageTitle(t("windowTitle.forensicReport"));
    }

    setLocation(location);
  }, [t, Pages, reportSelected]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "90%",
              display: "flex",
              marginTop: "8px",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CircularProgress size={32} />
            <Typography variant="body1">{t("waitState.loading")}</Typography>
          </div>
        ) : (
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="h4">
                {reportSelected.name || EMPTY_VALUE}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReportContainer>
                {parse(reportSelected.report) || EMPTY_VALUE}
              </ReportContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>{t("ForensicsReportPage.datetime")}:</strong>{" "}
                {reportSelected.date_insert
                  ? format(
                      parseISO(reportSelected.date_insert),
                      t("CaptureReportPage.dateFormat")
                    )
                  : EMPTY_VALUE}
                .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>{t("ForensicsReportPage.created")}:</strong>{" "}
                {reportSelected.name_user_created || EMPTY_VALUE}.
              </Typography>
            </Grid>
          </Grid>
        )}
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default ForensicsReportViewer;
