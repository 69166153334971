import { FC, ReactNode } from "react";
import { Box, Card, Skeleton, Typography, CardContent } from "@mui/material";

interface DashboardCardProps {
  icon?: ReactNode;
  color?: string;
  qtd: number;
  title: string;
  isLoading: boolean;
}

const DashboardCard: FC<DashboardCardProps> = ({
  title,
  icon,
  qtd,
  color = "#768299",
  isLoading
}) => (
  <Card
    sx={{
      maxHeight: "124px",
      border: "1px solid #C2C9D1",
      transition: "none !important",
      boxShadow: "none !important"
    }}
  >
    <CardContent>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Skeleton variant="text" />
          <Skeleton variant="text" width={64} height={48} />
        </Box>
      ) : (
        <>
          <Typography sx={{ mb: 1, color: "#768299" }} variant="body1">
            {title}
          </Typography>
          <div
            style={{
              display: "flex"
            }}
          >
            {icon && <div style={{ marginRight: "8px" }}>{icon}</div>}
            <Typography variant="h4" color={color}>
              {qtd}
            </Typography>
          </div>
        </>
      )}
    </CardContent>
  </Card>
);

export default DashboardCard;
