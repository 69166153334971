import Pages from "enums/Pages";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useSpiaPrfReportAPI, { SpiaPrfReport } from "api/SpiaReportAPI";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Sliders } from "react-feather";
import SpiaReportFilter, { FormFilterSpiaReport } from "./SpiaReportFilter";
import LinkPlate from "components/LinkPlate";
import { format, parseISO } from "date-fns";
import {
  defaultPaginatorModelValues,
  PaginatorModel
} from "components/Paginator";
import { EMPTY_VALUE } from "utils/String";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import PageSection from "components/PageSection/PageSection";

const SpiaPrfReportPage: FC = () => {
  const SpiaPrfReportAPI = useSpiaPrfReportAPI();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  const defaultFilterValues: FormFilterSpiaReport = {
    plate: "",
    state: "",
    city: [],
    restrictions: []
  };

  const [reportResults, setReportResults] = useState<SpiaPrfReport[]>([]);
  const [filterData, setFilterData] =
    useState<FormFilterSpiaReport>(defaultFilterValues);

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const requestData = useCallback(
    async (
      filter: FormFilterSpiaReport | undefined,
      pageValue: number,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"] || !filter) return;
      const customerId = sessionUser["customer_id"];
      setIsLoading(true);
      try {
        const reportResponse = await SpiaPrfReportAPI.list({
          ["customer_id"]: customerId,
          page: pageValue,
          ["page_size"]: pageSizeValue,
          ...filter
        });
        setReportResults(reportResponse.registers.items || []);
        setPaginator({
          totalPages: reportResponse.registers.total_pages || 0,
          totalItems: reportResponse.registers.total_items || 0
        });
        setPage(reportResponse.registers.page || 1);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    if (!isMount) {
      if (filterData === null) {
        requestData(filterData, page, pageSize);
      } else {
        requestData(filterData, 1, pageSize);
      }
    }
  }, [filterData, page, pageSize]);

  const onFilter = (data: FormFilterSpiaReport) => {
    setFilterData(data);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.spiaReport"));
    setLocation([
      {
        label: t("menu.reports")
      },
      {
        label: t("SpiaReportPage.title"),
        page: Pages.SPIA_REPORT
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("SpiaReportPage.title")}
          isLoading={isLoading}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Sliders />}
              onClick={() => setIsFilterOpen(true)}
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "plate", label: t("SpiaReportPage.plate") },
              { key: "date_insert", label: t("SpiaReportPage.dateInsert") },
              {
                key: "spia_date_update",
                label: t("SpiaReportPage.spiaDateUpdate")
              },
              {
                key: "make_model",
                label: (
                  <>
                    <div>{t("SpiaReportPage.makeModel")}</div>
                    <div>{t("SpiaReportPage.color")}</div>
                  </>
                )
              },
              { key: "model_year", label: t("SpiaReportPage.year") },
              {
                key: "city",
                label: (
                  <>
                    <div>{t("SpiaReportPage.city")}</div>
                    <div>{t("SpiaReportPage.state")}</div>
                  </>
                )
              },
              { key: "restrictions", label: t("SpiaReportPage.restrictions") }
            ]}
            defaultSort={["plate", "asc"]}
            onHeaderSort={setReportResults}
            data={reportResults}
            renderRow={row => [
              <>
                <LinkPlate plate={row.plate} path={Pages.VEHICLE_OVERVIEW} />
              </>,
              <>
                {format(
                  parseISO(row["date_insert"]),
                  t("SpiaReportPage.dateInsertFormat")
                )}
              </>,
              <>
                {format(
                  parseISO(row["spia_date_update"]),
                  t("SpiaReportPage.spiaDateUpdateFormat")
                )}
              </>,
              <>
                <div>{row.make_model}</div>
                <div>{row.color}</div>
              </>,
              <>{`${row["make_year"]}/${row["model_year"]}`}</>,
              <>
                <div>{row.city}</div>
                <div>{row.federal_state}</div>
              </>,
              <>
                {row.restrictions
                  ? row.restrictions.map(r => [
                      <div key={`${r.codigoTipoRestricao}`}>
                        {`${r.codigoTipoRestricao} - ${r.tipoRestricao}`}
                      </div>
                    ])
                  : EMPTY_VALUE}
              </>
            ]}
            hideColumnsSm={[1, 2, 6]}
            hideColumnsXs={[1, 2, 3, 4, 6]}
            page={page}
            onPageChange={setPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <SpiaReportFilter
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        onFilter={data => onFilter(data)}
      />
    </DefaultPageLayout>
  );
};

export default SpiaPrfReportPage;
