import Pages from "enums/Pages";
import ConvoyBI from "./ConvoyBI";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { Sliders } from "react-feather";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import ConvoyDashboardFilter from "./ConvoyDashboardFilter";
import { usePageLocation } from "contexts/PageLocationContext";
import { ConvoyDashboardFilterProvider } from "./ConvoyDashboardFilterContext";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const ConvoyDashboardPage: FC = () => {
  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setPageTitle(t("windowTitle.convoyDashboard"));
    setLocation([
      {
        label: t("DefaultPageLayout.convoyDashboard"),
        page: Pages.CONVOY_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <ConvoyDashboardFilterProvider>
          <PageSection
            title={t("DefaultPageLayout.convoyDashboard")}
            isLoading={isLoading}
            tooltip={t("ConvoyDashboardPage.warning")}
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            }
          >
            <ConvoyDashboardFilter
              open={isFilterOpen}
              setOpen={setIsFilterOpen}
            />
            <ConvoyBI isLoading={isLoading} setLoading={setLoading} />
          </PageSection>
        </ConvoyDashboardFilterProvider>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default ConvoyDashboardPage;
