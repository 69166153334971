import {
  CSSProperties,
  FC,
  RefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { Box, CircularProgress } from "@mui/material";
import { VideoOff } from "react-feather";

type Props = {
  containerRef?: RefObject<HTMLDivElement>;
  image?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

const CameraView: FC<Props> = ({ containerRef, image, style, onClick }) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(!image || image === "");

  const imgRef = useRef<HTMLImageElement>(null);

  const onLoad = () => {
    setLoading(false);
    setError(false);
  };

  const onError = () => {
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    if (imgRef.current?.complete) onLoad();
  }, [imgRef.current]);

  const isValid = !isError && !isLoading;

  return (
    <div
      ref={containerRef}
      style={{ ...style, overflow: "hidden", lineHeight: 0 }}
      onClick={onClick}
    >
      <img
        ref={imgRef}
        src={image}
        onLoad={onLoad}
        onError={onError}
        alt=""
        width="100%"
        style={{
          margin: "0 0 auto 0",
          display: isValid ? "initial" : "none"
        }}
      />
      {isError && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: style?.height
          }}
        >
          <VideoOff />
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: style?.height
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default CameraView;
