import { FC, useEffect, useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputNumber from "./InputNumber";
import { ArrowRight } from "react-feather";

type GoToPageParams = {
  initialPage?: number;
  totalPages?: number;
  onPageChange?: (pageValue: number) => void;
  disabled?: boolean;
};

const StyledIconButton = styled(Button)(() => ({
  padding: "8px 0",
  minWidth: "44px"
}));

const GoToPage: FC<GoToPageParams> = ({
  initialPage = 1,
  totalPages = 1,
  onPageChange,
  disabled = false
}) => {
  const { t } = useTranslation();

  const [pageValue, setPageValue] = useState(initialPage);
  const [isValid, setValid] = useState(false);

  const actionDisabled = disabled || !isValid || pageValue === initialPage;

  const handleConfirm = () => {
    if (actionDisabled) return;
    if (onPageChange) onPageChange(pageValue);
  };

  useEffect(() => {
    setPageValue(initialPage);
  }, [initialPage]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <Typography variant="subtitle1" color="textSecondary">
        {t("GoToPage.goToPage")}:
      </Typography>
      <InputNumber
        min={1}
        max={totalPages}
        gutter={false}
        fullWidth={false}
        customProps={{
          disabled,
          value: pageValue,
          onChange({ target: { value } }) {
            if (!value) return;
            const nValue = Number(value);
            let vld = true;
            vld = vld && nValue > 0 && nValue <= totalPages;
            setValid(vld);
            setPageValue(nValue);
          },
          onKeyUp(event) {
            if (event.key === "Enter") handleConfirm();
          },
          sx: { width: 100, margin: "0 0.5rem" }
        }}
      />
      <StyledIconButton
        color="primary"
        disabled={actionDisabled}
        onClick={handleConfirm}
      >
        <ArrowRight />
      </StyledIconButton>
    </Box>
  );
};

export default GoToPage;
