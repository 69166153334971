import { AxiosResponse } from "axios";
import { NestedData, Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type CustomFilterSetParams = {
  ["customer_id"]: string;
  ["filter_name"]: string;
  ["filter_key"]: string;
  ["filter_data"]: string;
  users: string[];
  ["user_groups"]: string[];
};

export type CustomFilterListParams = {
  ["customer_id"]: string;
  ["filter_key"]: string;
};

export type CustomFilterDeleteParams = {
  ["customer_id"]: string;
  ["filter_id"]: string;
};

export type CustomFilter = {
  ["filter_id"]: string;
  ["filter_name"]: string;
  ["user_created"]: string;
  ["date_created"]: string;
  users: string[];
  ["filter_key"]: string;
  ["filter_data"]: string;
  ["user_groups"]: string[];
};

export type CustomFilterListResponse = {
  items: CustomFilter[];
};

type UseCustomFilterAPIType = {
  list: (
    params: CustomFilterListParams
  ) => Promise<NestedData<CustomFilterListResponse>>;
  deleteById: (params: CustomFilterDeleteParams) => Promise<Success>;
  set: (param: CustomFilterSetParams) => Promise<Success>;
};

const useCustomFilterAPI = (): UseCustomFilterAPIType => {
  const API = useAxios();

  const list = async (
    params: CustomFilterListParams
  ): Promise<NestedData<CustomFilterListResponse>> => {
    const response: AxiosResponse<NestedData<CustomFilterListResponse>> =
      await API.instance.post(APIEndpoints.CUSTOM_FILTER_LIST, params);
    return response.data;
  };

  const deleteById = async (
    params: CustomFilterDeleteParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOM_FILTER_DELETE,
      params
    );
    return response.data;
  };

  const set = async (data: CustomFilterSetParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOM_FILTER_SET,
      data
    );
    return response.data;
  };

  return {
    list,
    set,
    deleteById
  };
};

export default useCustomFilterAPI;
