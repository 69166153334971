import {
  Box,
  Dialog,
  Divider,
  useTheme,
  Typography,
  DialogActions,
  DialogContent,
  Button as MUIButton
} from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { AlertCircle, CheckCircle } from "react-feather";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState
} from "react";

interface ModalProps {
  title: string;
  autoHide?: boolean;
  modalType?: "alert" | "confirm";
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void | Promise<void>;
  type?: "error" | "success";
  description: string | ReactNode;
}

interface ContextProps {
  showModal: (modalProps: ModalProps) => void;
  hideModal: () => void;
}

const ModalContext = createContext<ContextProps>({
  showModal() {
    throw new Error("showModal must be defined.");
  },
  hideModal() {
    throw new Error("hideModal must be defined.");
  }
});

export const ModalProvider: FC = ({ children }) => {
  const { t } = useTranslation();

  const defaultModalProps: ModalProps = {
    title: "",
    autoHide: true,
    type: "success",
    description: "",
    modalType: "alert",
    onConfirm() {},
    confirmText: t("action.ok"),
    cancelText: t("action.cancel")
  };

  const [show, setShow] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);
  const {
    type,
    title,
    modalType,
    description,
    confirmText,
    cancelText,
    onConfirm,
    autoHide
  } = modalProps;

  const onClickConfirmHandler = () => {
    if (autoHide) {
      setShow(false);
    }

    if (onConfirm) {
      onConfirm();
    }
  };

  const modalContextProps = useMemo<ContextProps>(
    () => ({
      showModal(props) {
        setModalProps({ ...modalProps, ...props });
        setShow(true);
      },
      hideModal: () => setShow(false)
    }),
    [modalProps]
  );

  const theme = useTheme();

  return (
    <ModalContext.Provider value={modalContextProps}>
      {modalType === "confirm" ? (
        <Dialog
          maxWidth="xs"
          className="alertDialog"
          open={show}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setShow(false);
            }
          }}
        >
          <DialogContent>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ textAlign: "left", mr: 2 }}>
                <AlertCircle color={theme.palette.error.main} size={34} />
              </Box>
              <div>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h4">
                    <strong>{title}</strong>
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1" color="textSecondary">
                    {description}
                  </Typography>
                </Box>
              </div>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              customProps={{
                color: "primary",
                variant: "outlined",
                onClick: () => setShow(false)
              }}
            >
              {cancelText}
            </Button>
            <Button
              customProps={{ color: "error", onClick: onClickConfirmHandler }}
            >
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          className="alertDialog"
          open={show}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setShow(false);
            }
          }}
        >
          <DialogContent>
            <Box sx={{ textAlign: "center", mb: 3 }}>
              {type === "error" && (
                <AlertCircle color={theme.palette.error.light} size={34} />
              )}
              {type === "success" && (
                <CheckCircle color={theme.palette.success.main} size={34} />
              )}
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography align="center" variant="h4">
                <strong>{title}</strong>
              </Typography>
            </Box>
            <Box sx={{ mb: 5 }}>
              <Typography variant="body1" align="center">
                {description}
              </Typography>
            </Box>
            <MUIButton fullWidth size="large" onClick={onClickConfirmHandler}>
              {confirmText}
            </MUIButton>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ContextProps => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within an ModalProvider");
  }
  return context;
};
