import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { Success } from "api/API";
import useAxios from "hooks/useAxios";

export type Customization = {
  ["header_image"]: string;
  ["login_image"]: string;
  ["secondary_color"]: string;
  title: string;
  ["primary_color"]: string;
};

export type CustomizationResponse = {
  ["customer_id"]: string;
  ["customization_data"]: Customization;
};

type SetCustomizationParams = {
  ["customer_id"]: string;
  title: string;
  ["primary_color"]: string;
  ["secondary_color"]: string;
  ["login_image"]: string;
  ["header_image"]: string;
};

type GetCustomizationParams = {
  domain: string;
};

type UseCustomizationAPIType = {
  setCustomization: (param: SetCustomizationParams) => Promise<Success>;
  getCustomization: (
    param: GetCustomizationParams
  ) => Promise<CustomizationResponse>;
};

const useCustomizationAPI = (): UseCustomizationAPIType => {
  const API = useAxios();

  const setCustomization = async (
    requestData: SetCustomizationParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMIZATION_SET,
      requestData
    );
    return response.data;
  };

  const getCustomization = async ({
    domain
  }: GetCustomizationParams): Promise<CustomizationResponse> => {
    const response: AxiosResponse<CustomizationResponse> =
      await API.instance.get(APIEndpoints.CUSTOMIZATION, {
        params: {
          domain
        }
      });
    return response.data;
  };

  return {
    setCustomization,
    getCustomization
  };
};

export default useCustomizationAPI;
