import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type Watermark = {
  cnpj: boolean;
  email: boolean;
  enabled: boolean;
};

export type WatermarkUpdateResponse = {
  success: string;
};

type UseWatermarkAPIType = {
  watermarkData: (customerId: string) => Promise<Watermark>;
  watermarkUpdate: (
    customerId: string,
    watermarkData: Watermark
  ) => Promise<WatermarkUpdateResponse>;
};

const useWatermarkAPI = (): UseWatermarkAPIType => {
  const API = useAxios();

  const watermarkData = async (customerId: string): Promise<Watermark> => {
    const response: AxiosResponse<Watermark> = await API.instance.get(
      APIEndpoints.CONFIGURATION_WATERMARK,
      {
        params: { ["customer_id"]: customerId }
      }
    );
    return response.data;
  };

  const watermarkUpdate = async (
    customerId: string,
    watermarkData: Watermark
  ): Promise<WatermarkUpdateResponse> => {
    const response: AxiosResponse<WatermarkUpdateResponse> =
      await API.instance.post(APIEndpoints.CONFIGURATION_WATERMARK_UPDATE, {
        ["customer_id"]: customerId,
        ["watermark_data"]: {
          cpf: false,
          cnpj: watermarkData.cnpj,
          email: watermarkData.email,
          enabled: watermarkData.enabled
        }
      });
    return response.data;
  };

  return {
    watermarkData,
    watermarkUpdate
  };
};

export default useWatermarkAPI;
