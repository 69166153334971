import {
  Box,
  Grid,
  styled,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Divider
} from "@mui/material";
import Button from "components/Button";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import useLocationAPI from "api/LocationAPI";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import useCameraAPI, { CameraFilter } from "api/CameraAPI";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { endOfDay, setHours, setMinutes, startOfDay, subDays } from "date-fns";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormRestrictionDashboardFilter = {
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  type: string[] | undefined;
  equipments: string[] | undefined;
  cameras: string[] | undefined;
};

const defaultDate = new Date();

export const defaultValues: FormRestrictionDashboardFilter = {
  startDate: setMinutes(setHours(defaultDate.setDate(1), 0), 0),
  endDate: new Date(),
  startTime: startOfDay(defaultDate),
  endTime: endOfDay(defaultDate),
  type: undefined,
  equipments: undefined,
  cameras: undefined
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilterData: (filterValues: FormRestrictionDashboardFilter) => void;
};

const RestrictionDashboardFilter: FC<Props> = ({
  open,
  setOpen,
  setFilterData
}) => {
  const { t } = useTranslation();
  const {
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch
  } = useForm<FormRestrictionDashboardFilter>({
    mode: "onChange",
    defaultValues
  });
  const watchType = watch("type");
  const CameraAPI = useCameraAPI();
  const { sessionUser } = useAuth();
  const LocationAPI = useLocationAPI();
  const { errorHandler } = useErrorHandler();
  const [cameras, setCameras] = useState<CameraFilter[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [monthCheckbox, setMonthCheckbox] = useState<boolean>(false);
  const [todayCheckbox, setTodayCheckbox] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<CameraFilter[]>([
    {
      ["location_name"]: t("form.all"),
      ["camera_name"]: t("form.all")
    }
  ]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([
    t("form.all")
  ]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>(["all"]);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const customerId = sessionUser["customer_id"];
      const [locationResponse, cameraResponse] = await Promise.all([
        LocationAPI.listAll(customerId),
        CameraAPI.listAll({ customerId })
      ]);
      setCameras([
        t("form.all"),
        ...cameraResponse.data.map(camera => ({
          ["location_name"]: camera["location_name"],
          ["camera_name"]: camera["camera_name"]
        }))
      ]);
      setLocations([
        t("form.all"),
        ...locationResponse.data.map(location => location.location_name)
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const clear = () => {
    const d = setMinutes(setHours(defaultDate.setDate(1), 0), 0);
    reset(defaultValues);
    setTodayCheckbox(false);
    setMonthCheckbox(false);
    setValue("startDate", d);
    setValue("startTime", startOfDay(defaultDate));
    setValue("endDate", defaultDate);
    setValue("endTime", endOfDay(defaultDate));
    setValue("type", undefined);
    setSelectedEquipments([t("form.all")]);
    setSelectedCameras([
      {
        ["location_name"]: t("form.all"),
        ["camera_name"]: t("form.all")
      }
    ]);
    setSelectedTypes(["all"]);
  };

  const handleTodayCheckbox = (newValue: boolean) => {
    if (monthCheckbox) {
      setMonthCheckbox(false);
    }

    setTodayCheckbox(newValue);

    if (newValue) {
      const d = new Date();
      setValue("startDate", startOfDay(d));
      setValue("endDate", d);
      setValue("startTime", startOfDay(d));
      setValue("endTime", d);
    }
  };

  const handleMonthCheckbox = (newValue: boolean) => {
    if (todayCheckbox) {
      setTodayCheckbox(false);
    }

    setMonthCheckbox(newValue);

    if (newValue) {
      setValue("startDate", setMinutes(setHours(defaultDate.setDate(1), 0), 0));
      setValue("endDate", new Date());
      setValue(
        "startTime",
        setMinutes(setHours(subDays(defaultDate, 7), 0), 0)
      );
      setValue("endTime", new Date());
    }
  };

  const onSubmit = async (data: FormRestrictionDashboardFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);
    newData.startTime = new Date(newData.startTime);
    newData.endTime = new Date(newData.endTime);

    if (
      selectedEquipments.includes(t("form.all")) ||
      selectedEquipments.length === 0
    ) {
      newData.equipments = undefined;
    } else {
      newData.equipments = selectedEquipments;
    }

    const IsAll = selectedCameras.filter(
      c => c["location_name"] === t("form.all")
    );

    if (IsAll.length > 0 || selectedCameras.length === 0) {
      newData.cameras = undefined;
    } else {
      newData.cameras = selectedCameras.map(camera => camera.camera_name);
    }

    if (selectedTypes.includes("all") || selectedTypes.length === 0) {
      newData.type = undefined;
    } else {
      newData.type = selectedTypes;
    }

    setFilterData(newData);
    setOpen(false);
  };

  useEffect(() => {
    if (
      getValues("startDate") !== "" &&
      getValues("endDate") !== "" &&
      getValues("startTime") !== "" &&
      getValues("endTime") !== ""
    ) {
      setMonthCheckbox(true);
    }
  }, []);

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("MonitoredVehiclesDashboardPage.filter")}
      testId="restriction-form-filter"
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8} data-testid="startDate">
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} data-testid="startTime">
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} data-testid="endDate">
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} data-testid="endTime">
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("RestrictionDashboardPage.typeTitle")}</FormLabel>
              <Autocomplete
                multiple
                filterSelectedOptions
                data-testid="typeAutocomplete"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={[
                  "all",
                  "border_division",
                  "speeding",
                  "exclusive_road",
                  "vehicle_rotation",
                  "blocked_road"
                ]}
                getOptionLabel={option =>
                  t(`RestrictionDashboardPage.type.${option}`)
                }
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.includes("all");
                  if (
                    selectedTypes.includes("all") &&
                    selectedTypes.length === 1
                  ) {
                    const idx = newValues.indexOf("all");
                    newValues.splice(idx, 1);
                    setSelectedTypes(newValues);
                  } else if (noIsAll) {
                    setSelectedTypes(newValues);
                  } else {
                    setSelectedTypes(["all"]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedTypes}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t("RestrictionDashboardPage.typeHint")}
                  />
                )}
              />
              {watchType !== undefined && watchType.length === 0 && (
                <Typography
                  align="left"
                  variant="caption"
                  color="primary.light"
                >
                  {t(`RestrictionDashboardPage.typeTooltip.${watchType}`)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.equipments")}
              </FormLabel>
              <Autocomplete
                multiple
                filterSelectedOptions
                data-testid="equipmentsAutocomplete"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={locations}
                getOptionLabel={option => option}
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.includes(t("form.all"));
                  if (
                    selectedEquipments.includes(t("form.all")) &&
                    selectedEquipments.length === 1
                  ) {
                    const idx = newValues.indexOf(t("form.all"));
                    newValues.splice(idx, 1);
                    setSelectedEquipments(newValues);
                  } else if (noIsAll) {
                    setSelectedEquipments(newValues);
                  } else {
                    setSelectedEquipments([t("form.all")]);
                    setSelectedCameras([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedEquipments}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.equipmentHint"
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("CaptureReportPage.cameras")}</FormLabel>
              <Autocomplete
                multiple
                size="small"
                disablePortal
                data-testid="camerasAutocomplete"
                disabled={isLoading}
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={[
                  {
                    ["location_name"]: t("form.all"),
                    ["camera_name"]: t("form.all")
                  },
                  ...cameras.filter(c =>
                    selectedEquipments.includes(c["location_name"])
                  )
                ]}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.camera_name}
                value={selectedCameras}
                onChange={(event, values) => {
                  event.preventDefault();
                  const noIsAll = values.findIndex(
                    c => c["location_name"] === t("form.all")
                  );
                  const allArray = selectedCameras.filter(
                    c => c["location_name"] === t("form.all")
                  );
                  if (allArray.length > 0 && selectedCameras.length === 1) {
                    values.splice(noIsAll, 1);
                    setSelectedCameras(values);
                  } else if (noIsAll === -1) {
                    setSelectedCameras(values);
                  } else {
                    setSelectedCameras([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={todayCheckbox}
                    onChange={(_event, newValue) => {
                      handleTodayCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.today").toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={monthCheckbox}
                    onChange={(_event, newValue) => {
                      handleMonthCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.month").toString()}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <div data-testid="filter-btn">
            <Button
              customProps={{
                disabled: !formState.isValid || isLoading,
                type: "submit"
              }}
            >
              {t("action.filter")}
            </Button>
          </div>
        </Footer>
      </form>
    </Drawer>
  );
};

export default RestrictionDashboardFilter;
