import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "contexts/AuthContext";
import useUserGroupAPI from "api/UserGroupAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";

type UserForm = {
  customerId: string;
  name: string;
  description: string;
  users: string[];
};

const defaultValues: UserForm = {
  customerId: "",
  name: "",
  description: "",
  users: []
};

type Props = {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
};

const UserGroupFormDialog: FC<Props> = ({
  name,
  open,
  setOpen,
  updateCallerContent
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isCreating = name === "";

  const { sessionUser } = useAuth();
  const UserGroupAPI = useUserGroupAPI();
  const { errorHandler } = useErrorHandler();

  const [group, setGroup] = useState<UserForm>(defaultValues);

  const { control, formState, handleSubmit, reset } = useForm<UserForm>({
    mode: "onChange",
    defaultValues
  });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setLoading(true);
    try {
      const [groupResponse] = await Promise.all([
        isCreating
          ? Promise.resolve(null)
          : UserGroupAPI.getByName({
              customerId: sessionUser["customer_id"],
              groupName: name
            })
      ]);

      if (!isCreating && groupResponse) {
        setGroup({
          customerId: sessionUser["customer_id"],
          name: groupResponse["group_name"],
          description: groupResponse["group_description"] ?? "",
          users: groupResponse["group_users"]
        });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, name]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(group);
  }, [reset, group]);

  useEffect(() => {
    if (!open) {
      setGroup(defaultValues);
      reset(group);
    }
    setOpen(open);
  }, [open]);

  const onSubmit = async (data: UserForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setLoading(true);
    try {
      if (isCreating) {
        const userGroupData = data;
        userGroupData.customerId = sessionUser["customer_id"];
        await UserGroupAPI.create(userGroupData);
      } else {
        await UserGroupAPI.update({
          customerId: sessionUser["customer_id"],
          data,
          userGroup: group
        });
      }
      setOpen(false);
      updateCallerContent();
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("UserGroupFormDialog.createANewUserGroup")
          : t("UserGroupFormDialog.editUserGroup")
      }
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("UserGroupFormDialog.name")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("UserGroupFormDialog.description")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default UserGroupFormDialog;
