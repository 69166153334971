import { FC } from "react";
import { Box, styled } from "@mui/material";
import noImage from "assets/image_unavailable.jpg";

const ImgContainer = styled(Box)(() => ({
  width: "48px",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7
  },
  img: {
    borderRadius: "4px",
    lineHeight: 0,
    textIndent: "-999em",
    "&:after": {
      content: "''",
      display: "block",
      backgroundColor: "white",
      backgroundImage: `url(${noImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      padding: "40% 0",
      position: "relative",
      width: "48px"
    }
  }
}));

interface CaptureImgButtonProps {
  image_link: string;
  onClick: () => void;
}

const CaptureImgButton: FC<CaptureImgButtonProps> = ({
  image_link,
  onClick
}) => (
  <ImgContainer onClick={() => onClick()}>
    <img src={image_link} width="48px" height="40px" alt="capture-image" />
  </ImgContainer>
);

export default CaptureImgButton;
