import Pages from "enums/Pages";
import { Sliders } from "react-feather";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import FlowDashboardFilter from "./FlowDashboardFilter";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import CapturedVehicleGraphs from "./CapturedVehicleGraphs";
import { usePageLocation } from "contexts/PageLocationContext";
import { FlowDashboardFilterProvider } from "./FlowDashboardFilterContext";
import TopSpeedRanking from "pages/FlowDashboard/TopSpeedRanking";
import RouteFlowMap from "pages/FlowDashboard/RouteFlowMap/RouteFlowMap";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const FlowDashboardPage: FC = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.flowDashboard"));
    setLocation([
      {
        label: t("FlowDashboardPage.flowDashboard"),
        page: Pages.FLOW_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <FlowDashboardFilterProvider>
          <PageSection
            title={t("FlowDashboardPage.flowDashboard")}
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            }
          >
            <FlowDashboardFilter
              open={isFilterOpen}
              setOpen={setIsFilterOpen}
            />
            <RouteFlowMap />
            <CapturedVehicleGraphs />
            <TopSpeedRanking />
          </PageSection>
        </FlowDashboardFilterProvider>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default FlowDashboardPage;
