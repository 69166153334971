import { forwardRef } from "react";
import { Box, Button, IconButton, styled, Typography } from "@mui/material";
import { AlertCircle, X } from "react-feather";
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import Pages from "enums/Pages";
import snackNotification from "components/SnackNotification";

type NotificationParams = {
  message: SnackbarMessage;
  key: SnackbarKey;
  bgColor?: string;
  id: string;
  plate: string;
  reason?: {
    plate?: boolean;
    date_hour?: boolean;
  };
};

const CustomSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  padding: "6px 8px 6px 20px",
  backgroundColor: "red",
  borderRadius: 4,
  boxShadow: theme.shadows[5]
}));

const CardActions = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const Buttons = styled(Box)(() => ({
  display: "flex",
  flexShrink: 0,
  marginLeft: 8
}));

const PlateNotification = forwardRef<HTMLDivElement, NotificationParams>(
  (props, ref) => {
    const { t } = useTranslation();
    const reason = (plate?: boolean, date_hour?: boolean): string => {
      if (plate && date_hour)
        return "MonitoredVehicleNotification.plate_and_date_hour";
      // if (plate) return "MonitoredVehicleNotification.plate";
      // if (date_hour) return "MonitoredVehicleNotification.date_hour";
      return "";
    };

    return (
      <CustomSnackbarContent
        ref={ref}
        style={{ backgroundColor: props.bgColor ?? "#323e54" }}
      >
        <CardActions>
          <AlertCircle style={{ color: "white" }} />
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "12px", color: "white", whiteSpace: "nowrap" }}
          >
            {props.message}
          </Typography>
          {props.reason && (
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: "12px", color: "white", whiteSpace: "nowrap" }}
            >
              {t(reason(props.reason.plate, props.reason.date_hour))}
            </Typography>
          )}
          <Buttons>
            <Button
              size="small"
              variant="text"
              sx={{ color: "white", textTransform: "uppercase" }}
              onClick={() =>
                history.push(
                  `${Pages.CAPTURE_REPORT}/${encodeURIComponent(props.plate)}`
                )
              }
            >
              {t("MonitoredVehicleNotification.record")}
            </Button>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => snackNotification.close(props.id)}
            >
              <X />
            </IconButton>
          </Buttons>
        </CardActions>
      </CustomSnackbarContent>
    );
  }
);

PlateNotification.displayName = "PlateNotification";

export default PlateNotification;
