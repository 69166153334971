import { useState } from "react";
import { flatMap } from "lodash";
import useMonitoredRouteAPI, { RouteData } from "api/MonitoredRouteAPI";
import { useAuth } from "contexts/AuthContext";

const sessionStorageKey = "route-map-data";

export type RouteDirections = {
  route: RouteData;
  direction: google.maps.DirectionsResult;
  renderer?: google.maps.DirectionsRenderer;
}[];

export const getLatLngFromPosition = (position: string): google.maps.LatLng => {
  const positionParts = position.split(",");
  const lat = Number(positionParts[0]);
  const lng = Number(positionParts[1]);
  return new google.maps.LatLng({
    lat,
    lng
  });
};

const routeIsEqual = (route: RouteData, newRoute: RouteData): boolean =>
  route.route_name === newRoute.route_name &&
  route.initial_camera === newRoute.initial_camera &&
  route.final_camera === newRoute.final_camera;

const routeExists = (route: RouteData, oldRoutes: RouteData[]): boolean => {
  if (!oldRoutes || oldRoutes.length <= 0) return false;

  for (const oldRoute of oldRoutes) {
    if (routeIsEqual(route, oldRoute)) {
      return true;
    }
  }
  return false;
};

const useRouteFlowManager = (): [
  boolean,
  () => Promise<RouteDirections>,
  (r: RouteData[]) => Promise<RouteDirections | undefined>,
  (r: RouteDirections) => void
] => {
  const [isBusy, setIsBusy] = useState(false);
  const [routeDirectionsState, setRouteDirectionsState] =
    useState<RouteDirections>([]);
  const { sessionUser } = useAuth();
  const MonitoredRouteAPI = useMonitoredRouteAPI();

  const getInitialDirections = async (): Promise<RouteDirections> => {
    try {
      setIsBusy(true);
      const savedDirections = getDirections();
      if (savedDirections && savedDirections.length > 0) {
        return savedDirections;
      }
      if (!sessionUser) return [];
      const response = await MonitoredRouteAPI.lastData({
        customerId: sessionUser.customer_id
      });
      const directions = await buildDirections(response.data);
      return directions || [];
    } finally {
      setIsBusy(false);
    }
  };

  const getDirections = (): RouteDirections => {
    setIsBusy(true);
    if (routeDirectionsState.length > 0) {
      return routeDirectionsState;
    }
    const cookieRoutesData = localStorage.getItem(sessionStorageKey);
    let routeDirectionsSaved: RouteDirections = [];
    if (cookieRoutesData) {
      routeDirectionsSaved = JSON.parse(cookieRoutesData);
      setRouteDirectionsState(routeDirectionsSaved);
    }
    return routeDirectionsSaved;
  };

  const clearUnusedMapRoutes = (routeDirections: RouteDirections) => {
    for (const routeDirection of routeDirections) {
      if (routeDirection.renderer) {
        routeDirection.renderer.setMap(null);
      }
    }
  };

  const buildDirections = async (
    routes: RouteData[]
  ): Promise<RouteDirections | undefined> => {
    try {
      setIsBusy(true);

      const routeDirectionsSaved = getDirections();

      const routeDirections = routeDirectionsSaved.filter(v =>
        routeExists(v.route, routes)
      );

      clearUnusedMapRoutes(
        routeDirectionsSaved.filter(v => !routeExists(v.route, routes))
      );

      const savedRoutes: RouteData[] = flatMap(routeDirections, "route");
      const directionsService = new google.maps.DirectionsService();
      const directionsToBuild = [];

      for (const route of routes) {
        if (routeExists(route, savedRoutes)) {
          const routeDirectionToUpdate = routeDirections.find(rd =>
            routeIsEqual(rd.route, route)
          );
          if (routeDirectionToUpdate) {
            routeDirectionToUpdate.route = route;
          }
        } else {
          const directionPromise = directionsService.route({
            origin: getLatLngFromPosition(route.initial_position),
            destination: getLatLngFromPosition(route.final_position),
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC
          });
          directionsToBuild.push({
            route,
            direction: directionPromise
          });
        }
      }

      if (directionsToBuild.length > 0) {
        const allPromises = flatMap(directionsToBuild, "direction");
        const directionsResponses = await Promise.all(allPromises);
        for (let i = 0; i < directionsToBuild.length; i++) {
          if (directionsResponses[i]) {
            routeDirections.push({
              route: directionsToBuild[i].route,
              direction: directionsResponses[i]
            });
          }
        }
      }

      setRouteDirectionsState(routeDirections);

      const toStorage = routeDirections.map(r => ({
        direction: r.direction,
        route: r.route
      }));
      localStorage.setItem(sessionStorageKey, JSON.stringify(toStorage));

      return routeDirections;
    } finally {
      setIsBusy(false);
    }
  };

  const updateDirectionsState = (routeDirections: RouteDirections) => {
    setRouteDirectionsState(routeDirections);
  };

  return [isBusy, getInitialDirections, buildDirections, updateDirectionsState];
};

export default useRouteFlowManager;
