import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { usePageLocation } from "contexts/PageLocationContext";
import Pages from "enums/Pages";
import AuthPageLayout from "layouts/AuthPageLayout";

import StepCode from "./StepCode";
import StepEmail from "./StepEmail";
import StepPassword from "./StepPassword";

const ResetPasswordPage: FC = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const history = useHistory();

  const goToSignInPage = () => {
    history.push(Pages.SIGN_IN);
  };

  const { t } = useTranslation();

  const { setPageTitle } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.confirmUser"));
  }, [t]);

  return (
    <AuthPageLayout noBg closeable onClose={goToSignInPage}>
      {step === 1 && <StepEmail setStep={setStep} setEmail={setEmail} />}
      {step === 2 && (
        <StepCode setStep={setStep} email={email} setCode={setCode} />
      )}
      {step === 3 && (
        <StepPassword setStep={setStep} email={email} code={code} />
      )}
    </AuthPageLayout>
  );
};

export default ResetPasswordPage;
