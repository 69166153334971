import {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent
} from "react";
import {
  Box,
  Chip,
  Grid,
  styled,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  DialogContent,
  OutlinedInput,
  FormControlLabel,
  SelectChangeEvent,
  CircularProgress
} from "@mui/material";
import useIntegrationsAPI, {
  TelegramCustomerIntegrationGroup
} from "api/IntegrationsAPI";
import { fomatDateToTestCase, fomatTimeToTestCase } from "utils/DateFunctions";
import debounce from "lodash/debounce";
import TabPanel from "components/TabPanel";
import { Error } from "components/FormField";
import useUserAPI, { User } from "api/UserAPI";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import RegexPatterns from "utils/RegexPatterns";
import ColorPicker from "components/ColorPicker";
import SelectField from "components/SelectField";
import { useModal } from "contexts/ModalContext";
import { Controller, useForm } from "react-hook-form";
import TimePickerField from "components/TimePickerField";
import alert1Mp3 from "../../../assets/alerts/alert1.mp3";
import alert2Mp3 from "../../../assets/alerts/alert2.mp3";
import alarm1Mp3 from "../../../assets/alerts/alarm1.mp3";
import alarm2Mp3 from "../../../assets/alerts/alarm2.mp3";
import snackNotification from "components/SnackNotification";
import SimpleSoundPlayer from "components/SimpleSoundPlayer";
import useUserGroupAPI, { UserGroup } from "api/UserGroupAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import carAlertMp3 from "../../../assets/alerts/car_alert.mp3";
import truckAlertMp3 from "../../../assets/alerts/truck_alert.mp3";
import InputNumber from "components/InputNumber";
import UploadFile from "components/UploadFile";
import useMonitoringRuleAPI, { Rule } from "api/MonitoringRuleAPI";
import FormDialog from "components/FormDialog";
import FormLabel from "components/FormLabel";
import { defaultRule } from "./MonitoringVehiclesPage";

const alerts = [
  {
    name: "Alerta 1",
    value: "alert1",
    pathMp3: alert1Mp3
  },
  {
    name: "Alerta 2",
    value: "alert2",
    pathMp3: alert2Mp3
  },
  {
    name: "Alarme 1",
    value: "alarm1",
    pathMp3: alarm1Mp3
  },
  {
    name: "Alarme 2",
    value: "alarm2",
    pathMp3: alarm2Mp3
  },
  {
    name: "Alerta Carro",
    value: "car_alert",
    pathMp3: carAlertMp3
  },
  {
    name: "Alerta Caminhão",
    value: "truck_alert",
    pathMp3: truckAlertMp3
  }
];

const StyledDivCheckbox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  div: {
    marginRight: theme.spacing(0.7)
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start"
  }
}));

type MonitoringRuleVehicleForm = {
  startTime: Date | string | null;
  endTime: Date | string | null;
  retentionPopup: boolean;
  notificationSound: string;
  notificationColor: string;
  owners: string[];
  ownersGroup: string[];
  soundAlert: boolean;
  popupAlert: boolean;
  email: boolean;
  whatsapp: boolean;
  telegram: boolean;
  sms: boolean;
};

type DialogProps = {
  monitoringRule: Rule;
  setMonitoringRule: (monitoringRule: Rule) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onRequestFinish: () => void;
};

const defaultValues: MonitoringRuleVehicleForm = {
  startTime: null,
  endTime: null,
  notificationSound: "alert1",
  notificationColor: "#323e54",
  owners: [],
  ownersGroup: [],
  retentionPopup: false,
  soundAlert: false,
  popupAlert: false,
  email: false,
  whatsapp: false,
  telegram: false,
  sms: false
};

const MonitoringRuleFormDialog: FC<DialogProps> = props => {
  const UserAPI = useUserAPI();
  const UserGroupAPI = useUserGroupAPI();
  const MonitoringRuleAPI = useMonitoringRuleAPI();
  const IntegrationsAPI = useIntegrationsAPI();
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { monitoringRule, open, setOpen, onRequestFinish, setMonitoringRule } =
    props;
  const { sessionUser } = useAuth();
  const isCreating =
    Object.keys(monitoringRule).length === 0 &&
    monitoringRule.constructor === Object;
  const { showModal } = useModal();
  const [searchUsers, setSearchUsers] = useState("");
  const [users, setUsers] = useState<string[]>([]);
  const [selectedSound, setSelectedSound] = useState<string>(alerts[0].pathMp3);
  const [permittedPlates, setPermittedPlates] = useState<string[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);
  const [selectedTelegramGroups, setSelectedTelegramGroups] = useState<
    string[]
  >([]);
  const [telegramGroups, setTelegramGroups] = useState<
    TelegramCustomerIntegrationGroup[]
  >([]);
  const { errorHandler } = useErrorHandler();

  const [notificationColor, setNotificationColor] = useState<string>(
    defaultValues.notificationColor
  );

  const [repet, setRepet] = useState<number>(0);
  const [daysQtd, setDaysQtd] = useState<number>(0);

  const { reset, watch, control, setValue, formState, handleSubmit } =
    useForm<MonitoringRuleVehicleForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const watchSound = watch("notificationSound");

  const watchTelegram = watch("telegram", false);

  useEffect(() => {
    const alert = alerts.find(s => s.value === watchSound);
    if (alert) {
      setSelectedSound(alert.pathMp3);
    }
  }, [watchSound]);

  useEffect(() => {
    if (!watchTelegram) {
      setSelectedTelegramGroups([]);
    }
  }, [watchTelegram]);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [userGroupResponse, telegramGroupsResponse] = await Promise.all([
        UserGroupAPI.listAll({
          customerId: sessionUser.customer_id,
          page: -1
        }),
        IntegrationsAPI.listTelegramCustomerIntegration(sessionUser.customer_id)
      ]);

      setUserGroups(
        (Array.isArray(userGroupResponse.data)
          ? (userGroupResponse.data as UserGroup[])
          : []
        ).map(userGroup => userGroup["group_name"])
      );
      setTelegramGroups(telegramGroupsResponse.data);

      if (!isCreating) {
        const datetime = new Date();
        reset({
          startTime: new Date(
            `${fomatDateToTestCase(datetime)} ${
              monitoringRule.rule_information.start_interval
            }`
          ),
          endTime: new Date(
            `${fomatDateToTestCase(datetime)} ${
              monitoringRule.rule_information.end_interval
            }`
          ),
          notificationSound:
            monitoringRule.custom_sound_alert ||
            defaultValues.notificationSound,
          notificationColor:
            monitoringRule.alert_color || defaultValues.notificationColor,
          owners: monitoringRule.owners || [],
          ownersGroup: monitoringRule.owners_group || [],
          retentionPopup:
            monitoringRule.persist_alert || defaultValues.retentionPopup,
          soundAlert: monitoringRule.sound_alert,
          popupAlert: monitoringRule.popup_alert,
          email: monitoringRule.rule_information.notifications.email,
          whatsapp: monitoringRule.rule_information.notifications.whatsapp,
          telegram: monitoringRule.rule_information.notifications.telegram,
          sms: monitoringRule.rule_information.notifications.sms
        });

        setNotificationColor(
          monitoringRule.alert_color || defaultValues.notificationColor
        );
        setPermittedPlates(monitoringRule.rule_information.allowed_plates);
        setRepet(monitoringRule.rule_information.num_passes);
        setDaysQtd(Number(monitoringRule.rule_information.monitoring_until));
        setSelectedOwners(monitoringRule.owners);
        setSelectedUserGroups(monitoringRule.owners_group || []);
        setSelectedTelegramGroups(
          monitoringRule.rule_information.notifications.telegram_groups || []
        );
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, monitoringRule]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "notificationColor" && value.notificationColor) {
        if (RegexPatterns.HEX_COLOR.test(value.notificationColor)) {
          setNotificationColor(value.notificationColor);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
      setUserGroups([]);
      setUsers([]);
      setRepet(0);
      setDaysQtd(0);
      setPermittedPlates([]);
      setSelectedUserGroups([]);
      setSelectedTelegramGroups([]);
      setNotificationColor(defaultValues.notificationColor);
    }
  }, [open]);

  const requestSearchUsers = async (search: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      setIsLoadingUsers(true);
      if (search) {
        const userResponse = await UserAPI.search(
          sessionUser["customer_id"],
          search,
          0
        );
        const usersData = userResponse.data as User[];
        if (usersData) {
          setUsers(usersData.map(user => user.username));
        } else {
          setUsers([]);
        }
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchUsers = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestSearchUsers(searchValue);
      }, 700),
    []
  );

  useEffect(() => {
    fetchUsers(searchUsers);
  }, [searchUsers, fetchUsers]);

  const onSubmit = async (data: MonitoringRuleVehicleForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    const newData = { ...data };

    try {
      newData.owners = selectedOwners;
      newData.ownersGroup = selectedUserGroups;
      newData.startTime = newData.startTime
        ? fomatTimeToTestCase(newData.startTime as Date)
        : "";
      newData.endTime = newData.endTime
        ? fomatTimeToTestCase(newData.endTime as Date)
        : "";

      if (isCreating) {
        await MonitoringRuleAPI.create({
          ["customer_id"]: sessionUser["customer_id"],
          ["custom_sound_alert"]: newData.notificationSound,
          ["alert_color"]: newData.notificationColor,
          ["persist_alert"]: newData.retentionPopup,
          ["sound_alert"]: newData.soundAlert,
          ["popup_alert"]: newData.popupAlert,
          owners: newData.owners,
          ["owners_group"]: newData.ownersGroup,
          ["rule_information"]: {
            ["allowed_plates"]: permittedPlates,
            ["start_interval"]: newData.startTime,
            ["end_interval"]: newData.endTime,
            ["num_passes"]: repet,
            ["monitoring_until"]: daysQtd.toString(),
            notifications: {
              email: newData.email,
              whatsapp: newData.whatsapp,
              telegram: newData.telegram,
              ["telegram_groups"]: selectedTelegramGroups,
              sms: newData.sms
            }
          }
        });
        snackNotification.success(t("MonitoringVehicles.monitoredRuleCreated"));
      } else {
        await MonitoringRuleAPI.update({
          ["customer_id"]: sessionUser["customer_id"],
          ["custom_sound_alert"]: newData.notificationSound,
          ["alert_color"]: newData.notificationColor,
          ["persist_alert"]: newData.retentionPopup,
          ["sound_alert"]: newData.soundAlert,
          ["popup_alert"]: newData.popupAlert,
          owners: newData.owners,
          ["owners_group"]: newData.ownersGroup,
          ["rule_information"]: {
            ["allowed_plates"]: permittedPlates,
            ["start_interval"]: newData.startTime,
            ["end_interval"]: newData.endTime,
            ["num_passes"]: repet,
            ["monitoring_until"]: daysQtd.toString(),
            notifications: {
              email: newData.email,
              whatsapp: newData.whatsapp,
              telegram: newData.telegram,
              ["telegram_groups"]: selectedTelegramGroups,
              sms: newData.sms
            }
          }
        });
        snackNotification.success(t("MonitoringVehicles.monitoredRuleUpdated"));
      }

      onRequestFinish();
      closeDialog();
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const isFormCompleted = (): boolean => {
    if (watchTelegram && selectedTelegramGroups.length === 0) return false;
    return (
      formState.isValid &&
      !isLoading &&
      (selectedOwners.length > 0 || selectedUserGroups.length > 0)
    );
  };

  const handleRemove = () => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle"),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async () => {
    try {
      if (sessionUser && monitoringRule) {
        await MonitoringRuleAPI.deleteRule({
          ["customer_id"]: sessionUser["customer_id"]
        });
        snackNotification.success(t("MonitoringVehicles.monitoredRuleRemoved"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setOpen(false);
      setMonitoringRule(defaultRule);
    }
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length <= 0) return;
    try {
      setUploadLoading(true);

      const response = await event.target.files[0].text();

      if (response) {
        setPermittedPlates(response.split(",") || []);
        snackNotification.success(
          t("MonitoringVehicles.uploadPlateFileSuccessMessage")
        );
      } else {
        snackNotification.error(t("MonitoringVehicles.uploadFileErrorMessage"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
      setUploadLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      onDelete={() => handleRemove()}
      hasDelete={Boolean(monitoringRule)}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid || !isFormCompleted()}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("MonitoringVehicles.createANewMonitoredRule")
          : t("MonitoringVehicles.editMonitoredRule")
      }
    >
      <TabPanel
        tabs={[
          {
            value: "general",
            label: t("MonitoringVehicles.labels.general"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h5" align="left">
                    {t("MonitoringVehicles.hourRange")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <TimePickerField
                        label={t("MonitoringVehicles.activationHour")}
                        disabled={isLoading}
                        required
                        gutter
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="endTime"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <TimePickerField
                        label={t("MonitoringVehicles.expirationHour")}
                        disabled={isLoading}
                        required
                        gutter
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>{t("MonitoringVehicles.repetition")}</FormLabel>
                  <InputNumber
                    min={0}
                    gutter={false}
                    fullWidth={false}
                    customProps={{
                      disabled: isLoading,
                      value: repet,
                      onChange({ target: { value } }) {
                        if (!value) return;
                        const newValue = Number(value);
                        setRepet(newValue >= 0 ? newValue : 0);
                      }
                    }}
                  />
                  <Typography
                    variant="caption"
                    align="left"
                    color="primary.light"
                  >
                    {t("MonitoringVehicles.repetitionHint")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h6" align="left">
                    {t("MonitoringVehicles.expiration")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>
                    {t("MonitoringVehicles.monitoringTime")}
                  </FormLabel>
                  <InputNumber
                    min={0}
                    gutter={false}
                    fullWidth={false}
                    customProps={{
                      disabled: isLoading,
                      value: daysQtd,
                      onChange({ target: { value } }) {
                        if (!value) return;
                        const newValue = Number(value);
                        setDaysQtd(newValue >= 0 ? newValue : 0);
                      }
                    }}
                  />
                  <Typography
                    variant="caption"
                    align="left"
                    color="primary.light"
                  >
                    {t("MonitoringVehicles.expirationHint")}
                  </Typography>
                </Grid>
              </Grid>
            )
          },
          {
            value: "exception",
            label: t("MonitoringVehicles.labels.exception"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h6" align="left">
                    {t("MonitoringVehicles.permittedVehicle")}
                  </Typography>
                  <Box>
                    <Autocomplete
                      multiple
                      freeSolo
                      popupIcon={<></>}
                      openText={t("action.open")}
                      closeText={t("action.close")}
                      clearText={t("action.clear")}
                      options={[]}
                      disableListWrap
                      getOptionLabel={option => option}
                      filterSelectedOptions
                      noOptionsText={t("form.noOptions")}
                      size="small"
                      value={permittedPlates}
                      onChange={(event, plate) => {
                        event.preventDefault();
                        setPermittedPlates(plate);
                      }}
                      renderInput={params => (
                        <div style={{ position: "relative" }}>
                          <TextField
                            {...params}
                            placeholder={t(
                              "MonitoringVehicles.permittedVehicleInputHint"
                            )}
                          />
                        </div>
                      )}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    align="left"
                    color="primary.light"
                  >
                    {t("MonitoringVehicles.permittedVehicleHint")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mt: 2 }}>
                    {uploadLoading && (
                      <DialogContent>
                        <Typography align="center">
                          {t("waitState.loading")}
                        </Typography>
                      </DialogContent>
                    )}
                    {!uploadLoading && (
                      <UploadFile
                        fileRef={hiddenFileInput}
                        onChange={event => handleChangeFile(event)}
                      />
                    )}
                    <Typography
                      variant="caption"
                      align="left"
                      color="primary.light"
                    >
                      {t("MonitoringVehicles.permittedVehicleCSVHint")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )
          },
          {
            value: "notification",
            label: t("MonitoringVehicles.labels.notification"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h5" align="left">
                    {t("MonitoringVehicles.selectedContact")}
                  </Typography>
                  <StyledDivCheckbox>
                    {["email", "telegram"].map(contact => (
                      <div key={contact}>
                        <Controller
                          name={contact as keyof MonitoringRuleVehicleForm}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={field.onChange}
                                  disabled={isLoading}
                                  checked={Boolean(field.value)}
                                />
                              }
                              label={t(
                                `MonitoringVehicles.${contact}`
                              ).toString()}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </StyledDivCheckbox>
                </Grid>
                {watchTelegram && (
                  <Grid item xs={12} sm={12}>
                    <SelectField
                      label={t("MonitoringVehicles.telegramsGroup")}
                      required
                      disabled={isLoading}
                      multiple
                      error={selectedTelegramGroups.length === 0}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      value={selectedTelegramGroups}
                      onChange={({ target }: SelectChangeEvent<unknown>) => {
                        const val = target.value as string[];
                        setSelectedTelegramGroups(val);
                      }}
                      renderValue={param => {
                        const selected = param as string[];
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5
                            }}
                          >
                            {telegramGroups
                              .filter(g => selected.includes(g.index || ""))
                              .map(group => (
                                <Chip key={group.index} label={group.name} />
                              ))}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        {t("MonitoringVehicles.selectOneOrMoreGroups")}
                      </MenuItem>
                      {telegramGroups.map((group, key) => (
                        <MenuItem key={key} value={group.index}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                    {selectedTelegramGroups.length === 0 && (
                      <Error>
                        {t("MonitoringVehicles.selectOneOrMoreGroups")}
                      </Error>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h5" align="left">
                    {t("MonitoringVehicles.selectedAlert")}
                  </Typography>
                  <StyledDivCheckbox>
                    {["soundAlert", "popupAlert"].map(alertType => (
                      <div key={alertType}>
                        <Controller
                          name={alertType as keyof MonitoringRuleVehicleForm}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={field.onChange}
                                  disabled={isLoading}
                                  checked={Boolean(field.value)}
                                />
                              }
                              label={t(
                                `MonitoringVehicles.${alertType}`
                              ).toString()}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </StyledDivCheckbox>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h5" align="left">
                    {t("MonitoringVehicles.notificationPeople")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" align="left">
                    {t("MonitoringVehicles.responsibleGroup")}
                    {!selectedOwners.length && !selectedUserGroups.length
                      ? " *"
                      : ""}
                  </Typography>
                  <Autocomplete
                    sx={{ mb: 2 }}
                    multiple
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={userGroups}
                    getOptionLabel={option => option}
                    filterSelectedOptions
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    value={selectedUserGroups}
                    onChange={(event, ug) => {
                      event.preventDefault();
                      setSelectedUserGroups(ug);
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          placeholder={t(
                            "MonitoringVehicles.responsibleGroupHint"
                          )}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" align="left">
                    {t("MonitoringVehicles.users")}
                    {!selectedOwners.length && !selectedUserGroups.length
                      ? " *"
                      : ""}
                  </Typography>
                  <Autocomplete
                    sx={{ mb: 2 }}
                    multiple
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    clearText={t("action.clear")}
                    options={users}
                    getOptionLabel={option => option}
                    filterSelectedOptions
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    value={selectedOwners}
                    onChange={(event, so) => {
                      event.preventDefault();
                      setSelectedOwners(so);
                    }}
                    renderInput={params => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          placeholder={t("form.startTyping")}
                          onChange={event => {
                            setSearchUsers(event.target.value);
                          }}
                        />
                        {isLoadingUsers && (
                          <CircularProgress
                            color="secondary"
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "10px",
                              right: "42px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2 }} variant="h5" align="left">
                    {t("MonitoringVehicles.alertCustom")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" align="left">
                    {t("MonitoringVehicles.notificationSound")}
                  </Typography>
                  <Controller
                    name="notificationSound"
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        sx={{ mb: 2 }}
                      >
                        {alerts.map(alert => (
                          <MenuItem key={alert.value} value={alert.value}>
                            {alert.name}
                          </MenuItem>
                        ))}
                      </SelectField>
                    )}
                  />
                  <div>
                    <SimpleSoundPlayer sound={selectedSound} />
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Controller
                    name="notificationColor"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.notificationColor")}
                        customProps={{
                          startAdornment: (
                            <ColorPicker
                              color={notificationColor}
                              colorChanged={hex => {
                                setValue("notificationColor", hex);
                              }}
                            />
                          ),
                          defaultValue: notificationColor
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Controller
                    name="retentionPopup"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            onChange={field.onChange}
                            disabled={isLoading}
                            checked={Boolean(field.value)}
                          />
                        }
                        label={t(
                          "MonitoringVehicles.retentionPopup"
                        ).toString()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          }
        ]}
      />
    </FormDialog>
  );
};

export default MonitoringRuleFormDialog;
