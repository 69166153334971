import { Box, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import useUserGroupAPI, { PaginatedUserGroups } from "api/UserGroupAPI";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useUserAPI, { DataUserCustomerList, User } from "api/UserAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";
import DataTable from "components/DataTable";
import Checkbox from "@mui/material/Checkbox";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import InputField from "components/InputField";
import { Search } from "react-feather";

type UserGroupForm = {
  name: string;
  users: string[];
  description: string;
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
  userGroup: UserGroupForm | null;
};

const AddUserGroupDialog: FC<Props> = ({
  open,
  setOpen,
  updateCallerContent,
  userGroup
}) => {
  const { t } = useTranslation();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { errorHandler } = useErrorHandler();
  const UserGroupAPI = useUserGroupAPI();
  const UserAPI = useUserAPI();
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const requestData = useCallback(
    async (searchValue, pageValue) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      let users: User[] = [];
      try {
        if (searchValue) {
          const userResponse = await UserAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue
          );
          const userData = userResponse.data as DataUserCustomerList;
          setPaginator({
            totalPages: userData["total_pages"] || 0,
            totalItems: userData["total_count"] || 0
          });
          users = userData.customers;
        } else {
          const userResponse = await UserGroupAPI.listGroupUsers({
            customerId: sessionUser["customer_id"],
            userGroup: userGroup?.name || "",
            exist: false,
            page: pageValue,
            pageSize: 10
          });
          const userData = userResponse.data as PaginatedUserGroups;
          setPaginator({
            totalPages: userData.total_pages,
            totalItems: userData.total_count
          });
          users = userData.users;
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setUsers(
          users.filter(user => !userGroup?.users.includes(user.username)) || []
        );
        setIsLoading(false);
      }
    },
    [sessionUser, userGroup]
  );

  useEffect(() => {
    requestData(search, 1);
  }, [requestData, open]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, 1);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const addUsers = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    snackNotification.close();
    if (userGroup) {
      const updateGroup = {
        name: userGroup.name,
        description: userGroup.description,
        users: [...userGroup.users, ...selectedUsers]
      };
      try {
        await UserGroupAPI.update({
          customerId: sessionUser["customer_id"],
          data: updateGroup,
          userGroup
        });
        updateCallerContent();
        setOpen(false);
        snackNotification.success(t("EditUserGroupsPage.usersAdded"));
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUserSelect = (username: string) => {
    if (username === "all") {
      if (selectAll) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(users.map(user => user.username));
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedUsers(prevSelectedUsers => {
        if (prevSelectedUsers.includes(username)) {
          return prevSelectedUsers.filter(user => user !== username);
        }
        return [...prevSelectedUsers, username];
      });
    }
  };

  return (
    <FormDialog
      fullScreenDialog
      onConfirm={addUsers}
      open={open}
      setOpen={setOpen}
      confirmText={t("action.add")}
      title={t("EditUserGroupsPage.addUsers")}
      isLoading={isLoading}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden"
        }}
      >
        <InputField
          gutter={false}
          customProps={{
            placeholder: t("EditUserGroupsPage.searchUser"),
            onChange: event => setSearch(event.target.value),
            startAdornment: <Search />,
            sx: {
              marginBottom: "16px"
            }
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            marginBottom: "16px"
          }}
        >
          <DataTable
            headers={[
              {
                key: "select",
                label: (
                  <Checkbox
                    checked={selectAll}
                    onChange={() => handleUserSelect("all")}
                    indeterminate={
                      selectedUsers.length > 0 &&
                      selectedUsers.length < users.length
                    }
                    sx={{
                      padding: 0,
                      "&.MuiCheckbox-root": {
                        marginTop: "-8px",
                        marginBottom: "-8px"
                      }
                    }}
                  />
                ),
                noSort: true
              },
              { key: "username", label: t("UsersPage.users") },
              { key: "name", label: t("UsersPage.name") },
              { key: "group", label: t("UsersPage.group") }
            ]}
            defaultSort={["username", "asc"]}
            data={users}
            renderRow={user => [
              <Checkbox
                key={`checkbox-${user.username}`}
                checked={selectedUsers.includes(user.username)}
                onChange={() => handleUserSelect(user.username)}
                disabled={userGroup?.users.includes(user.username)}
                sx={{
                  padding: 0,
                  "&.MuiCheckbox-root": {
                    marginTop: "-8px",
                    marginBottom: "-8px"
                  }
                }}
              />,
              user.username,
              <Typography component="div" variant="caption" key={user.username}>
                <strong>{user.name}</strong>
              </Typography>,
              user.group_name?.join(", ")
            ]}
            hideColumnsSm={[2, 3]}
            hideColumnsXs={[1, 2, 3]}
            page={page}
            onPageChange={pageValue => {
              setPage(pageValue);
              requestData(search, pageValue);
            }}
            pageSize={10}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </FormDialog>
  );
};

export default AddUserGroupDialog;
