enum HTTPStatus {
  CODE_200_SUCCESSFUL = 200,
  ERROR_400_BAD_REQUEST = 400,
  ERROR_401_UNAUTHORIZED = 401,
  ERROR_403_FORBIDDEN = 403,
  ERROR_404_NOT_FOUND = 404,
  ERROR_503_SERVICE_UNAVAILABLE = 503
}

export default HTTPStatus;
