import { AxiosResponse } from "axios";

import { Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import Crypto from "utils/Crypto";
import useAxios from "hooks/useAxios";

export type User = {
  ["customer_id"]: string;
  username: string;
  name: string;
  status: "ACTIVE" | "WRONG_1" | "WRONG_2" | "BLOCKED" | "INITIAL_BLOCKED";
  ["level_name"]?: string;
  ["sub_level_name"]?: string[];
  ["group_name"]?: string[];
};

type CreateAccountParams = {
  identification: string;
  username: string;
  name: string;
  password: string;
  confirmPassword: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  contact: string;
  email: string;
};

type UseWelcomeAPIType = {
  createAccount: (data: CreateAccountParams) => Promise<Success>;
};

const useWelcomeAPI = (): UseWelcomeAPIType => {
  const API = useAxios();

  const createAccount = async (data: CreateAccountParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.WELCOME,
      {
        ["customer_id"]: data.identification,
        ["customer_data"]: {
          name: data.name,
          address: data.address,
          ["address_number"]: data.addressNumber,
          ["address_complement"]: data.addressComplement,
          ["zip_code"]: data.zipcode,
          city: data.city,
          state: data.state,
          country: data.country,
          phone: data.phone,
          contact: data.contact,
          email: data.username
        },
        ["user_data"]: {
          username: data.username,
          password: Crypto.encrypt(data.password),
          name: data.name
        }
      }
    );
    return response.data;
  };

  return {
    createAccount
  };
};

export default useWelcomeAPI;
