import { FC } from "react";
import { X } from "react-feather";
import bgImage from "assets/login_bg.png";
import { Box, IconButton, styled, Typography } from "@mui/material";

const CancelButton = styled(Box)(() => ({
  position: "absolute",
  right: "32px",
  top: "16px"
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "table",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundColor: theme.palette.secondary.light,
  "&.noBackground": {
    backgroundImage: "none"
  },
  [theme.breakpoints.down("sm")]: {
    height: "initial",
    backgroundImage: "none"
  },
  "& > div": {
    display: "table-cell",
    verticalAlign: "middle"
  }
}));

const Container = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  borderRadius: "16px",
  background: theme.palette.background.default,
  padding: "50px 40px 20px 40px",
  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.12)",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0",
    boxShadow: "none"
  }
}));

const Link = styled("a")(() => ({
  fontWeight: 400,
  fontSize: "12px",
  marginLeft: "3px"
}));

type AuthPageProps = {
  closeable?: boolean;
  onClose?: () => void;
  large?: boolean;
  noBg?: boolean;
};

const AuthPageLayout: FC<AuthPageProps> = ({
  large = false,
  onClose = () => null,
  noBg = false,
  closeable = false,
  children
}) => (
  <Wrapper
    sx={{ backgroundImage: `url(${bgImage})` }}
    className={noBg ? "noBackground" : ""}
  >
    <div>
      <Container
        sx={{
          maxWidth: large ? "900px" : "520px",
          paddingTop: closeable ? "66px" : "50px"
        }}
      >
        {closeable && (
          <CancelButton>
            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </CancelButton>
        )}

        {children}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Typography variant="caption">Powered by </Typography>
          <Link href="https://www.pumatronix.com.br" color="secondary">
            <strong>Pumatronix</strong>
          </Link>
          <Typography variant="caption">®</Typography>
        </Box>
      </Container>
    </div>
  </Wrapper>
);

export default AuthPageLayout;
