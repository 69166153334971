import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Radio,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from "@mui/material";
import { MinusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { visuallyHidden } from "@mui/utils";
import Order from "enums/OrderType";
import { orderBy } from "lodash";
import { Table, TableContainer } from "components/Table";
import { format, parseISO } from "date-fns";
import snackNotification from "components/SnackNotification";
import useCustomFilterAPI, { CustomFilter } from "api/CustomFilterAPI";
import { useAuth } from "contexts/AuthContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useCustomFilter } from "components/CustomFilterMenu/CustomFilterContext";
import FormDialog from "components/FormDialog";

type DeleteItemState = {
  itemId: string;
  isDeleting: boolean;
};

const deleteItemInitialState: DeleteItemState = {
  itemId: "",
  isDeleting: false
};

const ListCustomFilterDialog: FC = () => {
  const { t } = useTranslation();
  const CustomFilterAPI = useCustomFilterAPI();
  const {
    isListFilterDialogOpen,
    setIsListFilterDialogOpen,
    setAppliedCustomFilter
  } = useCustomFilter();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [order, setOrder] = useState<Order>("asc");
  const [orderProperty, setOrderProperty] = useState<string>("filter_name");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItemState, setDeleteItemState] = useState<DeleteItemState>(
    deleteItemInitialState
  );
  const [customFilterData, setCustomFilterData] = useState<CustomFilter[]>([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const { currentFilterKey } = useCustomFilter();

  const requestData = useCallback(async () => {
    if (!sessionUser || !currentFilterKey) return;
    setIsLoading(true);
    try {
      const filtersResponse = await CustomFilterAPI.list({
        ["customer_id"]: sessionUser.customer_id,
        ["filter_key"]: currentFilterKey
      });

      setCustomFilterData(filtersResponse.data.items);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser, currentFilterKey]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderProperty === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderProperty(property);

    const sortReportResults = orderBy(
      customFilterData,
      ["", property],
      isAsc ? [false, "asc"] : [false, "desc"]
    );

    setCustomFilterData(sortReportResults || []);
  };

  const closeDialog = () => {
    setIsListFilterDialogOpen(false);
  };

  const handleRemoveCustomFilter = async (filterId: string) => {
    if (!sessionUser) return;
    try {
      setDeleteItemState({
        isDeleting: true,
        itemId: filterId
      });
      await CustomFilterAPI.deleteById({
        ["customer_id"]: sessionUser.customer_id,
        ["filter_id"]: filterId
      });
      snackNotification.success(
        t("CustomFilterMenu.customFilterSuccessfullyDeleted")
      );
      if (selectedFilter === filterId) {
        setSelectedFilter("");
      }
      setCustomFilterData(
        customFilterData.filter(filter => filter.filter_id !== filterId)
      );
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDeleteItemState({
        isDeleting: false,
        itemId: ""
      });
    }
  };

  const handleSelectFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.value);
  };

  const handleApplyFilterClick = () => {
    setAppliedCustomFilter(
      customFilterData.find(c => c.filter_id === selectedFilter)
    );
    closeDialog();
    snackNotification.success(
      t("CustomFilterMenu.customFilterSuccessfullyApplied")
    );
  };

  useEffect(() => {
    if (isListFilterDialogOpen) {
      setSelectedFilter("");
      setOrderProperty("filter_name");
      requestData();
    }
  }, [isListFilterDialogOpen]);

  return (
    <FormDialog
      onConfirm={handleApplyFilterClick}
      open={isListFilterDialogOpen}
      setOpen={setIsListFilterDialogOpen}
      isLoading={isLoading}
      confirmDisabled={!selectedFilter}
      confirmText={t("CustomFilterMenu.applySelectedFilter")}
      title={t("CustomFilterMenu.listFilters")}
    >
      <Typography gutterBottom>
        {t("CustomFilterMenu.selectFilterHint")}
      </Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                sortDirection={orderProperty === "name" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "filter_name"}
                  direction={orderProperty === "filter_name" ? order : "asc"}
                  onClick={() => handleRequestSort("filter_name")}
                >
                  {t("CustomFilterMenu.name")}
                  {orderProperty === "filter_name" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderProperty === "date_created" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "date_created"}
                  direction={orderProperty === "date_created" ? order : "asc"}
                  onClick={() => handleRequestSort("date_created")}
                >
                  {t("CustomFilterMenu.addedIn")}
                  {orderProperty === "date_created" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderProperty === "user_created" ? order : false}
              >
                <TableSortLabel
                  active={orderProperty === "user_created"}
                  direction={orderProperty === "user_created" ? order : "asc"}
                  onClick={() => handleRequestSort("user_created")}
                >
                  {t("CustomFilterMenu.createdBy")}
                  {orderProperty === "user_created" ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                {t("CustomFilterMenu.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [0, 1, 2, 3, 4].map(i => (
                <TableRow key={i}>
                  <TableCell align="left">
                    <Skeleton variant="circular" width={20} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={60} />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="circular" width={20} height={20} />
                  </TableCell>
                </TableRow>
              ))}
            {!isLoading && customFilterData.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <strong>{t("CustomFilterMenu.emptyData")}</strong>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              customFilterData &&
              customFilterData.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }
                  }}
                >
                  <TableCell>
                    <Radio
                      checked={selectedFilter === row.filter_id}
                      onChange={handleSelectFilterChange}
                      value={row.filter_id}
                      name="radio-buttons"
                      size="small"
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell onClick={() => setSelectedFilter(row.filter_id)}>
                    {row.filter_name}
                  </TableCell>
                  <TableCell onClick={() => setSelectedFilter(row.filter_id)}>
                    {format(parseISO(row.date_created), t("form.dateFormat"))}
                  </TableCell>
                  <TableCell onClick={() => setSelectedFilter(row.filter_id)}>
                    {row.user_created}
                  </TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                      sx={{ justifyContent: "end" }}
                    >
                      <Tooltip title={t("action.remove").toString()}>
                        <span style={{ position: "relative" }}>
                          <IconButton
                            onClick={() =>
                              handleRemoveCustomFilter(row.filter_id)
                            }
                            disabled={
                              row.user_created !== sessionUser?.username ||
                              (deleteItemState.itemId === row.filter_id &&
                                deleteItemState.isDeleting)
                            }
                          >
                            <MinusCircle size={16} />
                          </IconButton>
                          {deleteItemState.itemId === row.filter_id &&
                            deleteItemState.isDeleting && (
                              <CircularProgress
                                color="secondary"
                                size={24}
                                sx={{
                                  position: "absolute",
                                  top: 4,
                                  left: 4
                                }}
                              />
                            )}
                        </span>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

export default ListCustomFilterDialog;
