import { FC, useCallback, useEffect, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useTranslation } from "react-i18next";
import useVmsIntegrationAPI from "api/VmsIntegrationApi";
import snackNotification from "components/SnackNotification";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import PasswordField from "components/PasswordField";
import SelectField from "components/SelectField";
import FormDialog from "components/FormDialog";

export type VmsIntegrationForm = {
  customerId: string;
  index: string;
  model: string;
  url: string;
  protocol: string;
  username: string;
  password: string;
  outputFormat: string;
  timezone: string;
};

const defaultValues: VmsIntegrationForm = {
  customerId: "",
  index: "",
  model: "Digifort",
  url: "",
  protocol: "",
  username: "",
  password: "",
  outputFormat: "",
  timezone: ""
};

type Props = {
  vmsIndex: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: () => void;
};

const VmsIntegrationFormDialog: FC<Props> = ({
  vmsIndex,
  open,
  setOpen,
  onSave
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { errorHandler } = useErrorHandler();
  const VmsIntegrationAPI = useVmsIntegrationAPI();
  const [vmsIntegration, setVmsIntegration] =
    useState<VmsIntegrationForm>(defaultValues);

  const isCreating = vmsIndex === "";
  const { sessionUser } = useAuth();

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<VmsIntegrationForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    const customerId = sessionUser["customer_id"];
    setLoading(true);
    try {
      const vmsIntegrationResponse = isCreating
        ? null
        : await VmsIntegrationAPI.getByIndex(customerId, vmsIndex);

      if (!isCreating && vmsIntegrationResponse) {
        setVmsIntegration({
          customerId: sessionUser.customer_id,
          index: vmsIndex,
          username: vmsIntegrationResponse.username,
          model: vmsIntegrationResponse.model,
          protocol: vmsIntegrationResponse.protocol,
          url: vmsIntegrationResponse.url,
          outputFormat: vmsIntegrationResponse.output_format,
          password: vmsIntegrationResponse.password,
          timezone: vmsIntegrationResponse.timezone
        });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [isCreating, reset, sessionUser, vmsIndex]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(vmsIntegration);
  }, [setValue, vmsIntegration]);

  useEffect(() => {
    if (!open) {
      setVmsIntegration(defaultValues);
      reset(defaultValues);
    }
  }, [open]);

  const onSubmit = async (data: VmsIntegrationForm) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      setLoading(true);

      if (isCreating) {
        await VmsIntegrationAPI.create({
          ...data,
          customerId: sessionUser.customer_id
        });
        snackNotification.success(
          t("IntegrationsPage.vms.integrationAddedSuccessfully")
        );
      } else {
        await VmsIntegrationAPI.update({
          ...data,
          customerId: sessionUser.customer_id
        });
        snackNotification.success(
          t("IntegrationsPage.vms.integrationUpdatedSuccessfully")
        );
      }
      onSave();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const [timezones, setTimezones] = useState<
    { value: string; label: string }[]
  >([]);

  const getTimezones = useCallback(() => {
    const timezoneArray = [];
    for (let i = -12; i < 15; i++) {
      const timezoneValue = i > 0 ? `+${i}` : i.toString();
      timezoneArray.push({
        value: timezoneValue,
        label: `GMT${timezoneValue === "0" ? "" : timezoneValue}`
      });
      setTimezones(timezoneArray);
    }
  }, []);

  useEffect(() => {
    getTimezones();
  }, []);

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("IntegrationsPage.vms.newIntegration")
          : t("IntegrationsPage.vms.editVmsIntegration")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="url"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("IntegrationsPage.vms.url")}
                customProps={{
                  placeholder: t("IntegrationsPage.vms.urlHint"),
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="username"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("IntegrationsPage.vms.username")}
                customProps={{
                  placeholder: t("IntegrationsPage.vms.usernameHint"),
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="password"
            control={control}
            rules={
              isCreating
                ? {
                    required: t("form.requiredField").toString()
                  }
                : {}
            }
            render={({ field, fieldState }) => (
              <PasswordField
                label={t("IntegrationsPage.vms.password")}
                customProps={{
                  placeholder: t("IntegrationsPage.vms.passwordHint"),
                  disabled: isLoading,
                  required: isCreating
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="model"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("IntegrationsPage.vms.model")}
                customProps={{
                  placeholder: t("IntegrationsPage.vms.modelHint"),
                  disabled: true,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="protocol"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("IntegrationsPage.vms.protocol")}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
                disabled={isLoading}
              >
                <MenuItem value="">
                  {t("IntegrationsPage.vms.protocolHint")}
                </MenuItem>
                {["RTSP", "RTSPS"].map(protocol => (
                  <MenuItem key={protocol} value={protocol}>
                    {protocol}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="outputFormat"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("IntegrationsPage.vms.outputFormat")}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
                disabled={isLoading}
              >
                <MenuItem value="">
                  {t("IntegrationsPage.vms.outputFormatHint")}
                </MenuItem>
                {["AVI", "MP4"].map(outputFormat => (
                  <MenuItem key={outputFormat} value={outputFormat}>
                    {outputFormat}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="timezone"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("IntegrationsPage.vms.timezone")}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
                disabled={isLoading}
              >
                <MenuItem value="">
                  {t("IntegrationsPage.vms.timezoneHint")}
                </MenuItem>
                {timezones.map(timezone => (
                  <MenuItem key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default VmsIntegrationFormDialog;
