import { FC, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Radio,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { Edit, MinusCircle, Plus } from "react-feather";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import snackNotification from "components/SnackNotification";
import { useModal } from "contexts/ModalContext";
import BillingFormDialog from "pages/Settings/Billing/BillingFormDialog";
import { useErrorHandler } from "contexts/ErrorHandlerContext";

const Card = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  padding: "13px 24px 13px 16px",
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  "& .card-name": {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
  "&.active": {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.dark
  }
}));

const mock = [
  {
    id: 1,
    name: "Cartão de Débito Visa terminado em 9346",
    expires: "Expira em 06/2022"
  },
  {
    id: 2,
    name: "Cartão de Débito Visa terminado em 0125",
    expires: "Expira em 06/2022"
  }
];

const PaymentCardList: FC = () => {
  const [cardSelected, setCardSelected] = useState(1);
  const { showModal } = useModal();
  const [billingDialogOpen, setBillingDialogOpen] = useState(false);

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const handleCardChange = (id: number) => {
    setCardSelected(id);
  };

  const handleRemoveUser = (name: string) => {
    showModal({
      type: "error",
      modalType: "confirm",
      onConfirm: () => removeUser(name),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const removeUser = async (_name: string) => {
    try {
      snackNotification.success(t("BillingPage.cardSuccessfullyRemoved"));
    } catch (error) {
      errorHandler({ error });
    }
  };

  return (
    <>
      {mock.map(card => (
        <Card
          key={card.id}
          className={card.id === cardSelected ? "active" : ""}
        >
          <div>
            <Radio
              color="secondary"
              value={card.id}
              onChange={event => handleCardChange(Number(event.target.value))}
              checked={cardSelected === card.id}
            />
          </div>
          <div className="card-name">
            <Typography variant="subtitle1">
              <strong>{card.name}</strong>
            </Typography>
            <Typography variant="subtitle1">{card.expires}</Typography>
          </div>
          <div>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <IconButton>
                <Edit size={16} />
              </IconButton>
              <Button
                customProps={{
                  startIcon: <MinusCircle size={16} />,
                  size: "small",
                  variant: "outlined",
                  color: "primary",
                  onClick: () => handleRemoveUser(card.name)
                }}
              >
                {t("action.remove")}
              </Button>
            </Stack>
          </div>
        </Card>
      ))}
      <Button
        customProps={{
          variant: "text",
          startIcon: <Plus size={16} />,
          onClick: () => setBillingDialogOpen(true)
        }}
      >
        {t("BillingPage.addNewPaymentMethod")}
      </Button>
      <BillingFormDialog
        open={billingDialogOpen}
        setOpen={setBillingDialogOpen}
      />
    </>
  );
};

export default PaymentCardList;
