import { FC } from "react";
import { useTranslation } from "react-i18next";
import { styled, Typography } from "@mui/material";
import { useCloneDashboardFilter } from "../CloneDashboardFilterContext";

const Container = styled("div")(() => ({
  right: 0,
  width: "242px",
  bottom: "20px",
  padding: "16px",
  marginRight: "8px",
  position: "absolute",
  backgroundColor: "white"
}));

const SuspectVehiclesStatusGuide: FC = () => {
  const { t } = useTranslation();
  const { totalSuspectVehicles } = useCloneDashboardFilter();

  return (
    <Container>
      <Typography sx={{ mb: 1, color: "#768299" }} variant="body1">
        {t("CloneDashboardPage.totalVehicles")}
      </Typography>
      <Typography variant="h4">{totalSuspectVehicles}</Typography>
    </Container>
  );
};

export default SuspectVehiclesStatusGuide;
