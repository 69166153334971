import { Divider, Popover, styled, Typography } from "@mui/material";
import { AlertTriangle } from "react-feather";
import { FC, MouseEvent, useState } from "react";
import { Position } from "google-map-react";
import { useTranslation } from "react-i18next";
import EquipmentMapMarker from "pages/System/EquipmentMapMarker";

const Wrapper = styled("div")(() => ({
  ".alertIcon": {
    path: {
      strokeWidth: 0
    },
    line: {
      stroke: "#ffffff"
    }
  }
}));

const InfoPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    padding: `${theme.spacing(2)} !important`
  }
}));

const Alert = styled(AlertTriangle)(() => ({
  position: "absolute",
  transform: "translate(-50%, -100%)",
  cursor: "pointer",
  "& circle": {
    fill: "white"
  }
}));

export type RouteMarkerProps = {
  lat: number;
  lng: number;
  routeName: string;
  initialCamera: string;
  finalCamera: string;
  averageSpeed: string;
  legalSpeed: string;
  speedAveragePercentage: number;
};

export const RouteMarker: FC<Position & RouteMarkerProps> = ({
  routeName,
  initialCamera,
  finalCamera,
  speedAveragePercentage,
  lat,
  lng,
  averageSpeed,
  legalSpeed
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | SVGElement | null>(
    null
  );
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLElement | SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Wrapper>
      {speedAveragePercentage > 110 && (
        <Alert
          className="alertIcon"
          fill="#ED6C02"
          size={36}
          onClick={handleClick}
        />
      )}
      {speedAveragePercentage <= 110 && (
        <EquipmentMapMarker
          color="#323E54"
          type="static"
          onClick={handleClick}
          lat={lat}
          lng={lng}
        />
      )}
      <InfoPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Typography variant="subtitle1">
          <strong>{t("FlowDashboardPage.routeName")}</strong>
        </Typography>
        <Typography variant="subtitle1">{routeName}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1">
          <strong>{t("FlowDashboardPage.initialCamera")}</strong>
        </Typography>
        <Typography variant="subtitle1">{initialCamera}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1">
          <strong>{t("FlowDashboardPage.finalCamera")}</strong>
        </Typography>
        <Typography variant="subtitle1">{finalCamera}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1">
          <strong>{t("FlowDashboardPage.averageSpeed")}</strong>
        </Typography>
        <Typography variant="subtitle1">{averageSpeed}km/h</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1">
          <strong>{t("FlowDashboardPage.legalSpeed")}</strong>
        </Typography>
        <Typography variant="subtitle1">{legalSpeed}km/h</Typography>
      </InfoPopover>
    </Wrapper>
  );
};
