import { FC } from "react";
import ContextAggregator from "contexts/ContextAggregator";

import MonitoredVehicleNotification from "./MonitoredVehicleNotification";
import Router from "./Router";

const App: FC = () => (
  <ContextAggregator>
    <Router />
    <MonitoredVehicleNotification />
  </ContextAggregator>
);

export default App;
