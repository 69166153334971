import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationCard from "./NotificationCard";
import NotificationConfig from "./NotificationConfig";
import { ChevronDown, ChevronUp, Settings, X } from "react-feather";
import { useNotificationPanel } from "contexts/NotificationPanelContext";
import NotificationType from "enums/NotificationType";
import { parseISO } from "date-fns";
import Button from "components/Button";
import { useAuth } from "contexts/AuthContext";
import { UpdateNotification } from "api/NotificationAPI";

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const NotificationPanel: FC<Props> = ({ open, setOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    notifications,
    latestNotifications,
    oldestNotifications,
    isLoadingNotifications,
    updateNotifications,
    loadMoreNotifications,
    canLoadMore,
    isLoadingMore
  } = useNotificationPanel();
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expandedRecents, setExpandedRecents] = useState(true);
  const [expandedAllNotifications, setExpandedAllNotifications] =
    useState(true);
  const { sessionUser } = useAuth();

  const handleExpandedRecents = () => {
    setExpandedRecents(!expandedRecents);
  };

  const handleExpandedAllNotifications = () => {
    setExpandedAllNotifications(!expandedAllNotifications);
  };

  const onDelete = (id: string) => {
    updateNotifications([{ id, read: true, deleted: true }]);
  };

  useEffect(() => {
    if (open && notifications) {
      const toUpdate = notifications.filter(n => !n.read);
      if (toUpdate.length > 0) {
        updateNotifications(
          toUpdate.map(
            n =>
              ({
                id: n.id,
                deleted: n.deleted,
                read: true
              } as UpdateNotification)
          )
        );
      }
    }
  }, [open]);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        variant="temporary"
        onClose={() => setOpen(false)}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "0px !important",
            width: isMobile ? "100%" : "376px"
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "-8px",
            padding: "24px",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            color="primary"
            onClick={() => {
              setIsConfigOpen(true);
              setOpen(false);
            }}
          >
            <Settings size={24} />
          </IconButton>
          <Typography variant="h4" align="left">
            {t("NotificationPanel.notification")}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            <X size={24} />
          </IconButton>
        </div>
        {isLoadingNotifications && (
          <Typography align="center">{t("waitState.loading")}</Typography>
        )}
        {!isLoadingNotifications && (
          <div>
            <Divider />
            <List
              component="nav"
              aria-labelledby="list"
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
            >
              <ListItemButton onClick={handleExpandedRecents}>
                {expandedRecents ? <ChevronUp /> : <ChevronDown />}
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ marginLeft: "12px", fontWeight: 700 }}
                >
                  {t("NotificationPanel.recent")}
                </Typography>
              </ListItemButton>
              <Collapse in={expandedRecents} timeout="auto" unmountOnExit>
                {latestNotifications.length <= 0 && (
                  <Box
                    sx={{
                      padding: 2,
                      opacity: 0.6,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Typography variant="caption">
                      {t("NotificationPanel.noRecentNotifications")}
                    </Typography>
                  </Box>
                )}
                {latestNotifications.length > 0 && (
                  <List component="div" disablePadding>
                    {latestNotifications.map(notification => (
                      <ListItem key={notification.id} sx={{ padding: 0 }}>
                        <NotificationCard
                          id={notification.id}
                          type={notification.type as NotificationType}
                          read={notification.read ?? false}
                          date={parseISO(notification.date)}
                          title={notification.message}
                          onDelete={onDelete}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Collapse>
              <Divider />
              <ListItemButton onClick={handleExpandedAllNotifications}>
                {expandedAllNotifications ? <ChevronUp /> : <ChevronDown />}
                <Typography
                  align="left"
                  variant="subtitle1"
                  sx={{ marginLeft: "12px", fontWeight: 700 }}
                >
                  {t("NotificationPanel.allNotifications")}
                </Typography>
              </ListItemButton>
              <Collapse
                in={expandedAllNotifications}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {oldestNotifications.map(notification => (
                    <ListItem key={notification.id} sx={{ padding: 0 }}>
                      <NotificationCard
                        id={notification.id}
                        type={notification.type as NotificationType}
                        read={notification.read ?? false}
                        date={parseISO(notification.date)}
                        title={notification.message}
                        onDelete={onDelete}
                      />
                    </ListItem>
                  ))}
                </List>
                {canLoadMore && (
                  <Box
                    sx={{
                      padding: 2
                    }}
                  >
                    <Button
                      customProps={{
                        fullWidth: true,
                        variant: "outlined",
                        color: "primary",
                        size: "small",
                        disabled: isLoadingMore,
                        onClick() {
                          if (sessionUser) {
                            loadMoreNotifications(
                              sessionUser.customer_id,
                              sessionUser.username
                            );
                          }
                        }
                      }}
                    >
                      {t("NotificationPanel.loadMore")}
                    </Button>
                  </Box>
                )}
              </Collapse>
            </List>
          </div>
        )}
      </Drawer>
      <NotificationConfig
        open={isConfigOpen}
        setOpen={setIsConfigOpen}
        setNotification={setOpen}
      />
    </>
  );
};

export default NotificationPanel;
