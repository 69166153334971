import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import InputField from "components/InputField";
import React from "react";
import { ChangeEventHandler, FC, ReactNode } from "react";
import { AlertCircle, ArrowLeft, Search } from "react-feather";
import { primaryDefaultColors } from "styles/theme";

const HeaderContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "end",
  gap: theme.spacing(2),
  "& > .title-search-bar": {
    flexGrow: 1,
    "& > .title": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  "& > .action-bar": {
    display: "flex",
    gap: theme.spacing(2)
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "& > div": {
      width: "100%"
    },
    "& > .MuiButton-root": {
      width: "100%"
    }
  }
}));

const ChildrenContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

type Props = {
  actions?: ReactNode;
  isLoading?: boolean;
  onBackClick?: () => void;
  primaryActions?: ReactNode;
  search?: {
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    placeholder?: string;
  };
  testId?: string;
  title?: ReactNode;
  tooltip?: ReactNode;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const PageSection: FC<Props> = ({
  actions,
  children,
  isLoading = false,
  onBackClick,
  primaryActions,
  search,
  testId = "",
  title,
  tooltip,
  variant = "h3"
}) => (
  <Box data-testid={testId}>
    <HeaderContainer>
      <div className="title-search-bar">
        <div className="title">
          {onBackClick && (
            <IconButton onClick={onBackClick}>
              <ArrowLeft />
            </IconButton>
          )}
          {isLoading && <CircularProgress size={22} />}
          {title && React.isValidElement(title) ? (
            title
          ) : (
            <Typography variant={variant}>{title}</Typography>
          )}
          {tooltip && (
            <Tooltip title={tooltip}>
              <AlertCircle size={16} color={primaryDefaultColors.light} />
            </Tooltip>
          )}
        </div>
        <div>
          {search && (
            <div>
              <InputField
                gutter={false}
                customProps={{
                  ...search,
                  startAdornment: <Search />
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="action-bar">
        {actions}
        {primaryActions}
      </div>
    </HeaderContainer>
    {children && <ChildrenContainer>{children}</ChildrenContainer>}
  </Box>
);

export default PageSection;
