import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import { SharedDatas } from "./CameraAPI";
import { NestedData, Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";

export type Location = {
  ["location_name"]: string;
  ["customer_id"]: string;
  ["location_data"]: {
    code: string;
    type: string;
    ["zip_code"]: string;
    number: string;
    ["city"]: string;
    street: string;
    ["place_type"]: string;
    district: string;
    comment: string;
    complement: string;
    ["state"]: string;
    longitude: string;
    latitude: string;
  };
  cameras: string[];
  ["shared_data"]: SharedDatas;
};

export type CameraItem = {
  ["camera_data"]: {
    ["camera_name"]: string;
    active?: boolean;
    side?: string;
    ["serial_number"]?: string;
    ["request_image_recognized"]?: boolean;
    ["request_image_unrecognized"]?: boolean;
  };
};

export type LocationPaginated = {
  locations: Location[];
  page: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["page_size"]?: number;
};

type CreateParams = {
  customerId: string;
  locationName: string;
  comment?: string;
  type: string;
  zipCode: string;
  latitude: string;
  longitude: string;
};

type UpdateParams = {
  customerId: string;
  locationName: string;
  oldLocationName: string | undefined;
  comment?: string;
  type: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  ["shared_data"]?: SharedDatas;
};

type ListGroupLocationsParams = {
  customerId: string;
  userGroup: string;
  exist: boolean;
  page: number;
  pageSize: number;
};

type UseLocationAPIType = {
  listPaginated: (
    customerId: string,
    page: number,
    pageSize: number
  ) => Promise<NestedData<LocationPaginated>>;
  listAll: (customerId: string) => Promise<NestedData<Location[]>>;
  search: (
    customerId: string,
    name: string,
    page: number,
    pageSize?: number,
    ignoreUserGroup?: boolean
  ) => Promise<NestedData<LocationPaginated | Location[]>>;
  getByName: (customerId: string, name: string) => Promise<Location>;
  deleteByName: (customerId: string, name: string) => Promise<Success>;
  create: (
    data: CreateParams,
    idCity: string,
    idState: string,
    street: string,
    number: string
  ) => Promise<Success>;
  update: (
    data: UpdateParams,
    idCity: string,
    idState: string,
    street: string,
    number: string,
    cameras: CameraItem[]
  ) => Promise<Success>;
  listGroupLocations: (
    param: ListGroupLocationsParams
  ) => Promise<NestedData<LocationPaginated | Location[]>>;
};

const useLocationAPI = (): UseLocationAPIType => {
  const API = useAxios();

  const listPaginated = async (
    customerId: string,
    page: number,
    pageSize: number
  ): Promise<NestedData<LocationPaginated>> => {
    const response: AxiosResponse<NestedData<LocationPaginated>> =
      await API.instance.post(APIEndpoints.LOCATION_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const listAll = async (
    customerId: string
  ): Promise<NestedData<Location[]>> => {
    const response: AxiosResponse<NestedData<Location[]>> =
      await API.instance.post(APIEndpoints.LOCATION_LIST, {
        ["customer_id"]: customerId,
        page: -1
      });
    return response.data;
  };

  const listGroupLocations = async ({
    customerId,
    userGroup,
    exist,
    page,
    pageSize
  }: ListGroupLocationsParams): Promise<NestedData<LocationPaginated>> => {
    const response: AxiosResponse<NestedData<LocationPaginated>> =
      await API.instance.post(APIEndpoints.LOCATION_LIST_GROUP, {
        ["customer_id"]: customerId,
        ["user_group"]: userGroup,
        exist,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const search = async (
    customerId: string,
    name: string,
    page = -1,
    pageSize?: number,
    ignoreUserGroups?: boolean
  ): Promise<NestedData<LocationPaginated | Location[]>> => {
    const response: AxiosResponse<NestedData<LocationPaginated | Location[]>> =
      await API.instance.get(APIEndpoints.LOCATION_PARTIAL, {
        params: {
          ["customer_id"]: customerId,
          ["location_name"]: name,
          page,
          ["page_size"]: pageSize,
          ["ignore_user_group"]: ignoreUserGroups
        }
      });
    return response.data;
  };

  const getByName = async (
    customerId: string,
    name: string
  ): Promise<Location> => {
    const response: AxiosResponse<Location> = await API.instance.get(
      APIEndpoints.LOCATION,
      {
        params: {
          ["customer_id"]: customerId,
          ["location_name"]: name
        }
      }
    );
    return response.data;
  };

  const deleteByName = async (
    customerId: string,
    name: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.LOCATION_DELETE,
      {
        ["customer_id"]: customerId,
        ["location_name"]: name
      }
    );
    return response.data;
  };

  const create = async (
    data: CreateParams,
    city: string,
    state: string,
    street: string,
    number: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.LOCATION_CREATE,
      {
        ["location_data"]: {
          ["customer_id"]: data.customerId,
          ["location_name"]: data.locationName,
          type: data.type,
          comment: data.comment || "",
          street,
          number,
          ["city"]: city,
          ["state"]: state,
          ["zip_code"]: data.zipCode,
          latitude: data.latitude,
          longitude: data.longitude
        }
      }
    );
    return response.data;
  };

  const update = async (
    data: UpdateParams,
    idCity: string,
    idState: string,
    street: string,
    number: string,
    cameras: CameraItem[]
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.LOCATION_UPDATE,
      {
        ["location_data"]: {
          ["customer_id"]: data.customerId,
          ["location_name"]: data.locationName,
          ["old_location_name"]: data.oldLocationName,
          type: data.type,
          comment: data.comment || "",
          street,
          number,
          ["city"]: idCity,
          ["state"]: idState,
          ["zip_code"]: data.zipCode,
          latitude: data.latitude || "",
          longitude: data.longitude || ""
        },
        cameras,
        ["shared_data"]: data.shared_data
      }
    );
    return response.data;
  };

  return {
    listPaginated,
    listAll,
    search,
    getByName,
    create,
    update,
    deleteByName,
    listGroupLocations
  };
};

export default useLocationAPI;
