import { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Typography
} from "@mui/material";
import { Permission } from "api/ProfileAPI";
import { PageLabels } from "enums/Pages";
import { useTranslation } from "react-i18next";

type Props = {
  permissions: Permission[];
  onPermissionChange: (permissions: Permission[]) => void;
  defaultPermissions: Permission[];
};

const PermissionsTable: FC<Props> = ({
  permissions,
  onPermissionChange,
  defaultPermissions
}) => {
  const { t } = useTranslation();
  const emptyPermissions = defaultPermissions.map(permission => ({
    key: permission.key,
    subMenu: permission.subMenu.map(subMenu => ({
      key: subMenu.key,
      menu: []
    }))
  }));

  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    permissions.length > 0 ? permissions : emptyPermissions
  );

  useEffect(() => {
    setSelectedPermissions(
      permissions.length > 0 ? permissions : emptyPermissions
    );
  }, [permissions]);

  const handlePermissionChange = (
    option: string,
    subMenuKey: string,
    item: string
  ) => {
    const updatedMenu: Permission = permissions.find(p => p.key === option) || {
      key: option,
      subMenu: [{ key: subMenuKey, menu: [item] }]
    };

    const subMenu = updatedMenu.subMenu.find(sm => sm.key === subMenuKey) || {
      key: subMenuKey,
      menu: []
    };

    const menu = subMenu.menu.includes(item)
      ? subMenu.menu.filter(i => i !== item)
      : [...subMenu.menu, item];

    const updatedPermissions = permissions.find(p => p.key === option)
      ? permissions.map(permission => {
          if (permission.key !== option) {
            return permission;
          }
          if (permission.subMenu.find(sm => sm.key === subMenuKey)) {
            return {
              ...permission,
              subMenu: permission.subMenu.map(sm =>
                sm.key === subMenuKey ? { ...sm, menu } : sm
              )
            };
          }
          return {
            ...permission,
            subMenu: [...permission.subMenu, { key: subMenuKey, menu }]
          };
        })
      : [...permissions, updatedMenu];

    setSelectedPermissions(updatedPermissions);
    onPermissionChange(updatedPermissions);
  };

  const defaultMenuList = [];
  for (const defaultPermission of defaultPermissions) {
    for (const subMenu of defaultPermission.subMenu) {
      defaultMenuList.push({
        permission: defaultPermission.key,
        subMenu: subMenu.key,
        menu: subMenu.menu
      });
    }
  }

  return (
    <>
      <TableContainer sx={{ fontSize: "0.875rem", padding: "8px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                {t("ProfilesPage.permissions")}
              </TableCell>
              <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                {t("ProfilesPage.submenu")}
              </TableCell>
              <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                {t("ProfilesPage.items")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defaultMenuList.map(defaultMenu => (
              <TableRow key={defaultMenu.permission + defaultMenu.subMenu}>
                <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                  {t(
                    PageLabels[
                      `${defaultMenu.permission.toLocaleUpperCase()}_MENU`
                    ]
                  )}
                </TableCell>
                <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                  {defaultMenu.subMenu !== "main" &&
                    t(
                      PageLabels[
                        `${defaultMenu.subMenu.toLocaleUpperCase()}_MENU`
                      ]
                    )}
                </TableCell>
                <TableCell sx={{ fontSize: "0.875rem", padding: "8px" }}>
                  <div key={defaultMenu.subMenu}>
                    {defaultMenu.menu.map(item => {
                      const isChecked = selectedPermissions.some(
                        selectedPermission =>
                          selectedPermission.key === defaultMenu.permission &&
                          selectedPermission.subMenu.some(
                            selectedSubMenu =>
                              selectedSubMenu.key === defaultMenu.subMenu &&
                              selectedSubMenu.menu.includes(item)
                          )
                      );

                      return (
                        <div
                          key={item}
                          style={{ flex: "1" }}
                          onClick={event => {
                            event.preventDefault();
                            handlePermissionChange(
                              defaultMenu.permission,
                              defaultMenu.subMenu,
                              item
                            );
                          }}
                        >
                          <Checkbox
                            checked={isChecked}
                            onChange={() =>
                              handlePermissionChange(
                                defaultMenu.permission,
                                defaultMenu.subMenu,
                                item
                              )
                            }
                            sx={{ padding: "0px" }}
                          />
                          {t(PageLabels[item])}
                        </div>
                      );
                    })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Typography
          variant="body2"
          color="warning.main"
          sx={{ fontSize: "0.875rem", padding: "8px" }}
        >
          {t("ProfilesPage.permissionsUpdateWarning")}
        </Typography>
      </Box>
    </>
  );
};

export default PermissionsTable;
