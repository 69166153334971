import Map from "components/Map";
import { Position } from "google-map-react";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { Box, Skeleton, styled } from "@mui/material";
import HeatmapGradientScale from "components/HeatmapGradientScale";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350,

  "& .gm-style>div:nth-of-type(2)>div>div:nth-of-type(5)": {
    filter: "grayscale(100%)"
  }
}));

type GoogleHeatmapProps = {
  isFetching: boolean;
  geolocationDatas: Position[];
};

const GoogleHeatmap: FC<GoogleHeatmapProps> = ({
  isFetching,
  geolocationDatas
}) => {
  const { t } = useTranslation();
  const [geoCenter, setGeoCenter] = useState<Position>();

  useEffect(() => {
    setGeoCenter({ lat: -15.780132, lng: -47.929292 });
  }, [geolocationDatas]);

  return (
    <Container>
      <div
        style={{
          flexGrow: 1,
          width: "100%"
        }}
      >
        {isFetching && <Skeleton variant="rectangular" height={348} />}
        {!isFetching && (
          <Map
            zoom={3}
            center={geoCenter}
            heatmapPositions={geolocationDatas}
            customControls={
              <HeatmapGradientScale
                text={t(
                  "RestrictionDashboardPage.googleHeatmapTooltip"
                ).toString()}
              />
            }
          />
        )}
      </div>
    </Container>
  );
};

export default GoogleHeatmap;
