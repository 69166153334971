import Pages from "enums/Pages";
import CloneBI from "./CloneBI";
import { Sliders } from "react-feather";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import InnerPageLayout from "layouts/InnerPageLayout";
import CloneDashboardFilter from "./CloneDashboardFilter";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { CloneDashboardFilterProvider } from "./CloneDashboardFilterContext";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const CloneDashboardPage: FC = () => {
  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setPageTitle(t("windowTitle.cloneDashboard"));
    setLocation([
      {
        label: t("DefaultPageLayout.cloneDashboard"),
        page: Pages.CLONE_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <CloneDashboardFilterProvider>
          <PageSection
            title={t("menu.cloneDashboard")}
            isLoading={isLoading}
            tooltip={t("CloneDashboardPage.warning")}
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            }
          >
            <CloneDashboardFilter
              open={isFilterOpen}
              setOpen={setIsFilterOpen}
            />
            <CloneBI isLoading={isLoading} setLoading={setLoading} />
          </PageSection>
        </CloneDashboardFilterProvider>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default CloneDashboardPage;
