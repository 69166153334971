import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import { FC } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { Info, MinusCircle, XCircle } from "react-feather";
import NotificationType from "enums/NotificationType";
import { format, formatDistance } from "date-fns";
import { localeMap, useLocale } from "contexts/LocaleContext";

type NotificationCardProps = {
  type: NotificationType;
  date: Date;
  id: string;
  title: string;
  capture?: string;
  description?: string;
  read: boolean;
  onDelete: (index: string) => void;
};

const CardWithBorder = styled(Card)(({ theme }) => ({
  width: "100%",
  border: "none",
  boxShadow: "none",
  "&.read": {
    borderLeft: `8px solid ${theme.palette.warning.main}6b`
  }
}));

const NotificationTypeAndTime = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "12px",
  color: theme.palette.primary.light
}));

const NotificationDescription = styled(Typography)(() => ({
  opacity: "56%",
  fontWeight: 400,
  fontSize: "12px"
}));

const NotificationCard: FC<NotificationCardProps> = ({
  id,
  type,
  read,
  date,
  title,
  capture,
  description,
  onDelete
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useLocale();
  return (
    <>
      <CardWithBorder className={read ? "" : "read"}>
        <Divider />
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {type === NotificationType.alert ||
              type === NotificationType.information ? (
                <Info
                  size={24}
                  style={{ marginRight: "8px" }}
                  color={
                    type === NotificationType.alert
                      ? theme.palette.error.main
                      : theme.palette.secondary.main
                  }
                />
              ) : (
                <XCircle
                  size={24}
                  style={{ marginRight: "8px" }}
                  color={theme.palette.error.main}
                />
              )}
              <NotificationTypeAndTime>
                {t(`NotificationPanel.type.${type}`)}
              </NotificationTypeAndTime>
              <NotificationTypeAndTime
                sx={{
                  margin: "0px 8px 0px 8px"
                }}
              >
                •
              </NotificationTypeAndTime>
              <NotificationTypeAndTime
                title={format(date, t("form.dateFormat"))}
              >
                {formatDistance(date, new Date(), {
                  locale: localeMap[language]
                })}
              </NotificationTypeAndTime>
            </div>
            <IconButton color="primary" onClick={() => onDelete(id)}>
              <MinusCircle size={24} />
            </IconButton>
          </div>
          <div style={{ paddingLeft: "32px" }}>
            <Typography variant="subtitle1" sx={{ marginBottom: "12px" }}>
              {title}
            </Typography>
            {description && (
              <NotificationDescription>{description}</NotificationDescription>
            )}
          </div>
        </CardContent>
        {capture && (
          <CardActions sx={{ paddingLeft: "42px" }}>
            <Button
              customProps={{
                variant: "text",
                onClick() {}
              }}
            >
              {t("NotificationPanel.captureView")}
            </Button>
          </CardActions>
        )}
      </CardWithBorder>
    </>
  );
};

export default NotificationCard;
