import {
  Box,
  Grid,
  styled,
  Typography,
  MenuItem,
  Divider
} from "@mui/material";
import { FC } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { Period } from "api/ConvoyDashboardAPI";
import SelectField from "components/SelectField";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import { setHours, setMinutes, subMonths } from "date-fns";
import { useConvoyDashboardFilter } from "./ConvoyDashboardFilterContext";
import Drawer from "components/Drawer";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Placeholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormConvoyDashboardFilter = {
  startDate: string | Date;
  endDate: string | Date;
  period: Period;
  frequency: number | undefined;
  plate: string;
};

const defaultDate = new Date();

export const defaultValues: FormConvoyDashboardFilter = {
  startDate: setMinutes(setHours(defaultDate.setDate(1), 0), 0),
  endDate: new Date(),
  period: "daily",
  frequency: 2,
  plate: ""
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const ConvoyDashboardFilter: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { setFilterData } = useConvoyDashboardFilter();
  const { control, formState, handleSubmit, reset, setValue } =
    useForm<FormConvoyDashboardFilter>({
      mode: "onChange",
      defaultValues
    });

  const clear = () => {
    reset(defaultValues);
    const d = setMinutes(setHours(new Date().setDate(1), 0), 0);
    setValue(
      "startDate",
      subMonths(setMinutes(setHours(d.setDate(1), 0), 0), 1)
    );
    setValue("endDate", new Date());
  };

  const onSubmit = async (data: FormConvoyDashboardFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);

    setFilterData(newData);
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("MonitoredVehiclesDashboardPage.filter")}
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("RestrictionsPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("RestrictionsPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.plateSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    gutter={false}
                    label={t("ConvoyDashboardPage.plate")}
                    customProps={{
                      placeholder: t("MonitoredVehiclesDashboardPage.plateHint")
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
              <Placeholder variant="caption" align="left">
                {t("DefaultPageLayout.tooltipPlate")}
              </Placeholder>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="period"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    label={t("ConvoyDashboardPage.time")}
                    onChange={event => {
                      field.onChange(event.target.value);
                    }}
                    sx={{ m: 0 }}
                  >
                    <MenuItem value="daily">
                      {t("ConvoyDashboardPage.daily")}
                    </MenuItem>
                    <MenuItem value="monthly">
                      {t("ConvoyDashboardPage.monthly")}
                    </MenuItem>
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="frequency"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    customProps={{
                      sx: { mb: 2 },
                      type: "number",
                      inputMode: "numeric",
                      inputProps: { min: 2 }
                    }}
                    label={t("ConvoyDashboardPage.repetition")}
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: !formState.isValid,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default ConvoyDashboardFilter;
