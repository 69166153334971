import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { ItemContent } from "./UserGuideLayout";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

type UserGuideContentProps = {
  content: ItemContent;
  isOpenMenu: boolean;
};

const UserGuideContent: FC<UserGuideContentProps> = ({
  content,
  isOpenMenu
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        paddingLeft: (isMobile || isTablet) && !isOpenMenu ? "0px" : "230px"
      }}
    >
      <Typography
        variant="h4"
        sx={{ textAlign: "center", marginBottom: "24px" }}
      >
        {content.label}
      </Typography>
      <ReactMarkdown>{content.content}</ReactMarkdown>
    </div>
  );
};

export default UserGuideContent;
