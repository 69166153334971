import { FC, ReactNode, useEffect, useState } from "react";
import { Watermark as RW } from "@hirohe/react-watermark";
import { useAuth } from "contexts/AuthContext";
import Cookies from "utils/Cookies";
import { Watermark as ModelWatermark } from "api/WatermarkAPI";

type WatermarkProps = {
  enabled?: boolean;
  children: ReactNode;
};

const defaultValues = {
  cnpj: false,
  email: true,
  enabled: true
};

const Watermark: FC<WatermarkProps> = ({ enabled = false, children }) => {
  const [watermark, setWatermark] = useState<ModelWatermark>(defaultValues);
  const { sessionUser } = useAuth();

  useEffect(() => {
    const cookieWatermark = Cookies.get(
      Cookies.WATERMARK
    ) as ModelWatermark | null;
    setWatermark(cookieWatermark || defaultValues);
  }, []);

  const getText = (): string => {
    if (watermark.email) {
      return sessionUser?.username || "";
    }
    return `${sessionUser?.customer_id} - ${sessionUser?.username}` || "";
  };

  return (
    <RW
      show={watermark.enabled && enabled}
      text={getText()}
      textSize={20}
      opacity={0.28}
    >
      {children}
    </RW>
  );
};

export default Watermark;
