import { FC } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Box, Skeleton, styled, useMediaQuery, useTheme } from "@mui/material";
import PageSection from "components/PageSection/PageSection";
import { ApexOptions } from "apexcharts";

const Container = styled(Box)(({ theme }) => ({
  height: 464,
  display: "flex",
  padding: "16px 0px",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default
}));

export type HeatmapData = {
  name: string | undefined;
  data: {
    x: string;
    y: number | null;
  }[];
}[];

type HeatmapsProps = {
  isFetching: boolean;
  weekData: HeatmapData;
  hourData: HeatmapData;
};

const Heatmaps: FC<HeatmapsProps> = ({ isFetching, weekData, hourData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const options: ApexOptions = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 40,
              name: t("RestrictionDashboardPage.heatmapTooltipLow"),
              color: "#00A100"
            },
            {
              from: 41,
              to: 51,
              name: t("RestrictionDashboardPage.heatmapTooltipMedium"),
              color: "#FFB200"
            },
            {
              from: 52,
              to: Infinity,
              name: t("RestrictionDashboardPage.heatmapTooltipHigh"),
              color: "#FF0000"
            }
          ]
        }
      }
    },
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true
      }
    },
    yaxis: {
      labels: {
        show: !isMobile
      }
    }
  };

  return (
    <>
      <PageSection
        title={t("RestrictionDashboardPage.frequencyByWeek")}
        variant="h5"
      >
        <Container>
          <Box sx={{ width: "100%" }}>
            {isFetching && <Skeleton variant="rectangular" height={432} />}
            {!isFetching && (
              <Chart
                height={432}
                type="heatmap"
                options={options}
                series={weekData}
              />
            )}
          </Box>
        </Container>
      </PageSection>
      <PageSection
        title={t("RestrictionDashboardPage.frequencyByHour")}
        variant="h5"
      >
        <Container>
          <Box sx={{ width: "100%" }}>
            {isFetching && <Skeleton variant="rectangular" height={432} />}
            {!isFetching && (
              <Chart
                height={432}
                type="heatmap"
                options={options}
                series={hourData}
              />
            )}
          </Box>
        </Container>
      </PageSection>
    </>
  );
};

export default Heatmaps;
