import {
  TextNode,
  $isTextNode,
  ElementNode,
  $getSelection,
  $getNodeByKey,
  RangeSelection,
  $isRangeSelection,
  $createParagraphNode,
  $isRootOrShadowRoot,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  DEPRECATED_$isGridSelection
} from "lexical";
import {
  $isCodeNode,
  CODE_LANGUAGE_MAP,
  getLanguageFriendlyName,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP
} from "@lexical/code";
import {
  ListNode,
  $isListNode,
  REMOVE_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND
} from "@lexical/list";
import {
  mergeRegister,
  $findMatchingParent,
  $getNearestNodeOfType,
  $getNearestBlockElementAncestorOrThrow
} from "@lexical/utils";
import {
  HeadingTagType,
  $isHeadingNode,
  $createQuoteNode,
  $createHeadingNode
} from "@lexical/rich-text";
import {
  $wrapNodes,
  $selectAll,
  $patchStyleText,
  $isParentElementRTL,
  $getSelectionStyleValueForProperty
} from "@lexical/selection";
import { Video } from "react-feather";
import { IS_APPLE } from "utils/isApple";
import { useTranslation } from "react-i18next";
import { $isAtNodeEnd } from "@lexical/selection";
import type { LexicalEditor, NodeKey } from "lexical";
import { useCallback, useEffect, useState } from "react";
import DropDown, {
  DropDownItem
} from "../RichTextEditorDropDown/RichTextEditorDropDown";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import AddCaptureDialog from "pages/ForensicsReport/AddCaptureDialog";
import {
  InsertImagePayload,
  INSERT_IMAGE_COMMAND
} from "components/RichTextEditor/RichTextEditorImage/ImagePlugin";
import { InsertTableDialog } from "../RichTextEditorTable/TablePlugin";
import { InsertImageDialog } from "../RichTextEditorImage/ImagePlugin";
import useRichTextEditorModal from "../../../hooks/useRichTextEditorModal";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const blockTypeToBlockName = {
  bullet: "Bulleted List",
  check: "Check List",
  code: "Code Block",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  number: "Numbered List",
  paragraph: "Normal",
  quote: "Quote"
};

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = [];

  for (const [lang, friendlyName] of Object.entries(
    CODE_LANGUAGE_FRIENDLY_NAME_MAP
  )) {
    options.push([lang, friendlyName]);
  }

  return options;
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"]
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ["10px", "10px"],
  ["11px", "11px"],
  ["12px", "12px"],
  ["13px", "13px"],
  ["14px", "14px"],
  ["15px", "15px"],
  ["16px", "16px"],
  ["17px", "17px"],
  ["18px", "18px"],
  ["19px", "19px"],
  ["20px", "20px"]
];

function dropDownActiveClass(active: boolean) {
  if (active) return "active dropdown-item-active";
  return "";
}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $wrapNodes(selection as RangeSelection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();

        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $wrapNodes(selection as RangeSelection, () =>
            $createHeadingNode(headingSize)
          );
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType === "bullet") {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
  };

  const formatCheckList = () => {
    if (blockType === "check") {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType === "number") {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $wrapNodes(selection as RangeSelection, () => $createQuoteNode());
        }
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={`icon block-type ${blockType}`}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "paragraph")}`}
        onClick={formatParagraph}
      >
        <i className="icon paragraph" />
        <span className="text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "h1")}`}
        onClick={() => formatHeading("h1")}
      >
        <i className="icon h1" />
        <span className="text">{t("RichTextEditor.heading")} 1</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "h2")}`}
        onClick={() => formatHeading("h2")}
      >
        <i className="icon h2" />
        <span className="text">{t("RichTextEditor.heading")} 2</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "h3")}`}
        onClick={() => formatHeading("h3")}
      >
        <i className="icon h3" />
        <span className="text">{t("RichTextEditor.heading")} 3</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "bullet")}`}
        onClick={formatBulletList}
      >
        <i className="icon bullet-list" />
        <span className="text">{t("RichTextEditor.bulletList")}</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "number")}`}
        onClick={formatNumberedList}
      >
        <i className="icon numbered-list" />
        <span className="text">{t("RichTextEditor.numberedList")}</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "check")}`}
        onClick={formatCheckList}
      >
        <i className="icon check-list" />
        <span className="text">{t("RichTextEditor.checkList")}</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${dropDownActiveClass(blockType === "quote")}`}
        onClick={formatQuote}
      >
        <i className="icon quote" />
        <span className="text">{t("RichTextEditor.quote")}</span>
      </DropDownItem>
    </DropDown>
  );
}

function Divider(): JSX.Element {
  return <div className="divider" />;
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option
          });
        }
      });
    },
    [editor, style]
  );

  const buttonAriaLabel =
    style === "font-family"
      ? "Formatting options for font family"
      : "Formatting options for font size";

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`toolbar-item ${style}`}
      buttonLabel={value}
      buttonIconClassName={
        style === "font-family" ? "icon block-type font-family" : ""
      }
      buttonAriaLabel={buttonAriaLabel}
    >
      {(style === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
        ([option, text]) => (
          <DropDownItem
            className={`item ${dropDownActiveClass(value === option)} ${
              style === "font-size" ? "fontsize-item" : ""
            }`}
            onClick={() => handleClick(option)}
            key={option}
          >
            <span className="text">{text}</span>
          </DropDownItem>
        )
      )}
    </DropDown>
  );
}

export default function ToolbarPlugin(): JSX.Element {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(
    null
  );
  const [fontSize, setFontSize] = useState<string>("15px");
  const [fontFamily, setFontFamily] = useState<string>("Arial");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [modal, showModal] = useRichTextEditorModal();
  const [isRTL, setIsRTL] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<string>("");
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isAddCaptureDialogOpen, setIsAddCaptureDialogOpen] = useState(false);

  function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
    const { anchor } = selection;
    const { focus } = selection;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsSubscript(selection.hasFormat("subscript"));
      setIsSuperscript(selection.hasFormat("superscript"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
          if ($isCodeNode(element)) {
            const language =
              element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : ""
            );
            return;
          }
        }
      }
      // Handle buttons
      setFontSize(
        $getSelectionStyleValueForProperty(selection, "font-size", "15px")
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, "font-family", "Arial")
      );
    }
  }, [activeEditor]);

  useEffect(
    () =>
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
    [editor, updateToolbar]
  );

  useEffect(
    () =>
      mergeRegister(
        editor.registerEditableListener(editable => {
          setIsEditable(editable);
        }),
        activeEditor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        })
      ),
    [activeEditor, editor, updateToolbar]
  );

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $selectAll(selection);
        selection.getNodes().forEach(node => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle("");
            $getNearestBlockElementAncestorOrThrow(node).setFormat("");
          }
          if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }, [activeEditor]);

  const sanitizeUrl = (url: string): string => {
    const SAFE_URL_PATTERN =
      /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

    const DATA_URL_PATTERN =
      /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;

    const trimmedUrl = String(url).trim();

    if (
      trimmedUrl.match(SAFE_URL_PATTERN) ||
      trimmedUrl.match(DATA_URL_PATTERN)
    ) {
      return trimmedUrl;
    }

    return "https://";
  };

  const insertLink = useCallback(() => {
    if (isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
    }
  }, [editor, isLink]);

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [activeEditor, selectedElementKey]
  );

  return (
    <>
      <div className="toolbar">
        {blockType in blockTypeToBlockName && activeEditor === editor && (
          <>
            <BlockFormatDropDown
              disabled={!isEditable}
              blockType={blockType}
              editor={editor}
            />
            <Divider />
          </>
        )}
        {blockType === "code" ? (
          <>
            <DropDown
              disabled={!isEditable}
              buttonClassName="toolbar-item code-language"
              buttonLabel={getLanguageFriendlyName(codeLanguage)}
              buttonAriaLabel="Select language"
            >
              {CODE_LANGUAGE_OPTIONS.map(([value, name]) => (
                <DropDownItem
                  className={`item ${dropDownActiveClass(
                    value === codeLanguage
                  )}`}
                  onClick={() => onCodeLanguageSelect(value)}
                  key={value}
                >
                  <span className="text">{name}</span>
                </DropDownItem>
              ))}
            </DropDown>
          </>
        ) : (
          <>
            <FontDropDown
              disabled={!isEditable}
              style="font-family"
              value={fontFamily}
              editor={editor}
            />
            <FontDropDown
              disabled={!isEditable}
              style="font-size"
              value={fontSize}
              editor={editor}
            />
            <Divider />
            <button
              type="button"
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              className={`toolbar-item spaced ${isBold ? "active" : ""}`}
              title={IS_APPLE ? "Bold (⌘B)" : `${t("RichTextEditor.bold")}`}
              aria-label={`Format text as bold. Shortcut: ${
                IS_APPLE ? "⌘B" : "Ctrl+B"
              }`}
            >
              <i className="format bold" />
            </button>
            <button
              type="button"
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              className={`toolbar-item spaced ${isItalic ? "active" : ""}`}
              title={IS_APPLE ? "Italic (⌘I)" : `${t("RichTextEditor.italic")}`}
              aria-label={`Format text as italics. Shortcut: ${
                IS_APPLE ? "⌘I" : "Ctrl+I"
              }`}
            >
              <i className="format italic" />
            </button>
            <button
              type="button"
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              className={`toolbar-item spaced ${isUnderline ? "active" : ""}`}
              title={
                IS_APPLE ? "Underline (⌘U)" : `${t("RichTextEditor.underline")}`
              }
              aria-label={`Format text to underlined. Shortcut: ${
                IS_APPLE ? "⌘U" : "Ctrl+U"
              }`}
            >
              <i className="format underline" />
            </button>
            <button
              type="button"
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
              }}
              className={`toolbar-item spaced ${isCode ? "active" : ""}`}
              title={`${t("RichTextEditor.insert")} ${t(
                "RichTextEditor.codeBlock"
              )}`}
              aria-label="Insert code block"
            >
              <i className="format code" />
            </button>
            <button
              type="button"
              disabled={!isEditable}
              onClick={insertLink}
              className={`toolbar-item spaced ${isLink ? "active" : ""}`}
              aria-label="Insert link"
              title="Link"
            >
              <i className="format link" />
            </button>
            <DropDown
              disabled={!isEditable}
              buttonClassName="toolbar-item spaced"
              buttonLabel=""
              buttonAriaLabel="Formatting options for additional text styles"
              buttonIconClassName="icon dropdown-more"
            >
              <DropDownItem
                onClick={() => {
                  activeEditor.dispatchCommand(
                    FORMAT_TEXT_COMMAND,
                    "strikethrough"
                  );
                }}
                className={`item ${dropDownActiveClass(isStrikethrough)}`}
                title={t("RichTextEditor.strikethrough") as string}
                aria-label="Format text with a strikethrough"
              >
                <i className="icon strikethrough" />
                <span className="text">
                  {t("RichTextEditor.strikethrough")}
                </span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  activeEditor.dispatchCommand(
                    FORMAT_TEXT_COMMAND,
                    "subscript"
                  );
                }}
                className={`item ${dropDownActiveClass(isSubscript)}`}
                title={t("RichTextEditor.subscript") as string}
                aria-label="Format text with a subscript"
              >
                <i className="icon subscript" />
                <span className="text">{t("RichTextEditor.subscript")}</span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  activeEditor.dispatchCommand(
                    FORMAT_TEXT_COMMAND,
                    "superscript"
                  );
                }}
                className={`item ${dropDownActiveClass(isSuperscript)}`}
                title={t("RichTextEditor.superscript") as string}
                aria-label="Format text with a superscript"
              >
                <i className="icon superscript" />
                <span className="text">{t("RichTextEditor.superscript")}</span>
              </DropDownItem>
              <DropDownItem
                onClick={clearFormatting}
                className="item"
                title={t("RichTextEditor.clearFormatter") as string}
                aria-label="Clear all text formatting"
              >
                <i className="icon clear" />
                <span className="text">
                  {t("RichTextEditor.clearFormatter")}
                </span>
              </DropDownItem>
            </DropDown>
            <Divider />
            <DropDown
              disabled={!isEditable}
              buttonClassName="toolbar-item spaced"
              buttonLabel={t("RichTextEditor.insert")}
              buttonAriaLabel="Insert specialized editor node"
              buttonIconClassName="icon plus"
            >
              <DropDownItem
                onClick={() => {
                  showModal(
                    `${t("RichTextEditor.insert")} ${t(
                      "RichTextEditor.image"
                    )}`,
                    onClose => (
                      <InsertImageDialog
                        activeEditor={activeEditor}
                        onClose={onClose}
                      />
                    )
                  );
                }}
                className="item"
              >
                <i className="icon image" />
                <span className="text">{t("RichTextEditor.image")}</span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  showModal(
                    `${t("RichTextEditor.insert")} ${t(
                      "RichTextEditor.table"
                    )}`,
                    onClose => (
                      <InsertTableDialog
                        activeEditor={activeEditor}
                        onClose={onClose}
                      />
                    )
                  );
                }}
                className="item"
              >
                <i className="icon table" />
                <span className="text">{t("RichTextEditor.table")}</span>
              </DropDownItem>
            </DropDown>
          </>
        )}
        <Divider />
        <DropDown
          disabled={!isEditable}
          buttonLabel={t("RichTextEditor.align")}
          buttonIconClassName="icon left-align"
          buttonClassName="toolbar-item spaced alignment"
          buttonAriaLabel="Formatting options for text alignment"
        >
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
            }}
            className="item"
          >
            <i className="icon left-align" />
            <span className="text">{t("RichTextEditor.leftAlign")}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
            }}
            className="item"
          >
            <i className="icon center-align" />
            <span className="text">{t("RichTextEditor.centerAlign")}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
            }}
            className="item"
          >
            <i className="icon right-align" />
            <span className="text">{t("RichTextEditor.rigthAlign")}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
            }}
            className="item"
          >
            <i className="icon justify-align" />
            <span className="text">{t("RichTextEditor.justifyAlign")}</span>
          </DropDownItem>
          <Divider />
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            }}
            className="item"
          >
            <i className={`icon ${isRTL ? "indent" : "outdent"}`} />
            <span className="text">{t("RichTextEditor.outdent")}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
            }}
            className="item"
          >
            <i className={`icon ${isRTL ? "outdent" : "indent"}`} />
            <span className="text">{t("RichTextEditor.indent")}</span>
          </DropDownItem>
        </DropDown>
        <Divider />
        <button
          type="button"
          disabled={!isEditable}
          title={t("RichTextEditor.systemCapture") as string}
          aria-label="Add system capture"
          onClick={() => {
            setIsAddCaptureDialogOpen(true);
          }}
          className="toolbar-item spaced"
        >
          <Video size={18} color="#4d4d4d" />
        </button>
        {modal}
      </div>
      <AddCaptureDialog
        open={isAddCaptureDialogOpen}
        setOpen={setIsAddCaptureDialogOpen}
        onCaptureSelected={(filePayload: InsertImagePayload) => {
          activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, filePayload);
          if (filePayload.altText !== "") {
            activeEditor.update(() => {
              const selection = $getSelection();
              if (selection) {
                selection.insertText(filePayload.altText);
              }
            });
          }
        }}
      />
    </>
  );
}
