const RegexPatterns = {
  CPF: /(\d{2}[\\.]?\d{3}[\\.]?\d{3}[\\/]?\d{4}[-]?\d{2})|(\d{3}[\\.]?\d{3}[\\.]?\d{3}[-]?\d{2})/,
  EMAIL_ADDRESS:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  LAT: /^(?:-)?(?:\b(?:\d|[1-8]\d)\b(?:.\d{1,6})?|90(?:.0{1,6})?)$/,
  LON: /^(?:-)?(?:\b(?:\d|[1-9]\d|1[0-7]\d)\b(?:.\d{1,6})?|180(?:.0{1,6})?)$/,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$ %^&*-]).{8,}$/,
  PHONE_NUMBER: /[0-9+\-()]/,
  HEX_COLOR: /^#([0-9a-f]{3}){1,2}$/i,
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
};

export default RegexPatterns;
