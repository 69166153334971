import { FC } from "react";
import { styled, Tooltip } from "@mui/material";

const Container = styled("div")(() => ({
  right: 0,
  height: "180px",
  width: "40px",
  bottom: "24px",
  padding: "16px",
  marginRight: "8px",
  position: "absolute",
  backgroundImage: "linear-gradient(red, yellow, #06d106)"
}));

export type HeatmapGradientScaleProps = {
  text: string;
};

const HeatmapGradientScale: FC<HeatmapGradientScaleProps> = ({ text }) => (
  <Tooltip arrow placement="left-start" title={text}>
    <Container />
  </Tooltip>
);

export default HeatmapGradientScale;
