import {
  Grid,
  Stack,
  useTheme,
  Typography,
  useMediaQuery
} from "@mui/material";
import Pages from "enums/Pages";
import logo from "assets/logo_h.png";
import Button from "components/Button";
import {
  setStorageTheme,
  useCustomization
} from "contexts/CustomizationContext";
import Avatar from "components/Avatar";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import RegexPatterns from "utils/RegexPatterns";
import ColorPicker from "components/ColorPicker";
import useCustomizationAPI from "api/CustomizationAPI";
import InnerPageLayout from "layouts/InnerPageLayout";
import { Controller, useForm } from "react-hook-form";
import PageSectionCard from "components/PageSection/PageSectionCard";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { primaryDefaultColors, secondaryDefaultColors } from "styles/theme";
import PageSection from "components/PageSection/PageSection";

type CustomizationForm = {
  appTitle: string;
  primaryColor: string;
  secondaryColor: string;
};

const CustomizationPage: FC = () => {
  const { t } = useTranslation();
  const CustomizationAPI = useCustomizationAPI();
  const {
    setPrimaryColor,
    setSecondaryColor,
    setAppTitle,
    primaryColor,
    secondaryColor,
    appTitle,
    imgHeader,
    setImgHeader,
    imgLogo,
    setImgLogo,
    isLoading
  } = useCustomization();
  const { errorHandler } = useErrorHandler();
  const { setPageTitle, setLocation } = usePageLocation();
  const [isSaving, setIsSaving] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const defaultValues: CustomizationForm = {
    appTitle,
    primaryColor,
    secondaryColor
  };

  const { control, handleSubmit, watch, setValue } = useForm<CustomizationForm>(
    {
      defaultValues
    }
  );

  useEffect(() => {
    setValue("appTitle", appTitle);
  }, [appTitle]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "primaryColor" && value.primaryColor) {
        if (RegexPatterns.HEX_COLOR.test(value.primaryColor)) {
          setPrimaryColor(value.primaryColor);
        }
      }
      if (name === "secondaryColor" && value.secondaryColor) {
        if (RegexPatterns.HEX_COLOR.test(value.secondaryColor)) {
          setSecondaryColor(value.secondaryColor);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [openFileSelectorHeader, { filesContent: filesContentHeader }] =
    useFilePicker({
      readAs: "DataURL",
      accept: [".png", ".jpg", ".jpeg"]
    });

  useEffect(() => {
    if (filesContentHeader?.length) {
      setImgHeader(filesContentHeader[0].content);
    }
  }, [filesContentHeader]);

  const [openFileSelectorLogo, { filesContent: filesContentLogo }] =
    useFilePicker({
      readAs: "DataURL",
      accept: [".png", ".jpg", ".jpeg"]
    });

  useEffect(() => {
    if (filesContentLogo?.length) {
      setImgLogo(filesContentLogo[0].content);
    }
  }, [filesContentLogo]);

  useEffect(() => {
    setPageTitle(t("windowTitle.customization"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.system")
      },
      {
        label: t("CustomizationPage.title"),
        page: Pages.CUSTOMIZATION
      }
    ]);
  }, [t, Pages]);

  const { sessionUser } = useAuth();

  const onSubmit = async (data: CustomizationForm) => {
    try {
      setIsSaving(true);
      if (sessionUser) {
        await CustomizationAPI.setCustomization({
          ["customer_id"]: sessionUser.customer_id,
          ["header_image"]: imgHeader || "",
          ["login_image"]: imgLogo || "",
          title: data.appTitle,
          ["primary_color"]: data.primaryColor,
          ["secondary_color"]: data.secondaryColor
        });
        setStorageTheme({
          ["header_image"]: imgHeader || "",
          ["login_image"]: imgLogo || "",
          title: data.appTitle || "",
          ["primary_color"]: data.primaryColor,
          ["secondary_color"]: data.secondaryColor
        });
        setAppTitle(data.appTitle || "");
        snackNotification.success(t("CustomizationPage.customizationSaved"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection title={t("CustomizationPage.title")} isLoading={isLoading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <PageSectionCard>
              <Grid container columnSpacing={2}>
                <Grid item sm={12} md={6}>
                  <Controller
                    name="appTitle"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CustomizationPage.name")}
                        customProps={{
                          defaultValue: appTitle
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </PageSectionCard>
            <PageSectionCard>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    {t("CustomizationPage.logo")}
                  </Typography>
                  <Avatar name={appTitle ?? "Pumatronix"} imgSrc={imgHeader} />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "26px" }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      customProps={{
                        color: "secondary",
                        onClick() {
                          openFileSelectorHeader();
                        },
                        type: "button"
                      }}
                    >
                      {t("CustomizationPage.imageBtn")}
                    </Button>
                    <Button
                      customProps={{
                        disabled: !imgHeader,
                        color: "primary",
                        variant: "outlined",
                        onClick() {
                          setImgHeader("");
                        },
                        type: "button"
                      }}
                    >
                      {t("CustomizationPage.defaultImage")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </PageSectionCard>
            <PageSectionCard>
              <Grid container columnSpacing={2}>
                <Grid item sm={12} md={4}>
                  <Typography variant="h5" gutterBottom>
                    {t("CustomizationPage.loginLogo")}
                  </Typography>
                  {imgLogo && <img src={imgLogo} alt="Lince" height={150} />}
                  {!imgLogo && <img src={logo} alt="Lince" height={50} />}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "26px" }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      customProps={{
                        color: "secondary",
                        onClick() {
                          openFileSelectorLogo();
                        }
                      }}
                    >
                      {t("CustomizationPage.imageBtn")}
                    </Button>
                    <Button
                      customProps={{
                        disabled: !imgLogo,
                        color: "primary",
                        variant: "outlined",
                        onClick() {
                          setImgLogo("");
                        },
                        type: "button"
                      }}
                    >
                      {t("CustomizationPage.defaultImage")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </PageSectionCard>
            <PageSectionCard>
              <Grid container columnSpacing={1}>
                <Grid item sm={12} md={3} lg={2}>
                  <Controller
                    name="primaryColor"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CustomizationPage.primaryColor")}
                        customProps={{
                          startAdornment: (
                            <ColorPicker
                              color={primaryColor}
                              colorChanged={hex => {
                                setValue("primaryColor", hex);
                              }}
                            />
                          ),
                          defaultValue: primaryColor
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                  <Controller
                    name="secondaryColor"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CustomizationPage.secondaryColor")}
                        customProps={{
                          startAdornment: (
                            <ColorPicker
                              color={secondaryColor}
                              colorChanged={hex => {
                                setValue("secondaryColor", hex);
                              }}
                            />
                          ),
                          defaultValue: secondaryColor
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <Button
                    customProps={{
                      disabled:
                        primaryColor === primaryDefaultColors.main &&
                        secondaryColor === secondaryDefaultColors.main,
                      sx: { marginTop: mobile ? "0px" : "25px" },
                      color: "primary",
                      variant: "outlined",
                      onClick() {
                        setValue("primaryColor", primaryDefaultColors.main);
                        setValue("secondaryColor", secondaryDefaultColors.main);
                      },
                      type: "button"
                    }}
                  >
                    {t("CustomizationPage.defaultImage")}
                  </Button>
                </Grid>
              </Grid>
            </PageSectionCard>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                customProps={{
                  disabled: isSaving,
                  color: "secondary",
                  type: "submit"
                }}
              >
                {t("CustomizationPage.save")}
              </Button>
            </div>
          </form>
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};
export default CustomizationPage;
