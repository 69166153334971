import {
  Box,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableSortLabel
} from "@mui/material";
import Pages from "enums/Pages";
import Order from "enums/OrderType";
import Button from "components/Button";
import { visuallyHidden } from "@mui/utils";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import { MinusCircle, Plus } from "react-feather";
import InnerPageLayout from "layouts/InnerPageLayout";
import { useHistory, useParams } from "react-router-dom";
import { Table, TableContainer } from "components/Table";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import PageSection from "components/PageSection/PageSection";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import useUserGroupAPI from "api/UserGroupAPI";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import AddUserGroupDialog from "pages/Settings/UserGroups/AddUserGroupDialog";
import AddEquipmentDialog from "pages/Settings/UserGroups/AddEquipmentDialog";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

type UserGroupForm = {
  name: string;
  users: string[];
  customerId: string;
  description: string;
  locations: string[];
};

const EditUserGroupsPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { sessionUser } = useAuth();
  const UserGroupAPI = useUserGroupAPI();
  const [userSearch, setUserSearch] = useState("");
  const [equipmentSearch, setEquipmentSearch] = useState("");
  const { errorHandler } = useErrorHandler();
  const { name } = useParams<{ name: string }>();
  const [orderUserGroup, setOrderUserGroup] = useState<Order>("asc");
  const [orderEquipment, setOrderEquipment] = useState<Order>("asc");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [userGroup, setUserGroup] = useState<UserGroupForm | null>(null);
  const [orderPropertyUserGroup, setOrderPropertyUserGroup] =
    useState<string>("user");
  const [orderPropertyEquipment, setOrderPropertyEquipment] =
    useState<string>("location_name");

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setLoading(true);
    try {
      const userGroup = await UserGroupAPI.getByName({
        customerId: sessionUser["customer_id"],
        groupName: name
      });
      if (userGroup) {
        setUserGroup({
          customerId: sessionUser["customer_id"],
          name: userGroup.group_name,
          description: userGroup.group_description ?? "",
          users: userGroup.group_users,
          locations: userGroup.locations ?? []
        });
      }
      setOrderUserGroup("asc");
      setOrderPropertyUserGroup("user");
      setOrderEquipment("asc");
      setOrderPropertyEquipment("location_name");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [addEquipmentDialogOpen, setAddEquipmentDialogOpen] = useState(false);

  const openAddUserDialog = () => {
    setAddUserDialogOpen(true);
  };

  const openAddEquipmentDialog = () => {
    setAddEquipmentDialogOpen(true);
  };

  const handleRemoveUser = (selectedUsername: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeUser(selectedUsername),
      confirmText: t("action.remove"),
      title: t("EditUserGroupsPage.removeConfirmTitle", {
        name: selectedUsername.split("@")[0]
      }),
      description: t("EditUserGroupsPage.removeConfirmDescription")
    });
  };

  const handleRemoveEquipment = (selectedEquipment: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeEquipment(selectedEquipment),
      confirmText: t("action.remove"),
      title: t("EditUserGroupsPage.removeConfirmTitle", {
        name: selectedEquipment
      }),
      description: t("EditUserGroupsPage.removeConfirmDescription")
    });
  };

  const handleRequestSortUser = (property: string) => {
    if (!sessionUser) return;
    const isAsc =
      orderPropertyUserGroup === property && orderUserGroup === "asc";
    setOrderUserGroup(isAsc ? "desc" : "asc");
    setOrderPropertyUserGroup(property);
    if (userGroup) {
      const sortGroupUsers = userGroup.users;
      if (isAsc) {
        sortGroupUsers.sort();
      } else {
        sortGroupUsers.reverse();
      }
      setUserGroup({
        customerId: sessionUser["customer_id"],
        name: userGroup?.name,
        description: userGroup?.description,
        users: sortGroupUsers,
        locations: userGroup?.locations
      });
    }
  };

  const handleRequestSortEquipment = (property: string) => {
    if (!sessionUser) return;
    const isAsc =
      orderPropertyEquipment === property && orderEquipment === "asc";
    setOrderEquipment(isAsc ? "desc" : "asc");
    setOrderPropertyEquipment(property);
    if (userGroup) {
      const sortGroupEquipment = userGroup.locations;
      if (isAsc) {
        sortGroupEquipment.sort();
      } else {
        sortGroupEquipment.reverse();
      }
      setUserGroup({
        customerId: sessionUser["customer_id"],
        name: userGroup?.name,
        description: userGroup?.description,
        users: userGroup?.users,
        locations: sortGroupEquipment
      });
    }
  };

  const removeUser = async (selectedUsername: string) => {
    if (!sessionUser) return;
    try {
      setIsDeleting(true);
      if (userGroup) {
        const updateGroup = {
          customerId: sessionUser["customer_id"],
          name: userGroup.name,
          description: userGroup.description,
          users: userGroup.users.filter(
            username => username !== selectedUsername
          ),
          locations: userGroup.locations
        };
        await UserGroupAPI.update({
          customerId: sessionUser["customer_id"],
          data: updateGroup,
          userGroup
        });
        setUserGroup(updateGroup);
        snackNotification.success(t("EditUserGroupsPage.userRemoved"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsDeleting(false);
    }
  };

  const removeEquipment = async (selectedEquipment: string) => {
    if (!sessionUser) return;
    try {
      setIsDeleting(true);
      if (userGroup) {
        const updateEquipment = {
          customerId: sessionUser["customer_id"],
          name: userGroup.name,
          description: userGroup.description,
          users: userGroup.users,
          locations: userGroup.locations.filter(
            equipment => equipment !== selectedEquipment
          )
        };
        await UserGroupAPI.update({
          customerId: sessionUser["customer_id"],
          data: updateEquipment,
          userGroup
        });
        setUserGroup(updateEquipment);
        snackNotification.success(t("EditUserGroupsPage.equipmentRemoved"));
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsDeleting(false);
    }
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.editUserGroup"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.accessControl")
      },
      {
        label: t("UserGroupsPage.title"),
        page: Pages.USER_GROUPS
      },
      {
        label: name,
        page: `${Pages.USER_GROUPS}/${name}`
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          onBackClick={() => history.push(Pages.USER_GROUPS)}
          title={name}
          search={{
            onChange: e => setUserSearch(e.target.value),
            placeholder: t("EditUserGroupsPage.searchUser")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={openAddUserDialog}
            >
              {t("EditUserGroupsPage.addUser")}
            </PageSectionHeaderAction>
          }
        ></PageSection>
        {isLoading && (
          <Typography align="center">{t("waitState.loading")}</Typography>
        )}
        {!isLoading && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={
                        orderPropertyUserGroup === "plate"
                          ? orderUserGroup
                          : false
                      }
                    >
                      <TableSortLabel
                        active={orderPropertyUserGroup === "user"}
                        direction={
                          orderPropertyUserGroup === "user"
                            ? orderUserGroup
                            : "asc"
                        }
                        onClick={() => handleRequestSortUser("user")}
                      >
                        {t("EditUserGroupsPage.username")}
                        {orderPropertyUserGroup === "user" ? (
                          <Box component="span" sx={visuallyHidden}>
                            {orderUserGroup === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      {t("EditUserGroupsPage.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userGroup?.users
                    .filter(username =>
                      username.toLowerCase().includes(userSearch.toLowerCase())
                    )
                    .map((username, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          <strong>{username}</strong>
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <Button
                            customProps={{
                              color: "primary",
                              variant: "outlined",
                              size: "small",
                              onClick: () => handleRemoveUser(username),
                              startIcon: <MinusCircle size={16} />,
                              disabled: isDeleting
                            }}
                          >
                            {t("action.remove")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </InnerPageLayout>
      <InnerPageLayout>
        <PageSection
          search={{
            onChange: e => setEquipmentSearch(e.target.value),
            placeholder: t("EditUserGroupsPage.searchEquipment")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={openAddEquipmentDialog}
            >
              {t("EditUserGroupsPage.addEquipment")}
            </PageSectionHeaderAction>
          }
        ></PageSection>

        {isLoading && (
          <Typography align="center">{t("waitState.loading")}</Typography>
        )}
        {!isLoading && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={
                        orderPropertyEquipment === "plate"
                          ? orderEquipment
                          : false
                      }
                    >
                      <TableSortLabel
                        active={orderPropertyEquipment === "location_name"}
                        direction={
                          orderPropertyEquipment === "location_name"
                            ? orderEquipment
                            : "asc"
                        }
                        onClick={() =>
                          handleRequestSortEquipment("location_name")
                        }
                      >
                        {t("EditUserGroupsPage.equipment")}
                        {orderPropertyEquipment === "location_name" ? (
                          <Box component="span" sx={visuallyHidden}>
                            {orderEquipment === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      {t("EditUserGroupsPage.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userGroup?.locations
                    .filter(equipment =>
                      equipment
                        .toLowerCase()
                        .includes(equipmentSearch.toLowerCase())
                    )
                    .map((equipment, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          <strong>{equipment}</strong>
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <Button
                            customProps={{
                              color: "primary",
                              variant: "outlined",
                              size: "small",
                              onClick: () => handleRemoveEquipment(equipment),
                              startIcon: <MinusCircle size={16} />,
                              disabled: isDeleting
                            }}
                          >
                            {t("action.remove")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </InnerPageLayout>
      {userGroup && (
        <>
          <AddUserGroupDialog
            open={addUserDialogOpen}
            setOpen={setAddUserDialogOpen}
            updateCallerContent={requestData}
            userGroup={userGroup}
          />
          <AddEquipmentDialog
            open={addEquipmentDialogOpen}
            setOpen={setAddEquipmentDialogOpen}
            updateCallerContent={requestData}
            userGroup={userGroup}
          />
        </>
      )}
    </DefaultPageLayout>
  );
};

export default EditUserGroupsPage;
