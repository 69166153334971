import { FC } from "react";
import {
  Box,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import SelectField from "components/SelectField";
import { useTranslation } from "react-i18next";

type ItensPerPageParams = {
  pageSize: number;
  onChange: (itemsPerPage: number) => void;
  disabled?: boolean;
};

const ItemsPerPage: FC<ItensPerPageParams> = ({
  pageSize,
  onChange,
  disabled = false
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: isMobile ? "16px" : "0px"
      }}
    >
      <Box>
        <Typography variant="subtitle1" color="textSecondary">
          {t("pagination.itemsPerPage")}:
        </Typography>
      </Box>
      <Box>
        <SelectField
          size="small"
          value={pageSize}
          style={{
            marginBottom: 0,
            marginLeft: "8px"
          }}
          onChange={event => {
            onChange(event.target.value as number);
          }}
          disabled={disabled}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </SelectField>
      </Box>
    </Box>
  );
};

export default ItemsPerPage;
