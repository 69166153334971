import { Box, Grid, styled } from "@mui/material";
import Pages from "enums/Pages";
import { format } from "date-fns";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import { MapPin, ArrowUpRight } from "react-feather";
import { ConvoyVehicles } from "api/ConvoyDashboardAPI";
import DataTable from "components/DataTable";
import MonitoringStatus from "components/MonitoringStatus";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import DataTableActions from "components/DataTableActions";

export const RowContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column"
}));

interface ConvoyHistoryTableProps {
  isLoading: boolean;
  monitoringPlates: string[];
  convoyVehicles: ConvoyVehicles[];
  setMapContent: (convoy: ConvoyVehicles) => void;
  openConvoyDialog: (convoy: ConvoyVehicles) => void;
  page?: number;
  paginator?: PaginatorModel;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (itemsPerPage: number) => void;
}

const ConvoyHistoryTable: FC<ConvoyHistoryTableProps> = ({
  isLoading,
  convoyVehicles,
  monitoringPlates,
  openConvoyDialog,
  setMapContent,
  page = 1,
  paginator,
  onPageChange,
  pageSize = 10,
  onPageSizeChange
}) => {
  const { t } = useTranslation();
  const [convoyResults, setConvoyResults] = useState<ConvoyVehicles[]>([]);

  useEffect(() => {
    if (convoyVehicles.length > 0) {
      setConvoyResults(convoyVehicles);
    } else {
      setConvoyResults([]);
    }
  }, [convoyVehicles]);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <DataTable
            watermarked
            headers={[
              { key: "id_convoy", label: t("ConvoyDashboardPage.plate") },
              { key: "date", label: t("ConvoyDashboardPage.captureDate") },
              {
                key: "convoy_count",
                label: t("ConvoyDashboardPage.repetition")
              },
              {
                key: "status",
                label: t("ConvoyDashboardPage.status"),
                noSort: true
              },
              {
                key: "actions",
                label: t("CamerasPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["date", "asc"]}
            onHeaderSort={setConvoyResults}
            data={convoyResults}
            renderRow={row => [
              <>
                <div>
                  <LinkPlate
                    plate={row.id_convoy.split("_")[0] || EMPTY_VALUE}
                    path={Pages.VEHICLE_OVERVIEW}
                  />
                </div>
                <div>
                  <LinkPlate
                    plate={row.id_convoy.split("_")[1] || EMPTY_VALUE}
                    path={Pages.VEHICLE_OVERVIEW}
                  />
                </div>
              </>,
              <>
                {row.date ? (
                  <div>
                    {format(new Date(row.date), "dd/MM/yyyy") || EMPTY_VALUE}
                  </div>
                ) : (
                  <div>
                    {t(`ConvoyDashboardPage.monthLabel.${row.month}`)}{" "}
                    {t("ConvoyDashboardPage.of")} {row.year}
                  </div>
                )}
              </>,
              <>{row["convoy_count"] ?? EMPTY_VALUE}</>,
              <>
                <div>
                  <MonitoringStatus
                    monitoringPlates={monitoringPlates}
                    plate={row.id_convoy.split("_")[0]}
                  />
                </div>
                <div>
                  <MonitoringStatus
                    monitoringPlates={monitoringPlates}
                    plate={row.id_convoy.split("_")[1]}
                  />
                </div>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("ConvoyDashboardPage.focus"),
                      icon: MapPin,
                      onClick: () => setMapContent(row)
                    },
                    {
                      tooltip: t("ConvoyDashboardPage.info"),
                      icon: ArrowUpRight,
                      onClick: () => openConvoyDialog(row)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[3, 4]}
            hideColumnsXs={[1, 2, 3, 4]}
            page={page}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            totalPages={
              paginator?.totalPages ?? defaultPaginatorModelValues.totalPages
            }
            totalItems={
              paginator?.totalItems ?? defaultPaginatorModelValues.totalItems
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConvoyHistoryTable;
