import {
  Box,
  Grid,
  styled,
  MenuItem,
  Checkbox,
  TextField,
  Autocomplete,
  FormControlLabel,
  Divider
} from "@mui/material";
import Button from "components/Button";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import useLocationAPI from "api/LocationAPI";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import SelectField from "components/SelectField";
import { Controller, useForm } from "react-hook-form";
import TimePickerField from "components/TimePickerField";
import DatePickerField from "components/DatePickerField";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { endOfDay, setHours, setMinutes, startOfDay } from "date-fns";
import { useFlowDashboardFilter } from "./FlowDashboardFilterContext";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFlowDashboardFilter = {
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  equipments: string[] | undefined;
  ranking: number | undefined;
};

const defaultDate = new Date();

export const defaultValues: FormFlowDashboardFilter = {
  startDate: startOfDay(defaultDate),
  endDate: endOfDay(defaultDate),
  startTime: startOfDay(defaultDate),
  endTime: endOfDay(defaultDate),
  equipments: undefined,
  ranking: 5
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const FlowDashboardFilter: FC<Props> = ({ open, setOpen }) => {
  const LocationAPI = useLocationAPI();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const { setFilterData } = useFlowDashboardFilter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [monthCheckbox, setMonthCheckbox] = useState<boolean>(false);
  const [todayCheckbox, setTodayCheckbox] = useState<boolean>(false);
  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm<FormFlowDashboardFilter>({
      mode: "onChange",
      defaultValues
    });
  const [locations, setLocations] = useState<string[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([
    t("form.all")
  ]);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const locationsResponse = await LocationAPI.listAll(
        sessionUser.customer_id
      );

      setLocations([
        t("form.all"),
        ...locationsResponse.data.map(location => location.location_name)
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const clear = () => {
    reset(defaultValues);
    setTodayCheckbox(false);
    setMonthCheckbox(false);
    const d = setMinutes(setHours(new Date().setDate(1), 0), 0);
    setValue("startDate", d);
    setValue("startTime", startOfDay(defaultDate));
    setValue("endDate", new Date());
    setValue("endTime", endOfDay(defaultDate));
    setSelectedEquipments([t("form.all")]);
  };

  const handleTodayCheckbox = (newValue: boolean) => {
    if (monthCheckbox) {
      setMonthCheckbox(false);
    }

    setTodayCheckbox(newValue);

    if (newValue) {
      const d = new Date();
      setValue("startDate", startOfDay(d));
      setValue("endDate", d);
      setValue("startTime", startOfDay(d));
      setValue("endTime", d);
    }
  };

  const handleMonthCheckbox = (newValue: boolean) => {
    if (todayCheckbox) {
      setTodayCheckbox(false);
    }

    setMonthCheckbox(newValue);

    if (newValue) {
      setValue("startDate", setMinutes(setHours(new Date().setDate(1), 0), 0));
      setValue("endDate", new Date());
      setValue("startTime", setMinutes(setHours(new Date().setDate(1), 0), 0));
      setValue("endTime", new Date());
    }
  };

  const onSubmit = async (data: FormFlowDashboardFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);
    newData.startTime = new Date(newData.startTime);
    newData.endTime = new Date(newData.endTime);

    if (
      selectedEquipments.includes(t("form.all")) ||
      selectedEquipments.length === 0
    ) {
      newData.equipments = undefined;
    } else {
      newData.equipments = selectedEquipments;
    }

    setFilterData(newData);
    setOpen(false);
  };

  useEffect(() => {
    if (
      getValues("startDate") !== "" &&
      getValues("endDate") !== "" &&
      getValues("startTime") !== "" &&
      getValues("endTime") !== ""
    ) {
      setTodayCheckbox(true);
    }
  }, []);

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("MonitoredVehiclesDashboardPage.filter")}
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.equipments")}
              </FormLabel>
              <Autocomplete
                multiple
                filterSelectedOptions
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={locations}
                getOptionLabel={option => option}
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.includes(t("form.all"));
                  if (
                    selectedEquipments.includes(t("form.all")) &&
                    selectedEquipments.length === 1
                  ) {
                    const idx = newValues.indexOf(t("form.all"));
                    newValues.splice(idx, 1);
                    setSelectedEquipments(newValues);
                  } else if (noIsAll) {
                    setSelectedEquipments(newValues);
                  } else {
                    setSelectedEquipments([t("form.all")]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedEquipments}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.equipmentHint"
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.ranking")}
              </FormLabel>
              <Controller
                name="ranking"
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    onChange={event => {
                      field.onChange(event.target.value as number);
                    }}
                    sx={{ m: 0 }}
                  >
                    <MenuItem value="5">Top 5</MenuItem>
                    <MenuItem value="10">Top 10</MenuItem>
                    <MenuItem value="20">Top 20</MenuItem>
                    <MenuItem value="50">Top 50</MenuItem>
                    <MenuItem value="100">Top 100</MenuItem>
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={todayCheckbox}
                    onChange={(_event, newValue) => {
                      handleTodayCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.today").toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={monthCheckbox}
                    onChange={(_event, newValue) => {
                      handleMonthCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.month").toString()}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: !formState.isValid || isLoading,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default FlowDashboardFilter;
