import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { subDays } from "date-fns";
import { Box, Tooltip, Typography } from "@mui/material";

import useMonitoringVehiclesReportAPI, {
  MonitoringVehiclesReport
} from "api/MonitoringVehiclesReportAPI";
import useMosaicAPI from "api/MosaicAPI";
import { useAuth } from "contexts/AuthContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { useWebSocket } from "contexts/WebSocketContext";
import Pages from "enums/Pages";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import InnerPageLayout from "layouts/InnerPageLayout";
import MonitoringTable from "pages/System/MonitoringTable";

import Mosaic, { GridItem, GridSize } from "./Mosaic";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { AlertCircle } from "react-feather";
import { primaryDefaultColors } from "styles/theme";
import { dateToString } from "utils/DateFunctions";

type MosaicWebSocketMessage = {
  key: "mosaicData";
  data: {
    location: string;
    camera: string;
    id: string;
    plate: string;
    type: string;
    brand: string;
    model: string;
    color: string;
    path: string;
    date: string;
  };
};

type MonitoringWebSocketMessageData = {
  id: string;
  date: string;
  plate: string;
  location: string;
  camera: string;
  type: string;
  description: string;
  path: string;
};

type MonitoringWebSocketMessage = {
  key: "monitoringData";
  data: MonitoringWebSocketMessageData[];
};

const CamerasMosaicPage: FC = () => {
  const MonitoringVehiclesReportAPI = useMonitoringVehiclesReportAPI();
  const MosaicAPI = useMosaicAPI();
  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.cameraMosaic"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("CamerasMosaicPage.title"),
        page: Pages.MOSAIC
      }
    ]);
  }, [t, Pages]);

  const { sessionUser } = useAuth();

  const [isDragging, setIsDragging] = useState(false);
  const [gridSize, setGridSize] = useState<GridSize | null>(null);
  const [gridItems, setGridItems] = useState<GridItem[]>([]);
  const { errorHandler } = useErrorHandler();

  const [monitoringList, setMonitoringList] = useState<
    MonitoringVehiclesReport[]
  >([]);

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      const mosaicResponse = await MosaicAPI.getByUsername({
        customerId: sessionUser["customer_id"],
        username: sessionUser.username
      });

      const { mosaicData } = mosaicResponse;

      setGridSize(mosaicData.size as GridSize);

      const newGridItems = mosaicData["mosaic_data"].map(data => ({
        key: `${data.location}/${data.camera}`,
        id: data.id ?? "",
        location: data.location,
        camera: data.camera,
        image: data.image ?? "",
        dateTime: data.date,
        plate: data.plate,
        type: data.type,
        brand: data.brand,
        model: data.model,
        color: data.color,
        owner: data.owner,
        latitude: data.latitude ?? "",
        longitude: data.longitude ?? ""
      }));

      setGridItems(newGridItems);

      const response = await MonitoringVehiclesReportAPI.list({
        ["customer_id"]: customerId,
        ["initial_date"]: dateToString(subDays(new Date(), 1)),
        ["final_date"]: dateToString(new Date()),
        cameras: newGridItems.map(item => item.camera),
        page: 1,
        ["page_size"]: 50
      });

      // Workaround to avoid duplicated monitoring entries
      const uniqueRows = Array.from(
        new Map(response.registers.items.map(item => [item.id, item])).values()
      );
      setMonitoringList(uniqueRows);
    } catch (error) {
      errorHandler({ error });
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const { lastMessage } = useWebSocket();

  const handleMosaicMessage = ({ data }: MosaicWebSocketMessage) => {
    if (isDragging) return;
    const newGridItems = [...gridItems];
    const itemToChange = newGridItems.find(
      item => item.location === data.location && item.camera === data.camera
    );
    if (!itemToChange) return;
    itemToChange.id = data.id ?? "";
    itemToChange.image = data.path ?? "";
    itemToChange.dateTime = data.date ?? "";
    itemToChange.plate = data.plate;
    itemToChange.type = data.type;
    itemToChange.brand = data.brand;
    itemToChange.model = data.model;
    itemToChange.color = data.color;
    setGridItems(newGridItems);
  };

  const handleMonitoringMessage = (data: MonitoringWebSocketMessageData) => {
    if (!data?.id) {
      return;
    }
    if (monitoringList.find(element => element.id === data.id)) {
      return;
    }
    const newMonitoring = {
      id: data.id,
      ["date_capture"]: data.date,
      ["serial_number"]: "",
      image_link: data.path,
      plate: data.plate,
      equipment: data.location,
      camera: data.camera,
      type: data.type,
      description: data.description
    } as MonitoringVehiclesReport;
    setMonitoringList([newMonitoring, ...monitoringList]);
  };

  useEffect(() => {
    if (!lastMessage?.data) return;

    const parsedMessage: MosaicWebSocketMessage | MonitoringWebSocketMessage =
      JSON.parse(lastMessage.data);

    if (
      !parsedMessage?.key ||
      !["mosaicData", "monitoringData"].includes(parsedMessage.key)
    )
      return;

    const { key } = parsedMessage;

    if (key === "mosaicData") {
      handleMosaicMessage(parsedMessage);
    } else {
      parsedMessage?.data.forEach(data => handleMonitoringMessage(data));
    }
  }, [lastMessage]);

  const updateMosaicSettings = async (
    size: GridSize,
    mosaicData: GridItem[]
  ) => {
    if (!size || !sessionUser?.["customer_id"]) return;
    try {
      await MosaicAPI.update({
        customerId: sessionUser["customer_id"],
        username: sessionUser.username,
        size,
        mosaicData
      });
    } catch (error) {
      errorHandler({ error });
    }
  };

  const handleSizeChange = async (size: GridSize) => {
    await updateMosaicSettings(size, gridItems);
  };

  const handleItemsChange = async (items: GridItem[]) => {
    if (gridSize) await updateMosaicSettings(gridSize, items);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <Box>
          <Mosaic
            size={gridSize}
            setSize={setGridSize}
            onSizeChange={handleSizeChange}
            items={gridItems}
            setItems={setGridItems}
            onItemsChange={handleItemsChange}
            setIsDragging={setIsDragging}
          />
          <Box sx={{ pt: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  mb: 1,
                  mr: 1,
                  display: "block"
                }}
                gutterBottom
                variant="caption"
                color="textSecondary"
              >
                {t("SystemPageLayout.monitoring")}
              </Typography>
              <Tooltip title={t("SystemPageLayout.monitoringHint")}>
                <AlertCircle
                  size={16}
                  style={{ marginBottom: "10px" }}
                  color={primaryDefaultColors.light}
                />
              </Tooltip>
            </Box>
            <MonitoringTable
              rows={monitoringList}
              setRows={setMonitoringList}
            />
          </Box>
        </Box>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default CamerasMosaicPage;
