import "./RichTextEditorStyle.css";
import { FC, useState } from "react";
import { styled } from "@mui/material";
import { HashtagNode } from "@lexical/hashtag";
import { useTranslation } from "react-i18next";
import { $generateNodesFromDOM } from "@lexical/html";
import "./RichTextEditorImage/ImageNode/ImageNode.css";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $generateHtmlFromNodes } from "@lexical/html";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import ImagesPlugin from "./RichTextEditorImage/ImagePlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { $getRoot, EditorState, LexicalEditor } from "lexical";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import ClearPlugin from "./RichTextEditorClear/RichTextEditorClear";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import TableCellResizer from "./RichTextEditorTable/TableCellResizer";
import { ImageNode } from "./RichTextEditorImage/ImageNode/ImageNode";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import ToolbarPlugin from "./RichTextEditorToolbar/RichTextEditorToolbar";
import { TableNode as NewTableNode } from "./RichTextEditorTable/TableNode";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import AutoLinkPlugin from "./RichTextEditorAutoLink/RichTextEditorAutoLink";
import TableActionMenuPlugin from "./RichTextEditorTable/TableActionMenuPlugin";
import ClickableLinkPlugin from "./RichTextEditorClickableLink/RichTextEditorClickableLink";
import RichTextEditorErrorBoundary from "./RichTextEditorErrorBoundary/RichTextEditorErrorBoundary";
import FloatingLinkEditorPlugin from "./RichTextEditorFloatingLinkEditorPlugin/FloatingLinkEditorPlugin";

const EditorContainer = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: "#000",
  position: "relative",
  lineHeight: "20px",
  fontWeight: 400,
  textAlign: "left"
}));

const TextContainer = styled("div")(() => ({
  borderRadius: "4px 4px 4px 4px",
  position: "relative",
  marginTop: "-2px"
}));

export const Placeholder = styled("div")(() => ({
  fontSize: "15px",
  color: "#999",
  overflow: "hidden",
  position: "absolute",
  textOverflow: "ellipsis",
  top: "8px",
  left: "8px",
  right: "28px",
  userSelect: "none",
  whiteSpace: "nowrap",
  display: "inline-block",
  pointerEvents: "none"
}));

interface RichTextEditorProps {
  isCreating: boolean;
  isClear?: boolean;
  editorHeight?: string;
  isComment?: boolean;
  content: string;
  showToolbar: boolean;
  setValue: (value: string) => void;
}

const RichTextEditor: FC<RichTextEditorProps> = ({
  isClear,
  content,
  setValue,
  isComment,
  isCreating,
  showToolbar,
  editorHeight
}) => {
  const { t } = useTranslation();

  const placeholder = (
    <Placeholder>{t("RichTextEditor.placeholder")}</Placeholder>
  );

  function prepopulatedRichText(editor: LexicalEditor) {
    if (!isCreating && !isComment) {
      const root = $getRoot();

      if (root.getFirstChild() === null) {
        const parser = new DOMParser();

        const dom = parser.parseFromString(content, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        if (nodes.length > 0) {
          nodes.forEach(node => {
            root.append(node);
          });
        }
      }
    }
  }

  const initialConfig = {
    editorState: prepopulatedRichText,
    namespace: "RichTextEditor",
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      HashtagNode,
      CodeHighlightNode,
      AutoLinkNode,
      LinkNode,
      TableCellNode,
      TableNode,
      NewTableNode,
      TableRowNode,
      ImageNode
    ],
    onError(error: unknown) {
      throw error;
    },
    theme: {
      characterLimit: "PlaygroundEditorTheme__characterLimit",
      code: "PlaygroundEditorTheme__code",
      codeHighlight: {
        atrule: "PlaygroundEditorTheme__tokenAttr",
        attr: "PlaygroundEditorTheme__tokenAttr",
        boolean: "PlaygroundEditorTheme__tokenProperty",
        builtin: "PlaygroundEditorTheme__tokenSelector",
        cdata: "PlaygroundEditorTheme__tokenComment",
        char: "PlaygroundEditorTheme__tokenSelector",
        class: "PlaygroundEditorTheme__tokenFunction",
        "class-name": "PlaygroundEditorTheme__tokenFunction",
        comment: "PlaygroundEditorTheme__tokenComment",
        constant: "PlaygroundEditorTheme__tokenProperty",
        deleted: "PlaygroundEditorTheme__tokenProperty",
        doctype: "PlaygroundEditorTheme__tokenComment",
        entity: "PlaygroundEditorTheme__tokenOperator",
        function: "PlaygroundEditorTheme__tokenFunction",
        important: "PlaygroundEditorTheme__tokenVariable",
        inserted: "PlaygroundEditorTheme__tokenSelector",
        keyword: "PlaygroundEditorTheme__tokenAttr",
        namespace: "PlaygroundEditorTheme__tokenVariable",
        number: "PlaygroundEditorTheme__tokenProperty",
        operator: "PlaygroundEditorTheme__tokenOperator",
        prolog: "PlaygroundEditorTheme__tokenComment",
        property: "PlaygroundEditorTheme__tokenProperty",
        punctuation: "PlaygroundEditorTheme__tokenPunctuation",
        regex: "PlaygroundEditorTheme__tokenVariable",
        selector: "PlaygroundEditorTheme__tokenSelector",
        string: "PlaygroundEditorTheme__tokenSelector",
        symbol: "PlaygroundEditorTheme__tokenProperty",
        tag: "PlaygroundEditorTheme__tokenProperty",
        url: "PlaygroundEditorTheme__tokenOperator",
        variable: "PlaygroundEditorTheme__tokenVariable"
      },
      embedBlock: {
        base: "PlaygroundEditorTheme__embedBlock",
        focus: "PlaygroundEditorTheme__embedBlockFocus"
      },
      hashtag: "PlaygroundEditorTheme__hashtag",
      heading: {
        h1: "PlaygroundEditorTheme__h1",
        h2: "PlaygroundEditorTheme__h2",
        h3: "PlaygroundEditorTheme__h3",
        h4: "PlaygroundEditorTheme__h4",
        h5: "PlaygroundEditorTheme__h5",
        h6: "PlaygroundEditorTheme__h6"
      },
      image: "editor-image",
      link: "PlaygroundEditorTheme__link",
      list: {
        listitem: "PlaygroundEditorTheme__listItem",
        listitemChecked: "PlaygroundEditorTheme__listItemChecked",
        listitemUnchecked: "PlaygroundEditorTheme__listItemUnchecked",
        nested: {
          listitem: "PlaygroundEditorTheme__nestedListItem"
        },
        olDepth: [
          "PlaygroundEditorTheme__ol1",
          "PlaygroundEditorTheme__ol2",
          "PlaygroundEditorTheme__ol3",
          "PlaygroundEditorTheme__ol4",
          "PlaygroundEditorTheme__ol5"
        ],
        ul: "PlaygroundEditorTheme__ul"
      },
      ltr: "PlaygroundEditorTheme__ltr",
      mark: "PlaygroundEditorTheme__mark",
      markOverlap: "PlaygroundEditorTheme__markOverlap",
      paragraph: "PlaygroundEditorTheme__paragraph",
      quote: "PlaygroundEditorTheme__quote",
      rtl: "PlaygroundEditorTheme__rtl",
      table: "PlaygroundEditorTheme__table",
      tableAddColumns: "PlaygroundEditorTheme__tableAddColumns",
      tableAddRows: "PlaygroundEditorTheme__tableAddRows",
      tableCell: "PlaygroundEditorTheme__tableCell",
      tableCellActionButton: "PlaygroundEditorTheme__tableCellActionButton",
      tableCellActionButtonContainer:
        "PlaygroundEditorTheme__tableCellActionButtonContainer",
      tableCellEditing: "PlaygroundEditorTheme__tableCellEditing",
      tableCellHeader: "PlaygroundEditorTheme__tableCellHeader",
      tableCellPrimarySelected:
        "PlaygroundEditorTheme__tableCellPrimarySelected",
      tableCellResizer: "PlaygroundEditorTheme__tableCellResizer",
      tableCellSelected: "PlaygroundEditorTheme__tableCellSelected",
      tableCellSortedIndicator:
        "PlaygroundEditorTheme__tableCellSortedIndicator",
      tableResizeRuler: "PlaygroundEditorTheme__tableCellResizeRuler",
      tableSelected: "PlaygroundEditorTheme__tableSelected",
      text: {
        bold: "PlaygroundEditorTheme__textBold",
        code: "PlaygroundEditorTheme__textCode",
        italic: "PlaygroundEditorTheme__textItalic",
        strikethrough: "PlaygroundEditorTheme__textStrikethrough",
        subscript: "PlaygroundEditorTheme__textSubscript",
        superscript: "PlaygroundEditorTheme__textSuperscript",
        underline: "PlaygroundEditorTheme__textUnderline",
        underlineStrikethrough:
          "PlaygroundEditorTheme__textUnderlineStrikethrough"
      }
    }
  };

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  function onChange(_editorState: EditorState, lexicalEditor: LexicalEditor) {
    lexicalEditor.update(() => {
      const htmlString = $generateHtmlFromNodes(lexicalEditor);
      setValue(htmlString);
    });
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorContainer>
        {showToolbar && <ToolbarPlugin />}
        <TextContainer
          style={{
            border: showToolbar || isComment ? "1px solid #e0e0e0" : "0px"
          }}
        >
          <RichTextPlugin
            contentEditable={
              <div
                className="editor-scroller"
                style={{
                  height: editorHeight ? editorHeight : "360px",
                  minHeight: "32px"
                }}
              >
                <div className="editor" ref={onRef}>
                  <ContentEditable className="ContentEditable__root" />
                </div>
              </div>
            }
            placeholder={placeholder}
            ErrorBoundary={RichTextEditorErrorBoundary}
          />
          <ListPlugin />
          <LinkPlugin />
          <HashtagPlugin />
          <HistoryPlugin />
          <AutoLinkPlugin />
          <CheckListPlugin />
          <TablePlugin />
          <TableCellResizer />
          <ClearEditorPlugin />
          <ClickableLinkPlugin />
          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableActionMenuPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
          <OnChangePlugin onChange={onChange} />
          <ImagesPlugin />
          <ClearPlugin
            isComment={isComment || false}
            isClear={isClear || false}
          />
        </TextContainer>
      </EditorContainer>
    </LexicalComposer>
  );
};

export default RichTextEditor;
