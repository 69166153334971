import { ReactElement, ReactNode } from "react";
import { Box, Skeleton, useTheme } from "@mui/material";
import Watermark from "components/Watermark";
import Paginator from "./Paginator";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import DataTableActions, { DataTableAction } from "./DataTableActions";

const SKELETON_ROWS = 3;

const ItemBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  "& .itemName": {
    flexGrow: 1,
    ":hover": {
      cursor: "pointer"
    }
  }
}));

type DataListProps<D> = {
  //
  // Rows & Columns
  data: D[];
  renderName: (row: D) => ReactNode;
  actions: (row: D) => DataTableAction[];
  hideColumnsSm?: number[];
  hideColumnsXs?: number[];
  //
  // Pagination
  page?: number;
  totalPages?: number;
  pageSize?: number;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (itemsPerPage: number) => void;
  // Other
  isLoading?: boolean;
  watermarked?: boolean;
};

const DataList = <D extends Record<string, unknown>>({
  //
  // Rows & Columns
  data,
  renderName,
  actions,
  //
  // Pagination
  pageSize = 10,
  onPageSizeChange,
  page = 1,
  onPageChange,
  totalPages = 1,
  totalItems = 0,
  //
  //Other
  isLoading = false,
  watermarked = false
}: DataListProps<D>): ReactElement => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const high = Math.min(pageSize * page, totalItems);
  const low = high - data.length + 1;

  return (
    <>
      <Watermark enabled={watermarked && !isLoading}>
        {isLoading &&
          Array.from(Array(SKELETON_ROWS).keys()).map(v => (
            <ItemBox key={`row_skeleton_${v}`}>
              <Box className="itemName">
                <Skeleton animation="wave" variant="text" width={60} />
              </Box>
              <Box>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={60}
                  sx={{ margin: "right" }}
                />
              </Box>
            </ItemBox>
          ))}
        {!isLoading &&
          data.map((row, rowIndex) => (
            <ItemBox key={rowIndex}>
              <Box className="itemName">{renderName(row)}</Box>
              <Box>
                <DataTableActions actions={actions(row)} />
              </Box>
            </ItemBox>
          ))}
        <ItemBox
          sx={{
            justifyContent: "center",
            lineHeight: "1.3rem",
            padding: "0.5rem",
            fontWeight: 500,
            fontSize: "0.8rem",
            color: theme.palette.text.secondary
          }}
        >
          {isLoading
            ? t("waitState.loading")
            : totalItems > 0
            ? t("pagination.displayingOfTotalItems", {
                size: data.length,
                totalItems: totalItems.toLocaleString(i18n.language),
                low: low.toLocaleString(i18n.language),
                high: high.toLocaleString(i18n.language)
              })
            : t("pagination.noItems")}
        </ItemBox>
      </Watermark>
      <Paginator
        disabled={isLoading}
        initialPage={page}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};

export default DataList;
