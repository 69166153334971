import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import useVmsIntegrationAPI from "api/VmsIntegrationApi";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useAuth } from "contexts/AuthContext";
import { Trans, useTranslation } from "react-i18next";
import FormDialog from "components/FormDialog";

export type VideoViewerData = {
  requestId: string;
  url: string;
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  videoData: VideoViewerData;
};

const VideoViewerDialog: FC<Props> = ({ open, setOpen, videoData }) => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isLoadVideoError, setIsLoadVideoError] = useState(false);
  const [isVideoPlayerLoaded, setIsVideoPlayerLoaded] = useState(false);
  const [getDataInterval, setGetDataInterval] =
    useState<NodeJS.Timeout | null>();
  const [videoUrl, setVideoUrl] = useState("");
  const VmsIntegrationAPI = useVmsIntegrationAPI();
  const { errorHandler } = useErrorHandler();
  const { sessionUser } = useAuth();
  const { t } = useTranslation();

  const getVideo = async (requestId: string) => {
    try {
      if (!sessionUser || !videoData.requestId) return;
      const dataVideoResponse = await VmsIntegrationAPI.getData({
        requestId,
        customerId: sessionUser.customer_id
      });
      if (dataVideoResponse.message === "Error on getting data") {
        setIsLoadVideoError(true);
        setIsFetchingData(false);
      } else if (dataVideoResponse.isUrl) {
        setVideoUrl(dataVideoResponse.message);
        setIsFetchingData(false);
      }
    } catch (error) {
      errorHandler({ error });
    }
  };

  useEffect(() => {
    if (!isFetchingData && getDataInterval) {
      clearInterval(getDataInterval);
      setGetDataInterval(null);
    }
    if (isFetchingData) {
      if (!getDataInterval) {
        (async () => {
          await getVideo(videoData.requestId);
        })();
        const newInterval = setInterval(() => {
          (async () => {
            await getVideo(videoData.requestId);
          })();
        }, 5000);

        setGetDataInterval(newInterval);
      }
    }
    return () => {
      if (getDataInterval) {
        clearInterval(getDataInterval);
        setGetDataInterval(null);
      }
    };
  }, [isFetchingData]);

  useEffect(() => {
    if (!open) {
      setIsLoadVideoError(false);
      setIsFetchingData(false);
      if (getDataInterval) {
        clearInterval(getDataInterval);
        setGetDataInterval(null);
      }
    } else if (videoData.url) {
      setVideoUrl(videoData.url);
      setIsFetchingData(false);
    } else {
      setIsFetchingData(true);
    }
  }, [open, videoData]);

  return (
    <FormDialog
      noCancel
      open={open}
      setOpen={setOpen}
      isLoading={isFetchingData}
    >
      {isFetchingData && (
        <div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            <CircularProgress sx={{ mb: 2 }} />
            <Typography align="center">
              {t("waitState.processingVideo")}
            </Typography>
          </Box>
        </div>
      )}
      {!isFetchingData && videoUrl && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          {isVideoPlayerLoaded && "Video player not loaded"}
          <video
            style={{
              marginBottom: "16px"
            }}
            src={videoUrl}
            width="750"
            height="500"
            controls
            autoPlay
            onLoadedData={() => setIsVideoPlayerLoaded(true)}
          />
          <Typography gutterBottom align="center">
            <Trans
              i18nKey="CaptureImageViewer.noVideoPlayerMessage"
              t={t}
              components={[
                <Link
                  key={0}
                  target="_blank"
                  href={videoUrl}
                  color="secondary"
                />
              ]}
            />
          </Typography>
        </Box>
      )}
      {isLoadVideoError && (
        <div>
          <Typography align="center">
            <strong>{t("error.videoError")}</strong>
          </Typography>
        </div>
      )}
    </FormDialog>
  );
};

export default VideoViewerDialog;
