import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Edit, MinusCircle, Plus } from "react-feather";
import DataTable from "components/DataTable";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import useVmsIntegrationAPI, { VmsIntegration } from "api/VmsIntegrationApi";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import VmsIntegrationFormDialog from "pages/Settings/Integrations/VmsIntegration/VmsIntegrationFormDialog";
import snackNotification from "components/SnackNotification";
import { useModal } from "contexts/ModalContext";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const VmsIntegrationTable: FC = () => {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [vmsIntegrations, setVmsIntegrations] = useState<VmsIntegration[]>([]);
  const { sessionUser } = useAuth();
  const VmsIntegrationAPI = useVmsIntegrationAPI();
  const { errorHandler } = useErrorHandler();
  const { showModal } = useModal();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<{
    vmsIndex: string;
  }>({
    vmsIndex: ""
  });

  const openFormDialog = (vmsIndex = "") => {
    setItemSelected({ vmsIndex });
    setIsFormOpen(true);
  };

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsFetching(true);
    try {
      const response = await VmsIntegrationAPI.listAll(sessionUser.customer_id);
      setVmsIntegrations(response.data);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsFetching(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const handleRemove = (data: VmsIntegration) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(data),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name: data.url }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async (data: VmsIntegration) => {
    try {
      if (!sessionUser) return;
      setIsFetching(true);
      await VmsIntegrationAPI.deleteByIndex({
        customerId: sessionUser.customer_id,
        index: data.index
      });
      snackNotification.success(
        t("IntegrationsPage.vms.integrationRemovedSuccessfully")
      );
      requestData();
    } catch (error) {
      errorHandler({ error });
      setIsFetching(false);
    }
  };

  return (
    <Box>
      <PageSection
        primaryActions={
          <PageSectionHeaderAction
            variant="contained"
            color="secondary"
            startIcon={<Plus />}
            onClick={() => openFormDialog()}
          >
            {t("IntegrationsPage.vms.newIntegration")}
          </PageSectionHeaderAction>
        }
      >
        <DataTable
          watermarked
          headers={[
            { key: "username", label: t("IntegrationsPage.vms.username") },
            { key: "model", label: t("IntegrationsPage.vms.model") },
            { key: "url", label: t("IntegrationsPage.vms.url") },
            { key: "protocol", label: t("IntegrationsPage.vms.protocol") },
            {
              key: "outputFormat",
              label: t("IntegrationsPage.vms.outputFormat")
            },
            { key: "timezone", label: t("IntegrationsPage.vms.timezone") },
            {
              key: "actions",
              label: t("IntegrationsPage.vms.actions"),
              align: "right",
              noSort: true
            }
          ]}
          defaultSort={["username", "asc"]}
          onHeaderSort={setVmsIntegrations}
          data={vmsIntegrations}
          renderRow={row => [
            <>{row.username}</>,
            <>{row.model}</>,
            <>{row.url}</>,
            <>{row.protocol}</>,
            <>{row.output_format}</>,
            <>{`GMT${row.timezone === "0" ? "" : row.timezone}`}</>,
            <>
              <DataTableActions
                actions={[
                  {
                    tooltip: t("action.edit"),
                    icon: Edit,
                    onClick: () => openFormDialog(row.index)
                  },
                  {
                    tooltip: t("action.remove"),
                    icon: MinusCircle,
                    onClick: () => handleRemove(row)
                  }
                ]}
              />
            </>
          ]}
          hideColumnsSm={[1, 3, 5]}
          hideColumnsXs={[0, 1, 3, 4, 5]}
          page={1}
          onPageChange={_pageValue => {}}
          pageSize={100}
          onPageSizeChange={_itemsPerPage => {}}
          totalPages={1}
          totalItems={vmsIntegrations.length}
          isLoading={isFetching}
        />
      </PageSection>
      <VmsIntegrationFormDialog
        vmsIndex={itemSelected.vmsIndex}
        open={isFormOpen}
        setOpen={setIsFormOpen}
        onSave={() => requestData()}
      />
    </Box>
  );
};

export default VmsIntegrationTable;
