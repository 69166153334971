import { Checkbox, FormControlLabel, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { Controller, useForm } from "react-hook-form";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import useVerticalAPI from "api/VerticalAPI";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormLabel from "components/FormLabel";
import FormDialog from "components/FormDialog";

const StyledDivPermission = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  div: {
    marginRight: theme.spacing(0.7)
  }
}));

type VerticalForm = {
  verticalName: string;
  functionality: string[];
};

const defaultValues: VerticalForm = {
  verticalName: "",
  functionality: []
};

type Props = {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
};

const VerticalFormDialog: FC<Props> = ({
  name,
  open,
  setOpen,
  updateCallerContent
}) => {
  const { t } = useTranslation();

  const VerticalAPI = useVerticalAPI();
  const { sessionUser } = useAuth();

  const isCreating = name === "";
  const [isLoading, setLoading] = useState<boolean>(false);

  const [vertical, setVertical] = useState<VerticalForm>(defaultValues);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset } = useForm<VerticalForm>({
    mode: "onChange",
    defaultValues
  });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setLoading(true);
    try {
      if (!isCreating) {
        const verticalResponse = await VerticalAPI.getByName(name);
        setVertical({
          verticalName: verticalResponse.vertical_name,
          functionality: verticalResponse.functionality
        });
        setSelectedPermissions(verticalResponse.functionality);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [sessionUser, name]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(vertical);
  }, [reset, vertical]);

  useEffect(() => {
    if (!open) {
      setVertical(defaultValues);
      reset(vertical);
      setSelectedPermissions([]);
    }
    setOpen(open);
  }, [open]);

  const onSubmit = async (/*data: VerticalForm*/) => {
    if (!sessionUser?.["customer_id"]) return;
    setLoading(true);
    //const newData = { ...data, functionality: selectedPermissions };
    try {
      if (isCreating) {
        //await VerticalAPI.create(newData);
        snackNotification.success(t("VerticalFormDialog.verticalCreated"));
      } else {
        //await VerticalAPI.update(newData);
        snackNotification.success(t("VerticalFormDialog.verticalUpdated"));
      }
      setOpen(false);
      updateCallerContent();
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedPermissions([...selectedPermissions, event.target.value]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter(p => p !== event.target.value)
      );
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("VerticalsPage.createANewVertical")
          : t("VerticalsPage.editVertical")
      }
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controller
            name="verticalName"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("VerticalFormDialog.name")}
                customProps={{
                  required: true,
                  disabled: isLoading || !isCreating
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>{t("VerticalFormDialog.permissions")}</FormLabel>
          <StyledDivPermission>
            {["user", "permission"].map(permission => (
              <div key={permission}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={handlePermissionChange}
                      disabled={isLoading}
                      value={permission}
                      checked={selectedPermissions.includes(permission)}
                    />
                  }
                  label={permission}
                />
              </div>
            ))}
          </StyledDivPermission>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default VerticalFormDialog;
