import { OutlinedInput, styled } from "@mui/material";

type Props = { uppercase?: boolean };

const CustomInput = styled(OutlinedInput, {
  shouldForwardProp: prop => prop !== "uppercase"
})<Props>(({ theme, uppercase }) => ({
  backgroundColor: theme.palette.background.default,
  input: {
    height: 40,
    padding: "0 8px",
    textTransform: uppercase ? "uppercase" : "normal"
  },
  fieldset: {
    display: "none"
  },
  paddingRight: 0,
  "&.MuiInputBase-adornedStart": {
    paddingLeft: "8px"
  },
  border: `1px solid ${theme.palette.grey["300"]}`,
  "&.Mui-focused": {
    borderColor: theme.palette.secondary.main
  },
  "&.error": {
    borderColor: theme.palette.error.main
  },
  svg: {
    color: theme.palette.primary.light
  }
}));

export default CustomInput;
