import "./RichTextEditorErrorBoundary.css";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

type Props = {
  children: JSX.Element;
  onError: (error: Error) => void;
};

export default function RichTextEditorErrorBoundary({
  children,
  onError
}: Props): JSX.Element {
  return (
    <ReactErrorBoundary
      fallback={
        <span className="ErrorBoundary__container">
          Lexical Rich Text Editor crashed
        </span>
      }
      onError={onError}
    >
      {children}
    </ReactErrorBoundary>
  );
}
