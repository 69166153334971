import { FC } from "react";

const DEFAULT_SIZE = 17;

type Props = {
  country: string;
  size?: number;
};

const Flag: FC<Props> = ({ country, size = DEFAULT_SIZE, ...props }) => {
  const splitCountry = country.split(/[-_]/g);
  const parsedCountry = splitCountry[splitCountry.length - 1].toLowerCase();
  return (
    <span
      className={`fi fi-${parsedCountry}`}
      style={{ width: size }}
      {...props}
    />
  );
};

export default Flag;
