import {
  Box,
  Grid,
  Card,
  Radio,
  styled,
  Typography,
  CardContent,
  CircularProgress
} from "@mui/material";
import { EMPTY_VALUE } from "utils/String";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import noImage from "assets/image_unavailable.jpg";
import { CaptureReport } from "api/CaptureReportAPI";

export interface CardSelectedProps {
  active: string;
}

const CardContainer = styled(Card)(({ active }: CardSelectedProps) => ({
  padding: "24px",
  cursor: "pointer",
  border: active === "true" ? "1px solid #0B2BFC" : "1px solid #000",

  "& .MuiCardContent-root": {
    img: {
      maxWidth: "222px",
      maxHeight: "183px"
    },

    ul: {
      marginBottom: "8px",
      marginLeft: "-38px"
    },

    "& .price": {
      fontWeight: 600,
      marginLeft: "-16px"
    }
  },

  "& .subtitle": {
    fontWeight: 600,
    color: active === "true" ? "#0B2BFC" : "##000"
  }
}));

const HeaderCard = styled(Box)(({ active }: CardSelectedProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  color: active === "true" ? "#0B2BFC" : "##000"
}));

const ImageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "24px"
}));

interface SelectCardProps {
  isLoading: boolean;
  data: CaptureReport;
  selectedCard: CaptureReport;
  setSelectedCard: (value: CaptureReport) => void;
}

const SelectCard: FC<SelectCardProps> = ({
  data,
  selectedCard,
  setSelectedCard,
  isLoading
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (data.id === selectedCard.id) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selectedCard]);

  const handleChange = (value: CaptureReport) => {
    setSelectedCard(value);
  };

  return (
    <CardContainer
      active={checked.toString()}
      onClick={() => {
        handleChange(data);
      }}
    >
      <HeaderCard active={checked.toString()}>
        <Typography variant="h4">{`${t(
          "MonitoredVehiclesDashboardPage.capture"
        )} ${data.id}`}</Typography>

        <Radio
          color="secondary"
          size="small"
          checked={checked}
          onChange={() => handleChange(data)}
        />
      </HeaderCard>

      <CardContent>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 240
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ImageContainer>
          <img
            src={data.image_link || noImage}
            alt="system-capture-img"
            style={{
              borderRadius: "4px",
              minHeight: "166px"
            }}
          />
        </ImageContainer>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" align="left">
              {t("ImageDialog.make")}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {data.vehicle_make || EMPTY_VALUE}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" align="left">
              {t("ImageDialog.model")}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {data.vehicle_model || EMPTY_VALUE}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" align="left">
              {t("ImageDialog.color")}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {data.vehicle_color || EMPTY_VALUE}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" align="left">
              {t("ForensicsReportPage.datetime")}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {data.date_capture
                ? format(
                    parseISO(data.date_capture),
                    t("CaptureDialog.dateTimeFormat")
                  )
                : EMPTY_VALUE}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardContainer>
  );
};

export default SelectCard;
