import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography
} from "@mui/material";
import useCameraAPI from "api/CameraAPI";
import TabPanel from "components/TabPanel";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import SelectField from "components/SelectField";
import useIntegrationsAPI from "api/IntegrationsAPI";
import { Controller, useForm } from "react-hook-form";
import useLocationAPI from "api/LocationAPI";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import useVmsIntegrationAPI, { VmsIntegration } from "api/VmsIntegrationApi";
import { EMPTY_VALUE } from "utils/String";
import FormDialog from "components/FormDialog";
import FormLabel from "components/FormLabel";

export type CameraForm = {
  customerId: string;
  locationName: string;
  oldLocationName: string;
  cameraName: string;
  oldCameraName: string;
  active: boolean;
  side: string;
  serialNumber: string;
  requestImageRecognized: boolean;
  requestImageUnrecognized: boolean;
  vmsCameraName: string;
  vmsIndex: string;
  cortex: {
    lane: number;
  };
  ["cconet"]: {
    ["external_id"]: string;
  };
  ["detecta_sp"]: {
    ["external_id"]: string;
  };
  ["spia_prf"]: {
    ["external_id"]: string;
  };
};

const defaultValues = {
  customerId: "",
  cameraName: "",
  oldCameraName: "",
  locationName: "",
  oldLocationName: "",
  active: false,
  side: "",
  serialNumber: "",
  requestImageRecognized: false,
  requestImageUnrecognized: false,
  vmsCameraName: "",
  vmsIndex: "",
  cortex: {
    lane: 1
  },
  ["cconet"]: {
    ["external_id"]: ""
  },
  ["detecta_sp"]: {
    ["external_id"]: "00000000"
  },
  ["spia_prf"]: {
    ["external_id"]: ""
  }
};

type Props = {
  cameraName: string;
  locationName: string;
  open: boolean;
  persist: boolean;
  setOpen: (open: boolean) => void;
  onSave: (camera?: CameraForm) => void;
};

const CamerasFormDialog: FC<Props> = ({
  cameraName,
  locationName,
  open,
  setOpen,
  onSave,
  persist
}) => {
  const CameraAPI = useCameraAPI();
  const LocationAPI = useLocationAPI();
  const VmsIntegrationAPI = useVmsIntegrationAPI();
  const IntegrationsAPI = useIntegrationsAPI();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isCreating = cameraName === "";
  const { sessionUser } = useAuth();
  const [locations, setLocations] = useState<string[]>([]);
  const [vms, setVms] = useState<VmsIntegration[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [camera, setCamera] = useState<CameraForm>(defaultValues);
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<CameraForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedFeatures([...selectedFeatures, event.target.value]);
    } else {
      setSelectedFeatures(
        selectedFeatures.filter(p => p !== event.target.value)
      );
    }
  };

  useEffect(() => {
    if (
      selectedFeatures.includes("active") &&
      !selectedFeatures.includes("requestImageRecognized")
    ) {
      setSelectedFeatures([...selectedFeatures, "requestImageRecognized"]);
    }
  }, [selectedFeatures]);

  const [displayCCONETTab, setDisplayCCONETTab] = useState(false);
  const [displayDetectaSpTab, setDisplayDetectaSpTab] = useState(false);
  const [displaySpiaPrfTab, setDisplaySpiaPrfTab] = useState(false);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    const customerId = sessionUser["customer_id"];
    setLoading(true);
    try {
      const [
        vmsResponse,
        locationsResponse,
        cconetResponse,
        detectaSpResponse,
        spiaPrfResponse,
        cameraResponse
      ] = await Promise.all([
        VmsIntegrationAPI.listAll(sessionUser["customer_id"]),
        LocationAPI.listAll(sessionUser["customer_id"]),
        IntegrationsAPI.getCCONETCustomerIntegration(
          sessionUser["customer_id"]
        ),
        IntegrationsAPI.getDetectaSpCustomerIntegration(
          sessionUser["customer_id"]
        ),
        IntegrationsAPI.getSpiaPrfCustomerIntegration(
          sessionUser["customer_id"]
        ),
        isCreating
          ? Promise.resolve(null)
          : await CameraAPI.getByName({
              customerId,
              cameraName,
              locationName
            })
      ]);

      setLocations(
        locationsResponse.data.map(location => location["location_name"])
      );

      setVms(vmsResponse.data || []);

      if (cconetResponse?.enabled) {
        setDisplayCCONETTab(true);
      }

      if (detectaSpResponse?.enabled) {
        setDisplayDetectaSpTab(true);
      }

      if (spiaPrfResponse?.enabled) {
        setDisplaySpiaPrfTab(true);
      }

      if (!persist && isCreating) {
        setCamera({
          ...defaultValues,
          locationName: locationName ?? EMPTY_VALUE
        });
      }

      if (!isCreating && cameraResponse) {
        const cameraObj: CameraForm = {
          customerId: sessionUser["customer_id"],
          locationName: cameraResponse["location_name"],
          oldLocationName: cameraResponse["location_name"],
          cameraName: cameraResponse["camera_name"],
          oldCameraName: cameraResponse["camera_name"],
          active: cameraResponse["camera_data"].active,
          side: cameraResponse["camera_data"].side,
          serialNumber: cameraResponse["serial_number"],
          requestImageRecognized:
            cameraResponse["camera_data"]["request_image_recognized"],
          requestImageUnrecognized:
            cameraResponse["camera_data"]["request_image_unrecognized"],
          vmsCameraName: cameraResponse.camera_data.vms?.name ?? "",
          vmsIndex: cameraResponse.camera_data.vms?.index ?? "",
          cortex: {
            lane: cameraResponse["camera_data"].cortex?.lane ?? 1
          },
          ["cconet"]: {
            ["external_id"]:
              cameraResponse["camera_data"]["cconet"]?.["external_id"] ?? ""
          },
          ["detecta_sp"]: {
            ["external_id"]:
              cameraResponse["camera_data"]["detecta_sp"]?.["external_id"] ??
              "00000000"
          },
          ["spia_prf"]: {
            ["external_id"]:
              cameraResponse["camera_data"]["spia_prf"]?.["external_id"] ?? ""
          }
        };
        setCamera(cameraObj);
        const features = [
          cameraObj.active ? "active" : "",
          cameraObj.requestImageRecognized ? "requestImageRecognized" : "",
          cameraObj.requestImageUnrecognized ? "requestImageUnrecognized" : ""
        ].filter(e => e);
        setSelectedFeatures(features);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [isCreating, reset, sessionUser, cameraName, locationName]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(camera);
  }, [setValue, camera]);

  useEffect(() => {
    if (!open) {
      setCamera(defaultValues);
      reset(defaultValues);
      setSelectedFeatures([]);
    }
  }, [open]);

  const onSubmit = async (data: CameraForm) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      setLoading(true);

      const newData = { ...data };

      newData.customerId = sessionUser.customer_id;
      newData.active = selectedFeatures.includes("active");
      newData.requestImageRecognized = selectedFeatures.includes(
        "requestImageRecognized"
      );
      newData.requestImageUnrecognized = selectedFeatures.includes(
        "requestImageUnrecognized"
      );

      if (persist) {
        if (isCreating) {
          await CameraAPI.create(newData);
          snackNotification.success(t("CamerasPage.cameraCreated"));
        } else {
          await CameraAPI.update({
            ...newData,
            oldLocationName:
              newData.oldLocationName === newData.locationName
                ? undefined
                : newData.oldLocationName,
            oldCameraName:
              newData.oldCameraName === newData.cameraName
                ? undefined
                : newData.oldCameraName
          });
          snackNotification.success(t("CamerasPage.cameraUpdated"));
        }
        onSave();
      } else {
        onSave(newData);
      }
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("CamerasPage.createANewCamera")
          : t("CamerasPage.editCamera")
      }
    >
      <TabPanel
        tabs={[
          {
            value: "general",
            label: t("CamerasPage.general"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="cameraName"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CamerasPage.name")}
                        customProps={{
                          inputProps: {
                            maxLength: 100
                          },
                          disabled: isLoading,
                          required: true
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("CamerasPage.status")}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        disabled={isLoading}
                        value="active"
                        onChange={handleCheckboxChange}
                        checked={selectedFeatures.includes("active")}
                      />
                    }
                    label={t("status.active").toString()}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="locationName"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        required
                        label={t("CamerasPage.equipment")}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        disabled={isLoading || (!persist && isCreating)}
                      >
                        <MenuItem value="">
                          {t("CamerasPage.equipmentHint")}
                        </MenuItem>
                        {!persist && isCreating && (
                          <MenuItem value="-">{EMPTY_VALUE}</MenuItem>
                        )}
                        {locations.map(location => (
                          <MenuItem key={location} value={location}>
                            {location}
                          </MenuItem>
                        ))}
                      </SelectField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="side"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        required
                        label={t("CamerasPage.direction")}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        disabled={isLoading}
                      >
                        <MenuItem value="">
                          {t("CamerasPage.directionHint")}
                        </MenuItem>
                        <MenuItem value="front">
                          {t("CameraDirection.front")}
                        </MenuItem>
                        <MenuItem value="rear">
                          {t("CameraDirection.rear")}
                        </MenuItem>
                        <MenuItem value="in">
                          {t("CameraDirection.in")}
                        </MenuItem>
                        <MenuItem value="out">
                          {t("CameraDirection.out")}
                        </MenuItem>
                        <MenuItem value="none">
                          {t("CameraDirection.none")}
                        </MenuItem>
                      </SelectField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormLabel
                    required
                    helperText={t("CamerasPage.serialNumberHint")}
                  >
                    {t("CamerasPage.serialNumber")}
                  </FormLabel>
                  <Controller
                    name="serialNumber"
                    control={control}
                    rules={{
                      required: t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        customProps={{
                          inputProps: {
                            maxLength: 45
                          },
                          disabled: isLoading || !isCreating,
                          required: true
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                        uppercase
                        filter={/[^A-Za-z0-9]/g}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("CamerasPage.images")}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        disabled={
                          isLoading || selectedFeatures.includes("active")
                        }
                        value="requestImageRecognized"
                        onChange={handleCheckboxChange}
                        checked={selectedFeatures.includes(
                          "requestImageRecognized"
                        )}
                      />
                    }
                    label={t("status.active").toString()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>{t("CamerasPage.noImages")}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        disabled={isLoading}
                        value="requestImageUnrecognized"
                        onChange={handleCheckboxChange}
                        checked={selectedFeatures.includes(
                          "requestImageUnrecognized"
                        )}
                      />
                    }
                    label={t("status.active").toString()}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                  >
                    <strong>{t("CamerasPage.vmsConfiguration")}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="vmsCameraName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CamerasPage.cameraName")}
                        customProps={{
                          disabled: isLoading
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="vmsIndex"
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        label={t("CamerasPage.vms")}
                        onChange={event => {
                          field.onChange(event.target.value as string);
                        }}
                        disabled={isLoading}
                      >
                        <MenuItem value="">{t("CamerasPage.vmsHint")}</MenuItem>
                        {vms.map(vms => (
                          <MenuItem key={vms.index} value={vms.index}>
                            {vms.url}
                          </MenuItem>
                        ))}
                      </SelectField>
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            hide: !displayCCONETTab,
            value: "CCONET",
            label: t("CamerasPage.cconet"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controller
                    name="cconet.external_id"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CamerasPage.cconetExternalId")}
                        customProps={{
                          disabled: isLoading,
                          required: displayCCONETTab
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            hide: !displayDetectaSpTab,
            value: "DetectaSp",
            label: t("CamerasPage.detectaSp"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controller
                    name="detecta_sp.external_id"
                    control={control}
                    rules={{
                      required:
                        displayDetectaSpTab &&
                        t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CamerasPage.detectaExternalId")}
                        helperText="Camera ID PM"
                        customProps={{
                          disabled: isLoading,
                          required: displayDetectaSpTab
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            hide: !displaySpiaPrfTab,
            value: "SpiaPrf",
            label: t("CamerasPage.spiaPrf"),
            content: (
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Controller
                    name="spia_prf.external_id"
                    control={control}
                    rules={{
                      required:
                        displaySpiaPrfTab && t("form.requiredField").toString()
                    }}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("CamerasPage.spiaExternalId")}
                        helperText="Spia PRF Camera ID"
                        customProps={{
                          disabled: isLoading,
                          required: displaySpiaPrfTab
                        }}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
          }
        ]}
      />
    </FormDialog>
  );
};

export default CamerasFormDialog;
