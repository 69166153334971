import { Box, styled } from "@mui/material";

const PageSectionCard = styled(Box)(({ theme }) => ({
  padding: "27px 24px",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey["200"]}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3)
}));

export default PageSectionCard;
