import {
  Drawer as MuiDrawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC } from "react";
import { ArrowLeft, X } from "react-feather";

type Props = {
  onBackClick?: () => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  testId?: string;
  title?: string;
  wide?: boolean;
};

const Drawer: FC<Props> = ({
  children,
  onBackClick,
  open,
  setOpen,
  testId = "",
  title = "",
  wide = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let width = "100%";
  if (!isMobile) {
    width = wide ? "850px" : "376px";
  }
  return (
    <MuiDrawer
      open={open}
      anchor="right"
      variant="temporary"
      onClose={() => setOpen(false)}
      sx={{ ".MuiPaper-root": { borderRadius: "0px !important" } }}
    >
      <div
        style={{
          width,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          })
        }}
        data-testid={testId}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "-8px",
            padding: "32px 32px 24px 32px",
            justifyContent: "space-between"
          }}
        >
          {onBackClick && (
            <IconButton color="primary" onClick={onBackClick}>
              <ArrowLeft size={24} />
            </IconButton>
          )}
          <Typography variant="h4" align="left">
            {title}
          </Typography>
          <IconButton color="primary" onClick={() => setOpen(false)}>
            <X size={24} />
          </IconButton>
        </div>
        {children}
      </div>
    </MuiDrawer>
  );
};

export default Drawer;
