import {
  Grid,
  Dialog,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Box
} from "@mui/material";
import Pages from "enums/Pages";
import { X } from "react-feather";
import { FC, useState } from "react";
import { EMPTY_VALUE } from "utils/String";
import { format, parseISO } from "date-fns";
import LinkPlate from "components/LinkPlate";
import { useTranslation } from "react-i18next";
import ImageViewer from "components/ImageViewer";
import { CaptureImageDialog } from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";

type Props = {
  open: boolean;
  firstVehicle: CaptureImageDialog | null;
  secondVehicle: CaptureImageDialog | null;
  setOpen: (open: boolean) => void;
};

const CloneImageViewerDialog: FC<Props> = ({
  open,
  setOpen,
  firstVehicle,
  secondVehicle
}) => {
  const { t } = useTranslation();
  const [isFullscreenFirstVehicle, setIsFullscreenFirstVehicle] =
    useState(false);
  const [isFullscreenSecondVehicle, setIsFullscreenSecondVehicle] =
    useState(false);

  const closeDialog = () => {
    if (isFullscreenFirstVehicle || isFullscreenSecondVehicle) {
      setIsFullscreenFirstVehicle(false);
      setIsFullscreenSecondVehicle(false);
    } else {
      setOpen(false);
    }
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={
        fullScreen || isFullscreenFirstVehicle || isFullscreenSecondVehicle
      }
      maxWidth="lg"
      fullWidth
      open={open}
      aria-labelledby="dialog"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeDialog();
        }
      }}
    >
      {firstVehicle && secondVehicle && (
        <>
          <DialogTitle
            sx={{
              padding:
                isFullscreenFirstVehicle || isFullscreenSecondVehicle
                  ? "5px 10px !important"
                  : ""
            }}
          >
            {isFullscreenFirstVehicle || isFullscreenSecondVehicle ? (
              <div />
            ) : (
              t("CloneDashboardPage.cloneDetails")
            )}
            <IconButton aria-label="close" onClick={closeDialog}>
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              padding:
                isFullscreenFirstVehicle || isFullscreenSecondVehicle
                  ? "0 !important"
                  : ""
            }}
          >
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={
                  isFullscreenFirstVehicle || isFullscreenSecondVehicle ? 12 : 6
                }
              >
                <strong>{t("CloneDashboardPage.vehicle")} 1</strong>
                <Box sx={{ mt: 2 }}>
                  <ImageViewer
                    isDialogOpen={open}
                    isFullscreen={isFullscreenFirstVehicle}
                    setIsFullscreen={setIsFullscreenFirstVehicle}
                    img={firstVehicle.image}
                    id={firstVehicle.id}
                    cameraId={firstVehicle.camera}
                    plateCoordinate={firstVehicle.plateCoordinate}
                  />
                </Box>
                <Grid container rowSpacing={1} columnSpacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("MonitoringReportPage.datetime")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.dateTime
                        ? format(
                            parseISO(firstVehicle.dateTime),
                            t("CaptureDialog.dateTimeFormat")
                          )
                        : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.capturePoint")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.equipment
                        ? firstVehicle.equipment
                        : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.cameraId")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.camera ? firstVehicle.camera : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.city")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.city ? firstVehicle.city : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.state")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.state ? firstVehicle.state : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={
                  isFullscreenFirstVehicle || isFullscreenSecondVehicle ? 12 : 6
                }
                sx={{ mb: 2 }}
              >
                <strong>{t("CloneDashboardPage.vehicle")} 2</strong>
                <Box sx={{ mt: 2 }}>
                  <ImageViewer
                    isDialogOpen={open}
                    isFullscreen={isFullscreenSecondVehicle}
                    setIsFullscreen={setIsFullscreenSecondVehicle}
                    img={secondVehicle.image}
                    id={secondVehicle.id}
                    cameraId={secondVehicle.camera}
                    plateCoordinate={secondVehicle.plateCoordinate}
                  />
                </Box>
                <Grid container rowSpacing={1} columnSpacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("MonitoringReportPage.datetime")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {secondVehicle.dateTime
                        ? format(
                            parseISO(secondVehicle.dateTime),
                            t("CaptureDialog.dateTimeFormat")
                          )
                        : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.capturePoint")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {secondVehicle.equipment
                        ? secondVehicle.equipment
                        : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.cameraId")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {firstVehicle.camera ? firstVehicle.camera : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.city")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {secondVehicle.city ? secondVehicle.city : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="left">
                      {t("CloneDashboardPage.state")}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {secondVehicle.state ? secondVehicle.state : EMPTY_VALUE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>

              <Grid container rowSpacing={1} columnSpacing={1} sx={{ ml: 0.2 }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <strong>{t("CloneDashboardPage.sameDatas")}</strong>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    {t("MonitoringReportPage.plate")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {firstVehicle.plate ? (
                      <LinkPlate
                        plate={firstVehicle.plate}
                        onClick={closeDialog}
                        path={Pages.VEHICLE_OVERVIEW}
                      />
                    ) : (
                      EMPTY_VALUE
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    {t("ConvoyReportPage.make")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {firstVehicle.make ? firstVehicle.make : EMPTY_VALUE}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    {t("ConvoyReportPage.model")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {firstVehicle.model ? firstVehicle.model : EMPTY_VALUE}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    {t("ConvoyReportPage.color")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {firstVehicle.color ? firstVehicle.color : EMPTY_VALUE}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CloneImageViewerDialog;
