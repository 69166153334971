import { FC, useEffect, useState } from "react";
import { Link, useTheme, IconButton, TextField } from "@mui/material";
import { Link as RLink } from "react-router-dom";
import { Edit2, Save } from "react-feather";
import { formatPlate, isValidPlate } from "utils/validatePlate";

type LinkPlateParams = {
  plate: string;
  onClick?: () => void;
  path: string;
  onEdit?: (oldPlate: string, newPlate: string) => void;
};

const LinkPlate: FC<LinkPlateParams> = ({ plate, onClick, path, onEdit }) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlate, setEditedPlate] = useState(plate);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (onEdit && isValidPlate(editedPlate)) {
      onEdit(plate, editedPlate);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setEditedPlate(formatPlate(editedPlate));
  }, [editedPlate]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isEditing ? (
        <TextField
          value={editedPlate}
          onChange={e => setEditedPlate(e.target.value)}
          size="small"
          variant="outlined"
          inputProps={{ maxLength: 7 }}
        />
      ) : (
        <Link
          component={RLink}
          onClick={onClick}
          to={`${path}/${plate}`}
          color={theme.palette.secondary.main}
        >
          {plate}
        </Link>
      )}
      {onEdit && (
        <IconButton
          size="small"
          onClick={isEditing ? handleSaveClick : handleEditClick}
          style={{ marginLeft: "4px" }}
          disabled={isEditing && !isValidPlate(editedPlate)}
        >
          {isEditing ? <Save size={16} /> : <Edit2 size={16} />}
        </IconButton>
      )}
    </div>
  );
};

export default LinkPlate;
