import { Box, Checkbox, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import useUserGroupAPI from "api/UserGroupAPI";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";
import useLocationAPI, { Location, LocationPaginated } from "api/LocationAPI";
import DataTable from "components/DataTable";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import InputField from "components/InputField";
import { Search } from "react-feather";

type UserGroupForm = {
  name: string;
  users: string[];
  description: string;
  locations: string[];
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
  userGroup: UserGroupForm;
};

const AddEquipmentDialog: FC<Props> = ({
  open,
  setOpen,
  updateCallerContent,
  userGroup
}) => {
  const { t } = useTranslation();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [search, setSearch] = useState("");
  const [equipments, setEquipments] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([]);
  const { errorHandler } = useErrorHandler();
  const UserGroupAPI = useUserGroupAPI();
  const LocationAPI = useLocationAPI();
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const requestData = useCallback(
    async (searchValue, pageValue) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      let equipments: Location[] = [];
      try {
        if (searchValue) {
          const equipmentResponse = await LocationAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue,
            10,
            true
          );
          const equipmentData = equipmentResponse.data as LocationPaginated;
          setPaginator({
            totalPages: equipmentData.total_pages || 0,
            totalItems: equipmentData.total_count || 0
          });
          equipments = equipmentData.locations;
        } else {
          const equipmentResponse = await LocationAPI.listGroupLocations({
            customerId: sessionUser["customer_id"],
            userGroup: userGroup.name,
            exist: false,
            page: pageValue,
            pageSize: 10
          });
          const equipmentData = equipmentResponse.data as LocationPaginated;
          setPaginator({
            totalPages: equipmentData.total_pages,
            totalItems: equipmentData.total_count
          });
          equipments = equipmentData.locations;
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setEquipments(
          equipments.filter(
            equipment => !userGroup?.locations.includes(equipment.location_name)
          ) || []
        );
        setIsLoading(false);
      }
    },
    [sessionUser, userGroup]
  );

  useEffect(() => {
    requestData(search, 1);
  }, [requestData, open]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, 1);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const addEquipments = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    snackNotification.close();
    if (userGroup) {
      const updateGroup = {
        name: userGroup.name,
        description: userGroup.description,
        users: userGroup.users,
        locations: [...userGroup.locations, ...selectedEquipments]
      };
      try {
        await UserGroupAPI.update({
          customerId: sessionUser["customer_id"],
          data: updateGroup,
          userGroup
        });
        updateCallerContent();
        setOpen(false);
        snackNotification.success(t("EditUserGroupsPage.equipmentsAdded"));
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEquipmentSelect = (locationName: string) => {
    if (locationName === "all") {
      if (selectAll) {
        setSelectedEquipments([]);
      } else {
        setSelectedEquipments(
          equipments.map(equipment => equipment.location_name)
        );
      }
      setSelectAll(!selectAll);
    } else {
      setSelectedEquipments(prevSelectedEquipments => {
        if (prevSelectedEquipments.includes(locationName)) {
          return prevSelectedEquipments.filter(
            equipment => equipment !== locationName
          );
        }
        return [...prevSelectedEquipments, locationName];
      });
    }
  };

  return (
    <FormDialog
      fullScreenDialog
      onConfirm={addEquipments}
      open={open}
      setOpen={setOpen}
      confirmText={t("action.add")}
      title={t("EditUserGroupsPage.addEquipments")}
      isLoading={isLoading}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden"
        }}
      >
        <InputField
          gutter={false}
          customProps={{
            placeholder: t("EditUserGroupsPage.searchUser"),
            onChange: event => setSearch(event.target.value),
            startAdornment: <Search />,
            sx: {
              marginBottom: "16px"
            }
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto"
          }}
        >
          <DataTable
            headers={[
              {
                key: "select",
                label: (
                  <Checkbox
                    checked={selectAll}
                    onChange={() => handleEquipmentSelect("all")}
                    indeterminate={
                      selectedEquipments.length > 0 &&
                      selectedEquipments.length < equipments.length
                    }
                  />
                ),
                noSort: true
              },
              { key: "location_name", label: t("EquipmentsPage.name") }
            ]}
            defaultSort={["location_name", "asc"]}
            data={equipments}
            renderRow={equipment => [
              <Checkbox
                key={`checkbox-${equipment.location_name}`}
                checked={selectedEquipments.includes(equipment.location_name)}
                onChange={() => handleEquipmentSelect(equipment.location_name)}
                disabled={userGroup?.locations.includes(
                  equipment.location_name
                )}
                sx={{
                  padding: 0,
                  "&.MuiCheckbox-root": {
                    marginTop: "-8px",
                    marginBottom: "-8px"
                  }
                }}
              />,
              <Typography
                component="div"
                variant="caption"
                key={equipment.location_name}
              >
                <strong>{equipment.location_name}</strong>
              </Typography>
            ]}
            hideColumnsSm={[1]}
            hideColumnsXs={[1]}
            page={page}
            onPageChange={pageValue => {
              setPage(pageValue);
              requestData(search, pageValue);
            }}
            pageSize={10}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </FormDialog>
  );
};

export default AddEquipmentDialog;
