import {
  Box,
  Grid,
  Skeleton,
  TableRow,
  TableBody,
  TableCell,
  TableHead
} from "@mui/material";
import { FC } from "react";
import Watermark from "components/Watermark";
import { useTranslation } from "react-i18next";
import { RankingPlate } from "api/FlowDashboardAPI";
import { Table, TableContainer } from "components/Table";

interface ClassCaptureProps {
  isFetching: boolean;
  title: string;
  data: RankingPlate[];
}

const TableCaptures: FC<ClassCaptureProps> = ({ data, isFetching, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Watermark>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="h0" component="th">
                      #
                    </TableCell>
                    <TableCell key="h1" component="th">
                      {title}
                    </TableCell>
                    <TableCell key="h2" component="th">
                      {t("MonitoredVehiclesDashboardPage.reasonsQtd")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching &&
                    [0, 1, 2, 3, 4].map(i => (
                      <TableRow key={i}>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={60} />
                        </TableCell>
                      </TableRow>
                    ))}
                  {!isFetching &&
                    data &&
                    data.map((row, idx) => (
                      <TableRow key={`r0${idx}`}>
                        <TableCell component="td">{idx + 1}</TableCell>
                        <TableCell
                          component="td"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {row.plate}
                          {row.icon && (
                            <Box
                              sx={{
                                "& svg": { width: "0.7em", height: "0.7em" },
                                marginLeft: "8px"
                              }}
                            >
                              {row.icon}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell component="td">{row.matches}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Watermark>
        </Grid>
      </Grid>
    </>
  );
};

export default TableCaptures;
