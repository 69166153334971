import {
  Box,
  styled,
  Skeleton,
  TextField,
  Typography,
  Autocomplete
} from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { CacheDatas } from "./CameraDashboardPage";
import { primaryDefaultColors } from "styles/theme";
import PageSection from "components/PageSection/PageSection";
import { dateToString } from "utils/DateFunctions";

const Container = styled(Box)(({ theme }) => ({
  height: 688,
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default
}));

export type HeatmapData = {
  name: string;
  data: {
    x: string;
    y: number | null;
  }[];
};

type Props = {
  heatmapData: HeatmapData[];
  isFetching: boolean;
  options: ApexOptions;
  setCacheData: (cacheData: CacheDatas) => void;
};

const CameraDashboardHeatmap: FC<Props> = ({
  heatmapData,
  isFetching,
  options,
  setCacheData
}) => {
  const { t } = useTranslation();
  const [selectedDatas, setSelectedDatas] = useState<CacheDatas | null>(null);

  useEffect(() => {
    if (selectedDatas) {
      setCacheData(selectedDatas);
    }
  }, [selectedDatas]);

  return (
    <PageSection variant="h5">
      {isFetching && <Skeleton variant="rectangular" height={508} />}
      {!isFetching && (
        <Container>
          <div style={{ width: "40%", marginBottom: "16px" }}>
            <Autocomplete
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              options={
                localStorage.getItem("camera_dashboard")
                  ? (JSON.parse(
                      localStorage.getItem("camera_dashboard") as string
                    ) as CacheDatas[])
                  : []
              }
              getOptionLabel={(option: CacheDatas) =>
                `${option.filter.camera}: (${dateToString(
                  new Date(option.filter.startDate)
                )} - ${dateToString(new Date(option.filter.endDate))}) `
              }
              filterSelectedOptions
              onChange={(event, newValue) => {
                event.preventDefault();
                setSelectedDatas(newValue);
              }}
              noOptionsText={t("form.noOptions")}
              value={selectedDatas as CacheDatas}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={t("CameraDashboardPage.cacheHint")}
                />
              )}
            />
            <Typography variant="caption" align="left" color="primary.light">
              {t("CameraDashboardPage.tooltipCacheDate")}
            </Typography>
          </div>
          {heatmapData.length > 0 && (
            <div
              style={{
                width: "100%"
              }}
            >
              <Chart
                height={564}
                type="heatmap"
                options={options}
                series={heatmapData}
              />
            </div>
          )}
          {heatmapData.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              <Typography variant="h3" color={primaryDefaultColors.light}>
                {t("MonitoredVehiclesDashboardPage.emptyHeatmap")}
              </Typography>
            </div>
          )}
        </Container>
      )}
    </PageSection>
  );
};

export default CameraDashboardHeatmap;
