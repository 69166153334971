import {
  defaultValues,
  FormConvoyDashboardFilter
} from "pages/ConvoyDashboard/ConvoyDashboardFilter";
import { createContext, FC, useContext, useState } from "react";

interface ContextProps {
  setFilterData: (filter: FormConvoyDashboardFilter) => void;
  getFilterData: () => FormConvoyDashboardFilter;
  filterData: FormConvoyDashboardFilter;
}

const ConvoyDashboardFilterContext = createContext<ContextProps>({
  setFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  getFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  filterData: {
    startDate: "",
    endDate: "",
    period: "daily",
    frequency: 2,
    plate: ""
  }
});

export const ConvoyDashboardFilterProvider: FC = ({ children }) => {
  const [filterData, setFilterData] =
    useState<FormConvoyDashboardFilter>(defaultValues);

  const getFilterData = (): FormConvoyDashboardFilter => filterData;

  return (
    <ConvoyDashboardFilterContext.Provider
      value={{
        setFilterData,
        getFilterData,
        filterData
      }}
    >
      {children}
    </ConvoyDashboardFilterContext.Provider>
  );
};

export const useConvoyDashboardFilter = (): ContextProps => {
  const context = useContext(ConvoyDashboardFilterContext);
  if (context === undefined) {
    throw new Error(
      "useFlowDashboardFilter must be used within an FlowDashboardFilterProvider"
    );
  }
  return context;
};
