import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import useIntegrationsAPI from "api/IntegrationsAPI";
import Button from "components/Button";
import InputField from "components/InputField";
import PageSectionCard from "components/PageSection/PageSectionCard";
import snackNotification from "components/SnackNotification";
import TabPanel from "components/TabPanel";
import { useAuth } from "contexts/AuthContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TelegramIntegrationTable from "./TelegramIntegrationTable";
import VmsIntegrationTable from "pages/Settings/Integrations/VmsIntegration/VmsIntegrationTable";
import PageSection from "components/PageSection/PageSection";

type TelegramGroup = {
  enabled: boolean;
  index?: string;
  name: string;
  chatId: string;
};

type CustomerIntegrationsForm = {
  ["cconet"]: {
    enabled: boolean;
  };
  ["detecta_sp"]: {
    enabled: boolean;
  };
  ["spia_prf"]: {
    enabled: boolean;
    ["access_token"]: string;
    key: string;
    name: string;
  };
  telegram: {
    groups: {
      createList: TelegramGroup[];
      updateList: TelegramGroup[];
      deleteList: TelegramGroup[];
    };
  };
};

const defaultValues: CustomerIntegrationsForm = {
  ["cconet"]: {
    enabled: false
  },
  ["detecta_sp"]: {
    enabled: false
  },
  ["spia_prf"]: {
    enabled: false,
    ["access_token"]: "",
    key: "",
    name: ""
  },
  telegram: {
    groups: {
      createList: [],
      updateList: [],
      deleteList: []
    }
  }
};

const groupToGroupDb = (group: TelegramGroup) => ({
  enabled: group.enabled,
  index: group.index,
  name: group.name,
  ["chat_id"]: group.chatId
});

const CustomerIntegrations: FC = () => {
  const { control, formState, handleSubmit, reset, setValue, watch } =
    useForm<CustomerIntegrationsForm>({
      defaultValues,
      mode: "onChange"
    });

  const IntegrationsAPI = useIntegrationsAPI();
  const { sessionUser } = useAuth();
  const [isFetchingData, setFetchingData] = useState(false);
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(async () => {
    const customerId = sessionUser?.["customer_id"];
    if (!customerId) return;
    setFetchingData(true);
    try {
      const responses = await IntegrationsAPI.getAllCustomerIntegrations(
        customerId
      );
      if (responses) {
        const [cconetData, detectaSpData, spiaPrfData, telegramData] =
          responses;
        reset({
          ["cconet"]: cconetData,
          ["detecta_sp"]: detectaSpData,
          ["spia_prf"]: spiaPrfData,
          telegram: {
            groups: {
              createList: [],
              updateList: telegramData.data.map(group => ({
                enabled: group.enabled,
                index: group.index,
                name: group.name,
                chatId: group["chat_id"]
              })),
              deleteList: []
            }
          }
        });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setFetchingData(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const [isSaving, setSaving] = useState(false);

  const { t } = useTranslation();

  const telegramGroups = watch().telegram.groups;

  const onSubmit = async (data: CustomerIntegrationsForm) => {
    const customerId = sessionUser?.["customer_id"];
    if (!customerId) return;
    setSaving(true);
    try {
      const { groups } = data.telegram;
      const responses = await IntegrationsAPI.saveCustomerIntegrations({
        customerId,
        data: {
          ["cconet"]: data["cconet"],
          ["detecta_sp"]: data["detecta_sp"],
          ["spia_prf"]: data["spia_prf"],
          telegram: {
            createList: groups.createList.map(groupToGroupDb),
            updateList: groups.updateList.map(groupToGroupDb),
            deleteList: groups.deleteList.map(groupToGroupDb)
          }
        }
      });
      if (!responses) return;
      const [, , , telegramResponse] = responses;

      if (telegramResponse?.[0]?.success) {
        /*
          Successful response from create request means items were created,
          so handle their returned index (index is their id)
        */

        const indexes = telegramResponse[0].index;

        setValue("telegram.groups", {
          createList: [],
          updateList: [
            ...telegramGroups.updateList,
            ...telegramGroups.createList.map((group, i) => ({
              ...group,
              index: indexes[i]
            }))
          ],
          deleteList: []
        });
      }

      snackNotification.success(t("IntegrationsPage.integrationsSaved"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSaving(false);
    }
  };

  const isSpiaPrfEnabled = watch()?.spia_prf?.enabled ?? false;

  const isSaveDisabled = !formState.isValid || isFetchingData || isSaving;

  return (
    <PageSection title={t("IntegrationsPage.title")} isLoading={isFetchingData}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanel
          tabs={[
            {
              value: "cconet",
              label: t("IntegrationsPage.cconet.label"),
              content: (
                <PageSectionCard>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item sm={12}>
                      <Controller
                        name="cconet.enabled"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                disabled={isFetchingData}
                                ref={field.ref}
                                onChange={field.onChange}
                                checked={Boolean(field.value)}
                              />
                            }
                            label={t(
                              "IntegrationsPage.cconet.enabled"
                            ).toString()}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </PageSectionCard>
              )
            },
            {
              value: "detectaSp",
              label: t("IntegrationsPage.detectaSp.label"),
              content: (
                <PageSectionCard>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item sm={12}>
                      <Controller
                        name="detecta_sp.enabled"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                disabled={isFetchingData}
                                ref={field.ref}
                                onChange={field.onChange}
                                checked={Boolean(field.value)}
                              />
                            }
                            label={t(
                              "IntegrationsPage.detectaSp.enabled"
                            ).toString()}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </PageSectionCard>
              )
            },
            {
              value: "spiaPrf",
              label: t("IntegrationsPage.spiaPrf.label"),
              content: (
                <PageSectionCard>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item sm={12}>
                      <Controller
                        name="spia_prf.enabled"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                disabled={isFetchingData}
                                ref={field.ref}
                                onChange={field.onChange}
                                checked={Boolean(field.value)}
                              />
                            }
                            label={t(
                              "IntegrationsPage.spiaPrf.enabled"
                            ).toString()}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Controller
                        name="spia_prf.name"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputField
                            label={t("IntegrationsPage.spiaPrf.name")}
                            customProps={{
                              autoFocus: true,
                              disabled: !isSpiaPrfEnabled || isFetchingData,
                              required: true,
                              style: { maxWidth: "700px" }
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Controller
                        name="spia_prf.key"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputField
                            label={t("IntegrationsPage.spiaPrf.key")}
                            customProps={{
                              autoFocus: true,
                              disabled: !isSpiaPrfEnabled || isFetchingData,
                              required: true,
                              style: { maxWidth: "700px" }
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Controller
                        name="spia_prf.access_token"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputField
                            label={t("IntegrationsPage.spiaPrf.accessToken")}
                            customProps={{
                              autoFocus: true,
                              disabled: !isSpiaPrfEnabled || isFetchingData,
                              required: true,
                              style: { maxWidth: "700px" }
                            }}
                            field={{ ...field }}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </PageSectionCard>
              )
            },
            {
              value: "telegram",
              label: t("IntegrationsPage.telegram.label"),
              content: (
                <PageSectionCard>
                  <Controller
                    name="telegram.groups"
                    control={control}
                    render={({ field }) => (
                      <TelegramIntegrationTable
                        field={{ ...field }}
                        isLoading={isFetchingData}
                      />
                    )}
                  />
                </PageSectionCard>
              )
            },
            {
              value: "vms",
              label: t("IntegrationsPage.vms.label"),
              content: (
                <PageSectionCard>
                  <VmsIntegrationTable />
                </PageSectionCard>
              )
            }
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            customProps={{
              disabled: isSaveDisabled,
              color: "secondary",
              type: "submit"
            }}
          >
            {t("IntegrationsPage.save")}
          </Button>
        </div>
      </form>
    </PageSection>
  );
};

export default CustomerIntegrations;
