import {
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

type MenuAction = {
  isLoading?: boolean;
  key: string;
  label: ReactNode;
  onClick?: () => void | Promise<void>;
};

type Props = {
  actions?: MenuAction[];
  icon?: ReactNode;
  id: string;
  label?: ReactNode;
  primary?: boolean;
  disabled?: boolean;
};

const MenuButton: FC<Props> = ({
  actions = [],
  icon,
  id,
  label,
  primary = false,
  disabled = false
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleActionClick = async (onClick: MenuAction["onClick"]) => {
    if (onClick) await onClick();
    handleClose();
  };

  return (
    <>
      <Button
        id={id}
        disabled={disabled}
        aria-controls={id}
        aria-haspopup="true"
        color={primary ? "secondary" : "primary"} // "secondary" is used for the main accent color, this is why this seems reversed
        variant={primary ? "contained" : "outlined"}
        startIcon={icon}
        endIcon={anchorEl ? <ChevronUp /> : <ChevronDown />}
        sx={{
          width: isMobile ? "100%" : "auto"
        }}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {label}
      </Button>
      <Menu
        id={`${id}-menu`}
        aria-labelledby={`${id}-menu`}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {actions.map(({ isLoading, key, label, onClick }) => (
          <MenuItem
            key={key}
            disabled={isLoading}
            onClick={async () => handleActionClick(onClick)}
          >
            {isLoading && (
              <ListItemIcon>
                <CircularProgress size={20} />
              </ListItemIcon>
            )}
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
