import { Success } from "api/API";
import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

export type Rule = {
  ["customer_id"]: string;
  ["rule_information"]: {
    ["allowed_plates"]: string[];
    ["start_interval"]: string;
    ["end_interval"]: string;
    ["num_passes"]: number;
    ["monitoring_until"]: string;
    notifications: {
      sms: boolean;
      whatsapp: boolean;
      telegram: boolean;
      ["telegram_groups"]: string[];
      email: boolean;
    };
  };
  ["sound_alert"]: boolean;
  ["popup_alert"]: boolean;
  ["custom_sound_alert"]?: string;
  ["alert_color"]?: string;
  ["persist_alert"]?: boolean;
  owners: string[];
  ["owners_group"]: string[];
};

type Params = {
  ["customer_id"]: string;
};

type UseMonitoringRuleAPIType = {
  create: (data: Rule) => Promise<Success>;
  update: (data: Rule) => Promise<Success>;
  deleteRule: (data: Params) => Promise<Success>;
  getByCustomerId: (customerId: string) => Promise<Rule>;
};

const useMonitoringAPI = (): UseMonitoringRuleAPIType => {
  const API = useAxios();

  const create = async (data: Rule): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_CREATE_RULE,
      {
        ["customer_id"]: data.customer_id,
        rule_information: {
          allowed_plates: data.rule_information.allowed_plates,
          start_interval: data.rule_information.start_interval,
          end_interval: data.rule_information.end_interval,
          num_passes: data.rule_information.num_passes,
          monitoring_until: data.rule_information.monitoring_until,
          notifications: {
            email: data.rule_information.notifications.email,
            whatsapp: data.rule_information.notifications.whatsapp,
            telegram: data.rule_information.notifications.telegram,
            ["telegram_groups"]:
              data.rule_information.notifications.telegram_groups,
            sms: data.rule_information.notifications.sms
          }
        },
        sound_alert: data.sound_alert,
        popup_alert: data.popup_alert,
        custom_sound_alert: data.custom_sound_alert,
        alert_color: data.alert_color,
        persist_alert: data.persist_alert,
        owners: data.owners,
        ["owners_group"]: data.owners_group
      }
    );
    return response.data;
  };

  const update = async (data: Rule): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_UPDATE_RULE,
      {
        ["customer_id"]: data.customer_id,
        rule_information: {
          allowed_plates: data.rule_information.allowed_plates,
          start_interval: data.rule_information.start_interval,
          end_interval: data.rule_information.end_interval,
          num_passes: data.rule_information.num_passes,
          monitoring_until: data.rule_information.monitoring_until,
          notifications: {
            email: data.rule_information.notifications.email,
            whatsapp: data.rule_information.notifications.whatsapp,
            telegram: data.rule_information.notifications.telegram,
            ["telegram_groups"]:
              data.rule_information.notifications.telegram_groups,
            sms: data.rule_information.notifications.sms
          }
        },
        sound_alert: data.sound_alert,
        popup_alert: data.popup_alert,
        custom_sound_alert: data.custom_sound_alert,
        alert_color: data.alert_color,
        persist_alert: data.persist_alert,
        owners: data.owners,
        ["owners_group"]: data.owners_group
      }
    );
    return response.data;
  };

  const deleteRule = async (data: Params): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_DELETE_RULE,
      {
        ["customer_id"]: data.customer_id
      }
    );
    return response.data;
  };

  const getByCustomerId = async (customerId: string): Promise<Rule> => {
    const response: AxiosResponse<Rule> = await API.instance.get(
      APIEndpoints.MONITORING_RULE,
      {
        params: {
          ["customer_id"]: customerId
        }
      }
    );
    return response.data;
  };

  return {
    getByCustomerId,
    create,
    update,
    deleteRule
  };
};

export default useMonitoringAPI;
