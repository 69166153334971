import {
  Box,
  List,
  Link,
  styled,
  Tooltip,
  useTheme,
  //ListItem,
  Typography,
  IconButton,
  //ListItemText,
  useMediaQuery
} from "@mui/material";
import { FC, useState } from "react";
import Languages from "enums/Languages";
import { useTranslation } from "react-i18next";
import UserGuideContent from "./UserGuideContent";
import { useLocale } from "contexts/LocaleContext";
//import userGuideItems from "./userGuideItems.json";
import { ChevronRight, ChevronLeft } from "react-feather";
import userGuidePtBr from "../../assets/userguide_pt-BR.pdf";
import PageSection from "components/PageSection/PageSection";

const sideBarWidth = 230;

const userGuides = {
  [Languages.EN]: userGuidePtBr,
  [Languages.ES]: userGuidePtBr,
  [Languages.PT]: userGuidePtBr
};

const SideMenuBar = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  height: "100%",
  overflowY: "auto",
  position: "absolute",
  width: `${sideBarWidth}px`,
  backgroundColor: theme.palette.background.default,
  borderRight: `1px solid ${theme.palette.grey["200"]}`
}));

const SideMenu = styled(List)(({ theme }) => ({
  "& .MuiListItemText-root span": {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .active .MuiButtonBase-root": {
    borderLeft: `2px solid ${theme.palette.secondary.main}`
  },
  "& .active .MuiButtonBase-root .MuiTypography-root": {
    color: theme.palette.text.primary
  }
}));

/*const SectionName = styled("p")(({ theme }) => ({
  fontSize: "12px",
  paddingLeft: "16px",
  textTransform: "uppercase",
  color: theme.palette.primary.light
}));*/

const MenuHeader = styled("div")(() => ({
  display: "flex",
  padding: "24px 16px 0px 16px",
  justifyContent: "space-between"
}));

export type ItemContent = {
  key?: string;
  label: string;
  content: string;
};

const UserGuideLayout: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useLocale();
  //const guideItems = userGuideItems;
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  //const [currentContent, setCurrentContent] = useState<ItemContent>({
  const [currentContent] = useState<ItemContent>({
    label: "",
    content: ""
    //label: guideItems.userGuideItems[0].items[0].label,
    //content: guideItems.userGuideItems[0].items[0].content
  });

  const userGuide = userGuides[language];

  const handleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        position: "relative"
      }}
    >
      {isOpenMenu && (
        <SideMenuBar
          sx={{
            transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
          }}
        >
          <MenuHeader>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {t("menu.userGuide")}
              </Typography>
              <div>
                <Link
                  href={userGuide}
                  target="_blank"
                  rel="noreferrer"
                  color="secondary"
                >
                  <Typography variant="caption">
                    {t("UserGuidePage.linkPDF").toString()}
                  </Typography>
                </Link>
              </div>
            </div>
            {(isMobile || isTablet) && (
              <Tooltip
                placement="right"
                title={
                  isOpenMenu
                    ? t("DefaultPageLayout.tooltipRetract").toString()
                    : t("DefaultPageLayout.tooltipExpand").toString()
                }
              >
                <IconButton
                  sx={{
                    height: "16px",
                    color: "#768299",
                    marginRight: "-8px"
                  }}
                  size="small"
                  onClick={handleMenu}
                >
                  {isOpenMenu ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
              </Tooltip>
            )}
          </MenuHeader>
          <SideMenu>
            {/*guideItems.userGuideItems.map((item, key) => (
              <div key={key} style={{ margin: "24px 0px 24px 0px" }}>
                <SectionName>{t(item.title)}</SectionName>
                {item.items.map(subitem => (
                  <ListItem
                    key={subitem.key}
                    button
                    onClick={() => {
                      setCurrentContent(subitem);
                    }}
                  >
                    <ListItemText>{t(subitem.label)}</ListItemText>
                  </ListItem>
                ))}
              </div>
                  ))*/}
          </SideMenu>
        </SideMenuBar>
      )}
      <Box
        sx={{
          padding: 3,
          flexGrow: 1,
          width: "1px",
          overflow: "auto",
          backgroundColor: thm => thm.extraColors?.backgroundLight
        }}
        component="main"
      >
        {!isOpenMenu && (isMobile || isTablet) && (
          <div style={{ marginBottom: "32px" }}>
            <Tooltip
              placement="right"
              title={t("DefaultPageLayout.tooltipExpand").toString()}
            >
              <IconButton
                sx={{
                  position: "fixed",
                  color: "#768299",
                  marginTop: "-5px"
                }}
                size="small"
                onClick={handleMenu}
              >
                <ChevronRight />
              </IconButton>
            </Tooltip>
            <PageSection title={t("menu.userGuide")}>
              <UserGuideContent
                content={currentContent}
                isOpenMenu={isOpenMenu}
              />
            </PageSection>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default UserGuideLayout;
