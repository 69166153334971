import { FC, useState } from "react";
import { styled } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorPreview = styled("div")(({ color }: { color: string }) => ({
  width: "24px",
  height: "24px",
  cursor: "pointer",
  marginRight: "3px",
  backgroundColor: color
}));

type ColorPickerProps = {
  color: string;
  colorChanged: (hexColor: string) => void;
};

const ColorPicker: FC<ColorPickerProps> = ({ color, colorChanged }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <ColorPreview color={color} onClick={() => setIsOpen(!isOpen)} />
      {isOpen ? (
        <div
          style={{
            cursor: "default",
            position: "absolute",
            zIndex: 2,
            bottom: 0,
            left: 26
          }}
        >
          <div
            style={{
              zIndex: -1,
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px"
            }}
            onClick={() => setIsOpen(false)}
          />
          <ChromePicker
            color={color}
            onChange={value => colorChanged(value.hex)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
