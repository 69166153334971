import { FC, useState } from "react";
import {
  Box,
  Button,
  Pagination as MPagination,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ArrowLeft, ArrowRight } from "react-feather";
import ItemsPerPage from "./ItemsPerPage";
import GoToPage from "./GoToPage";

export type PaginatorModel = {
  totalPages: number;
  totalItems: number;
};

export const defaultPaginatorModelValues: PaginatorModel = {
  totalPages: 1,
  totalItems: 0
};

type ContainerProps = {
  mode?: "stack" | "row";
};

const Container = styled(Box)<ContainerProps>(({ mode }) => ({
  width: "100%",
  padding: "1rem 0.1rem",
  display: "flex",
  flexDirection: mode && mode === "stack" ? "column" : "row",
  alignItems: "center",
  justifyContent: "space-between"
}));

const ItemsPerPageBox = styled(Box)<ContainerProps>(({ mode }) => ({
  minWidth: 270,
  marginBottom: "1rem",
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: mode && mode === "stack" ? "center" : "flex-start"
}));

const NavigatorBox = styled(Box)(() => ({
  padding: "0 1rem",
  marginBottom: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const GoToPageBox = styled(Box)<ContainerProps>(({ mode }) => ({
  minWidth: 270,
  marginBottom: "1rem",
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: mode && mode === "stack" ? "center" : "flex-end"
}));

const StyledPagination = styled(MPagination)(({ theme }) => ({
  button: {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: "12px",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  }
}));

const StyledIconButton = styled(Button)(() => ({
  padding: "8px 0",
  minWidth: "44px"
}));

type Props = {
  totalPages?: number;
  initialPage?: number;
  totalItems?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (itemsPerPage: number) => void;
  disabled?: boolean;
  showItemsPerPage?: boolean;
};

const Paginator: FC<Props> = ({
  initialPage = 1,
  totalPages = 1,
  pageSize = 10,
  onPageChange,
  onPageSizeChange,
  disabled,
  showItemsPerPage = true
}) => {
  if (totalPages < 1) {
    return <></>;
  }

  const [page, setPage] = useState(initialPage);

  function nextPage() {
    if (page >= totalPages) return;

    const newPage = page + 1;
    setPage(newPage);
    if (onPageChange) onPageChange(newPage);
  }

  function previousPage() {
    if (page <= 1) return;

    const newPage = page - 1;
    setPage(newPage);
    if (onPageChange) onPageChange(newPage);
  }

  function handlePageChange(pageValue: number) {
    setPage(pageValue);
    if (onPageChange) onPageChange(pageValue);
  }

  function handlePageSizeChange(items: number) {
    setPage(1);
    if (onPageSizeChange) onPageSizeChange(items);
  }

  const theme = useTheme();
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"));
  const mode = isSmallerThanLg ? "stack" : "row";

  return (
    <Container mode={mode}>
      <ItemsPerPageBox mode={mode}>
        {showItemsPerPage && (
          <ItemsPerPage
            pageSize={pageSize}
            onChange={handlePageSizeChange}
            disabled={disabled}
          />
        )}
      </ItemsPerPageBox>
      <NavigatorBox>
        <StyledIconButton
          color="primary"
          onClick={previousPage}
          disabled={page === 1 || disabled}
        >
          <ArrowLeft />
        </StyledIconButton>
        <StyledPagination
          hidePrevButton
          hideNextButton
          shape="rounded"
          disabled={disabled}
          color="primary"
          page={page}
          defaultPage={page}
          count={totalPages}
          siblingCount={isSmallerThanLg ? 1 : 2}
          onChange={(_event, value) => {
            handlePageChange(value);
          }}
          data-testid="paginator"
        />
        <StyledIconButton
          color="primary"
          onClick={nextPage}
          disabled={page === totalPages || disabled}
        >
          <ArrowRight />
        </StyledIconButton>
      </NavigatorBox>
      <GoToPageBox mode={mode}>
        <GoToPage
          initialPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          disabled={disabled}
        />
      </GoToPageBox>
    </Container>
  );
};

export default Paginator;
