import {
  defaultValues,
  FormCloneDashboardFilter
} from "pages/CloneDashboard/CloneDashboardFilter";
import { createContext, FC, useContext, useState } from "react";

interface ContextProps {
  setFilterData: (filter: FormCloneDashboardFilter) => void;
  getFilterData: () => FormCloneDashboardFilter;
  filterData: FormCloneDashboardFilter;
  totalSuspectVehicles: number;
  setTotalSuspectVehicles: (total: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isLoadingCameras: boolean;
  setIsLoadingCameras: (isLoading: boolean) => void;
  cameras: string[];
  setCameras: (cameras: string[]) => void;
}

const CloneDashboardFilterContext = createContext<ContextProps>({
  setFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  getFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  filterData: {
    plate: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    cameras: undefined,
    equipments: undefined
  },
  totalSuspectVehicles: 0,
  setTotalSuspectVehicles() {
    throw new Error("setTotalSuspectVehicles must be defined.");
  },
  setIsLoading() {
    throw new Error("setIsLoading must be defined.");
  },
  isLoading: true,
  setIsLoadingCameras() {
    throw new Error("setIsLoadingCameras must be defined.");
  },
  isLoadingCameras: true,
  setCameras() {
    throw new Error("setCameras must be defined.");
  },
  cameras: []
});

export const CloneDashboardFilterProvider: FC = ({ children }) => {
  const [filterData, setFilterData] =
    useState<FormCloneDashboardFilter>(defaultValues);
  const [totalSuspectVehicles, setTotalSuspectVehicles] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingCameras, setIsLoadingCameras] = useState<boolean>(true);
  const [cameras, setCameras] = useState<string[]>([]);

  const getFilterData = (): FormCloneDashboardFilter => filterData;

  return (
    <CloneDashboardFilterContext.Provider
      value={{
        setFilterData,
        getFilterData,
        filterData,
        setTotalSuspectVehicles,
        totalSuspectVehicles,
        isLoading,
        setIsLoading,
        cameras,
        setCameras,
        isLoadingCameras,
        setIsLoadingCameras
      }}
    >
      {children}
    </CloneDashboardFilterContext.Provider>
  );
};

export const useCloneDashboardFilter = (): ContextProps => {
  const context = useContext(CloneDashboardFilterContext);
  if (context === undefined) {
    throw new Error(
      "useFlowDashboardFilter must be used within an FlowDashboardFilterProvider"
    );
  }
  return context;
};
