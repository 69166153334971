import {
  Box,
  Grid,
  Dialog,
  Divider,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import { X } from "react-feather";
import Button from "components/Button";
import { EMPTY_VALUE } from "utils/String";
import Paginator, {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import SelectCard from "components/SelectCard";
import { useAuth } from "contexts/AuthContext";
import { format, parseISO, set } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { FC, useState, useCallback, useEffect } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { dateTimeToString } from "utils/DateFunctions";
import useCaptureReportAPI, { CaptureReport } from "api/CaptureReportAPI";
import { InsertImagePayload } from "components/RichTextEditor/RichTextEditorImage/ImagePlugin";

type FormCaptures = {
  plate: string;
  startTime: string | Date | null;
  endTime: string | Date | null;
  startDate: string | Date | null;
  endDate: string | Date | null;
};

const defaultValues: FormCaptures = {
  plate: "",
  startDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
  endDate: new Date(),
  startTime: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
  endTime: new Date()
};

type AddCaptureDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCaptureSelected: (captureSelected: InsertImagePayload) => void;
};

const AddCaptureDialog: FC<AddCaptureDialogProps> = ({
  open,
  setOpen,
  onCaptureSelected
}) => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const { errorHandler } = useErrorHandler();
  const CaptureReportAPI = useCaptureReportAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<CaptureReport>({
    id: "",
    ["date_capture"]: "",
    camera: "",
    side: "",
    direction: "",
    street: "",
    number: "",
    city: "",
    state: "",
    zip_code: "",
    classifier_list: "",
    ["serial_number"]: "",
    equipment: "",
    plate: "",
    image_link: "",
    ["vehicle_make"]: "",
    ["vehicle_model"]: "",
    ["vehicle_color"]: "",
    ["vehicle_class"]: "",
    ["vehicle_year"]: "",
    latitude: "",
    longitude: "",
    ["ocr_total_time"]: 0,
    ["plate_color"]: 0,
    ["plate_coordinate"]: ""
  });
  const [filter, setFilter] = useState<FormCaptures>(defaultValues);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [captureReports, setCaptureReports] = useState<CaptureReport[]>([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { formState, control, handleSubmit, reset } = useForm<FormCaptures>({
    mode: "onChange",
    defaultValues
  });

  const requestData = useCallback(
    async (data: FormCaptures, pageValue: number, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setIsLoading(true);
      try {
        if (data) {
          const reportResponse = await CaptureReportAPI.list({
            ["customer_id"]: customerId,
            ["basic_filter"]: {
              ["initial_date"]: data.startDate
                ? dateTimeToString(
                    data.startDate as Date,
                    data.startTime as Date
                  )
                : undefined,
              ["final_date"]: data.endDate
                ? dateTimeToString(data.endDate as Date, data.endTime as Date)
                : undefined,
              plate: data.plate
            },
            page: pageValue,
            ["page_size"]: pageSizeValue
          });
          setCaptureReports(reportResponse.registers.items || []);
          setPaginator({
            totalPages: reportResponse.registers.total_pages || 0,
            totalItems: reportResponse.registers.total_items || 0
          });
          setPage(reportResponse.registers.page || 1);
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  const closeDialog = () => {
    setOpen(false);
    reset(defaultValues);
    setFilter(defaultValues);
    setSelectedCard({
      id: "",
      ["date_capture"]: "",
      camera: "",
      side: "",
      direction: "",
      street: "",
      number: "",
      city: "",
      state: "",
      zip_code: "",
      classifier_list: "",
      ["serial_number"]: "",
      equipment: "",
      plate: "",
      image_link: "",
      ["vehicle_make"]: "",
      ["vehicle_model"]: "",
      ["vehicle_color"]: "",
      ["vehicle_class"]: "",
      ["vehicle_year"]: "",
      latitude: "",
      longitude: "",
      ["ocr_total_time"]: 0,
      ["plate_color"]: 0,
      ["plate_coordinate"]: ""
    });
    setCaptureReports([]);
    setPage(1);
    setPaginator(defaultPaginatorModelValues);
  };

  const onConfirm = () => {
    onCaptureSelected({
      altText: `
      
      ${t("ImageDialog.make")}: ${selectedCard.vehicle_make || EMPTY_VALUE}.
      ${t("ImageDialog.model")}: ${selectedCard.vehicle_model || EMPTY_VALUE}.
      ${t("ImageDialog.color")}: ${selectedCard.vehicle_color || EMPTY_VALUE}.
      ${t("ForensicsReportPage.datetime")}: ${
        selectedCard.date_capture
          ? format(
              parseISO(selectedCard.date_capture),
              t("CaptureDialog.dateTimeFormat")
            )
          : EMPTY_VALUE
      }.`,
      src: selectedCard.image_link
    });
    closeDialog();
  };

  const clearForm = () => {
    reset(defaultValues);
    setFilter(defaultValues);
  };

  useEffect(() => {
    if (filter.plate !== "") {
      requestData(filter, page, 25);
    }
  }, [filter]);

  const onSubmit = (data: FormCaptures) => {
    const newData = { ...data };

    newData.startTime = newData.startTime ? new Date(newData.startTime) : null;
    newData.endTime = newData.endTime ? new Date(newData.endTime) : null;
    newData.startDate = newData.startDate ? new Date(newData.startDate) : null;
    newData.endDate = newData.endDate ? new Date(newData.endDate) : null;

    setFilter(newData);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      fullScreen={fullScreen}
      aria-labelledby="dialog"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeDialog();
        }
      }}
    >
      <DialogTitle>
        {t("ForensicsReportPage.importCaptures")}
        <IconButton aria-label="close" onClick={closeDialog}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    uppercase
                    label={`${t("CaptureReportPage.plate")} *`}
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    label={t("CaptureReportPage.startDateTime")}
                    gutter={false}
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: "25px" }}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    gutter={false}
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    label={t("CaptureReportPage.endDateTime")}
                    gutter={false}
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: "25px" }}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    gutter={false}
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                customProps={{
                  color: "primary",
                  sx: { width: "100%" },
                  variant: "outlined",
                  onClick: clearForm
                }}
              >
                {t("action.clear")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                customProps={{
                  sx: { width: "100%" },
                  disabled: !formState.isValid || isLoading,
                  type: "button",
                  onClick: handleSubmit(onSubmit)
                }}
              >
                {t("action.filter")}
              </Button>
            </Grid>
            {isLoading ? (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress size={32} />
                  <Typography variant="body1">
                    {t("waitState.loading")}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <>
                {captureReports.length === 0 ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      sx={{ mt: 2 }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ mt: 2, mb: -1, color: "#959799" }}
                      >
                        {t("ForensicsReportPage.emptyTitleCard")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Typography variant="h5" sx={{ color: "#959799" }}>
                        {t("ForensicsReportPage.emptySubtitleCard")}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Typography variant="h5">
                        {t("ForensicsReportPage.selectCaptures")}
                      </Typography>
                    </Grid>
                    {captureReports.map(capture => (
                      <Grid item xs={6} key={capture.id}>
                        <SelectCard
                          data={capture}
                          isLoading={isLoading}
                          selectedCard={selectedCard}
                          setSelectedCard={setSelectedCard}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          </Grid>
        </div>
        {paginator.totalPages > 1 && (
          <Box
            sx={{
              display: "flex",
              marginTop: "32px",
              justifyContent: "center"
            }}
          >
            <Paginator
              initialPage={page}
              totalPages={paginator.totalPages}
              onPageChange={pageValue => requestData(filter, pageValue, 25)}
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          customProps={{
            color: "primary",
            variant: "outlined",
            onClick: closeDialog,
            type: "button"
          }}
        >
          {t("action.cancel")}
        </Button>
        <Button
          customProps={{
            disabled: selectedCard.id === "" || isLoading,
            color: "secondary",
            onClick: onConfirm
          }}
        >
          {t("action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCaptureDialog;
