import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "api/API";
import useAxios from "hooks/useAxios";

export type RouteData = {
  ["average_speed"]: number;
  ["average_time"]: number;
  ["fastest_plate"]: string;
  ["final_camera"]: string;
  ["final_location"]: string;
  ["final_position"]: string;
  ["initial_camera"]: string;
  ["initial_location"]: string;
  ["initial_position"]: string;
  ["legal_speed"]: number;
  ["maximum_speed"]: number;
  ["maximum_time"]: number;
  ["minimum_speed"]: number;
  ["minimum_time"]: number;
  ["route_name"]: string;
  ["slowest_plate"]: string;
  ["vehicle_quantity"]: number;
};

export type MonitoredRoute = {
  ["initial_location"]: string;
  ["final_camera"]: string;
  ["initial_camera"]: string;
  distance: number;
  ["legal_speed"]: number;
  ["customer_id"]: string;
  ["final_location"]: string;
  ["route_name"]: string;
};

export type RoutePaginated = {
  routes: MonitoredRoute[];
  page: number;
  ["total_count"]: number;
  ["total_pages"]: number;
};

type ListPaginatedParams = {
  customerId: string;
  page: number;
  pageSize?: number;
};

type ListAllParams = {
  customerId: string;
};

type SearchParams = {
  customerId: string;
  name: string;
  page?: number;
  pageSize?: number;
};

type GetByNameParams = {
  customerId: string;
  routeName: string;
};

type DeleteParams = {
  customerId: string;
  routeName: string;
  cameras: string[];
};

type CreateParams = {
  customerId: string;
  routeName: string;
  cameras: string[];
  distance: number[];
  legalSpeed: number[];
};

type UpdateParams = {
  customerId: string;
  routeName: string;
  oldRouteName: string | undefined;
  initialCamera: string;
  oldInitialCamera: string | undefined;
  finalCamera: string;
  oldFinalCamera: string | undefined;
  distance: number | undefined;
  legalSpeed: number;
};

type LastDataParams = {
  customerId: string;
};

type UseMonitoredRouteAPIType = {
  listPaginated: (
    param: ListPaginatedParams
  ) => Promise<NestedData<RoutePaginated>>;
  listAll: (param: ListAllParams) => Promise<NestedData<MonitoredRoute[]>>;
  search: (
    param: SearchParams
  ) => Promise<NestedData<RoutePaginated | MonitoredRoute[]>>;
  getByName: (param: GetByNameParams) => Promise<MonitoredRoute>;
  deleteByName: (param: DeleteParams) => Promise<Success>;
  create: (param: CreateParams) => Promise<Success>;
  update: (param: UpdateParams) => Promise<Success>;
  lastData: (data: LastDataParams) => Promise<NestedData<RouteData[]>>;
};

const useMonitoredRouteAPI = (): UseMonitoredRouteAPIType => {
  const API = useAxios();

  const listPaginated = async ({
    customerId,
    page,
    pageSize
  }: ListPaginatedParams): Promise<NestedData<RoutePaginated>> => {
    const response: AxiosResponse<NestedData<RoutePaginated>> =
      await API.instance.post(APIEndpoints.ROUTE_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const listAll = async ({
    customerId
  }: ListAllParams): Promise<NestedData<MonitoredRoute[]>> => {
    const response: AxiosResponse<NestedData<MonitoredRoute[]>> =
      await API.instance.post(APIEndpoints.ROUTE_LIST, {
        ["customer_id"]: customerId,
        page: -1
      });
    return response.data;
  };

  const search = async ({
    customerId,
    name,
    page = -1,
    pageSize
  }: SearchParams): Promise<NestedData<RoutePaginated | MonitoredRoute[]>> => {
    const response: AxiosResponse<
      NestedData<RoutePaginated | MonitoredRoute[]>
    > = await API.instance.get(APIEndpoints.ROUTE_PARTIAL, {
      params: {
        ["customer_id"]: customerId,
        ["route_name"]: name,
        page,
        ["page_size"]: pageSize
      }
    });
    return response.data;
  };

  const getByName = async ({
    customerId,
    routeName
  }: GetByNameParams): Promise<MonitoredRoute> => {
    const response: AxiosResponse<MonitoredRoute> = await API.instance.get(
      APIEndpoints.ROUTE,
      {
        params: {
          ["customer_id"]: customerId,
          ["route_name"]: routeName
        }
      }
    );
    return response.data;
  };

  const deleteByName = async ({
    customerId,
    routeName,
    cameras
  }: DeleteParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.ROUTE_DELETE,
      {
        ["customer_id"]: customerId,
        ["route_name"]: routeName,
        cameras
      }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.ROUTE_CREATE,
      {
        ["route_data"]: {
          ["customer_id"]: data.customerId,
          ["route_name"]: data.routeName,
          cameras: data.cameras,
          distance: data.distance,
          ["legal_speed"]: data.legalSpeed
        }
      }
    );

    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.ROUTE_UPDATE,
      {
        ["route_data"]: {
          ["customer_id"]: data.customerId,
          ["route_name"]: data.routeName,
          ["old_route_name"]: data.oldRouteName,
          ["initial_camera"]: data.initialCamera,
          ["old_initial_camera"]: data.oldInitialCamera,
          ["final_camera"]: data.finalCamera,
          ["old_final_camera"]: data.oldFinalCamera,
          distance: data.distance,
          ["legal_speed"]: data.legalSpeed
        }
      }
    );

    return response.data;
  };

  const lastData = async (
    data: LastDataParams
  ): Promise<NestedData<RouteData[]>> => {
    const response: AxiosResponse<NestedData<RouteData[]>> =
      await API.instance.get(APIEndpoints.ROUTE_LAST_DATA, {
        params: {
          ["customer_id"]: data.customerId
        }
      });

    return response.data;
  };

  return {
    listPaginated,
    listAll,
    search,
    getByName,
    create,
    update,
    deleteByName,
    lastData
  };
};

export default useMonitoredRouteAPI;
