import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Autocomplete,
  useTheme,
  Divider
} from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import InputNumber from "components/InputNumber";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterConvoys = {
  plate: string;
  equipment: string;
  referenceDate: string | Date;
  referenceTime: string | Date;
  maxGapMinutes: number;
};

type Props = {
  open: boolean;
  equipments: string[];
  setOpen: (isOpen: boolean) => void;
  filter: FormFilterConvoys;
  defaultValues: FormFilterConvoys;
  onFilter: (data: FormFilterConvoys) => void;
  onClear?: () => void;
};

const ConvoyReportFilter: FC<Props> = ({
  open,
  setOpen,
  filter,
  defaultValues,
  onFilter,
  onClear,
  equipments
}) => {
  const [equipmentSelected, setEquipmentSelected] = useState<string>("");
  const { t } = useTranslation();
  const theme = useTheme();

  const { control, handleSubmit, reset } = useForm<FormFilterConvoys>({
    mode: "onChange",
    defaultValues: filter
  });

  useEffect(() => {
    reset(filter);
  }, [filter]);

  const clearForm = () => {
    setEquipmentSelected("");
    reset(defaultValues);
    if (onClear) onClear();
  };

  const onSubmit = async (data: FormFilterConvoys) => {
    const newData = { ...data };

    newData.equipment = equipmentSelected;
    newData.referenceDate = new Date(newData.referenceDate);
    newData.referenceTime = new Date(newData.referenceTime);

    onFilter(newData);
    setOpen(false);
  };

  return (
    <Drawer open={open} setOpen={setOpen} title={t("ConvoyReportPage.filter")}>
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                rules={{
                  required: t("form.requiredField").toString()
                }}
                name="plate"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    uppercase
                    label={t("ConvoyReportPage.plate")}
                    gutter={false}
                    customProps={{
                      autoFocus: true,
                      required: true,
                      inputProps: {
                        maxLength: 7
                      }
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
              <Typography variant="caption" align="left" color="primary.light">
                {t("DefaultPageLayout.tooltipPlate")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel required>{t("ConvoyReportPage.equipment")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                noOptionsText={t("form.noOptions")}
                options={equipments}
                getOptionLabel={option => option}
                value={equipmentSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  if (value) setEquipmentSelected(value);
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      required
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="referenceDate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    label={t("ConvoyReportPage.referenceDateTime")}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="referenceTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="maxGapMinutes"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    min={1}
                    max={120}
                    label={t("ConvoyReportPage.maxGapMinutes")}
                    helperText={`1 - 120 ${t("time.minutes")}`}
                    customProps={{
                      required: true,
                      endAdornment: (
                        <div style={{ paddingRight: theme.spacing(1) }}>
                          {t("time.minutes")}
                        </div>
                      )
                    }}
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default ConvoyReportFilter;
