import { Box, Grid, Typography, IconButton } from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { Plus, Trash } from "react-feather";
import { useModal } from "contexts/ModalContext";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import React, { FC, useCallback, useEffect, useState } from "react";
import FormDialog from "components/FormDialog";

type Service = {
  title: string;
  value: string;
};

type InvoiceForm = {
  customer: string;
  paymentStatus: string;
  dueDate: string;
  discount: string;
  description: string;
  totalValue: string;
};

const defaultValues: InvoiceForm = {
  customer: "",
  paymentStatus: "",
  dueDate: "",
  discount: "",
  description: "",
  totalValue: ""
};

async function load(): Promise<InvoiceForm> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        customer: "PRF",
        paymentStatus: "Em aberto",
        dueDate: "26/01/2022",
        discount: "R$ 2.000,00",
        description: "Lorem Ipsum",
        totalValue: "R$ 5.701,00"
      });
    }, 500);
  });
}

type Props = {
  open: boolean;
  customerId: string;
  setOpen: (open: boolean) => void;
};

const InvoiceFormDialog: FC<Props> = ({ customerId, open, setOpen }) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { sessionUser } = useAuth();
  const isCreating = customerId === "";
  const [services, setServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoiceDetails, setInvoiceDetails] =
    useState<InvoiceForm>(defaultValues);
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<InvoiceForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const response = await load();
      setServices([
        {
          title: "Cadastro do Plano Business",
          value: "R$ 2.567,00"
        },
        {
          title: "4.374 Capturas",
          value: "R$ 2.567,00"
        },
        {
          title: "API SaaS",
          value: "R$ 2.567,00"
        }
      ]);
      setValue("customer", response.customer);
      setValue("paymentStatus", response.paymentStatus);
      setValue("dueDate", response.dueDate);
      setValue("discount", response.discount);
      setValue("description", response.description);
      setValue("totalValue", response.totalValue);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t]);

  useEffect(() => {
    if (open && !isCreating) {
      requestData();
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setServices([]);
      setInvoiceDetails(defaultValues);
      reset(defaultValues);
    }
  }, [open]);

  const addNewService = () => {
    setServices(prevState => [...prevState, { title: "", value: "" }]);
  };

  const removeService = (index: number) => {
    try {
      setIsLoading(true);
      services.splice(index, 1);
      setServices(prevState => [...prevState]);
    } catch (error) {
      errorHandler({ error });
      setIsLoading(false);
    }
  };

  const handleRemove = (index: number) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeService(index),
      confirmText: t("action.confirm"),
      title: t("InvoicePage.removeInvoice"),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const onSubmit = async (_data: InvoiceForm) => {
    setIsLoading(true);
    try {
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("InvoicePage.createANewInvoice")
          : t("InvoicePage.invoiceDetails")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="customer"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("InvoicePage.customer")}
                customProps={{
                  value: invoiceDetails.customer
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="paymentStatus"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("InvoicePage.paymentStatus")}
                customProps={{
                  value: invoiceDetails.paymentStatus
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="dueDate"
            control={control}
            render={({ field, fieldState }) => (
              <DatePickerField
                gutter
                field={{ ...field }}
                fieldState={fieldState}
                label={t("InvoicePage.dueDate")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="body1" align="left" color="textSecondary">
              <strong>{t("InvoicePage.serviceDescription")}</strong>
            </Typography>
          </Box>
        </Grid>
        {services.map((service: Service, index) => (
          <React.Fragment key={index}>
            <Grid item xs={8}>
              <InputField
                gutter={false}
                customProps={{
                  onBlur(event) {
                    services[index].title = event.target.value;
                    setServices(prevState => [...prevState]);
                  },
                  defaultValue: service.title
                }}
              />
            </Grid>
            <Grid item sm={2.5}>
              <InputField
                gutter={false}
                customProps={{
                  onBlur(event) {
                    services[index].value = event.target.value;
                    setServices(prevState => [...prevState]);
                  },
                  defaultValue: service.value
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <IconButton onClick={() => handleRemove(index)}>
                <Trash size={24} />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12} sm={12}>
          <Button
            customProps={{
              variant: "text",
              startIcon: <Plus size={16} />,
              onClick: () => addNewService()
            }}
          >
            {t("InvoicePage.addNewService")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="discount"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("InvoicePage.discount")}
                customProps={{
                  value: invoiceDetails.discount
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("InvoicePage.discountDescription")}
                customProps={{
                  value: invoiceDetails.description
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="totalValue"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("InvoicePage.totalValue")}
                customProps={{
                  disabled: !isCreating,
                  readOnly: !isCreating,
                  value: invoiceDetails.totalValue
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default InvoiceFormDialog;
