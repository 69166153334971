import {
  Box,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { InnerPageMenu } from "./SideMenuBar";
import { useTranslation } from "react-i18next";
import { FC, ReactNode, useEffect, useState } from "react";
import { Link as RLink, useLocation } from "react-router-dom";
import Breadcrumb from "layouts/Breadcrumb";

const sideBarWidth = 230;

const SideMenuBar = styled(Box)(({ theme }) => ({
  width: `${sideBarWidth}px`,
  flexShrink: 0,
  backgroundColor: theme.palette.background.default,
  borderRight: `1px solid ${theme.palette.grey["200"]}`
}));

const SideMenu = styled(List)(({ theme }) => ({
  svg: {
    color: theme.palette.primary.light
  },
  "& .MuiListItemText-root span": {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .active .MuiButtonBase-root": {
    borderLeft: `2px solid ${theme.palette.secondary.main}`
  },
  "& .active .MuiButtonBase-root .MuiTypography-root": {
    color: theme.palette.text.primary
  }
}));

const SectionName = styled("p")(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: "12px",
  textTransform: "uppercase",
  paddingLeft: "16px",
  marginTop: theme.spacing(4)
}));

type InnerPageLayoutProps = {
  children: ReactNode;
  menu?: InnerPageMenu;
  isLoadingContent?: boolean;
};

const InnerPageLayout: FC<InnerPageLayoutProps> = ({
  children,
  menu,
  isLoadingContent
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const locations = useLocation();
  const menuItems = menu?.map(m => m.items).flat(1);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedKey, setSelectedKey] = useState(
    menuItems?.find(_item => locations.pathname.startsWith(_item.path))?.key
  );

  useEffect(() => {
    setSelectedKey(
      menuItems?.find(_item => locations.pathname.startsWith(_item.path))?.key
    );
  }, [locations]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1, position: "relative" }}>
      {menu && (
        <SideMenuBar>
          <SideMenu>
            {menu.map((m, key) => (
              <div key={key}>
                <SectionName>{t(m.title)}</SectionName>
                {m.items.map(item => (
                  <Link
                    key={item.key}
                    component={RLink}
                    to={item.path}
                    className={selectedKey === item.key ? "active" : ""}
                  >
                    <ListItem button>
                      <ListItemText>{t(item.label)}</ListItemText>
                    </ListItem>
                  </Link>
                ))}
              </div>
            ))}
          </SideMenu>
        </SideMenuBar>
      )}
      <Box
        sx={{
          flexGrow: 1,
          padding: 3,
          backgroundColor: thm => thm.extraColors?.backgroundLight,
          overflow: "auto",
          width: "1px"
        }}
        component="main"
      >
        {isLoadingContent && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoadingContent && (
          <>
            {(isMobile || isTablet) && (
              <div style={{ marginBottom: "24px" }}>
                <Breadcrumb />
              </div>
            )}
            {children}
          </>
        )}
      </Box>
    </Box>
  );
};

export default InnerPageLayout;
