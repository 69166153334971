import { createContext, FC, useCallback, useContext, useState } from "react";
import { Coords } from "google-map-react";

type EquipmentMapContextValue = {
  center?: Coords;
  setCenter: (() => void) | ((center: Coords) => void);
  requestGeolocation: () => void;
  zoom?: number;
  setZoom: (() => void) | ((zoom: number) => void);
};

const EquipmentMapContext = createContext<EquipmentMapContextValue>({
  center: undefined,
  setCenter() {
    throw new Error("setCenter must be defined.");
  },
  requestGeolocation() {
    throw new Error("requestGeolocation must be defined.");
  },
  zoom: undefined,
  setZoom() {
    throw new Error("setZoom must be defined.");
  }
});

export const EquipmentMapProvider: FC = ({ children }) => {
  const [center, setCenter] = useState<Coords | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);

  const requestGeolocation = useCallback(() => {
    if (center) return;
    navigator.geolocation.getCurrentPosition(pos => {
      setCenter({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      });
    });
  }, [navigator]);

  return (
    <EquipmentMapContext.Provider
      value={{
        center,
        setCenter,
        requestGeolocation,
        zoom,
        setZoom
      }}
    >
      {children}
    </EquipmentMapContext.Provider>
  );
};

export const useEquipmentMap = (): EquipmentMapContextValue => {
  const context = useContext(EquipmentMapContext);
  if (context === undefined) {
    throw new Error(
      "useEquipmentMap must be used within an EquipmentMapProvider"
    );
  }
  return context;
};
