import { ChangeEvent, FC, useEffect, useState } from "react";
import { MinusCircle } from "react-feather";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import Button from "components/Button";
import InputField from "components/InputField";
import DataTable from "components/DataTable";
import DataTableActions from "components/DataTableActions";

type Item = {
  enabled: boolean;
  index?: string; // uuid, undefined when not saved in db
  name: string;
  chatId: string;
};

type Value = {
  createList: Item[];
  updateList: Item[];
  deleteList: Item[];
};

const defaultValue: Value = {
  createList: [],
  updateList: [],
  deleteList: []
};

type Props = {
  field: ControllerRenderProps<FieldValues, string>;
  isLoading?: boolean;
};

const TelegramIntegrationTable: FC<Props> = ({ field, isLoading = false }) => {
  const fieldValue: Value = field?.value ?? defaultValue;

  const [name, setName] = useState("");
  const [chatId, setChatId] = useState("");

  const { t } = useTranslation();

  const [nameOrChatIdExists, setNameOrChatIdExists] = useState(false);

  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    setItems([...fieldValue.createList, ...fieldValue.updateList]);
  }, [fieldValue]);

  const itemsToCreateAndUpdate: Item[] = [
    ...fieldValue.createList,
    ...fieldValue.updateList
  ];

  useEffect(() => {
    setNameOrChatIdExists(
      itemsToCreateAndUpdate.some(
        (item: Item) => name === item.name || chatId === item.chatId
      )
    );
  }, [field.value, name, chatId]);

  const isAddDisabled =
    isLoading || !name?.trim() || !chatId?.trim() || nameOrChatIdExists;

  const handleAddItem = () => {
    field.onChange({
      ...fieldValue,
      createList: [...fieldValue.createList, { enabled: true, name, chatId }]
    });
  };

  const handleToggleItem = (
    event: ChangeEvent<HTMLInputElement>,
    item: Item
  ) => {
    const newValue = { ...fieldValue };
    let foundItem = newValue.createList.find(
      (nv: Item) => nv.name === item.name
    );
    if (!foundItem) {
      foundItem = newValue.updateList.find((nv: Item) => nv.name === item.name);
    }
    if (!foundItem) return;
    foundItem.enabled = event.target.checked;
    field.onChange(newValue);
  };

  const handleRemoveItem = (item: Item) => {
    const newValue: Value = { ...fieldValue };
    if (!item.index) {
      // if not saved in db, just remove it from the list outright
      field.onChange({
        ...newValue,
        createList: [
          ...newValue.createList.filter((v: Item) => v.name !== item.name)
        ]
      });
      return;
    }
    // if saved in db, mark for removal
    field.onChange({
      ...newValue,
      updateList: [
        ...newValue.updateList.filter((v: Item) => v.name !== item.name)
      ],
      deleteList: [...newValue.deleteList, item]
    });
  };

  return (
    <div ref={field.ref}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            {t("IntegrationsPage.telegram.addGroup")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            {t("IntegrationsPage.telegram.addGroupDescription")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <InputField
            label={t("IntegrationsPage.telegram.name")}
            customProps={{
              disabled: isLoading,
              value: name,
              onChange(event) {
                setName(event.target.value);
              },
              style: { maxWidth: "700px" }
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <InputField
            label={t("IntegrationsPage.telegram.chatId")}
            customProps={{
              disabled: isLoading,
              value: chatId,
              onChange(event) {
                setChatId(event.target.value);
              },
              style: { maxWidth: "700px" }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={1}>
          <Button
            customProps={{
              disabled: isAddDisabled,
              color: "secondary",
              type: "button",
              onClick: handleAddItem
            }}
          >
            {t("action.add")}
          </Button>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t("IntegrationsPage.telegram.groups")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            watermarked
            headers={[
              { key: "enabled", label: t("IntegrationsPage.telegram.enabled") },
              { key: "name", label: t("IntegrationsPage.telegram.name") },
              { key: "chatId", label: t("IntegrationsPage.telegram.chatId") },
              {
                key: "actions",
                label: t("IntegrationsPage.telegram.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["name", "asc"]}
            onHeaderSort={setItems}
            data={items}
            renderRow={row => [
              <>
                <Switch
                  color="secondary"
                  checked={Boolean(row.enabled)}
                  onChange={event => handleToggleItem(event, row)}
                  disabled={isLoading}
                />
              </>,
              <>{row.name}</>,
              <>{row.chatId}</>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemoveItem(row)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsXs={[0, 2]}
            hideColumnsSm={[2]}
            page={1}
            onPageChange={_pageValue => {}}
            pageSize={100}
            onPageSizeChange={_itemsPerPage => {}}
            totalPages={1}
            totalItems={items.length}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TelegramIntegrationTable;
