import {
  Stack,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton } from "./ToggleButton";
import { Activity, BarChart2 } from "react-feather";

export type ChartStyle = "bar" | "line";

type Props = {
  chartStyle: ChartStyle;
  onChartStyleChange: (event: MouseEvent, value: ChartStyle) => void;
};

const VisualizationSwitcher: FC<Props> = ({
  chartStyle,
  onChartStyleChange
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
      {!isMobile && (
        <Typography variant="subtitle1" color="textSecondary">
          {t("MonitoredVehiclesDashboardPage.visualization")}
        </Typography>
      )}
      <ToggleButtonGroup
        value={chartStyle}
        exclusive
        onChange={onChartStyleChange}
        color="secondary"
      >
        <ToggleButton size="small" value="bar">
          <BarChart2 />
        </ToggleButton>
        <ToggleButton size="small" value="line">
          <Activity />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default VisualizationSwitcher;
