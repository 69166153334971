import { FC } from "react";
import { SnackbarProvider as Snackbar } from "notistack";
import { AlertCircle, CheckCircle, XCircle } from "react-feather";
import { SnackbarUtilsConfigurator } from "components/SnackNotification";

const SnackbarProvider: FC = ({ children }) => (
  <Snackbar
    maxSnack={5}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    iconVariant={{
      success: <CheckCircle style={{ marginRight: "8px" }} />,
      error: <XCircle style={{ marginRight: "8px" }} />,
      warning: <AlertCircle style={{ marginRight: "8px" }} />
    }}
    classes={{
      variantSuccess: "bgPrimary",
      variantError: "bgPrimary",
      variantWarning: "bgPrimary",
      variantInfo: "bgPrimary"
    }}
  >
    <SnackbarUtilsConfigurator />
    {children}
  </Snackbar>
);

export default SnackbarProvider;
