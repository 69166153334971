import { FC } from "react";

import { AuthProvider } from "./AuthContext";
import { EquipmentMapProvider } from "./EquipmentMapContext";
import { LocaleProvider } from "./LocaleContext";
import { ModalProvider } from "./ModalContext";
import { PageLocationProvider } from "./PageLocationContext";
import SnackbarProvider from "./SnackbarProvider";
import { CustomThemeProvider } from "./ThemeContext";
import { WebSocketProvider } from "./WebSocketContext";
import { ErrorHandlerProvider } from "contexts/ErrorHandlerContext";
import { CustomizationProvider } from "contexts/CustomizationContext";
import { NotificationPanelProvider } from "contexts/NotificationPanelContext";
import { MainMenuProvider } from "./MainMenuContext";

/*
 * An aggregator for all application-wide contexts.
 *
 * <LocaleProvider> handles the app's interface language.
 *
 * <ErrorHandlerProvider> handles general system errors.
 *
 * <EquipmentMapProvider> saves the position and zoom of the equipment map,
 *   even when leaving its screen and then going back.
 *
 * <CustomThemeProvider> handles theme mode (dark/light).
 *
 * <CustomizationProvider> handles user's customizations settings.
 *
 * <NotificationPanelProvider> handles the notification panel.
 *
 * <AuthProvider> handles user authentication.
 *
 * <WebSocketProvider> handles communication to the backend via websocket.
 *
 * <PageLocationProvider> handles window/tab titles and navigation breadcrumbs.
 *
 * <MainMenuProvider> handles opened items in the main menu.
 *
 * <ModalProvider> provide a hook to open modals.
 *
 * <SnackbarProvider> handles pop-up messages.
 *
 */
const ContextAggregator: FC = ({ children }) => (
  <LocaleProvider>
    <ErrorHandlerProvider>
      <EquipmentMapProvider>
        <CustomThemeProvider>
          <CustomizationProvider>
            <AuthProvider>
              <NotificationPanelProvider>
                <WebSocketProvider>
                  <PageLocationProvider>
                    <MainMenuProvider>
                      <ModalProvider>
                        <SnackbarProvider>{children}</SnackbarProvider>
                      </ModalProvider>
                    </MainMenuProvider>
                  </PageLocationProvider>
                </WebSocketProvider>
              </NotificationPanelProvider>
            </AuthProvider>
          </CustomizationProvider>
        </CustomThemeProvider>
      </EquipmentMapProvider>
    </ErrorHandlerProvider>
  </LocaleProvider>
);

export default ContextAggregator;
