import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Map from "components/Map";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import TabPanel from "components/TabPanel";
import ImageViewer, {
  Classifier,
  ImageCoordinate
} from "components/ImageViewer";
import Pages from "enums/Pages";
import FormDialog from "components/FormDialog";
import { Download } from "react-feather";
import { DelayInfo, DownloadCaptureDetailsParams } from "api/CaptureReportAPI";
import { useAuth } from "contexts/AuthContext";
import { useLocale } from "contexts/LocaleContext";
import useCaptureReportAPI from "api/CaptureReportAPI";
import Button from "components/Button";
import useUpdatePlateAPI from "api/UpdatePlateAPI";
import { useHistory } from "react-router-dom";
import { dateToString, msToSeconds } from "utils/DateFunctions";

export type CaptureImageDialog = {
  id: string;
  image: string;
  plateCoordinate?: ImageCoordinate;
  equipment: string;
  camera: string;
  dateTime: string;
  plate: string;
  longitude?: string;
  latitude?: string;
  city?: string;
  state?: string;
  make?: string;
  classifier_list?: Classifier[];
  color?: string;
  model?: string;
  statistic?: {
    ocrReadTime: string;
    ocrPlateColor: string;
    motorcycle?: boolean;
    country: string;
  };
  renavam?: {
    vehicleMake: string;
    vehicleModel: string;
    vehicleColor: string;
    vehicleCategory: string;
    vehicleYear: string;
  };
};

export const initialCaptureImageDialogProps: CaptureImageDialog = {
  id: "",
  image: "",
  equipment: "",
  camera: "",
  dateTime: "",
  plate: ""
};

type Props = {
  open: boolean;
  capture: CaptureImageDialog | null;
  setOpen: (open: boolean) => void;
  setCapture?: (capture: CaptureImageDialog) => void;
};

const CaptureImageViewerDialog: FC<Props> = ({
  open,
  setOpen,
  capture,
  setCapture
}) => {
  const { t } = useTranslation();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [delay, setDelay] = useState<DelayInfo>();
  const { sessionUser } = useAuth();
  const { language } = useLocale();
  const CaptureReportAPI = useCaptureReportAPI();
  const UpdatePlate = useUpdatePlateAPI();
  const history = useHistory();

  const closeDialog = () => {
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setOpen(false);
    }
  };

  const downloadPdf = async (capture: CaptureImageDialog) => {
    let customerId = "";
    if (sessionUser) customerId = sessionUser["customer_id"];
    try {
      const downloadParams: DownloadCaptureDetailsParams = {
        ["customer_id"]: customerId,
        ["id_register"]: capture.id,
        language: language.split("-")[0]
      };
      setLoading(true);
      const response = await CaptureReportAPI.downloadDetailsPdf(
        downloadParams
      );
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const getDelay = async (capture: CaptureImageDialog) => {
    let customerId = "";
    if (sessionUser) customerId = sessionUser["customer_id"];
    try {
      setLoading(true);
      const response = await CaptureReportAPI.getDelay(customerId, capture.id);
      if (
        response.delayRegister ||
        response.processRegister ||
        response.delayImage ||
        response.processImage
      ) {
        setDelay(response);
      } else {
        setDelay(undefined);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "registerDetails.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const updatePlate = setCapture
    ? async (oldPlate: string, newPlate: string) => {
        if (oldPlate === newPlate || !capture) return;
        let customerId = "";
        if (sessionUser) customerId = sessionUser["customer_id"];
        try {
          setLoading(true);
          await UpdatePlate.updatePlate({
            ["customer_id"]: customerId,
            ["old_plate"]: oldPlate,
            ["new_plate"]: newPlate,
            ["register_id"]: capture.id
          });
        } catch (error) {
          errorHandler({ error });
        } finally {
          const currentPath = history.location.pathname;
          if (currentPath.includes(oldPlate))
            history.push(currentPath.replace(oldPlate, newPlate));
          setCapture({ ...capture, plate: newPlate });
          setLoading(false);
        }
      }
    : undefined;

  useEffect(() => {
    if (
      open &&
      capture &&
      capture.latitude &&
      capture.longitude &&
      Number(capture.latitude) !== 0 &&
      Number(capture.longitude) !== 0
    ) {
      setShowMap(true);
    } else {
      setShowMap(false);
    }

    if (!delay && open && capture) {
      getDelay(capture);
    }
    if (delay && (!open || !capture)) {
      setDelay(undefined);
    }
  }, [open, capture]);

  return (
    <FormDialog
      noCancel
      maxWidth="lg"
      fullScreen={isFullscreen}
      open={open}
      setOpen={setOpen}
      title={t("MonitoringReportPage.captureDetails")}
    >
      {capture && (
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={isFullscreen ? 12 : 6}>
            <ImageViewer
              isDialogOpen={open}
              isFullscreen={isFullscreen}
              setIsFullscreen={setIsFullscreen}
              img={capture.image}
              id={capture.id}
              cameraId={capture.camera}
              plateCoordinate={capture.plateCoordinate}
              classifierCoordinate={capture.classifier_list}
            />
          </Grid>
          <Grid item hidden={isFullscreen} xs={12} sm={12} md={12} lg={6}>
            <TabPanel
              tabs={[
                {
                  value: "info",
                  label: t("MonitoringReportPage.info"),
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("MonitoringReportPage.plate")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.plate ? (
                            <LinkPlate
                              plate={capture.plate}
                              onClick={closeDialog}
                              path={Pages.VEHICLE_OVERVIEW}
                              onEdit={updatePlate}
                            />
                          ) : (
                            EMPTY_VALUE
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("MonitoringReportPage.datetime")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.dateTime
                            ? dateToString(
                                capture.dateTime,
                                t("CaptureReportPage.dateFormat")
                              )
                            : EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("MonitoringReportPage.equipment")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.equipment || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("MonitoringReportPage.camera")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.camera || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("MonitoringReportPage.id")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.id || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          customProps={{
                            variant: "outlined",
                            size: "small",
                            startIcon: <Download />,
                            onClick() {
                              downloadPdf(capture);
                            }
                          }}
                        >
                          {t("ImageDialog.downloadPdf")}
                        </Button>
                        {isLoading && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  )
                },
                {
                  value: "statistic",
                  label: t("ImageDialog.statistic"),
                  hide: !capture.statistic,
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h4" align="left">
                          OCR
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.readTime")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.plateColor")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.motorcycle")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.country")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          Brasil
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                },
                {
                  value: "renavam",
                  label: t("ImageDialog.renavam"),
                  hide: !capture.renavam,
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.make")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.renavam?.vehicleMake || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.model")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.renavam?.vehicleModel || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.color")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.renavam?.vehicleColor || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.category")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.renavam?.vehicleCategory || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.year")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {capture.renavam?.vehicleYear || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                },
                {
                  value: "delay",
                  label: t("CaptureImageViewer.times"),
                  hide: !delay,
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item sm={6} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("CaptureImageViewer.delayRegister")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {delay?.delayRegister
                            ? msToSeconds(delay.delayRegister)
                            : EMPTY_VALUE}{" "}
                          {t("form.second")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("CaptureImageViewer.processRegister")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {delay?.processRegister
                            ? msToSeconds(delay.processRegister)
                            : EMPTY_VALUE}{" "}
                          {t("form.second")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("CaptureImageViewer.delayImage")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {delay?.delayImage
                            ? msToSeconds(delay.delayImage)
                            : EMPTY_VALUE}{" "}
                          {t("form.second")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("CaptureImageViewer.processImage")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {delay?.processImage
                            ? msToSeconds(delay.processImage)
                            : EMPTY_VALUE}{" "}
                          {t("form.second")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }
              ]}
            />
          </Grid>
          {showMap && capture && (
            <Grid item hidden={isFullscreen} xs={12}>
              <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                <strong>{t("EquipmentMapPage.address")}</strong>
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Map
                    minHeight={200}
                    geolocations={[
                      {
                        lat: Number(capture.latitude),
                        lng: Number(capture.longitude),
                        color: "#26abfc"
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </FormDialog>
  );
};

export default CaptureImageViewerDialog;
function errorHandler(_arg0: { error: unknown }) {
  throw new Error("Function not implemented.");
}
