import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "api/API";
import useAxios from "hooks/useAxios";

export type NotificationConfig = {
  ["panel_status"]: boolean;
  ["notification_channel"]: {
    panel: boolean;
    email: boolean;
  };
  ["notification_type"]: {
    error: boolean;
    alert: boolean;
    information: boolean;
  };
};

export type Notification = {
  date: string;
  deleted?: boolean;
  id: string;
  message: string;
  read?: boolean;
  type: string;
};

export type NotificationPaginated = {
  page: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  notifications: Notification[];
};

type GetNotificationsParams = {
  customerId: string;
  username: string;
  page: number;
};

export type UpdateNotification = {
  id: string;
  read: boolean;
  deleted: boolean;
};

type UpdateNotificationsParams = {
  customerId: string;
  username: string;
  notifications: UpdateNotification[];
};

type GetConfigParams = {
  customerId: string;
  username: string;
};

type SetConfigParams = {
  ["customer_id"]: string;
  username: string;
  ["notification_type"]: {
    alert: boolean;
    error: boolean;
    information: boolean;
  };
  ["notification_channel"]: {
    panel: boolean;
    email: boolean;
  };
  ["panel_status"]: boolean;
};

type UseNotificationAPIType = {
  getNotifications: (
    param: GetNotificationsParams
  ) => Promise<NestedData<NotificationPaginated>>;
  updateNotifications: (data: UpdateNotificationsParams) => Promise<Success>;
  getConfig: (param: GetConfigParams) => Promise<NotificationConfig>;
  setConfig: (data: SetConfigParams) => Promise<Success>;
};

const useNotificationAPI = (): UseNotificationAPIType => {
  const API = useAxios();

  const getNotifications = async ({
    customerId,
    username,
    page
  }: GetNotificationsParams): Promise<NestedData<NotificationPaginated>> => {
    const response: AxiosResponse<NestedData<NotificationPaginated>> =
      await API.instance.get(APIEndpoints.NOTIFICATIONS, {
        params: {
          ["customer_id"]: customerId,
          username,
          page
        }
      });
    return response.data;
  };

  const updateNotifications = async (
    data: UpdateNotificationsParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.NOTIFICATIONS_UPDATE,
      {
        ["customer_id"]: data.customerId,
        username: data.username,
        notifications: data.notifications
      }
    );
    return response.data;
  };

  const getConfig = async ({
    customerId,
    username
  }: GetConfigParams): Promise<NotificationConfig> => {
    const response: AxiosResponse<NotificationConfig> = await API.instance.get(
      APIEndpoints.USER_NOTIFICATIONS,
      {
        params: {
          ["customer_id"]: customerId,
          username
        }
      }
    );
    return response.data;
  };

  const setConfig = async (data: SetConfigParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_NOTIFICATIONS_UPDATE,
      data
    );
    return response.data;
  };

  return {
    getConfig,
    setConfig,
    getNotifications,
    updateNotifications
  };
};

export default useNotificationAPI;
