import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

type CloneVehiclesParams = {
  ["customer_id"]: string;
  ["initial_date"]: string | undefined;
  ["final_date"]: string | undefined;
  cameras: string[] | undefined;
  plate: string | undefined;
  equipments: string[] | undefined;
  page: number;
  ["page_size"]: number;
};

export type SuspectVehicle = {
  id: number | null;
  ["location_name"]: string | null;
  ["camera_name"]: string | null;
  city: string | null;
  ["date_capture"]: string;
  ["id_cloning"]: string;
  ["id_vehicle"]: string;
  latitude: number;
  longitude: number;
  plate: string;
  ["speed_kmh"]: string | null;
  state: string | null;
  street: string | null;
  ["vehicle_class"]: string;
  ["vehicle_color"]: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["images_path"]: string;
  ["plate_coordinate"]: string | null;
  ["dist_to_prev"]: number | null;
};

type SuspectVehicleResponse = {
  ["suspect_vehicles"]: SuspectVehicle[][];
  ["total_items"]: number;
  ["total_pages"]: number;
  page: number;
};

type UseCloneDashboardAPIType = {
  analyticsBiSuspectVehicles: (
    param: CloneVehiclesParams
  ) => Promise<SuspectVehicleResponse>;
};

const useCloneDashboardAPI = (): UseCloneDashboardAPIType => {
  const API = useAxios();

  const analyticsBiSuspectVehicles = async (
    filter: CloneVehiclesParams
  ): Promise<SuspectVehicleResponse> => {
    const response: AxiosResponse<SuspectVehicleResponse> =
      await API.instance.post(APIEndpoints.SUSPECT_VEHICLES, filter);

    return response.data;
  };

  return {
    analyticsBiSuspectVehicles
  };
};

export default useCloneDashboardAPI;
