import Pages from "enums/Pages";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import MonitoringTable from "pages/System/MonitoringTable";
import { usePageLocation } from "contexts/PageLocationContext";
import { MonitoringVehiclesReport } from "api/MonitoringVehiclesReportAPI";
import PageSection from "components/PageSection/PageSection";

const MonitoringPage: FC = () => {
  const { t } = useTranslation();
  const [, setSearch] = useState("");
  const [rows, setRows] = useState<MonitoringVehiclesReport[]>([]);
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.monitoring"));
    setLocation([
      {
        label: t("DefaultPageLayout.system")
      },
      {
        label: t("MonitoringPage.title"),
        page: Pages.SYSTEM_MONITORING
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("SystemPageLayout.monitoring")}
          tooltip={t("SystemPageLayout.monitoringHint")}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("SystemPageLayout.searchMonitoring")
          }}
        >
          <MonitoringTable rows={rows} setRows={setRows} />
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default MonitoringPage;
