import { Box, Divider, Typography, DialogContent } from "@mui/material";
import UploadFile from "components/UploadFile";
import { ChangeEvent, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useMonitoringAPI from "api/MonitoringAPI";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onUploadComplete: () => void;
};

const MonitoringVehiclesUploadDialog: FC<Props> = ({
  open,
  setOpen,
  onUploadComplete
}) => {
  const { t } = useTranslation();
  const MonitoringAPI = useMonitoringAPI();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { errorHandler } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length <= 0) return;
    try {
      setLoading(true);
      const response = await MonitoringAPI.importFile({
        file: event.target.files[0]
      });
      if (response.success) {
        const ignoredCount = response.success.ignored.length;
        const importedCount = response.success.imported.length;
        snackNotification.success(
          t("MonitoringVehicles.uploadFileSuccessMessage", {
            imported: importedCount,
            ignored: ignoredCount
          })
        );
        onUploadComplete();
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
      setLoading(false);
      closeDialog();
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      open={open}
      setOpen={setOpen}
      confirmText={t("action.add")}
      title={t("MonitoringVehicles.addVehicle")}
      isLoading={loading}
    >
      {loading && (
        <DialogContent>
          <Typography align="center">{t("waitState.loading")}</Typography>
        </DialogContent>
      )}
      {!loading && (
        <Box sx={{ ml: 5, mr: 5 }}>
          <UploadFile
            fileRef={hiddenFileInput}
            onChange={event => handleChangeFile(event)}
          />
        </Box>
      )}
      <Divider />
    </FormDialog>
  );
};

export default MonitoringVehiclesUploadDialog;
