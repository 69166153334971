import { DialogContent, styled, Typography } from "@mui/material";
import { ChangeEvent, FC, RefObject } from "react";
import Button from "./Button";
import { Upload } from "react-feather";
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  borderRadius: "4px",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: `${theme.spacing(3)} !important`,
  border: `2px dashed ${theme.palette.grey["300"]}`,

  "& > button": {
    width: "224px",
    height: "40px",
    borderStyle: "dashed",
    marginTop: theme.spacing(6),

    "&:hover": {
      borderStyle: "dashed"
    }
  }
}));

type UploadFileProps = {
  fileRef: RefObject<HTMLInputElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const UploadFile: FC<UploadFileProps> = ({ fileRef, onChange }) => {
  const { t } = useTranslation();

  return (
    <StyledDialogContent>
      <Typography align="center">
        {t("MonitoringVehicles.uploadCSV")}
      </Typography>
      <input
        style={{ display: "none" }}
        ref={fileRef}
        type="file"
        multiple={false}
        onChange={onChange}
      />
      <Button
        customProps={{
          startIcon: <Upload color="#0B2BFC" />,
          color: "primary",
          variant: "outlined",
          onClick() {
            if (fileRef.current) {
              fileRef.current.click();
            }
          },
          type: "button"
        }}
      >
        {t("action.importCSV")}
      </Button>
    </StyledDialogContent>
  );
};

export default UploadFile;
