import { createContext, FC, useContext, useEffect, useState } from "react";

import Cookies from "utils/Cookies";
import Themes from "enums/Themes";
import {
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeOptions,
  ThemeProvider,
  useMediaQuery
} from "@mui/material";
import {
  baseTheme,
  darkTheme,
  lightTheme,
  primaryDefaultColors,
  secondaryDefaultColors
} from "styles/theme";
import merge from "deepmerge";
import { ColorLuminance, ContrastColor } from "utils/ColorLuminance";

const FALLBACK_THEME = Themes.LIGHT;

type ThemeContextValue = {
  themeColor: Themes;
  isDark: boolean;
  isOpenSideBar: boolean;
  toggleTheme: () => void;
  setIsOpenSideBar: (value: boolean) => void;
  primaryColor: string;
  secondaryColor: string;
  setPrimaryColor: (color: string) => void;
  setSecondaryColor: (color: string) => void;
  sideBarScrollPosition: number;
  setSideBarScrollPosition: (position: number) => void;
};

const ThemeContext = createContext<ThemeContextValue>({
  themeColor: FALLBACK_THEME,
  isDark: false,
  toggleTheme() {
    throw new Error("toggleTheme must be defined.");
  },
  isOpenSideBar: true,
  setIsOpenSideBar() {
    throw new Error("setIsOpenSideBar must be defined.");
  },
  primaryColor: "",
  secondaryColor: "",
  setPrimaryColor() {
    throw new Error("setPrimaryColor must be defined.");
  },
  setSecondaryColor() {
    throw new Error("setSecondaryColor must be defined.");
  },
  setSideBarScrollPosition() {
    throw new Error("setSideBarScrollPosition must be defined.");
  },
  sideBarScrollPosition: 0
});

export const CustomThemeProvider: FC = ({ children }) => {
  const initialTheme = useMediaQuery("(prefers-color-scheme: dark)")
    ? Themes.DARK
    : Themes.LIGHT;

  const defaultTheme = (Cookies.get(Cookies.THEME) as Themes) ?? initialTheme;
  const [themeColor, setThemeColor] = useState<Themes>(defaultTheme);
  const [theme, setTheme] = useState<ThemeOptions>(createTheme(baseTheme));
  const [primaryColor, setPrimaryColor] = useState<string>(
    primaryDefaultColors.main
  );
  const [secondaryColor, setSecondaryColor] = useState<string>(
    secondaryDefaultColors.main
  );
  const [sideBarScrollPosition, setSideBarScrollPosition] = useState<number>(0);
  const [isOpenSideBar, setIsOpenSideBar] = useState(true);

  const toggleTheme = () => {
    setThemeColor(themeColor === Themes.LIGHT ? Themes.DARK : Themes.LIGHT);
  };

  useEffect(() => {
    const newTheme = baseTheme;
    if (
      primaryColor &&
      newTheme.palette &&
      primaryColor !== primaryDefaultColors.main
    ) {
      newTheme.palette.sideBar = {
        main: primaryColor,
        light: ColorLuminance(primaryColor, 0.7),
        dark: ColorLuminance(primaryColor, -0.4),
        contrastText: ContrastColor(primaryColor)
      } as PaletteColor;
    } else if (newTheme.palette) {
      newTheme.palette.sideBar = {
        main: primaryDefaultColors.main,
        light: primaryDefaultColors.light,
        dark: primaryDefaultColors.dark,
        contrastText: ContrastColor(primaryDefaultColors.main)
      };
    }
    if (
      secondaryColor &&
      newTheme.palette &&
      secondaryColor !== secondaryDefaultColors.main
    ) {
      newTheme.palette.secondary = {
        main: secondaryColor
      };
    } else if (newTheme.palette) {
      newTheme.palette.secondary = {
        main: secondaryDefaultColors.main,
        light: secondaryDefaultColors.light,
        dark: secondaryDefaultColors.dark
      };
    }
    setTheme(
      createTheme(
        merge(newTheme, themeColor === "light" ? lightTheme : darkTheme)
      )
    );
    Cookies.set(Cookies.THEME, themeColor);
  }, [themeColor, primaryColor, secondaryColor]);

  return (
    <ThemeContext.Provider
      value={{
        themeColor,
        isDark: themeColor === Themes.DARK,
        toggleTheme,
        primaryColor,
        isOpenSideBar,
        setIsOpenSideBar,
        secondaryColor,
        setPrimaryColor,
        setSecondaryColor,
        setSideBarScrollPosition,
        sideBarScrollPosition
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextValue => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within an ThemeProvider");
  }
  return context;
};
