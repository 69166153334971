import { FC, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FrequencyPlateData } from "pages/FlowDashboard/PlateGraphByFrequency";
import { useTranslation } from "react-i18next";
import FormDialog from "components/FormDialog";

const LIMIT = 1000;

type Props = {
  data: FrequencyPlateData;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PlateFrequencyDetailDialog: FC<Props> = ({ data, open, setOpen }) => {
  const { t } = useTranslation();
  const [plates, setPlates] = useState<string[]>([]);
  const [isLimited, setIsLimited] = useState(false);

  useEffect(() => {
    if (data.plates.length > LIMIT) {
      setPlates(data.plates.splice(0, LIMIT));
      setIsLimited(true);
    } else {
      setPlates(data.plates);
      setIsLimited(false);
    }
  }, [data]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      confirmText={t("action.apply")}
      title={
        <Box>
          Bin{data.name} ({data.total})
          {isLimited && (
            <>
              <br />
              <Typography variant="caption">
                {t("FlowDashboardPage.limitedPlates")}
              </Typography>
            </>
          )}
        </Box>
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        {plates.map(plate => (
          <Grid key={plate} item xs={4} sm={2}>
            {plate}
          </Grid>
        ))}
      </Grid>
    </FormDialog>
  );
};

export default PlateFrequencyDetailDialog;
