import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import { NestedData, Success } from "./API";
import APIEndpoints from "enums/APIEndpoints";

export type ForensicComment = {
  id: string;
  comment: string;
  ["user_created"]: string;
  ["name_user_created"]: string;
  ["date_insert"]: string;
  ["date_update"]: string;
  index: string;
};

export type ForensicCommentPaginated = {
  page: number;
  forensicReportComments: ForensicComment[];
  ["total_count"]: number;
  ["total_pages"]: number;
  ["page_size"]?: number;
};

type UseForensicCommentAPIType = {
  commentListPaginated: (
    customerId: string,
    forensicReportIndex: string,
    page: number,
    pageSize: number
  ) => Promise<NestedData<ForensicCommentPaginated>>;
  deleteCommentByIndex: (customerId: string, index: string) => Promise<Success>;
  getForensicComment: (customerId: string, index: string) => Promise<Comment>;
  createComment: (
    customerId: string,
    forensicReportIndex: string,
    comment: string
  ) => Promise<Success>;
  updateComment: (
    customerId: string,
    index: string,
    comment: string
  ) => Promise<Success>;
};

const useForensicCommentAPI = (): UseForensicCommentAPIType => {
  const API = useAxios();

  const commentListPaginated = async (
    customerId: string,
    forensicReportIndex: string,
    page: number,
    pageSize: number
  ): Promise<NestedData<ForensicCommentPaginated>> => {
    const response: AxiosResponse<NestedData<ForensicCommentPaginated>> =
      await API.instance.post(APIEndpoints.FORENSIC_COMMENT_LIST, {
        ["customer_id"]: customerId,
        ["forensic_report_index"]: forensicReportIndex,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const deleteCommentByIndex = async (
    customerId: string,
    index: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_COMMENT_DELETE,
      {
        ["customer_id"]: customerId,
        index
      }
    );
    return response.data;
  };

  const getForensicComment = async (
    customerId: string,
    index: string
  ): Promise<Comment> => {
    const response: AxiosResponse<Comment> = await API.instance.get(
      APIEndpoints.FORENSIC_COMMENT_GET,
      { params: { ["customer_id"]: customerId, index } }
    );
    return response.data;
  };

  const createComment = async (
    customerId: string,
    forensicReportIndex: string,
    comment: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_COMMENT_CREATE,
      {
        ["forensic_report_comment_data"]: {
          ["customer_id"]: customerId,
          ["forensic_report_index"]: forensicReportIndex,
          comment
        }
      }
    );
    return response.data;
  };

  const updateComment = async (
    customerId: string,
    index: string,
    comment: string
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.FORENSIC_COMMENT_UPDATE,
      {
        ["forensic_report_comment_data"]: {
          ["customer_id"]: customerId,
          index,
          comment
        }
      }
    );
    return response.data;
  };

  return {
    updateComment,
    createComment,
    getForensicComment,
    commentListPaginated,
    deleteCommentByIndex
  };
};

export default useForensicCommentAPI;
