import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "./API";
import Crypto from "utils/Crypto";
import useAxios from "hooks/useAxios";

/* Integration settings per customer */
export type CCONETCustomerIntegration = {
  enabled: boolean;
};

export type DetectaSpCustomerIntegration = {
  enabled: boolean;
};

export type SpiaPrfCustomerIntegration = {
  enabled: boolean;
  ["access_token"]: string;
  key: string;
  name: string;
};

export type TelegramCustomerIntegrationGroup = {
  index?: string;
  ["chat_id"]: string;
  name: string;
  enabled: boolean;
};

export type TelegramCustomerIntegration = {
  groups: TelegramCustomerIntegrationGroup[];
};

type UpdateCCONETCustomerIntegrationParams = {
  customerId: string;
  data: CCONETCustomerIntegration;
};

type UpdateDetectaSpCustomerIntegrationParams = {
  customerId: string;
  data: DetectaSpCustomerIntegration;
};

type UpdateSpiaPrfCustomerIntegrationParams = {
  customerId: string;
  data: SpiaPrfCustomerIntegration;
};

type CreateTelegramCustomerIntegrationParams = {
  customerId: string;
  data: TelegramCustomerIntegration["groups"];
};

type CreateTelegramCustomerIntegrationReturn = {
  success: string;
  index: string[];
};

type UpdateTelegramCustomerIntegrationParams = {
  customerId: string;
  data: TelegramCustomerIntegration["groups"];
};

type DeleteTelegramCustomerIntegrationParams = {
  customerId: string;
  data: TelegramCustomerIntegration["groups"];
};

type SaveTelegramCustomerIntegrationParams = {
  customerId: string;
  data: {
    createList: TelegramCustomerIntegration["groups"];
    updateList: TelegramCustomerIntegration["groups"];
    deleteList: TelegramCustomerIntegration["groups"];
  };
};

type SaveTelegramCustomerIntegrationReturn = [
  CreateTelegramCustomerIntegrationReturn | null,
  Success | null,
  Success | null
];

type SaveCustomerIntegrationsParams = {
  customerId: string;
  data: {
    ["cconet"]: CCONETCustomerIntegration;
    ["detecta_sp"]: DetectaSpCustomerIntegration;
    ["spia_prf"]: SpiaPrfCustomerIntegration;
    telegram: {
      createList: TelegramCustomerIntegration["groups"];
      updateList: TelegramCustomerIntegration["groups"];
      deleteList: TelegramCustomerIntegration["groups"];
    };
  };
};

type UseIntegrationsAPIType = {
  getCCONETCustomerIntegration: (
    customerId: string
  ) => Promise<CCONETCustomerIntegration>;
  updateCCONETCustomerIntegration: (
    param: UpdateCCONETCustomerIntegrationParams
  ) => Promise<Success>;
  getDetectaSpCustomerIntegration: (
    customerId: string
  ) => Promise<DetectaSpCustomerIntegration>;
  updateDetectaSpCustomerIntegration: (
    param: UpdateDetectaSpCustomerIntegrationParams
  ) => Promise<Success>;
  getSpiaPrfCustomerIntegration: (
    customerId: string
  ) => Promise<SpiaPrfCustomerIntegration>;
  updateSpiaPrfCustomerIntegration: (
    param: UpdateSpiaPrfCustomerIntegrationParams
  ) => Promise<Success>;
  getTelegramCustomerIntegration: (
    customerId: string
  ) => Promise<TelegramCustomerIntegration>;
  listTelegramCustomerIntegration: (
    customerId: string
  ) => Promise<NestedData<TelegramCustomerIntegrationGroup[]>>;
  updateTelegramCustomerIntegration: (
    param: UpdateTelegramCustomerIntegrationParams
  ) => Promise<Success>;
  saveTelegramCustomerIntegration: (
    param: SaveTelegramCustomerIntegrationParams
  ) => Promise<SaveTelegramCustomerIntegrationReturn>;
  getAllCustomerIntegrations: (
    customerId: string
  ) => Promise<
    [
      CCONETCustomerIntegration,
      DetectaSpCustomerIntegration,
      SpiaPrfCustomerIntegration,
      NestedData<TelegramCustomerIntegrationGroup[]>
    ]
  >;
  saveCustomerIntegrations: (
    param: SaveCustomerIntegrationsParams
  ) => Promise<
    [Success, Success, Success, SaveTelegramCustomerIntegrationReturn]
  >;
};

const useIntegrationsAPI = (): UseIntegrationsAPIType => {
  const API = useAxios();

  const getCCONETCustomerIntegration = async (
    customerId: string
  ): Promise<CCONETCustomerIntegration> => {
    const response: AxiosResponse<CCONETCustomerIntegration> =
      await API.instance.get(APIEndpoints.CUSTOMER_INTEGRATION_CCONET, {
        params: { ["customer_id"]: customerId }
      });
    return response.data;
  };

  const updateCCONETCustomerIntegration = async ({
    customerId,
    data
  }: UpdateCCONETCustomerIntegrationParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_INTEGRATION_CCONET_UPDATE,
      {
        ["customer_id"]: customerId,
        ["integration_data"]: data
      }
    );
    return response.data;
  };

  const getDetectaSpCustomerIntegration = async (
    customerId: string
  ): Promise<DetectaSpCustomerIntegration> => {
    const response: AxiosResponse<DetectaSpCustomerIntegration> =
      await API.instance.get(APIEndpoints.CUSTOMER_INTEGRATION_DETECTA_SP, {
        params: { ["customer_id"]: customerId }
      });
    return response.data;
  };

  const updateDetectaSpCustomerIntegration = async ({
    customerId,
    data
  }: UpdateDetectaSpCustomerIntegrationParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_INTEGRATION_DETECTA_SP_UPDATE,
      {
        ["customer_id"]: customerId,
        ["integration_data"]: data
      }
    );
    return response.data;
  };

  const getSpiaPrfCustomerIntegration = async (
    customerId: string
  ): Promise<SpiaPrfCustomerIntegration> => {
    const response: AxiosResponse<SpiaPrfCustomerIntegration> =
      await API.instance.get(APIEndpoints.CUSTOMER_INTEGRATION_SPIA_PRF, {
        params: { ["customer_id"]: customerId }
      });

    if (response.data.enabled) {
      return {
        ...response.data,
        ["access_token"]: Crypto.decrypt(response.data.access_token),
        key: Crypto.decrypt(response.data.key)
      };
    }

    return {
      ...response.data
    };
  };

  const updateSpiaPrfCustomerIntegration = async ({
    customerId,
    data
  }: UpdateSpiaPrfCustomerIntegrationParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_INTEGRATION_SPIA_PRF_UPDATE,
      {
        ["customer_id"]: customerId,
        ["integration_data"]: {
          ...data,
          ["access_token"]: data.access_token
            ? Crypto.encrypt(data.access_token)
            : undefined,
          key: data.key ? Crypto.encrypt(data.key) : undefined
        }
      }
    );
    return response.data;
  };

  const getTelegramCustomerIntegration = async (
    customerId: string
  ): Promise<TelegramCustomerIntegration> => {
    const response: AxiosResponse<TelegramCustomerIntegration> =
      await API.instance.get(APIEndpoints.CUSTOMER_INTEGRATION_TELEGRAM, {
        params: { ["customer_id"]: customerId }
      });
    return response.data;
  };

  const listTelegramCustomerIntegration = async (
    customerId: string
  ): Promise<NestedData<TelegramCustomerIntegrationGroup[]>> => {
    const response: AxiosResponse<
      NestedData<TelegramCustomerIntegrationGroup[]>
    > = await API.instance.post(
      APIEndpoints.CUSTOMER_INTEGRATION_TELEGRAM_LIST,
      {
        ["customer_id"]: customerId
      }
    );

    return response.data;
  };

  const createTelegramCustomerIntegration = async ({
    customerId,
    data
  }: CreateTelegramCustomerIntegrationParams): Promise<CreateTelegramCustomerIntegrationReturn> => {
    const response = await API.instance.post(
      APIEndpoints.CUSTOMER_INTEGRATION_TELEGRAM_CREATE,
      {
        ["customer_id"]: customerId,
        ["telegram_data"]: data
      }
    );
    return response.data;
  };

  const updateTelegramCustomerIntegration = async ({
    customerId,
    data
  }: UpdateTelegramCustomerIntegrationParams): Promise<Success> =>
    API.instance.post(APIEndpoints.CUSTOMER_INTEGRATION_TELEGRAM_UPDATE, {
      ["customer_id"]: customerId,
      ["telegram_data"]: data
    });

  const deleteTelegramCustomerIntegration = async ({
    customerId,
    data
  }: DeleteTelegramCustomerIntegrationParams): Promise<Success> =>
    API.instance.post(APIEndpoints.CUSTOMER_INTEGRATION_TELEGRAM_DELETE, {
      ["customer_id"]: customerId,
      ["telegram_data"]: data.map(({ index }) => ({ index }))
    });

  const saveTelegramCustomerIntegration = async ({
    customerId,
    data
  }: SaveTelegramCustomerIntegrationParams): Promise<SaveTelegramCustomerIntegrationReturn> => {
    const { createList, updateList, deleteList } = data;
    return Promise.all([
      createList.length > 0
        ? createTelegramCustomerIntegration({ customerId, data: createList })
        : Promise.resolve(null),
      updateList.length > 0
        ? updateTelegramCustomerIntegration({ customerId, data: updateList })
        : Promise.resolve(null),
      deleteList.length > 0
        ? deleteTelegramCustomerIntegration({ customerId, data: deleteList })
        : Promise.resolve(null)
    ]);
  };

  const getAllCustomerIntegrations = async (
    customerId: string
  ): Promise<
    [
      CCONETCustomerIntegration,
      DetectaSpCustomerIntegration,
      SpiaPrfCustomerIntegration,
      NestedData<TelegramCustomerIntegrationGroup[]>
    ]
  > =>
    Promise.all([
      getCCONETCustomerIntegration(customerId),
      getDetectaSpCustomerIntegration(customerId),
      getSpiaPrfCustomerIntegration(customerId),
      listTelegramCustomerIntegration(customerId)
    ]);

  const saveCustomerIntegrations = async ({
    customerId,
    data
  }: SaveCustomerIntegrationsParams): Promise<
    [Success, Success, Success, SaveTelegramCustomerIntegrationReturn]
  > =>
    Promise.all([
      updateCCONETCustomerIntegration({ customerId, data: data.cconet }),
      updateDetectaSpCustomerIntegration({ customerId, data: data.detecta_sp }),
      updateSpiaPrfCustomerIntegration({ customerId, data: data.spia_prf }),
      saveTelegramCustomerIntegration({ customerId, data: data.telegram })
    ]);

  return {
    getCCONETCustomerIntegration,
    updateCCONETCustomerIntegration,
    getDetectaSpCustomerIntegration,
    updateDetectaSpCustomerIntegration,
    getSpiaPrfCustomerIntegration,
    updateSpiaPrfCustomerIntegration,
    getTelegramCustomerIntegration,
    listTelegramCustomerIntegration,
    updateTelegramCustomerIntegration,
    getAllCustomerIntegrations,
    saveCustomerIntegrations,
    saveTelegramCustomerIntegration
  };
};

export default useIntegrationsAPI;
