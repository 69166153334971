import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem
} from "@mui/material";
import { Plus, Trash } from "react-feather";
import { Camera } from "api/CameraAPI";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import SelectField from "components/SelectField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FC, useEffect, useState } from "react";
import InputNumber from "components/InputNumber";
import { uniq } from "lodash";
import useMonitoredRouteAPI from "api/MonitoredRouteAPI";
import FormDialog from "components/FormDialog";

type RouteForm = {
  initialCamera: string;
  finalCamera: string;
  distance: string;
  legalSpeed: string;
};

export type MonitoredRouteForm = {
  routeName: string;
  routes: RouteForm[];
};

const defaultValues: MonitoredRouteForm = {
  routeName: "",
  routes: [
    {
      initialCamera: "",
      finalCamera: "",
      distance: "",
      legalSpeed: ""
    }
  ]
};

type Props = {
  cameras: Camera[];
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: () => void;
};

const MonitoredRoutesCreateFormDialog: FC<Props> = ({
  open,
  setOpen,
  cameras,
  onSave
}) => {
  const MonitoredRouteAPI = useMonitoredRouteAPI();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const { control, formState, handleSubmit, reset } =
    useForm<MonitoredRouteForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "routes"
  });

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
    }
  }, [open]);

  const onSubmit = async (data: MonitoredRouteForm) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      setLoading(true);
      let cameras: string[] = [];
      const distance: number[] = data.routes.map(r =>
        r.distance === "" ? -1 : Number(r.distance)
      );
      const legalSpeed: number[] = data.routes.map(r => Number(r.legalSpeed));
      for (const element of data.routes) {
        cameras.push(element.initialCamera);
        cameras.push(element.finalCamera);
      }
      cameras = uniq(cameras);

      await MonitoredRouteAPI.create({
        customerId: sessionUser.customer_id,
        routeName: data.routeName,
        cameras,
        distance,
        legalSpeed
      });
      snackNotification.success(t("MonitoredRoutesPage.routeCreated"));
      onSave();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.confirm")}
      title={t("MonitoredRoutesPage.createANewRoute")}
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Controller
            name="routeName"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("MonitoredRoutesPage.routeName")}
                customProps={{
                  inputProps: {
                    maxLength: 100
                  },
                  placeholder: t("MonitoredRoutesPage.routeNameHint"),
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ padding: "0 !important" }} />
        {fields.map((_value, idx) => (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            sx={{ pl: 2 }}
            key={`route${idx}`}
          >
            {idx > 0 && (
              <Grid item xs={12} md={9}>
                <Divider sx={{ marginBottom: "10px" }} />
              </Grid>
            )}
            <Grid item md={12} sm={12} sx={{ padding: "0 !important" }} />
            <Grid item xs={6}>
              <Controller
                name={`routes.${idx}.initialCamera`}
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    label={t("MonitoredRoutesPage.initialCamera")}
                    onChange={event => {
                      field.onChange(event.target.value as string);
                    }}
                    disabled={isLoading}
                  >
                    <MenuItem value="">
                      {t("MonitoredRoutesPage.initialCameraHint")}
                    </MenuItem>
                    {cameras.map(camera => (
                      <MenuItem
                        key={camera.camera_name}
                        value={camera.camera_name}
                      >
                        {camera.camera_name}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`routes.${idx}.finalCamera`}
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    label={t("MonitoredRoutesPage.finalCamera")}
                    onChange={event => {
                      field.onChange(event.target.value as string);
                    }}
                    disabled={isLoading}
                  >
                    <MenuItem value="">
                      {t("MonitoredRoutesPage.finalCameraHint")}
                    </MenuItem>
                    {cameras.map(camera => (
                      <MenuItem
                        key={camera.camera_name}
                        value={camera.camera_name}
                      >
                        {camera.camera_name}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} sx={{ padding: "0 !important" }} />
            <Grid item xs={5}>
              <Controller
                name={`routes.${idx}.distance`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label={t("MonitoredRoutesPage.distanceBetweenCameras")}
                    max={9999}
                    customProps={{
                      disabled: isLoading,
                      endAdornment: (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          m
                        </InputAdornment>
                      )
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name={`routes.${idx}.legalSpeed`}
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label={t("MonitoredRoutesPage.routeSpeed")}
                    max={300}
                    min={1}
                    customProps={{
                      disabled: isLoading,
                      required: true,
                      endAdornment: (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          km/h
                        </InputAdornment>
                      )
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <IconButton
                disabled={fields.length < 2}
                onClick={() => remove(idx)}
              >
                <Trash />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            customProps={{
              startIcon: <Plus size={16} />,
              variant: "text",
              color: "primary",
              onClick: () =>
                append({
                  distance: "",
                  finalCamera: "",
                  initialCamera: "",
                  legalSpeed: ""
                })
            }}
          >
            {t("MonitoredRoutesPage.addNewRoute")}
          </Button>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default MonitoredRoutesCreateFormDialog;
