import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled, Tooltip, Typography } from "@mui/material";

const Container = styled("div")(() => ({
  right: 0,
  width: "252px",
  bottom: "20px",
  padding: "16px",
  marginRight: "8px",
  position: "absolute",
  backgroundColor: "white"
}));

const Option = styled("div")(() => ({
  display: "flex",
  cursor: "pointer",
  paddingTop: "16px",
  alignItems: "center"
}));

type MarkIconProps = {
  color: string;
};

const MarkIcon = styled("div")(({ color }: MarkIconProps) => ({
  width: "20px",
  height: "20px",
  marginRight: "8px",
  borderRadius: "3px",
  backgroundColor: color,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: -6,
    width: "10px",
    height: "6px",
    backgroundColor: color,
    clipPath: "polygon(0 0, 50% 100%, 100% 0)"
  }
}));

type Props = {
  statusColors: {
    [status: string]: string;
  };
  onChange: (selectedStatus: string[]) => void;
};

const disabledColor = "#768299";

const EquipmentStatusGuide: FC<Props> = ({ statusColors, onChange }) => {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    Object.keys(statusColors)
  );

  const statuses = Object.keys(statusColors);

  const handleStatusClick = (status: string) => {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter(s => s !== status));
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  };

  useEffect(() => {
    onChange(selectedStatus);
  }, [selectedStatus]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={t("EquipmentMapPage.equipmentStatus").toString()}
    >
      <Container>
        <Typography variant="caption" sx={{ fontWeight: "400" }}>
          <strong>{t("EquipmentMapPage.equipmentStatusMap")}</strong>
        </Typography>
        {statuses.map(status => (
          <div
            key={status}
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Option style={{}} onClick={() => handleStatusClick(status)}>
              <MarkIcon
                color={
                  selectedStatus.includes(status)
                    ? statusColors[status]
                    : disabledColor
                }
              />
              <Typography variant="caption">
                {t(`EquipmentMapPage.status.${status}`)}
              </Typography>
            </Option>
          </div>
        ))}
      </Container>
    </Tooltip>
  );
};

export default EquipmentStatusGuide;
