import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import useCustomerAPI from "api/CustomerAPI";
import useVerticalAPI from "api/VerticalAPI";
import useToolsAPI, { City, State, Timezone } from "api/ToolsAPI";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import RegexPatterns from "utils/RegexPatterns";
import SelectField from "components/SelectField";
import { Controller, useForm } from "react-hook-form";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import timezoneData from "i18n/timezone.json";
import FormLabel from "components/FormLabel";
import FormDialog from "components/FormDialog";

type CustomerForm = {
  customerId: string;
  name: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phone: string;
  contact: string;
  verticals: string[];
  timezone: string;
};

const defaultValues: CustomerForm = {
  customerId: "",
  name: "",
  address: "",
  addressNumber: "",
  addressComplement: "",
  zipCode: "",
  city: "",
  state: "",
  country: "Brasil",
  email: "",
  verticals: [],
  phone: "",
  timezone: "",
  contact: ""
};

type Props = {
  customerId: string;
  open: boolean;
  updateCallerContent: () => void;
  setOpen: (open: boolean) => void;
};

const CustomerFormDialog: FC<Props> = ({
  customerId,
  open,
  setOpen,
  updateCallerContent
}) => {
  const CustomerAPI = useCustomerAPI();
  const VerticalAPI = useVerticalAPI();
  const ToolsAPI = useToolsAPI();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isCreating = customerId === "";
  const [cities, setCities] = useState<City[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [newCity, setNewCity] = useState<string>("");
  const [newState, setNewState] = useState<string>("");
  const [newTimezone, setNewTimezone] = useState<string>("");
  const { sessionUser } = useAuth();
  const [isCNPJ, setIsCNPJ] = useState<boolean>(false);
  const [isTypeCountry, setIsTypeCountry] = useState<boolean>(false);
  const [customer, setCustomer] = useState<CustomerForm>(defaultValues);
  const [verticals, setVerticals] = useState<string[]>([]);
  const [selectedVerticals, setSelectedVerticals] = useState<string[]>([]);
  const { errorHandler } = useErrorHandler();

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch
  } = useForm<CustomerForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const watchCountry = watch("country");

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [verticalResponse, customerResponse] = await Promise.all([
        VerticalAPI.listAll(),
        isCreating
          ? Promise.resolve(null)
          : CustomerAPI.getByCustomerId(customerId)
      ]);

      setVerticals(
        verticalResponse.data.map(vertical => vertical.vertical_name)
      );

      if (customerResponse) {
        setCustomer({
          customerId: customerResponse.customer_id,
          name: customerResponse.customer_name,
          address: customerResponse.customer_data.address,
          addressNumber: customerResponse.customer_data.address_number,
          addressComplement:
            customerResponse.customer_data.address_complement || "",
          zipCode: customerResponse.customer_data.zip_code,
          city: customerResponse.customer_data.city,
          state: customerResponse.customer_data.state,
          country: customerResponse.customer_data.country,
          email: customerResponse.customer_data.email,
          verticals: customerResponse.vertical_name || [],
          phone: customerResponse.customer_data.phone,
          contact: customerResponse.customer_data.contact,
          timezone: customerResponse.timezone
        });
        setNewCity(customerResponse.customer_data.city);
        setNewState(customerResponse.customer_data.state);
        setNewTimezone(customerResponse.timezone);
        setSelectedVerticals(customerResponse.vertical_name);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, customerId]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(customer);
  }, [setValue, customer]);

  useEffect(() => {
    if (open) {
      getCountry(getValues("country"));
    } else {
      reset(defaultValues);
      setSelectedVerticals([]);
      setCustomer(defaultValues);
    }
  }, [open]);

  const getCities = async (country: string, state: string) => {
    setIsLoading(true);
    try {
      const citiesResponse = await ToolsAPI.getCities(country, state);
      setCities(citiesResponse.data[0].cities);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const isMount = useIsMount();

  useEffect(() => {
    const currentCountry = getValues("country");
    if (!isMount && newState && currentCountry) {
      getCities(currentCountry, newState);
    }
  }, [newState]);

  const cancel = () => {
    setOpen(false);
    setCities([]);
    setStates([]);
    setNewCity("");
    setNewState("");
    setNewTimezone("");
    setIsTypeCountry(false);
  };

  const getCountry = async (country: string) => {
    setIsLoading(true);
    try {
      if (country) {
        const countryResponse = await ToolsAPI.getCountry(country);
        setStates(countryResponse.data[0].states);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!getValues("country")) {
      setIsTypeCountry(true);
      setNewCity("");
      setNewState("");
      getCountry(getValues("country"));
    }
  }, [watchCountry]);

  const onSubmit = async (data: CustomerForm) => {
    const newData = { ...data };

    newData.verticals = selectedVerticals;
    if (newState && newCity) {
      newData.state = newState;
      newData.city = newCity;
    }
    newData.timezone = newTimezone;

    setIsLoading(true);
    try {
      if (isCreating) {
        await CustomerAPI.create(newData);
        snackNotification.success(t("CustomerPage.customerCreated"));
      } else {
        await CustomerAPI.update(newData);
        snackNotification.success(t("CustomerPage.customerUpdated"));
      }
      updateCallerContent();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
      cancel();
    }
  };

  const customerIdMask = () => {
    if (isCreating) {
      if (isCNPJ) {
        return "99.999.999/9999-99";
      }
      return "999.999.999-99";
    }
    return "";
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid || !newState || !newCity}
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("CustomerPage.createANewCustomer")
          : t("CustomerPage.editCustomer")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" align="left" color="textSecondary">
              <strong>{t("CustomerFormDialog.basicInfo")}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="customerId"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                onChange={() => {
                  if (getValues("customerId").length > 10) {
                    setIsCNPJ(true);
                  } else {
                    setIsCNPJ(false);
                  }
                }}
                mask={customerIdMask()}
                label={t("CreateAccountPage.identification")}
                customProps={{
                  disabled: isLoading || !isCreating,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CreateAccountPage.name")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <Controller
            name="address"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CustomerFormDialog.address")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Controller
            name="addressNumber"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CustomerFormDialog.addressNumber")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="addressComplement"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CustomerFormDialog.addressComplement")}
                customProps={{
                  disabled: isLoading
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Controller
            name="zipCode"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                mask="99999-999"
                label={t("CustomerFormDialog.zipCode")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="country"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CustomerFormDialog.country")}
                customProps={{
                  disabled: isLoading,
                  required: true
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        {isTypeCountry ? (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CustomerFormDialog.state")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("CustomerFormDialog.city")}
                    customProps={{ required: true }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                required
                label={t("CustomerFormDialog.state")}
                value={newState}
                onChange={event => {
                  setNewState(event.target.value as string);
                }}
              >
                {states.map(option => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectField
                required
                disabled={isLoading}
                label={t("CustomerFormDialog.city")}
                value={newCity}
                onChange={event => {
                  setNewCity(event.target.value as string);
                }}
              >
                {cities.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Controller
            name="contact"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("CreateAccountPage.contact")}
                customProps={{ required: true }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: t("form.requiredField").toString(),
              pattern: {
                value: RegexPatterns.PHONE_NUMBER,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <InputField
                mask="(99) 99999-9999"
                label={t("CreateAccountPage.phone")}
                customProps={{ required: true }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SelectField
            required
            label={t("CustomerFormDialog.timezone")}
            value={newTimezone}
            onChange={event => {
              setNewTimezone(event.target.value as string);
            }}
          >
            {(timezoneData as Timezone[]).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormLabel>{t("CustomerFormDialog.verticals")}</FormLabel>
          <Autocomplete
            multiple
            openText={t("action.open")}
            closeText={t("action.close")}
            clearText={t("action.clear")}
            id="tags-outlined"
            options={verticals}
            getOptionLabel={vertical => vertical}
            filterSelectedOptions
            value={selectedVerticals}
            onChange={(event, newValue) => {
              event.preventDefault();
              setSelectedVerticals(newValue);
            }}
            noOptionsText={t("form.noOptions")}
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                placeholder={t("CustomerFormDialog.verticalsHint")}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default CustomerFormDialog;
