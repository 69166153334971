import {
  Context,
  useMemo,
  useState,
  useContext,
  useEffect,
  createContext,
  FC
} from "react";
import {
  Klass,
  LexicalNode,
  $getSelection,
  createCommand,
  LexicalEditor,
  $setSelection,
  LexicalCommand,
  $isRangeSelection,
  EditorThemeClasses,
  $isRootOrShadowRoot,
  $createNodeSelection,
  $createParagraphNode,
  COMMAND_PRIORITY_EDITOR
} from "lexical";
import { useTranslation } from "react-i18next";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { $createTableNodeWithDimensions, TableNode } from "./TableNode";
import RichTextEditorButton from "../RichTextEditorButton/RichTextEditorButton";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import RichTextEditorTextInput from "../RichTextEditorTextInput/RichTextEditorTextInput";
import { RichTextEditorDialogActions } from "../RichTextEditorDialog/RichTextEditorDialogActions";

export type InsertTableCommandPayload = Readonly<{
  columns: string;
  rows: string;
  includeHeaders?: boolean;
}>;

export type CellContextShape = {
  cellEditorConfig: null | CellEditorConfig;
  cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  set: (
    cellEditorConfig: null | CellEditorConfig,
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>
  ) => void;
};

export type CellEditorConfig = Readonly<{
  namespace: string;
  nodes?: ReadonlyArray<Klass<LexicalNode>>;
  onError: (error: Error, editor: LexicalEditor) => void;
  readOnly?: boolean;
  theme?: EditorThemeClasses;
}>;

export const INSERT_NEW_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> =
  createCommand();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: not sure why TS doesn't like using null as the value?
export const CellContext: Context<CellContextShape> = createContext({
  cellEditorConfig: null,
  cellEditorPlugins: null,
  set() {}
});

function invariant(cond?: boolean, message?: string): asserts cond {
  if (cond) {
    return;
  }

  throw new Error(
    message ||
      "Internal Lexical error: invariant() is meant to be replaced at compile " +
        "time. There is no runtime version."
  );
}

export const TableContext: FC<{ children: JSX.Element }> = ({ children }) => {
  const [contextValue, setContextValue] = useState<{
    cellEditorConfig: null | CellEditorConfig;
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  }>({
    cellEditorConfig: null,
    cellEditorPlugins: null
  });
  return (
    <CellContext.Provider
      value={useMemo(
        () => ({
          cellEditorConfig: contextValue.cellEditorConfig,
          cellEditorPlugins: contextValue.cellEditorPlugins,
          set(cellEditorConfig, cellEditorPlugins) {
            setContextValue({ cellEditorConfig, cellEditorPlugins });
          }
        }),
        [contextValue.cellEditorConfig, contextValue.cellEditorPlugins]
      )}
    >
      {children}
    </CellContext.Provider>
  );
};

export function InsertTableDialog({
  activeEditor,
  onClose
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [rows, setRows] = useState("5");
  const [columns, setColumns] = useState("5");

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows });
    onClose();
  };

  return (
    <>
      <RichTextEditorTextInput
        label={t("RichTextEditor.numRow")}
        onChange={setRows}
        value={rows}
      />
      <RichTextEditorTextInput
        label={t("RichTextEditor.numColumn")}
        onChange={setColumns}
        value={columns}
      />
      <RichTextEditorDialogActions data-test-id="table-model-confirm-insert">
        <RichTextEditorButton onClick={onClick}>
          {t("action.confirm")}
        </RichTextEditorButton>
      </RichTextEditorDialogActions>
    </>
  );
}

export function InsertNewTableDialog({
  activeEditor,
  onClose
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [rows, setRows] = useState("5");
  const [columns, setColumns] = useState("5");

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_NEW_TABLE_COMMAND, { columns, rows });
    onClose();
  };

  return (
    <>
      <RichTextEditorTextInput
        label={t("RichTextEditor.numRow")}
        onChange={setRows}
        value={rows}
      />
      <RichTextEditorTextInput
        label={t("RichTextEditor.numColumn")}
        onChange={setColumns}
        value={columns}
      />
      <RichTextEditorDialogActions data-test-id="table-model-confirm-insert">
        <RichTextEditorButton onClick={onClick}>
          {t("action.confirm")}
        </RichTextEditorButton>
      </RichTextEditorDialogActions>
    </>
  );
}

export function TablePlugin({
  cellEditorConfig,
  children
}: {
  cellEditorConfig: CellEditorConfig;
  children: JSX.Element | Array<JSX.Element>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const cellContext = useContext(CellContext);

  useEffect(() => {
    if (!editor.hasNodes([TableNode])) {
      invariant(false, "TablePlugin: TableNode is not registered on editor");
    }

    cellContext.set(cellEditorConfig, children);

    return editor.registerCommand<InsertTableCommandPayload>(
      INSERT_TABLE_COMMAND,
      ({ columns, rows, includeHeaders }) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return true;
        }

        const { focus } = selection;
        const focusNode = focus.getNode();

        if (focusNode !== null) {
          const tableNode = $createTableNodeWithDimensions(
            Number(rows),
            Number(columns),
            includeHeaders
          );

          if ($isRootOrShadowRoot(focusNode)) {
            const target = focusNode.getChildAtIndex(focus.offset);

            if (target === null) {
              focusNode.append(tableNode);
            } else {
              target.insertBefore(tableNode);
            }

            tableNode.insertBefore($createParagraphNode());
          } else {
            const topLevelNode = focusNode.getTopLevelElementOrThrow();
            topLevelNode.insertAfter(tableNode);
          }

          tableNode.insertAfter($createParagraphNode());
          const nodeSelection = $createNodeSelection();
          nodeSelection.add(tableNode.getKey());
          $setSelection(nodeSelection);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [cellContext, cellEditorConfig, children, editor]);

  return null;
}
