import { Plus, Edit, Link2, Download, MinusCircle } from "react-feather";
import Pages from "enums/Pages";
import useUserAPI from "api/UserAPI";
import debounce from "lodash/debounce";
import { format, parseISO } from "date-fns";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useForensicAPI, { Forensic } from "api/ForensicAPI";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import ForensicsReportFormDialog from "./ForensicsReportFormDialog";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { useLocale } from "contexts/LocaleContext";

const ForensicsReportPage: FC = () => {
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [editorUsers, setEditorUsers] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const ForensicAPI = useForensicAPI();
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [reportSelected, setReportSelected] = useState<Forensic>({
    id: "",
    name: "",
    report: "",
    ["user_created"]: "",
    ["name_user_created"]: "",
    ["date_insert"]: "",
    ["date_update"]: "",
    index: "",
    ["editor_users"]: []
  });
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const { language } = useLocale();

  const openFormDialog = (report?: Forensic) => {
    if (report) {
      setReportSelected(report);
    }
    setIsFormDialogOpen(true);
  };

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.forensicReport"));
    setLocation([
      {
        label: t("menu.reports")
      },
      {
        label: t("ForensicsReportPage.title"),
        page: Pages.FORENSIC_REPORT
      }
    ]);
  }, [t, Pages]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<Forensic[]>([]);
  const { sessionUser } = useAuth();
  const UserAPI = useUserAPI();

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        const usersArray: string[] = [];
        const customerId = sessionUser["customer_id"];
        try {
          const usersResponse = await UserAPI.listAll(customerId);
          usersResponse.data.forEach(user => {
            if (sessionUser.username !== user.username) {
              usersArray.push(user.username);
            }
          });
          setEditorUsers(usersArray || []);
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, []);

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        const forensicResponse = await ForensicAPI.listPaginated(
          sessionUser["customer_id"],
          pageValue,
          pageSizeValue,
          searchValue || undefined
        );
        setReports(forensicResponse.data.forensicReports);
        setPageSize(pageSizeValue);
        setPaginator({
          totalItems: forensicResponse.data.total_count || 0,
          totalPages: forensicResponse.data.total_pages || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string, pageSizeValue: number) => {
        requestData(searchValue, page, pageSizeValue);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search, pageSize);
    }
  }, [search, fetch]);

  const editItem = (report: Forensic) => {
    openFormDialog(report);
  };

  const handleRemove = (name: string, index: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(index),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const { errorHandler } = useErrorHandler();

  const remove = async (index: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      await ForensicAPI.deleteByIndex(sessionUser["customer_id"], index);
      snackNotification.success(t("ForensicsReportPage.reportRemoved"));
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "forensic_report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setDownloadingPdf(false);
  };

  const downloadPdf = async (data: Forensic) => {
    if (!data || isDownloadingPdf || !sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      setDownloadingPdf(true);
      const response = await ForensicAPI.downloadPdf(
        customerId,
        data.index,
        language.split("-")[0]
      );
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setReportSelected({
        id: "",
        name: "",
        report: "",
        ["user_created"]: "",
        ["name_user_created"]: "",
        ["date_insert"]: "",
        ["date_update"]: "",
        index: "",
        ["editor_users"]: []
      });
    }
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("ForensicsReportPage.title")}
          isLoading={isLoading}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("ForensicsReportPage.searchForensic")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openFormDialog()}
            >
              {t("ForensicsReportPage.newForensic")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "name", label: t("ForensicsReportPage.name") },
              {
                key: "date_insert",
                label: t("ForensicsReportPage.datetime")
              },
              {
                key: "name_user_created",
                label: t("ForensicsReportPage.created")
              },
              {
                key: "actions",
                label: t("ForensicsReportPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["name", "asc"]}
            onHeaderSort={setReports}
            data={reports}
            renderRow={row => [
              <>{row.name}</>,
              <>
                <strong>
                  {format(
                    parseISO(row.date_insert),
                    t("CaptureReportPage.dateFormat")
                  )}
                </strong>
              </>,
              <>
                <strong>{row.name_user_created}</strong>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => editItem(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemove(row.name, row.index)
                    },
                    {
                      tooltip: t("action.link"),
                      icon: Link2,
                      onClick() {
                        navigator.clipboard.writeText(
                          `${window.location.href}/${row.index}`
                        );
                        snackNotification.success(
                          t("ForensicsReportPage.reportCopy")
                        );
                      }
                    },
                    {
                      tooltip: t("action.download"),
                      icon: Download,
                      loading:
                        isDownloadingPdf && row.index === reportSelected.index,
                      onClick() {
                        setReportSelected(row);
                        downloadPdf(row);
                      }
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsXs={[1, 2, 3]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <ForensicsReportFormDialog
        users={editorUsers}
        open={isFormDialogOpen}
        setOpen={setIsFormDialogOpen}
        forensicReportData={reportSelected}
        clearFormData={setReportSelected}
        onRequestCompleted={() => requestData(search, page, pageSize)}
      />
    </DefaultPageLayout>
  );
};

export default ForensicsReportPage;
