import { FC } from "react";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

type TimerProps = {
  expiryTimestamp: Date;
  onExpire?: () => void;
  resendCode?: () => void;
};

const Timer: FC<TimerProps> = ({ expiryTimestamp, onExpire, resendCode }) => {
  const { t } = useTranslation();

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire() {
      if (onExpire) {
        onExpire();
      }
    }
  });

  const resetTimer = () => {
    const resetTimer = new Date();
    resetTimer.setSeconds(resetTimer.getSeconds() + 300);
    restart(resetTimer);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {minutes === 0 && seconds === 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Typography sx={{ mr: 0.5 }} variant="body1" color="textSecondary">
            {t("ConfirmUserPage.expired")}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            onClick={() => {
              if (resendCode) {
                resendCode();
                resetTimer();
              }
            }}
            sx={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
              "&:hover": {
                opacity: 0.7
              }
            }}
          >
            {t("action.resend")}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography sx={{ mr: 0.5 }} variant="body1" color="textSecondary">
            {t("ConfirmUserPage.warning")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            0{minutes}
          </Typography>
          :
          {seconds >= 0 && seconds < 10 && (
            <Typography variant="body1" color="textSecondary">
              0
            </Typography>
          )}
          <Typography variant="body1" color="textSecondary">
            {seconds}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Timer;
