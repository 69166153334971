import { AxiosResponse } from "axios";
import { NestedData, Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type Customer = {
  ["customer_id"]: string;
  ["customer_stats"]: string;
  ["vertical_name"]: string[];
  ["sub_customer_id"]: string;
  ["customer_name"]: string;
  ["customer_data"]: {
    address: string;
    ["address_complement"]: string;
    ["address_number"]: string;
    city: string;
    contact: string;
    district: string;
    country: string;
    email: string;
    whatsapp: string;
    telegram: string;
    phone: string;
    state: string;
    ["zip_code"]: string;
  };
  timezone: string;
};

export type DataCustomerList = {
  ["page"]: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["customers"]: Customer[];
};

export type DataCustomer = {
  data: Customer[];
};

type CreateParams = {
  customerId: string;
  name: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phone: string;
  contact: string;
  verticals: string[];
  timezone: string;
};

type UpdateParams = {
  customerId: string;
  name: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phone: string;
  contact: string;
  verticals: string[];
  timezone: string;
};

type UseCustomerAPIType = {
  search: (
    customerId: string,
    customerName: string,
    page: number
  ) => Promise<NestedData<DataCustomerList>>;
  listAll: (
    page: number,
    pageSize: number
  ) => Promise<NestedData<DataCustomerList | Customer[] | DataCustomer>>;
  getByCustomerId: (customerId: string) => Promise<Customer>;
  deleteById: (customerId: string) => Promise<Success>;
  create: (param: CreateParams) => Promise<Success>;
  update: (param: UpdateParams) => Promise<Success>;
};

const useCustomerAPI = (): UseCustomerAPIType => {
  const API = useAxios();

  const search = async (
    customerId: string,
    customerName: string,
    page: number
  ): Promise<NestedData<DataCustomerList>> => {
    const response: AxiosResponse<NestedData<DataCustomerList>> =
      await API.instance.get(APIEndpoints.CUSTOMER_PARTIAL, {
        params: {
          ["customer_id"]: customerId,
          ["customer_status"]: "ACTIVE",
          ["customer_name"]: customerName,
          page
        }
      });
    return response.data;
  };

  const listAll = async (
    page: number,
    pageSize: number
  ): Promise<NestedData<DataCustomerList | Customer[] | DataCustomer>> => {
    const response: AxiosResponse<NestedData<DataCustomerList | Customer[]>> =
      await API.instance.post(APIEndpoints.CUSTOMER_LIST, {
        ["customer_status"]: "active",
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const getByCustomerId = async (customerId: string): Promise<Customer> => {
    const response: AxiosResponse<Customer> = await API.instance.get(
      APIEndpoints.CUSTOMER,
      {
        params: { ["customer_id"]: customerId }
      }
    );
    return response.data;
  };

  const deleteById = async (customerId: string): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_DELETE,
      { ["customer_id"]: customerId }
    );
    return response.data;
  };
  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_CREATE,
      {
        ["customer_id"]: data.customerId,
        ["sub_customer_id"]: "",
        ["customer_name"]: data.name,
        ["customer_data"]: {
          address: data.address,
          ["address_number"]: data.addressNumber,
          ["address_complement"]: data.addressComplement,
          ["zip_code"]: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
          email: data.email,
          phone: data.phone,
          contact: data.contact
        },
        ["vertical_name"]: data.verticals,
        timezone: data.timezone
      }
    );
    return response.data;
  };
  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CUSTOMER_UPDATE,
      {
        ["customer_id"]: data.customerId,
        ["sub_customer_id"]: "",
        ["customer_name"]: data.name,
        ["customer_data"]: {
          address: data.address,
          ["address_number"]: data.addressNumber,
          ["address_complement"]: data.addressComplement,
          ["zip_code"]: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
          email: data.email,
          phone: data.phone,
          contact: data.contact
        },
        ["vertical_name"]: data.verticals,
        timezone: data.timezone
      }
    );
    return response.data;
  };

  return {
    listAll,
    getByCustomerId,
    create,
    update,
    deleteById,
    search
  };
};

export default useCustomerAPI;
