import { FC, useState } from "react";
import Button from "components/Button";
import { EMPTY_VALUE } from "utils/String";
import { Trash, Edit } from "react-feather";
import { format, parseISO } from "date-fns";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { ForensicComment } from "api/ForensicCommentAPI";
import RichTextEditor from "./RichTextEditor/RichTextEditor";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Grid, IconButton, styled, Tooltip, Typography } from "@mui/material";

const CommentUserName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.secondary.main
}));

interface CommentsProps {
  isNewComment: boolean;
  isCreating: boolean;
  isCommentLoading: boolean;
  setIsCommentLoading: (value: boolean) => void;
  deleteRequest?: (index: string) => void;
  onRequest: (comment: string) => void;
  commentContent: ForensicComment;
}

const Comments: FC<CommentsProps> = ({
  onRequest,
  isCreating,
  isNewComment,
  deleteRequest,
  commentContent,
  isCommentLoading,
  setIsCommentLoading
}) => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [showButton, setShowButton] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(commentContent.comment);

  const onSubmit = async () => {
    if (!sessionUser?.username) return;
    setIsCommentLoading(true);
    try {
      await onRequest(comment);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsCommentLoading(false);
      setShowButton(false);
      setComment("");
    }
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {commentContent.index && commentContent.name_user_created && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "6px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left"
                }}
              >
                <CommentUserName variant="body1" sx={{ mr: 0.8 }}>
                  {commentContent.name_user_created || EMPTY_VALUE}
                </CommentUserName>
                <Typography variant="body1" sx={{ fontWeight: 300 }}>
                  {`${t("ForensicsReportPage.on")} ${format(
                    parseISO(commentContent.date_insert),
                    t("CaptureReportPage.dateFormat")
                  )}` || EMPTY_VALUE}
                </Typography>
              </div>
              {commentContent.user_created === sessionUser?.username && (
                <div>
                  <Tooltip title={t("action.edit").toString()}>
                    <IconButton
                      disabled={isCommentLoading}
                      onClick={() => setShowButton(true)}
                    >
                      <Edit size={16} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("action.remove").toString()}>
                    <IconButton
                      disabled={isCommentLoading}
                      onClick={() => {
                        if (deleteRequest) deleteRequest(commentContent.index);
                      }}
                    >
                      <Trash size={16} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div
            style={{
              marginBottom: "8px",
              pointerEvents:
                isNewComment || (!isNewComment && showButton) ? "auto" : "none"
            }}
            onClick={() => {
              if (isNewComment) {
                setShowButton(true);
              }
            }}
          >
            <RichTextEditor
              editorHeight="auto"
              isComment={isNewComment}
              content={comment}
              isClear={!showButton}
              setValue={setComment}
              isCreating={isCreating}
              showToolbar={showButton}
            />
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: commentContent.date_update ? "space-between" : "right"
        }}
      >
        {commentContent.date_update && (
          <Typography variant="body1" sx={{ fontWeight: 300 }}>
            {`${t("ForensicsReportPage.editable")} ${t(
              "ForensicsReportPage.on"
            )} ${format(
              parseISO(commentContent.date_update),
              t("CaptureReportPage.dateFormat")
            )}` || EMPTY_VALUE}
          </Typography>
        )}
        {showButton && (
          <div>
            <Button
              customProps={{
                type: "button",
                color: "primary",
                onClick() {
                  setComment("");
                  setShowButton(false);
                },
                variant: "outlined",
                sx: { marginRight: "8px" }
              }}
            >
              {t("action.cancel")}
            </Button>
            <Button
              customProps={{
                color: "secondary",
                disabled: isCommentLoading,
                type: "button",
                onClick: () => onSubmit()
              }}
            >
              {t("action.save")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Comments;
