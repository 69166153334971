export const ColorLuminance = (hex: string, lum = 0): string => {
  let newHex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (newHex.length < 6) {
    newHex =
      newHex[0] + newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2];
  }
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(newHex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }
  return rgb;
};

export const ContrastColor = (hex: string): string => {
  let newHex = hex;
  if (newHex.indexOf("#") === 0) {
    newHex = newHex.slice(1);
  }
  if (newHex.length === 3) {
    newHex =
      newHex[0] + newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2];
  }
  if (newHex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  const r = parseInt(newHex.slice(0, 2), 16);
  const g = parseInt(newHex.slice(2, 4), 16);
  const b = parseInt(newHex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
};
