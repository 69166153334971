import { useMediaQuery, useTheme } from "@mui/material";
import Button from "components/Button";
import { FC, MouseEventHandler, ReactNode } from "react";

type Props = {
  id?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  testId?: string;
};

const PageSectionHeaderAction: FC<Props> = ({
  id,
  children,
  disabled = false,
  startIcon,
  endIcon,
  onClick,
  variant = "outlined",
  color = "primary",
  testId = ""
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div data-testid={testId} style={{ display: "inline-block" }}>
      <Button
        customProps={{
          id,
          disabled,
          onClick,
          startIcon,
          endIcon,
          variant,
          color,
          sx: {
            width: isMobile ? "100%" : "auto"
          }
        }}
      >
        {children}
      </Button>
    </div>
  );
};

export default PageSectionHeaderAction;
