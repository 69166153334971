import { Select, SelectProps, styled } from "@mui/material";
import { FC, forwardRef } from "react";
import { Wrapper } from "./FormField";
import FormLabel from "./FormLabel";

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  minHeight: "42px",
  marginBottom: 30,
  border: `1px solid ${theme.palette.grey["300"]}`,
  "&.Mui-error": {
    marginBottom: 0
  },
  fieldset: {
    display: "none"
  },
  em: {
    color: theme.palette.grey["500"]
  },
  ".MuiOutlinedInput-root": { height: "42px" },
  ".MuiOutlinedInput-input": { height: "11px" },
  "&.Mui-focused": {
    border: `0.5px solid ${theme.palette.secondary.main}`
  },

  "&.error": {
    borderColor: theme.palette.error.main
  }
}));

const SelectField: FC<SelectProps> = forwardRef(
  ({ children, label, required, ...props }, ref) => (
    <Wrapper>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <CustomSelect {...props} ref={ref}>
        {children}
      </CustomSelect>
    </Wrapper>
  )
);

SelectField.displayName = "SelectField";

export default SelectField;
