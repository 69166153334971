import { FC } from "react";
import { useTranslation } from "react-i18next";
import { primaryDefaultColors, secondaryDefaultColors } from "styles/theme";

type MonitoringStatusProps = {
  monitoringPlates: string[];
  plate: string;
  justifyCenter?: boolean;
};

const MonitoringStatus: FC<MonitoringStatusProps> = ({
  monitoringPlates,
  plate,
  justifyCenter = false
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: justifyCenter ? "center" : "flex-start"
      }}
    >
      <span
        style={{
          height: "8px",
          width: "8px",
          marginRight: "4px",
          backgroundColor: monitoringPlates.includes(plate || "")
            ? secondaryDefaultColors.main
            : primaryDefaultColors.light,
          borderRadius: "50%"
        }}
      />
      {monitoringPlates.includes(plate || "") ? (
        <div>{t("ConvoyDashboardPage.monitoringOk")}</div>
      ) : (
        <div>{t("ConvoyDashboardPage.notMonitoring")}</div>
      )}
    </div>
  );
};

export default MonitoringStatus;
