import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "contexts/AuthContext";
import { useLocale } from "contexts/LocaleContext";
import { usePageLocation } from "contexts/PageLocationContext";
import Languages from "enums/Languages";
import AuthPageLayout from "layouts/AuthPageLayout";

import termsPtBr from "../../assets/terms_pt-BR.pdf";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import useLicenceAPI from "api/LicenceAPI";
import { useHistory } from "react-router-dom";
import { HOME_PAGE } from "components/Router";

type FormFields = {
  termsAccepted: boolean;
};

const TermsPage: FC = () => {
  const { control, formState, handleSubmit } = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      termsAccepted: false
    }
  });

  const { t } = useTranslation();
  const LicenceAPI = useLicenceAPI();
  const history = useHistory();
  const { sessionUser, changeSessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const onSubmit = async () => {
    if (!sessionUser || !sessionUser.user_data) return;
    try {
      await LicenceAPI.setLicence({
        username: sessionUser.username,
        ["customer_id"]: sessionUser.customer_id,
        ["accept_licence"]: true
      });
      changeSessionUser({
        ...sessionUser,
        ["user_data"]: {
          ...sessionUser.user_data,
          ["accept_licence"]: true
        },
        isTermsAccepted: true
      });
      history.push(HOME_PAGE);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const { setPageTitle } = usePageLocation();

  const { signOut } = useAuth();

  useEffect(() => {
    setPageTitle(t("windowTitle.terms"));
  }, [t]);

  const { language } = useLocale();

  const termsByLanguage = {
    [Languages.EN]: termsPtBr,
    [Languages.ES]: termsPtBr,
    [Languages.PT]: termsPtBr
  };

  const terms = termsByLanguage[language];

  const rejectAction = async () => {
    if (!sessionUser) return;
    try {
      await LicenceAPI.setLicence({
        username: sessionUser.username,
        ["customer_id"]: sessionUser.customer_id,
        ["accept_licence"]: false
      });
    } finally {
      await signOut();
    }
  };

  return (
    <AuthPageLayout large closeable noBg onClose={rejectAction}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Typography variant="h3">{t("TermsPage.title")}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body1" color="textSecondary">
            {t("TermsPage.termsExplanation")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12}>
              <iframe src={`${terms}#toolbar=0`} width="100%" height="400px" />
            </Grid>
            <Grid item xs={12}>
              <Trans
                i18nKey="TermsPage.termsLinkFallback"
                t={t}
                components={[
                  <Link
                    key={0}
                    href={terms}
                    target="_blank"
                    rel="noreferrer"
                    color="secondary"
                  />
                ]}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="termsAccepted"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={field.onChange}
                        checked={Boolean(field.value)}
                      />
                    }
                    label={t("TermsPage.readTerms").toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                type="button"
                color="error"
                onClick={rejectAction}
              >
                {t("TermsPage.cancelAndSignOut")}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                disabled={!formState.isValid}
              >
                {t("action.continue")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </AuthPageLayout>
  );
};

export default TermsPage;
