import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";
import { RestrictionType } from "./RestrictionAPI";

export type IncidentReport = {
  ["restriction_type"]: RestrictionType;
  id: string;
  ["date_capture"]: string;
  camera: string;
  ["serial_number"]: string;
  equipment: string;
  plate: string;
  image_link: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  ["latitude"]: string;
  ["longitude"]: string;
  ["ocr_total_time"]: number;
  ["plate_color"]: number;
  ["plate_coordinate"]: string;
  city?: string;
  state?: string;
};

export type IncidentReportPaginated = {
  registers: {
    items: IncidentReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type VehiclesMake = {
  name: string;
  isDivider: boolean;
};

type ListParams = {
  ["customer_id"]: string;
  ["initial_date"]?: string;
  ["final_date"]?: string;
  plate?: string;
  ["page_size"]?: number;
  ["restrictions"]?: RestrictionType[];
  equipments?: string[];
  cameras?: string[];
  ["vehicle_make"]?: string;
  ["vehicle_model"]?: string;
  ["vehicle_color"]?: string;
  page?: number;
  city?: string;
  state?: string;
};

type DownloadParams = {
  ["customer_id"]: string;
  ["initial_date"]?: string;
  ["final_date"]?: string;
  plate?: string;
  ["restrictions"]?: RestrictionType[];
  equipments?: string[];
  cameras?: string[];
  ["vehicle_make"]?: string;
  ["vehicle_model"]?: string;
  ["vehicle_color"]?: string;
  language: string;
};

type UseIncidentReportAPIType = {
  list: (param: ListParams) => Promise<IncidentReportPaginated>;
  downloadCsv: (param: DownloadParams) => Promise<string>;
  downloadPdf: (param: DownloadParams) => Promise<string>;
};

const useIncidentReportAPI = (): UseIncidentReportAPIType => {
  const API = useAxios();

  const list = async (filter: ListParams): Promise<IncidentReportPaginated> => {
    const newFilter = { ...filter };
    if (!newFilter.plate) delete newFilter.plate;
    if (!newFilter.restrictions?.length) delete newFilter.restrictions;
    if (!newFilter.equipments?.length) delete newFilter.equipments;
    if (!newFilter.cameras?.length) delete newFilter.cameras;
    if (!newFilter.initial_date?.length) delete newFilter.initial_date;
    if (!newFilter.final_date?.length) delete newFilter.final_date;
    if (!newFilter["vehicle_make"]?.trim()) delete newFilter["vehicle_make"];
    if (!newFilter["vehicle_model"]?.trim()) delete newFilter["vehicle_model"];
    if (!newFilter["vehicle_color"]?.trim()) delete newFilter["vehicle_color"];

    const response: AxiosResponse<IncidentReportPaginated> =
      await API.instance.post(APIEndpoints.INCIDENT_REPORT_LIST, newFilter);

    return response.data;
  };

  const downloadCsv = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.INCIDENT_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "csv"
      },
      {
        responseType: "blob"
      }
    );
    return response.data;
  };

  const downloadPdf = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.INCIDENT_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "pdf"
      }
    );
    return response.data;
  };

  return {
    list,
    downloadCsv,
    downloadPdf
  };
};

export default useIncidentReportAPI;
