import { Typography } from "@mui/material";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import UserFormDialog from "./UserFormDialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import useUserAPI, { DataUserCustomerList, User } from "api/UserAPI";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const UsersPage: FC = () => {
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState("");
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [userFormOpen, setUserFormOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState<string>("");
  const UserAPI = useUserAPI();

  const openUserFormDialog = (username = "") => {
    setSelectedUsername(username);
    setUserFormOpen(true);
  };

  const [isFetchingUsers, setFetchingUsers] = useState<boolean>(true);
  const { errorHandler } = useErrorHandler();

  const [users, setUsers] = useState<User[]>([]);
  const { sessionUser } = useAuth();

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setFetchingUsers(true);
      try {
        if (searchValue) {
          const userResponse = await UserAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue
          );
          const userData = userResponse.data as DataUserCustomerList;
          setPaginator({
            totalPages: userData["total_pages"] || 0,
            totalItems: userData["total_count"] || 0
          });
          setUsers(userData.customers || []);
        } else {
          const userResponse = await UserAPI.listPaginated(
            sessionUser["customer_id"],
            pageValue,
            pageSizeValue
          );
          setPaginator({
            totalPages: userResponse.data.total_pages,
            totalItems: userResponse.data.total_count
          });
          setUsers(userResponse.data.users);
        }
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setFetchingUsers(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestData(searchValue, page, pageSize);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const { t } = useTranslation();

  const editUser = (user: User) => {
    openUserFormDialog(user.username);
  };

  const handleRemoveUser = (user: User) => {
    showModal({
      type: "error",
      modalType: "confirm",
      onConfirm: () => removeUser(user.username),
      confirmText: t("action.remove"),
      title: t("UsersPage.removeConfirmTitle"),
      description: t("UsersPage.removeConfirmDescription")
    });
  };

  const removeUser = async (username: string) => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      await UserAPI.deleteByUsername({
        customerId,
        username
      });
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.users"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.accessControl")
      },
      {
        label: t("UsersPage.title"),
        page: Pages.USERS
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("UsersPage.title")}
          isLoading={isFetchingUsers}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("DefaultPageLayout.researchUsers")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openUserFormDialog()}
            >
              {t("UsersPage.newUsers")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "username", label: t("UsersPage.users") },
              { key: "name", label: t("UsersPage.name") },
              { key: "level_name", label: t("UsersPage.profile") },
              { key: "group_name", label: t("UsersPage.group") },
              {
                key: "actions",
                label: t("UsersPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["name", "asc"]}
            onHeaderSort={setUsers}
            data={users}
            renderRow={row => [
              <>{row.username}</>,
              <>
                <Typography component="div" variant="caption">
                  <strong>{row.name}</strong>
                </Typography>
              </>,
              <>{row.level_name}</>,
              <>{row.group_name?.join(", ")}</>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => editUser(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemoveUser(row)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsXs={[1, 2, 3]}
            hideColumnsSm={[0, 3]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isFetchingUsers}
          />
        </PageSection>
      </InnerPageLayout>
      <UserFormDialog
        username={selectedUsername}
        open={userFormOpen}
        setOpen={setUserFormOpen}
        updateCallerContent={() => requestData(search, page, pageSize)}
      />
    </DefaultPageLayout>
  );
};

export default UsersPage;
