import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { ArrowLeft } from "react-feather";

type BackButtonProps = {
  goBack: () => void;
};

const Button = styled(Box)(() => ({
  alignItems: "center",
  display: "inline-flex",
  cursor: "pointer",
  position: "absolute",
  left: "16px",
  top: "16px"
}));

const BackButton: FC<BackButtonProps> = (props: BackButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button onClick={() => props.goBack()}>
      <IconButton>
        <ArrowLeft />
      </IconButton>
      <Typography
        style={{ display: "inline-block" }}
        variant="body2"
        color={theme => theme.palette.primary.light}
      >
        {t("action.back")}
      </Typography>
    </Button>
  );
};

export default BackButton;
