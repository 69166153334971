import { FC, useEffect } from "react";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ArrowLeft, Home, XCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import snackNotification from "components/SnackNotification";
import { usePageLocation } from "contexts/PageLocationContext";
import { useHistory } from "react-router-dom";
import Button from "components/Button";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  backgroundColor: theme.extraColors?.backgroundLight,
  textAlign: "center",
  gap: theme.spacing(1)
}));

const Error403Page: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      snackNotification.close();
    }, 800);
  }, [snackNotification]);

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.error403"));
    setLocation([]);
  }, [t]);

  const history = useHistory();

  return (
    <DefaultPageLayout>
      <Container>
        <XCircle
          style={{
            color: theme.palette.error.main,
            marginBottom: theme.spacing(1)
          }}
          size={42}
        />
        <Typography variant="h4">{t("error.error403Page.title")}</Typography>
        <Typography>{t("error.error403Page.description")}</Typography>
        <Stack direction={isMobile ? "column" : "row"} gap={theme.spacing(2)}>
          <Button
            customProps={{
              onClick: history.goBack,
              startIcon: <ArrowLeft />,
              variant: "outlined",
              color: "secondary"
            }}
          >
            {t("action.back")}
          </Button>
          <Button
            customProps={{
              onClick: () => history.replace("/"),
              startIcon: <Home />
            }}
          >
            {t("menu.home")}
          </Button>
        </Stack>
      </Container>
    </DefaultPageLayout>
  );
};

export default Error403Page;
