import { Box, Grid, styled, Skeleton } from "@mui/material";
import Map from "components/Map";
import { Position } from "google-map-react";
import useLocationAPI from "api/LocationAPI";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import useCaptureReportAPI from "api/CaptureReportAPI";
import { FormFilterCaptures } from "./CaptureReportFilter";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import HeatmapGradientScale from "../../components/HeatmapGradientScale";
import FormDialog from "components/FormDialog";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350,

  "& .gm-style>div:nth-of-type(2)>div>div:nth-of-type(5)": {
    filter: "grayscale(100%)"
  }
}));

interface CaptureReportHeatmapProps {
  filterData: FormFilterCaptures;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const HeatmapPlateDialog: FC<CaptureReportHeatmapProps> = ({
  filterData,
  open,
  setOpen
}) => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [isFetching, setIsFetching] = useState(false);
  const [geolocation, setGeolocation] = useState<Position[]>([]);
  const LocationAPI = useLocationAPI();
  const CaptureReportAPI = useCaptureReportAPI();

  const requestData = useCallback(
    async (filter: FormFilterCaptures) => {
      const customerId = sessionUser?.["customer_id"];
      if (!customerId) return;
      setIsFetching(true);
      try {
        const [locationResponse, reportResponse] = await Promise.all([
          LocationAPI.listAll(customerId),
          CaptureReportAPI.list({
            ["customer_id"]: customerId,
            ["page_size"]: 100,
            ["basic_filter"]: {
              plate: filter.plate || undefined
            }
          })
        ]);
        const configGeographic: Position[] = [];
        reportResponse.registers.items.forEach(register => {
          const coord = locationResponse.data.find(
            item => item?.location_name === register?.equipment
          );
          if (coord) {
            const geoCoordenate = {
              lat: Number(coord["location_data"]["latitude"]),
              lng: Number(coord["location_data"]["longitude"]),
              weight: 1
            };
            configGeographic.push(geoCoordenate);
          }
        });
        setGeolocation(configGeographic || []);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetching(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    if (open) {
      if (filterData.plate) {
        requestData(filterData);
      }
    }
  }, [requestData, open]);

  return (
    <FormDialog
      noCancel
      maxWidth="lg"
      open={open}
      setOpen={setOpen}
      title={t("CaptureReportPage.mapTitle")}
    >
      <Grid container>
        <Grid item xs={12}>
          <Container>
            <div
              style={{
                flexGrow: 1,
                width: "100%"
              }}
            >
              {isFetching && <Skeleton variant="rectangular" height={348} />}
              {!isFetching && (
                <Map
                  heatmapPositions={geolocation}
                  customControls={
                    <HeatmapGradientScale
                      text={t(
                        "MonitoredVehiclesDashboardPage.heatmapTooltip"
                      ).toString()}
                    />
                  }
                />
              )}
            </div>
          </Container>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default HeatmapPlateDialog;
