import { AxiosResponse } from "axios";

import { Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

type SetLicenceParams = {
  ["customer_id"]: string;
  username: string;
  ["accept_licence"]: boolean;
};

type UseLicenceAPIType = {
  setLicence: (params: SetLicenceParams) => Promise<Success>;
};

const useLicenceAPI = (): UseLicenceAPIType => {
  const API = useAxios();

  const setLicence = async (params: SetLicenceParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.LICENCE_SET,
      params
    );
    return response.data;
  };

  return {
    setLicence
  };
};

export default useLicenceAPI;
