import useMonitoredRouteAPI, {
  MonitoredRoute,
  RoutePaginated
} from "api/MonitoredRouteAPI";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import useCameraAPI, { Camera } from "api/CameraAPI";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import MonitoredRoutesCreateFormDialog from "./MonitoredRoutesCreateFormDialog";
import MonitoredRoutesUpdateFormDialog from "pages/Settings/MonitoredRoutes/MonitoredRoutesUpdateFormDialog";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const MonitoredRoutesPage: FC = () => {
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [search, setSearch] = useState("");
  const [isFormCreateOpen, setIsFormCreateOpen] = useState(false);
  const [isFormUpdateOpen, setIsFormUpdateOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [itemSelected, setItemSelected] = useState<MonitoredRoute | null>(null);
  const { errorHandler } = useErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [routes, setRoutes] = useState<MonitoredRoute[]>([]);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const { sessionUser } = useAuth();
  const CameraAPI = useCameraAPI();
  const MonitoredRouteAPI = useMonitoredRouteAPI();

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  const openFormDialog = (routeSelected: MonitoredRoute | null) => {
    if (routeSelected) {
      setItemSelected(routeSelected);
      setIsFormUpdateOpen(true);
    } else {
      setIsFormCreateOpen(true);
    }
  };

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        let routeApiCall;
        if (searchValue) {
          routeApiCall = MonitoredRouteAPI.search({
            customerId: sessionUser.customer_id,
            name: searchValue,
            page: pageValue,
            pageSize: pageSizeValue
          });
        } else {
          routeApiCall = MonitoredRouteAPI.listPaginated({
            customerId: sessionUser.customer_id,
            page: pageValue,
            pageSize: pageSizeValue
          });
        }
        const [routeResponse, cameraResponse] = await Promise.all([
          routeApiCall,
          CameraAPI.listAll({
            customerId: sessionUser.customer_id
          })
        ]);
        setCameras(cameraResponse.data);

        const routeData = routeResponse.data as RoutePaginated;
        setRoutes(routeData.routes || []);
        setPaginator({
          totalPages: routeData.total_pages || 0,
          totalItems: routeData.total_count || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string, pageSizeValue: number) => {
        requestData(searchValue, page, pageSizeValue);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search, pageSize);
    }
  }, [search, fetch]);

  useEffect(() => {
    setPageTitle(t("windowTitle.monitoredRoutes"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("MonitoredRoutesPage.title"),
        page: Pages.MONITORED_ROUTES
      }
    ]);
  }, [t, Pages]);

  const handleRemove = (routeName: string, cameras: string[]) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(routeName, cameras),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name: routeName }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async (routeName: string, cameras: string[]) => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    try {
      setIsLoading(true);
      await MonitoredRouteAPI.deleteByName({ customerId, routeName, cameras });
      snackNotification.success(t("MonitoredRoutesPage.routeRemoved"));
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
      setIsLoading(false);
    }
  };

  const onRequestCompleted = () => {
    requestData(search, page, pageSize);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("MonitoredRoutesPage.title")}
          isLoading={isLoading}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("MonitoredRoutesPage.searchRoutes")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openFormDialog(null)}
            >
              {t("MonitoredRoutesPage.newRoutes")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "route_name", label: t("MonitoredRoutesPage.name") },
              {
                key: "initial_camera",
                label: t("MonitoredRoutesPage.initialCamera")
              },
              {
                key: "final_camera",
                label: t("MonitoredRoutesPage.finalCamera")
              },
              { key: "distance", label: t("MonitoredRoutesPage.distance") },
              { key: "legal_speed", label: t("MonitoredRoutesPage.speed") },
              {
                key: "actions",
                label: t("MonitoredRoutesPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["startDateTime", "asc"]}
            onHeaderSort={setRoutes}
            data={routes}
            renderRow={row => [
              <>{row["route_name"]}</>,
              <>
                <strong>{row["initial_camera"]}</strong>
              </>,
              <>
                <strong>{row["final_camera"]}</strong>
              </>,
              <>{`${row.distance} m`}</>,
              <>{`${row["legal_speed"]} km/h`}</>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => openFormDialog(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () =>
                        handleRemove(row["route_name"], [
                          row["initial_camera"],
                          row["final_camera"]
                        ])
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[3, 4]}
            hideColumnsXs={[1, 2, 3, 4, 6]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <MonitoredRoutesCreateFormDialog
        cameras={cameras}
        open={isFormCreateOpen}
        setOpen={setIsFormCreateOpen}
        onSave={onRequestCompleted}
      />
      <MonitoredRoutesUpdateFormDialog
        selectedMonitoredRoute={itemSelected}
        cameras={cameras}
        open={isFormUpdateOpen}
        setOpen={setIsFormUpdateOpen}
        onSave={onRequestCompleted}
      />
    </DefaultPageLayout>
  );
};

export default MonitoredRoutesPage;
