import { createContext, FC, useContext, useEffect, useState } from "react";

export type LocationSection = {
  page?: string;
  label: string;
};

type PageLocationContextValue = {
  pageTitle: string;
  setPageTitle: (() => void) | ((title: string) => void);
  location: LocationSection[];
  setLocation: (() => void) | ((location: LocationSection[]) => void);
};

const PageLocationContext = createContext<PageLocationContextValue>({
  pageTitle: "",
  setPageTitle() {
    throw new Error("setPageTitle must be defined.");
  },
  location: [],
  setLocation() {
    throw new Error("setLocation must be defined.");
  }
});

export const getSearchParamValue = (
  search: string,
  param: string
): string | null => {
  const regex = new RegExp(`[?&]${param}=(?<value>[^&]+).*$`);
  return regex.exec(search)?.groups?.value ?? null;
};

export type Location = { from?: { pathname: string } } | undefined;

export const PageLocationProvider: FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [location, setLocation] = useState<LocationSection[]>([]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <PageLocationContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        location,
        setLocation
      }}
    >
      {children}
    </PageLocationContext.Provider>
  );
};

export const usePageLocation = (): PageLocationContextValue => {
  const context = useContext(PageLocationContext);
  if (context === undefined) {
    throw new Error(
      "usePageLocation must be used within an PageLocationProvider"
    );
  }
  return context;
};
