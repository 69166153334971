import Pages from "enums/Pages";
import { Plus } from "react-feather";
import useShareAPI from "api/ShareAPI";
import TabPanel from "components/TabPanel";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import useCameraAPI, { Camera } from "api/CameraAPI";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useLocationAPI, { Location } from "api/LocationAPI";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { Box, Chip } from "@mui/material";
import SharedDataFormDialog from "pages/Settings/SharedData/SharedDataFormDialog";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

type ReceivedData = {
  customer: string;
  customerId: string;
  locations: Location[];
  cameras: Camera[];
};

type SharedData = {
  customerId: string;
  locations: Location[];
  cameras: Camera[];
};

type AllSharedData = {
  received: ReceivedData[];
  shared: SharedData[];
};

const SharedDataPage: FC = () => {
  const CameraAPI = useCameraAPI();
  const LocationAPI = useLocationAPI();
  const ShareAPI = useShareAPI();
  const { showModal } = useModal();
  const [userFormOpen, setFormOpen] = useState(false);
  const { errorHandler } = useErrorHandler();
  const [received, setReceived] = useState<AllSharedData["received"]>([]);
  const [shared, setShared] = useState<AllSharedData["shared"]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { sessionUser } = useAuth();

  const openFormDialog = () => {
    setFormOpen(true);
  };

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      const [locationResponse, cameraResponse] = await Promise.all([
        LocationAPI.listAll(sessionUser.customer_id),
        CameraAPI.listAll({ customerId: sessionUser.customer_id })
      ]);
      const sharedArray: SharedData[] = [];
      const receivedArray: ReceivedData[] = [];
      for (const camera of cameraResponse.data.filter(x =>
        Boolean(x.shared_data)
      )) {
        if (camera.shared_data.shared && camera.shared_data.shared.length > 0) {
          for (const customerShared of camera.shared_data.shared) {
            const customer = sharedArray.find(
              v => v.customerId === customerShared
            );
            if (customer) {
              customer.cameras.push(camera);
            } else {
              sharedArray.push({
                customerId: customerShared,
                cameras: [camera],
                locations: []
              });
            }
          }
        }
        if (camera.shared_data.owner) {
          const customer = receivedArray.find(
            v => v.customerId === camera.shared_data.owner
          );
          if (customer) {
            customer.cameras.push(camera);
          } else {
            receivedArray.push({
              customer: camera.shared_data.owner_name,
              customerId: camera.shared_data.owner,
              cameras: [camera],
              locations: []
            });
          }
        }
      }
      for (const location of locationResponse.data.filter(x =>
        Boolean(x.shared_data)
      )) {
        if (
          location.shared_data.shared &&
          location.shared_data.shared.length > 0
        ) {
          for (const customerShared of location.shared_data.shared) {
            const customer = sharedArray.find(
              v => v.customerId === customerShared
            );
            if (customer) {
              customer.locations.push(location);
            } else {
              sharedArray.push({
                customerId: customerShared,
                cameras: [],
                locations: [location]
              });
            }
          }
        }
        if (location.shared_data.owner) {
          const customer = receivedArray.find(
            v => v.customerId === location.shared_data.owner
          );
          if (customer) {
            customer.locations.push(location);
          } else {
            receivedArray.push({
              customer: location.shared_data.owner_name,
              customerId: location.shared_data.owner,
              cameras: [],
              locations: [location]
            });
          }
        }
      }
      setReceived(receivedArray);
      setShared(sharedArray);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.sharedData"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("ShareDataPage.title"),
        page: Pages.SHARED_DATA
      }
    ]);
  }, [t, Pages]);

  const handleRemove = (
    customerIdShared: string,
    locationName: string,
    cameraName?: string
  ) => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    showModal({
      modalType: "confirm",
      onConfirm: () =>
        remove(customerId, customerIdShared, locationName, cameraName),
      confirmText: t("action.remove"),
      title: t("prompt.removeConfirmTitle", {
        name: cameraName ?? locationName
      }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async (
    customerId: string,
    customerIdShared: string,
    locationName: string,
    cameraName?: string
  ) => {
    try {
      setIsLoading(true);
      if (cameraName) {
        await ShareAPI.deleteSharedCamera({
          ["customer_id"]: customerId,
          ["shared_customer_id"]: customerIdShared,
          ["camera_name"]: cameraName,
          ["location_name"]: locationName
        });
      } else {
        await ShareAPI.deleteSharedLocation({
          ["customer_id"]: customerId,
          ["shared_customer_id"]: customerIdShared,
          ["location_name"]: locationName
        });
      }
      snackNotification.success(t("ShareDataPage.sharedDataRemoved"));
      requestData();
    } catch (error) {
      errorHandler({ error });
      setIsLoading(false);
    }
  };

  const onRequestCompleted = () => {
    requestData();
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("ShareDataPage.title")}
          isLoading={isLoading}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              testId="open-form-btn"
              onClick={() => openFormDialog()}
            >
              {t("ShareDataPage.newSharedData")}
            </PageSectionHeaderAction>
          }
        >
          <TabPanel
            tabs={[
              {
                value: "received",
                label: t("ShareDataPage.received"),
                content: (
                  <Box>
                    <DataTable
                      watermarked
                      headers={[
                        {
                          key: "receivedBy",
                          label: t("ShareDataPage.receivedBy")
                        },
                        {
                          key: "equipments",
                          label: t("ShareDataPage.equipments")
                        },
                        { key: "cameras", label: t("ShareDataPage.cameras") }
                      ]}
                      defaultSort={["receivedBy", "asc"]}
                      onHeaderSort={setReceived}
                      data={received}
                      renderRow={row => [
                        <>{row.customer}</>,
                        <>
                          {row.locations.map((location, locationIndex) => (
                            <Chip
                              sx={{ mb: 1, mr: 1 }}
                              key={`location-${locationIndex}`}
                              label={location.location_name}
                            />
                          ))}
                        </>,
                        <>
                          {row.cameras.map((camera, cameraIndex) => (
                            <Chip
                              sx={{ mb: 1, mr: 1 }}
                              key={`camera-${cameraIndex}`}
                              label={camera.camera_name}
                            />
                          ))}
                        </>
                      ]}
                      page={1}
                      onPageChange={_pageValue => requestData()}
                      pageSize={100}
                      onPageSizeChange={_itemsPerPage => {}}
                      totalPages={1}
                      totalItems={received.length}
                      isLoading={isLoading}
                    />
                  </Box>
                )
              },
              {
                value: "shared",
                label: t("ShareDataPage.shared"),
                content: (
                  <Box>
                    <DataTable
                      watermarked
                      headers={[
                        {
                          key: "owner",
                          label: t("ShareDataPage.sharedWith")
                        },
                        {
                          key: "eqipments",
                          label: t("ShareDataPage.equipments")
                        },
                        { key: "cameras", label: t("ShareDataPage.cameras") }
                      ]}
                      defaultSort={["owner", "asc"]}
                      onHeaderSort={setShared}
                      data={shared}
                      renderRow={row => [
                        <>{row.customerId}</>,
                        <>
                          {row.locations.map((location, locationIndex) => (
                            <Chip
                              sx={{ mb: 1, mr: 1 }}
                              key={`location-${locationIndex}`}
                              label={location.location_name}
                              onDelete={() =>
                                handleRemove(
                                  row.customerId,
                                  location.location_name
                                )
                              }
                            />
                          ))}
                        </>,
                        <>
                          {row.cameras.map((camera, cameraIndex) => (
                            <Chip
                              sx={{ mb: 1, mr: 1 }}
                              key={`camera-${cameraIndex}`}
                              label={camera.camera_name}
                              onDelete={() =>
                                handleRemove(
                                  row.customerId,
                                  camera.location_name,
                                  camera.camera_name
                                )
                              }
                            />
                          ))}
                        </>
                      ]}
                      page={1}
                      onPageChange={_pageValue => requestData()}
                      pageSize={100}
                      onPageSizeChange={_itemsPerPage => {}}
                      totalPages={1}
                      totalItems={shared.length}
                      isLoading={isLoading}
                    />
                  </Box>
                )
              }
            ]}
          />
        </PageSection>
      </InnerPageLayout>
      <SharedDataFormDialog
        open={userFormOpen}
        setOpen={setFormOpen}
        onSave={onRequestCompleted}
      />
    </DefaultPageLayout>
  );
};

export default SharedDataPage;
