import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import Comments from "components/Comments";
import Paginator, {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { Controller, useForm } from "react-hook-form";
import useForensicAPI, { Forensic } from "api/ForensicAPI";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import useForensicCommentAPI, { ForensicComment } from "api/ForensicCommentAPI";
import FormLabel from "components/FormLabel";
import FormDialog from "components/FormDialog";

const CommentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "32px"
}));

type ForensicsForm = {
  name: string;
};

const defaultValues = {
  name: ""
};

type Props = {
  open: boolean;
  users: string[];
  forensicReportData: Forensic;
  onRequestCompleted: () => void;
  setOpen: (open: boolean) => void;
  clearFormData: (value: Forensic) => void;
};

const ForensicsReportFormDialog: FC<Props> = ({
  open,
  users,
  setOpen,
  clearFormData,
  forensicReportData,
  onRequestCompleted
}) => {
  const pageSize = 10;
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { sessionUser } = useAuth();
  const [page, setPage] = useState(1);
  const { errorHandler } = useErrorHandler();
  const isCreating = forensicReportData.name === "";
  const [content, setContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<ForensicComment[]>([]);
  const [usersSelected, setUsersSelected] = useState<string[]>([]);
  const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const ForensicAPI = useForensicAPI();
  const ForensicCommentAPI = useForensicCommentAPI();

  const { control, formState, handleSubmit, reset, setValue, trigger } =
    useForm<ForensicsForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      if (!isCreating) {
        const forensicResponse = await ForensicAPI.getForensic(
          sessionUser["customer_id"],
          forensicReportData.index
        );
        setValue("name", forensicResponse.name);
        setContent(forensicResponse.report);
        setUsersSelected([...usersSelected, ...forensicResponse.editor_users]);
        await trigger();
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, forensicReportData]);

  const requestComments = useCallback(
    async (pageValue: number, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      setIsCommentLoading(true);
      try {
        if (!isCreating) {
          const forensicCommentsResponse =
            await ForensicCommentAPI.commentListPaginated(
              sessionUser["customer_id"],
              forensicReportData.index,
              pageValue,
              pageSizeValue
            );
          setComments(forensicCommentsResponse.data.forensicReportComments);
          setPaginator({
            totalItems: forensicCommentsResponse.data.total_count || 0,
            totalPages: forensicCommentsResponse.data.total_pages || 0
          });
          setPage(pageValue);
        }
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
        setIsCommentLoading(false);
      }
    },
    [sessionUser, forensicReportData]
  );

  useEffect(() => {
    if (open) {
      requestData();
      requestComments(page, pageSize);
    }
  }, [requestData, open]);

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
      setContent("");
      clearFormData({
        id: "",
        name: "",
        report: "",
        ["user_created"]: "",
        ["name_user_created"]: "",
        ["date_insert"]: "",
        ["date_update"]: "",
        index: "",
        ["editor_users"]: []
      });
      setUsersSelected([]);
    }
  }, [open]);

  const onSubmit = async (data: ForensicsForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      if (isCreating) {
        await ForensicAPI.create(
          sessionUser["customer_id"],
          data.name,
          content,
          usersSelected
        );
        snackNotification.success(t("ForensicsReportPage.reportCreated"));
      } else {
        await ForensicAPI.update(
          sessionUser["customer_id"],
          data.name,
          content,
          forensicReportData.index,
          usersSelected
        );
        snackNotification.success(t("ForensicsReportPage.reportUpdated"));
      }
      onRequestCompleted();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
      localStorage.removeItem("report");
    }
  };

  const createComment = async (
    forensicReportIndex: string,
    comment: string
  ) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      await ForensicCommentAPI.createComment(
        sessionUser["customer_id"],
        forensicReportIndex,
        comment
      );
      await requestComments(page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const updateComment = async (index: string, comment: string) => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      await ForensicCommentAPI.updateComment(
        sessionUser["customer_id"],
        index,
        comment
      );
      await requestComments(page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const handleRemove = (index: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => removeComment(index),
      confirmText: t("action.confirm"),
      title: t("ForensicsReportPage.removeConfirmTitle"),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const removeComment = async (index: string) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsCommentLoading(true);
    try {
      await ForensicCommentAPI.deleteCommentByIndex(
        sessionUser["customer_id"],
        index
      );
      await requestComments(page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const isPermission = (): boolean => {
    if (sessionUser) {
      return usersSelected.includes(sessionUser.username);
    }
    return false;
  };

  const disabledRichTextEditor = (): boolean =>
    !isPermission() &&
    !isCreating &&
    sessionUser?.username !== forensicReportData.user_created;

  return (
    <FormDialog
      maxWidth="lg"
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={
        disabledRichTextEditor() ||
        (isCreating && !formState.isValid) ||
        content === "" ||
        content === `<p class="PlaygroundEditorTheme__paragraph"><br></p>`
      }
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("ForensicsReportPage.createANewReport")
          : t("ForensicsReportPage.editReport")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("ForensicsReportPage.name")}
                customProps={{
                  required: true,
                  disabled:
                    isLoading ||
                    (!isPermission() &&
                      !isCreating &&
                      sessionUser?.username !== forensicReportData.user_created)
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel>{t("ForensicsReportPage.editorUsers")}</FormLabel>
          <Autocomplete
            size="small"
            multiple
            disablePortal
            disabled={
              isLoading ||
              (!isPermission() &&
                !isCreating &&
                sessionUser?.username !== forensicReportData.user_created)
            }
            openText={t("action.open")}
            closeText={t("action.close")}
            clearText={t("action.clear")}
            noOptionsText={t("form.noOptions")}
            options={users}
            getOptionLabel={option => option}
            value={usersSelected}
            onChange={(event, values) => {
              event.preventDefault();
              setUsersSelected(values);
            }}
            renderInput={params => (
              <div style={{ position: "relative" }}>
                <TextField
                  {...params}
                  placeholder={
                    !isCreating &&
                    usersSelected.length === 0 &&
                    !isPermission() &&
                    sessionUser?.username !== forensicReportData.user_created
                      ? t("ForensicsReportPage.disabled")
                      : t("form.startTyping")
                  }
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <FormLabel required>{t("ForensicsReportPage.description")}</FormLabel>
          <div
            style={{
              opacity: isLoading || disabledRichTextEditor() ? 0.7 : 1,
              borderRadius:
                isLoading || disabledRichTextEditor() ? "4px" : "unset",
              border:
                isLoading || disabledRichTextEditor()
                  ? "1px solid #959799"
                  : "0px",
              pointerEvents:
                isLoading || disabledRichTextEditor() ? "none" : "auto"
            }}
          >
            <RichTextEditor
              showToolbar={!(isLoading || disabledRichTextEditor())}
              setValue={setContent}
              isCreating={isCreating}
              content={
                forensicReportData.report ? forensicReportData.report : ""
              }
            />
          </div>
        </Grid>
        {!isCreating && (
          <>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <FormLabel>{t("ForensicsReportPage.comment")}</FormLabel>
              <Comments
                isCommentLoading={isCommentLoading}
                setIsCommentLoading={setIsCommentLoading}
                isNewComment
                commentContent={{
                  id: "",
                  comment: "",
                  ["user_created"]: "",
                  ["name_user_created"]: "",
                  ["date_insert"]: "",
                  ["date_update"]: "",
                  index: ""
                }}
                isCreating={isCreating}
                onRequest={(commentValue: string) =>
                  createComment(forensicReportData.index, commentValue)
                }
              />
            </Grid>
            {comments.length > 0 && !isCommentLoading && (
              <>
                {comments.map(comment => (
                  <Grid key={comment.index} item xs={12}>
                    <CommentContainer>
                      <Comments
                        isCommentLoading={isCommentLoading}
                        setIsCommentLoading={setIsCommentLoading}
                        commentContent={comment}
                        isNewComment={false}
                        isCreating={isCreating}
                        deleteRequest={handleRemove}
                        onRequest={(commentValue: string) =>
                          updateComment(comment.index, commentValue)
                        }
                      />
                    </CommentContainer>
                  </Grid>
                ))}
                {paginator.totalPages > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "8px",
                      justifyContent: "center"
                    }}
                  >
                    <Paginator
                      initialPage={page}
                      totalPages={paginator.totalPages}
                      onPageChange={pageValue =>
                        requestComments(pageValue, pageSize)
                      }
                    />
                  </Box>
                )}
              </>
            )}
            {isCommentLoading && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <div
                  style={{
                    display: "flex",
                    marginTop: "8px",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress size={32} />
                  <Typography variant="body1">
                    {t("waitState.loading")}
                  </Typography>
                </div>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </FormDialog>
  );
};

export default ForensicsReportFormDialog;
