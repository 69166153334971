import {
  Box,
  Grid,
  Stack,
  useTheme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Copy } from "react-feather";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "components/FormDialog";

type InvoiceDetails = {
  customer: string;
  paymentStatus: string;
  dueDate: string;
  documentNumber: string;
  value: string;
  barcode: string;
  service: string;
  serviceValue: string;
  serviceDetails: string;
  serviceDetailValue: string;
  amount: string;
};

const initialValues: InvoiceDetails = {
  customer: "",
  paymentStatus: "",
  dueDate: "",
  documentNumber: "",
  value: "",
  barcode: "",
  service: "",
  serviceValue: "",
  serviceDetails: "",
  serviceDetailValue: "",
  amount: ""
};

const mockData: InvoiceDetails = {
  customer: "PRF",
  paymentStatus: "Em aberto",
  dueDate: "26/01/2022",
  documentNumber: "14000100000000511-9",
  value: "R$ 4.567,00",
  barcode: "12390.00004 00000.000005 00000.000006 7 45670000012350",
  service: "Cadastro do Plano business",
  serviceValue: "R$ 2.567,00",
  serviceDetails: "4.374 capturas",
  serviceDetailValue: "R$ 2.567,00",
  amount: "R$ 4.134,00"
};

async function load(): Promise<InvoiceDetails> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(mockData);
    }, 500);
  });
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const InvoiceDetailsDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoiceDetails, setInvoiceDetails] =
    useState<InvoiceDetails>(initialValues);
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(async () => {
    if (open) {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        const response = await load();
        setInvoiceDetails(response);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    }
  }, [sessionUser, open]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  useEffect(() => {
    if (!open) {
      setInvoiceDetails(initialValues);
    }
  }, [open]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      confirmText={t("action.sendEmail")}
      title={t("BillingPage.addPayment")}
      actions={
        <>
          <Stack
            spacing={1}
            direction="row"
            sx={{
              marginBottom: fullScreen ? "8px" : "0px"
            }}
          >
            <div
              style={{
                maxWidth: fullScreen ? "194px" : "220px"
              }}
            >
              <InputField
                gutter={false}
                customProps={{
                  placeholder: t("InvoiceDetailsDialog.enterEmailHint"),
                  disabled: isLoading,
                  required: true
                }}
              />
            </div>
            <Button
              customProps={{
                color: "secondary",
                sx: {
                  marginRight: fullScreen ? "0px" : "8px !important"
                },
                onClick: () =>
                  snackNotification.success(
                    t("InvoiceDetailsDialog.emailSuccessfullySent")
                  )
              }}
            >
              {t("action.sendEmail")}
            </Button>
          </Stack>
          <Button
            customProps={{
              type: "button",
              color: "primary",
              variant: "outlined",
              onClick: () => setOpen(false),
              sx: { width: fullScreen ? "100%" : "unset" }
            }}
          >
            {t("action.print")}
          </Button>
        </>
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <InputField
            label={t("InvoiceDetailsDialog.customer")}
            customProps={{
              disabled: true,
              value: invoiceDetails.customer
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            label={t("InvoiceDetailsDialog.paymentStatus")}
            customProps={{
              disabled: true,
              value: invoiceDetails.paymentStatus
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            label={t("InvoiceDetailsDialog.dueDate")}
            customProps={{
              disabled: true,
              value: invoiceDetails.dueDate
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <InputField
            label={t("InvoiceDetailsDialog.documentNumber")}
            customProps={{
              disabled: true,
              value: invoiceDetails.documentNumber
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            label={t("InvoiceDetailsDialog.value")}
            customProps={{
              disabled: true,
              value: invoiceDetails.value
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <InputField
            label={t("InvoiceDetailsDialog.barcode")}
            gutter={false}
            customProps={{
              disabled: true,
              value: invoiceDetails.barcode
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ display: "flex", alignItems: "flex-end" }}
        >
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              fullWidth: true,
              onClick: () => setOpen(false),
              type: "button",
              startIcon: <Copy />
            }}
          >
            {t("action.copy")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="body1" align="left" color="textSecondary">
              <strong>{t("InvoiceDetailsDialog.descriptionServices")}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <InputField
            gutter={false}
            customProps={{
              disabled: true,
              value: invoiceDetails.service
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            gutter={false}
            customProps={{
              disabled: true,
              value: invoiceDetails.serviceValue
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <InputField
            gutter={false}
            customProps={{
              disabled: true,
              value: invoiceDetails.serviceDetails
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            gutter={false}
            customProps={{
              disabled: true,
              value: invoiceDetails.serviceDetailValue
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
          <InputField
            gutter={false}
            label={t("InvoiceDetailsDialog.amount")}
            customProps={{
              disabled: true,
              value: invoiceDetails.amount
            }}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default InvoiceDetailsDialog;
