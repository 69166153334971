import colorsJSON from "assets/vehicleColor.json";

export type VehicleColor = {
  color: string;
};

export const vehicleColors: VehicleColor[] = colorsJSON;

export const sliderMarks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 5,
    label: ""
  },
  {
    value: 10,
    label: ""
  },
  {
    value: 15,
    label: ""
  },
  {
    value: 20,
    label: ""
  },
  {
    value: 25,
    label: "25"
  },
  {
    value: 30,
    label: ""
  },
  {
    value: 35,
    label: ""
  },
  {
    value: 40,
    label: ""
  },
  {
    value: 45,
    label: ""
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 55,
    label: ""
  },
  {
    value: 60,
    label: ""
  },
  {
    value: 65,
    label: ""
  },
  {
    value: 70,
    label: ""
  },
  {
    value: 75,
    label: "75"
  },
  {
    value: 80,
    label: ""
  },
  {
    value: 85,
    label: ""
  },
  {
    value: 90,
    label: ""
  },
  {
    value: 95,
    label: ""
  },
  {
    value: 100,
    label: "100"
  },
  {
    value: 120,
    label: "100+"
  }
];
