import { FC, useMemo } from "react";
import {
  Avatar as MAvatar,
  AvatarProps as MAvatarProps,
  styled
} from "@mui/material";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgColor: stringToColor(name)
    },
    children: name[0]
  };
}

type AvatarProps = {
  name: string;
  imgSrc?: string;
  size?: "small" | "large";
  customProps?: MAvatarProps;
};

const Avatar: FC<AvatarProps> = ({ name, imgSrc, size, customProps }) => {
  const CustomAvatar = styled(MAvatar)(() => ({
    borderRadius: size === "small" ? "5px" : "8px",
    width: size === "small" ? "40px" : "48px",
    height: size === "small" ? "40px" : "48px"
  }));

  const memoizedAvatar = useMemo(
    () => (
      <CustomAvatar
        {...customProps}
        {...stringAvatar(name)}
        {...{ src: imgSrc || undefined }}
      />
    ),
    [imgSrc, name]
  );

  return <div>{memoizedAvatar}</div>;
};

export default Avatar;
