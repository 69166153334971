import { FC, useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import useUserAPI from "api/UserAPI";
import useUserGroupAPI, { UserGroup } from "api/UserGroupAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Controller, useForm } from "react-hook-form";
import InputField from "components/InputField";
import snackNotification from "components/SnackNotification";
import useCustomFilterAPI from "api/CustomFilterAPI";
import { useCustomFilter } from "components/CustomFilterMenu/CustomFilterContext";
import FormDialog from "components/FormDialog";
import FormLabel from "components/FormLabel";

type CustomFilterForm = {
  name: string;
  users: string[];
  userGroups: string[];
  ["filter_data"]: string;
};

const defaultValues: CustomFilterForm = {
  name: "",
  users: [],
  userGroups: [],
  ["filter_data"]: ""
};

const SaveCustomFilterDialog: FC = () => {
  const { t } = useTranslation();
  const CustomFilterAPI = useCustomFilterAPI();
  const {
    currentFilterKey,
    filterData,
    setFilterData,
    isSaveFilterDialogOpen,
    setIsSaveFilterDialogOpen
  } = useCustomFilter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { sessionUser } = useAuth();
  const UserAPI = useUserAPI();
  const UserGroupAPI = useUserGroupAPI();
  const { errorHandler } = useErrorHandler();
  const [users, setUsers] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);

  const { control, formState, getValues, handleSubmit, reset } =
    useForm<CustomFilterForm>({
      mode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [usersResponse, userGroupsResponse] = await Promise.all([
        UserAPI.listAll(sessionUser.customer_id),
        UserGroupAPI.listAll({
          customerId: sessionUser.customer_id,
          page: -1
        })
      ]);

      setUsers(usersResponse.data.map(user => user.username));
      setUserGroups(
        (userGroupsResponse.data as UserGroup[]).map(
          userGroup => userGroup.group_name
        )
      );
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    if (isSaveFilterDialogOpen) {
      requestData();
    } else {
      setFilterData("");
      reset(defaultValues);
      setSelectedUserGroups([]);
      setSelectedUsers([]);
    }
  }, [isSaveFilterDialogOpen]);

  const onSubmit = async () => {
    if (!sessionUser?.["customer_id"] || !currentFilterKey) return;
    setIsSaving(true);
    try {
      await CustomFilterAPI.set({
        ["customer_id"]: sessionUser.customer_id,
        ["filter_name"]: getValues("name"),
        ["filter_key"]: currentFilterKey,
        ["filter_data"]: filterData,
        users: selectedUsers,
        ["user_groups"]: selectedUserGroups
      });
      setIsSaveFilterDialogOpen(false);
      snackNotification.success(
        t("CustomFilterMenu.customFilterSuccessfullySaved")
      );
      setIsSaving(false);
    } catch (error) {
      errorHandler({ error });
    }
  };

  return (
    <FormDialog
      maxWidth="xs"
      onConfirm={handleSubmit(onSubmit)}
      open={isSaveFilterDialogOpen}
      setOpen={setIsSaveFilterDialogOpen}
      isLoading={isLoading || isSaving}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={t("CustomFilterMenu.saveCurrentFilters")}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("CustomFilterMenu.name")}
                  customProps={{
                    required: true,
                    placeholder: t("CustomFilterMenu.nameHint")
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Box>
          <Box>
            <FormLabel>{t("CustomFilterMenu.users")}</FormLabel>
            <Autocomplete
              sx={{ mb: 2 }}
              multiple
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              options={users}
              getOptionLabel={option => option}
              filterSelectedOptions
              noOptionsText={t("form.noOptions")}
              size="small"
              value={selectedUsers}
              onChange={(event, user) => {
                event.preventDefault();
                setSelectedUsers(user);
              }}
              renderInput={params => (
                <div style={{ position: "relative" }}>
                  <TextField
                    {...params}
                    placeholder={t("CustomFilterMenu.users")}
                  />
                  {isLoading && (
                    <CircularProgress
                      color="secondary"
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "42px"
                      }}
                    />
                  )}
                </div>
              )}
            />
          </Box>
          <Box>
            <FormLabel>{t("CustomFilterMenu.userGroups")}</FormLabel>
            <Autocomplete
              sx={{ mb: 2 }}
              multiple
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              options={userGroups}
              getOptionLabel={option => option}
              filterSelectedOptions
              noOptionsText={t("form.noOptions")}
              size="small"
              value={selectedUserGroups}
              onChange={(event, userGroup) => {
                event.preventDefault();
                setSelectedUserGroups(userGroup);
              }}
              renderInput={params => (
                <div style={{ position: "relative" }}>
                  <TextField
                    {...params}
                    placeholder={t("CustomFilterMenu.userGroups")}
                  />
                  {isLoading && (
                    <CircularProgress
                      color="secondary"
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "42px"
                      }}
                    />
                  )}
                </div>
              )}
            />
          </Box>
        </Grid>
        {/*<Grid item xs={12} md={6}>
              <FormLabel>
                {t("CustomFilterMenu.filterData")}
              </FormLabel>
              <Box>
                <Typography>
                  <strong>Campo 1</strong>
                </Typography>
                <Typography>Value</Typography>
              </Box>
              <Box>
                <Typography>
                  <strong>Campo 2</strong>
                </Typography>
                <Typography>Value</Typography>
              </Box>
              <Box>
                <Typography>
                  <strong>Campo 3</strong>
                </Typography>
                <Typography>Value</Typography>
              </Box>
            </Grid>*/}
      </Grid>
    </FormDialog>
  );
};

export default SaveCustomFilterDialog;
