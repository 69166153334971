import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import Pages from "enums/Pages";
import {
  Cpu,
  Shield,
  Archive,
  Sliders,
  FileText,
  PieChart,
  Map as MapIcon
} from "react-feather";
import Map, { MapGeolocation } from "components/Map";
import TypeBox from "components/TypeBox";
import { Box, Grid, Skeleton } from "@mui/material";
import useMonitoredVehiclesDashboardAPI, {
  MonitoredVehicles,
  MonitoringReason,
  VehiclesMake
} from "api/MonitoredVehiclesDashboardAPI";
import MonitoredVehiclesDashboardFilter, {
  defaultValues,
  FormDashboardFilter
} from "./MonitoredVehiclesDashboardFilter";
import { format, parseISO } from "date-fns";
import useToolsAPI from "api/ToolsAPI";
import DataTable from "components/DataTable";
import LinkPlate from "components/LinkPlate";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { MonitoringType } from "api/MonitoringAPI";
import DashboardCard from "components/DashboardCard";
import { dateTimeToString, dateToString } from "utils/DateFunctions";
import InnerPageLayout from "layouts/InnerPageLayout";
import { monitoringVehiclesColors } from "styles/theme";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import CaptureImgButton from "components/CaptureImgButton";
import PageSection from "components/PageSection/PageSection";
import { parsePlateCoordinate } from "components/ImageViewer";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import MonitoredReasonsGraphs from "./MonitoredReasonsGraphs";
import CaptureImageViewerDialog, {
  CaptureImageDialog,
  initialCaptureImageDialogProps
} from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import { EMPTY_VALUE, setMonitoringVehiclesColor } from "utils/String";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import CapturePointHeatmap, { HeatmapData } from "./CapturePointHeatmap";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const defaultMonitoringReason = {
  frequency: {
    ["theft_and_robbery"]: 0,
    documentation: 0,
    intelligence: 0,
    ["search_and_seizure"]: 0,
    ["automatic_monitoring"]: 0,
    none: 0,
    total: 0
  },
  ["frequency_by_weekday"]: [],
  ["frequency_by_hour"]: []
};

const MonitoredVehiclesDashboardPage: FC = () => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const ToolsAPI = useToolsAPI();
  const [page, setPage] = useState(1);
  const { errorHandler } = useErrorHandler();
  const [isFetching, setIsFetching] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setPageTitle, setLocation } = usePageLocation();
  const [filter, setFilter] = useState<FormDashboardFilter>(defaultValues);
  const MonitoredVehiclesDashboardAPI = useMonitoredVehiclesDashboardAPI();
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [isCaptureDialogOpen, setCaptureDialogOpen] = useState<boolean>(false);
  const [capture, setCapture] = useState<CaptureImageDialog>(
    initialCaptureImageDialogProps
  );
  const [hourCamAvailability, setHourCamAvailability] = useState<HeatmapData>(
    []
  );
  const [dayCamAvailability, setDayyCamAvailability] = useState<HeatmapData>(
    []
  );

  const [geolocations, setGeolocations] = useState<MapGeolocation[]>([]);

  const [monitoringReasonsScore, setMonitoringReasonsScore] =
    useState<MonitoringReason>(defaultMonitoringReason);
  const [monitorings, setMonitorings] = useState<MonitoredVehicles[]>([]);
  const [makes, setMakes] = useState<VehiclesMake[]>([]);

  useEffect(() => {
    setPageTitle(t("windowTitle.dashboard"));
    setLocation([
      {
        label: t("menu.dashboard"),
        page: Pages.MONITORING_VEHICLES_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  useEffect(() => {
    (async () => {
      try {
        const makeResponse = await ToolsAPI.listAllMakes();

        const liteMake = makeResponse.make
          .filter(item => item.lite === true)
          .map(item => ({ name: item.name, isDivider: false }));
        liteMake[liteMake.length - 1].isDivider = true;
        const nonLiteMake = makeResponse.make
          .filter(item => item.lite === false)
          .map(item => ({ name: item.name, isDivider: false }));
        const makes = liteMake.concat(nonLiteMake);

        setMakes(makes || []);
      } catch (error) {
        errorHandler({ error });
      }
    })();
  }, []);

  const changeWeekName = (values: MonitoringReason) => {
    const mappedData = values.frequency_by_weekday.map(day => ({
      ...day,
      weekday: t(`form.weekDays.${day.weekday}`).slice(0, 3)
    }));
    setMonitoringReasonsScore({
      frequency: values.frequency,
      ["frequency_by_weekday"]: mappedData,
      ["frequency_by_hour"]: values.frequency_by_hour
    });
  };

  const requestData = useCallback(
    async (
      filter: FormDashboardFilter,
      currentPage: number,
      pageSize: number
    ) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsFetching(true);
      try {
        const response =
          await MonitoredVehiclesDashboardAPI.getMonitoredVehicles({
            ["customer_id"]: sessionUser.customer_id,
            ["initial_date"]: dateTimeToString(
              filter.startDate as Date,
              filter.startTime as Date
            ),
            ["final_date"]: dateTimeToString(
              filter.endDate as Date,
              filter.endTime as Date
            ),
            plate: filter.plate,
            ["vehicle_make"]: filter.make === "" ? undefined : filter.make,
            ["vehicle_model"]: filter.model === "" ? undefined : filter.model,
            ["vehicle_color"]: filter.color === "" ? undefined : filter.color,
            ["vehicle_class"]: filter.vehicleClass,
            ["monitoring_reason"]: filter.monitoredReasons as MonitoringType[],
            locations: filter.equipments,
            ["grouping_reasons"]: "all",
            ["grouping_locations"]: "all",
            page: currentPage,
            ["page_size"]: pageSize
          });
        setGeolocations(response.geolocations || []);
        changeWeekName(
          response.monitoring_reasons.frequency
            ? response.monitoring_reasons
            : defaultMonitoringReason
        );
        setMonitorings(response.monitored_vehicles.items || []);

        let chartWeekData: HeatmapData = [];
        let chartHourData: HeatmapData = [];
        if (response.locations_score) {
          if (
            response.locations_score.frequency_by_weekday &&
            response.locations_score.frequency_by_weekday.length > 0
          ) {
            chartWeekData = response.locations_score.frequency_by_weekday.map(
              item => ({
                name: item.location,
                data: item.weekday_frequency.map(cData => ({
                  x: t(`form.weekDays.${cData.weekday.toString()}`).slice(0, 3),
                  y: cData.weight ? Number(cData.weight.toFixed(1)) : 0
                }))
              })
            );
          }

          if (
            response.locations_score.frequency_by_hour &&
            response.locations_score.frequency_by_hour.length > 0
          ) {
            chartHourData = response.locations_score.frequency_by_hour.map(
              item => ({
                name: item.location,
                data: item.hour_frequency.map(cData => ({
                  x: cData.hour.toString(),
                  y: cData.weight ? Number(cData.weight.toFixed(1)) : 0
                }))
              })
            );
          }
        }

        setDayyCamAvailability(chartWeekData);
        setHourCamAvailability(chartHourData);

        setPaginator({
          totalPages: response.monitored_vehicles.total_pages || 0,
          totalItems: response.monitored_vehicles.total_items || 0
        });
        setPage(response.monitored_vehicles.page);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetching(false);
      }
    },
    [sessionUser]
  );

  const openCaptureDialog = (item: MonitoredVehicles) => {
    const vehicleCategory = t(
      `VehicleOverviewPage.${
        item.plate === "0000000" ? "unknown" : item.vehicle_class
      }`
    );
    setCapture({
      id: item.id,
      image: item.image_link,
      equipment: item.location_name,
      camera: item.camera_name,
      dateTime: item.date_capture,
      plate: item.plate,
      plateCoordinate: item.plate_coordinate
        ? parsePlateCoordinate(item.plate_coordinate)
        : undefined,
      latitude: item.latitude || "0",
      longitude: item.longitude || "0",
      renavam: {
        vehicleMake: item.vehicle_make,
        vehicleModel: item.vehicle_model,
        vehicleColor: item.vehicle_color,
        vehicleCategory: vehicleCategory.toUpperCase(),
        vehicleYear: item.vehicle_year
      }
    });
    setCaptureDialogOpen(true);
  };

  const setMonitoringVehiclesIcon = (type: string): ReactNode => {
    switch (type) {
      case "search_and_seizure":
        return <Archive size={28} color={monitoringVehiclesColors.seizure} />;
      case "automatic_monitoring":
        return <MapIcon size={28} color={monitoringVehiclesColors.rule} />;
      case "documentation":
        return (
          <FileText size={28} color={monitoringVehiclesColors.documentation} />
        );
      case "theft_and_robbery":
        return <Shield size={28} color={monitoringVehiclesColors.steal} />;
      case "intelligence":
        return <Cpu size={28} color={monitoringVehiclesColors.intelligence} />;
      default:
        return <PieChart size={28} color={monitoringVehiclesColors.total} />;
    }
  };

  const setDateTitle = (): string =>
    filter.startDate && filter.endDate
      ? `(${dateToString(filter.startDate, "dd/MM/yyyy")} - ${dateToString(
          filter.endDate,
          "dd/MM/yyyy"
        )})`
      : "";

  useEffect(() => {
    requestData(filter, page, pageSize);
  }, [filter, pageSize]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <Box>
          <PageSection
            title={`${t("menu.dashboard")} ${setDateTitle()}`}
            isLoading={isFetching}
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            }
          >
            <MonitoredVehiclesDashboardFilter
              open={isFilterOpen}
              setOpen={setIsFilterOpen}
              setFilter={setFilter}
              makes={makes}
            />
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} sm={6}>
                {isFetching && <Skeleton variant="rectangular" height="100%" />}
                {!isFetching && (
                  <Map minHeight={300} geolocations={geolocations} />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={
                        monitoringReasonsScore.frequency.search_and_seizure || 0
                      }
                      color={monitoringVehiclesColors.seizure}
                      icon={
                        <Archive
                          size={28}
                          color={monitoringVehiclesColors.seizure}
                        />
                      }
                      isLoading={isFetching}
                      title={t(
                        "MonitoredVehiclesDashboardPage.search_and_seizure"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={
                        monitoringReasonsScore.frequency.automatic_monitoring ||
                        0
                      }
                      color={monitoringVehiclesColors.rule}
                      icon={
                        <MapIcon
                          size={28}
                          color={monitoringVehiclesColors.rule}
                        />
                      }
                      isLoading={isFetching}
                      title={t("MonitoredVehiclesDashboardPage.rules")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={monitoringReasonsScore.frequency.documentation || 0}
                      color={monitoringVehiclesColors.documentation}
                      icon={
                        <FileText
                          size={28}
                          color={monitoringVehiclesColors.documentation}
                        />
                      }
                      isLoading={isFetching}
                      title={t("MonitoredVehiclesDashboardPage.documentation")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={
                        monitoringReasonsScore.frequency.theft_and_robbery || 0
                      }
                      color={monitoringVehiclesColors.steal}
                      icon={
                        <Shield
                          size={28}
                          color={monitoringVehiclesColors.steal}
                        />
                      }
                      isLoading={isFetching}
                      title={t(
                        "MonitoredVehiclesDashboardPage.theft_and_robbery"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={monitoringReasonsScore.frequency.intelligence || 0}
                      color={monitoringVehiclesColors.intelligence}
                      icon={
                        <Cpu
                          size={28}
                          color={monitoringVehiclesColors.intelligence}
                        />
                      }
                      isLoading={isFetching}
                      title={t("MonitoredVehiclesDashboardPage.intelligence")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DashboardCard
                      qtd={paginator.totalItems || 0}
                      color={monitoringVehiclesColors.total}
                      icon={
                        <PieChart
                          size={28}
                          color={monitoringVehiclesColors.total}
                        />
                      }
                      isLoading={isFetching}
                      title={t("MonitoredVehiclesDashboardPage.totalVehicle")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ mb: 0 }} item xs={12}>
                <DataTable
                  watermarked
                  headers={[
                    {
                      key: "date_capture",
                      label: t("MonitoredVehiclesDashboardPage.datetime")
                    },
                    {
                      key: "plate",
                      label: t("MonitoredVehiclesDashboardPage.plate")
                    },
                    {
                      key: "vehicle_class",
                      label: t("MonitoredVehiclesDashboardPage.vehicleClass")
                    },
                    {
                      key: "characteristics",
                      label: t("MonitoringVehicles.characteristics")
                    },
                    {
                      key: "type",
                      label: t("MonitoringVehicles.type.label")
                    },
                    {
                      key: "location_name",
                      label: t("MonitoredVehiclesDashboardPage.equipment"),
                      noSort: true
                    },
                    {
                      key: "capture",
                      label: t("MonitoredVehiclesDashboardPage.capture"),
                      align: "right",
                      noSort: true
                    }
                  ]}
                  defaultSort={["date_capture", "asc"]}
                  onHeaderSort={setMonitorings}
                  data={monitorings}
                  renderRow={row => [
                    <>
                      <div>
                        {format(
                          parseISO(row["date_capture"]),
                          t("CaptureReportPage.dateFormat")
                        ) || EMPTY_VALUE}
                      </div>
                    </>,
                    <>
                      {row.plate ? (
                        <LinkPlate
                          plate={row.plate}
                          path={Pages.VEHICLE_OVERVIEW}
                        />
                      ) : (
                        EMPTY_VALUE
                      )}
                    </>,
                    <>
                      {row["vehicle_class"] === ""
                        ? EMPTY_VALUE
                        : t(
                            `MonitoredVehiclesDashboardPage.${row["vehicle_class"]}`
                          )}
                    </>,
                    <>
                      {row.characteristics === ""
                        ? EMPTY_VALUE
                        : row.characteristics}
                    </>,
                    <>
                      <TypeBox
                        text={
                          row.type
                            ? t(`MonitoredVehiclesDashboardPage.${row.type}`)
                            : EMPTY_VALUE
                        }
                        icon={setMonitoringVehiclesIcon(row.type)}
                        color={setMonitoringVehiclesColor(row.type)}
                        align="left"
                      />
                    </>,
                    <>
                      <strong>{row["location_name"]}</strong>
                    </>,
                    <>
                      <CaptureImgButton
                        image_link={row["image_link"] || ""}
                        onClick={() => openCaptureDialog(row)}
                      />
                    </>
                  ]}
                  hideColumnsSm={[4, 5, 6, 7]}
                  hideColumnsXs={[0, 3, 4, 5, 6, 7]}
                  page={page}
                  onPageChange={pageValue =>
                    requestData(filter, pageValue, pageSize)
                  }
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                  totalPages={paginator.totalPages}
                  totalItems={paginator.totalItems}
                  isLoading={isFetching}
                />
              </Grid>
              <Grid sx={{ mt: 0 }} item xs={12}>
                <MonitoredReasonsGraphs
                  data={monitoringReasonsScore}
                  isFetching={isFetching}
                />
              </Grid>
              <Grid item xs={12}>
                <CapturePointHeatmap
                  hourData={hourCamAvailability}
                  dayData={dayCamAvailability}
                  isFetching={isFetching}
                />
              </Grid>
            </Grid>
          </PageSection>
        </Box>
        <CaptureImageViewerDialog
          open={isCaptureDialogOpen}
          setOpen={setCaptureDialogOpen}
          capture={capture}
        />
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default MonitoredVehiclesDashboardPage;
