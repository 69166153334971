import Map from "components/Map";
import { Box, Grid, styled } from "@mui/material";
import {
  RouteMarker,
  RouteMarkerProps
} from "pages/FlowDashboard/RouteFlowMap/RouteMarker";
import { createRef, FC, useEffect, useState } from "react";
import { useEquipmentMap } from "contexts/EquipmentMapContext";
import RouteFlowMarkers from "pages/FlowDashboard/RouteFlowMap/RouteFlowMarkers";
import RouteFlowMapLegend from "pages/FlowDashboard/RouteFlowMap/RouteFlowMapLegend";
import PageSection from "components/PageSection/PageSection";
import { useTranslation } from "react-i18next";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350,

  "& .gm-style>div:nth-of-type(2)>div>div:nth-of-type(5)": {
    filter: "grayscale(100%)"
  }
}));

const RouteFlowMap: FC = () => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [markers, setMarkers] = useState<RouteMarkerProps[]>([]);
  const mapContainerRef = createRef<HTMLDivElement>();
  const [isCalculateBounds, setIsCalculateBounds] = useState(false);
  const { center, zoom, setZoom, setCenter } = useEquipmentMap();

  const fitBoundsWithRoutes = () => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      for (const m of markers) {
        bounds.extend(
          new google.maps.LatLng({
            lat: m.lat,
            lng: m.lng
          })
        );
      }

      map.fitBounds(bounds);
      const mapZoom = map.getZoom() ?? 15;
      if (mapZoom > 15) {
        map.setZoom(15);
      }
    }
  };

  useEffect(() => {
    if (!mapContainerRef?.current || !isCalculateBounds) return;
    fitBoundsWithRoutes();
    setIsCalculateBounds(false);
  }, [mapContainerRef, markers, isCalculateBounds]);

  const { t } = useTranslation();

  return (
    <PageSection title={t("FlowDashboardPage.trafficFlowMap")} variant="h5">
      <Grid container>
        <Grid item xs={12}>
          <Container>
            <div
              ref={mapContainerRef}
              style={{
                flexGrow: 1,
                width: "100%"
              }}
            >
              <Map
                onLoad={map => {
                  setMap(map);
                }}
                onChange={e => {
                  setCenter(e.center);
                  setZoom(e.zoom);
                }}
                customControls={
                  <RouteFlowMapLegend onFitBoundsClick={fitBoundsWithRoutes} />
                }
                center={center}
                zoom={zoom}
              >
                <RouteFlowMarkers
                  map={map}
                  setMarkers={setMarkers}
                  setIsCalculateBounds={setIsCalculateBounds}
                />
                {markers.map(marker => (
                  <RouteMarker
                    key={marker.routeName + marker.initialCamera}
                    lat={marker.lat}
                    lng={marker.lng}
                    routeName={marker.routeName}
                    speedAveragePercentage={marker.speedAveragePercentage}
                    initialCamera={marker.initialCamera}
                    finalCamera={marker.finalCamera}
                    averageSpeed={marker.averageSpeed}
                    legalSpeed={marker.legalSpeed}
                  />
                ))}
              </Map>
            </div>
          </Container>
        </Grid>
      </Grid>
    </PageSection>
  );
};

export default RouteFlowMap;
