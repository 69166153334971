import {
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography
} from "@mui/material";
import Pages from "enums/Pages";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import useVerticalAPI, { Vertical } from "api/VerticalAPI";
import DefaultPageLayout from "layouts/DefaultPageLayout";
//import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { Edit, MinusCircle, Plus } from "react-feather";
import VerticalFormDialog from "pages/Settings/Verticals/VerticalFormDialog";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

const GroupBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center"
}));

const VerticalsPage: FC = () => {
  const VerticalAPI = useVerticalAPI();
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [verticals, setVerticals] = useState<Vertical[]>([]);
  const [selectedVertical, setSelectedVertical] = useState("");
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      const response = await VerticalAPI.listAll();
      setVerticals(response.data);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  const openFormDialog = (name = "") => {
    setSelectedVertical(name);
    setIsFormDialogOpen(true);
  };

  useEffect(() => {
    requestData();
  }, [requestData]);

  const handleRemove = (name: string) => {
    showModal({
      modalType: "confirm",
      //onConfirm: () => remove(name),
      confirmText: t("action.remove"),
      title: t("prompt.removeConfirmTitle", { name }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  /*const remove = async (name: string) => {
    try {
      //await VerticalAPI.deleteByName(name);
      snackNotification.success(t("VerticalFormDialog.verticalDeleted"));
      requestData();
    } catch (error) {
      errorHandler({ error });
    }
  };*/

  const { t } = useTranslation();

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.verticals"));
    setLocation([
      {
        label: t("DefaultPageLayout.settings")
      },
      {
        label: t("VerticalsPage.title"),
        page: Pages.VERTICALS
      }
    ]);
  }, [t, Pages]);

  return (
    <>
      <DefaultPageLayout>
        <InnerPageLayout>
          <PageSection
            title={t("VerticalsPage.verticals")}
            search={{
              onChange: e => setSearch(e.target.value),
              placeholder: t("VerticalsPage.searchVertical")
            }}
            primaryActions={
              <PageSectionHeaderAction
                variant="contained"
                color="secondary"
                startIcon={<Plus />}
                onClick={() => openFormDialog("")}
              >
                {t("CamerasPage.newCameras")}
              </PageSectionHeaderAction>
            }
          >
            {isLoading && (
              <Typography align="center">{t("waitState.loading")}</Typography>
            )}
            {!isLoading && (
              <Box>
                {verticals
                  .filter(vertical =>
                    vertical.vertical_name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((vertical, key) => (
                    <GroupBox key={key}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          <strong>{vertical.vertical_name}</strong>
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <IconButton
                            onClick={() =>
                              openFormDialog(vertical.vertical_name)
                            }
                          >
                            <Edit size={16} />
                          </IconButton>
                          <Button
                            customProps={{
                              startIcon: <MinusCircle size={16} />,
                              size: "small",
                              variant: "outlined",
                              color: "primary",
                              onClick: () =>
                                handleRemove(vertical.vertical_name)
                            }}
                          >
                            {t("action.delete")}
                          </Button>
                        </Stack>
                      </Box>
                    </GroupBox>
                  ))}
              </Box>
            )}
          </PageSection>
        </InnerPageLayout>
      </DefaultPageLayout>
      <VerticalFormDialog
        name={selectedVertical}
        open={isFormDialogOpen}
        setOpen={setIsFormDialogOpen}
        updateCallerContent={requestData}
      />
    </>
  );
};

export default VerticalsPage;
