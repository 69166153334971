import {
  Autocomplete,
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { X } from "react-feather";
import Button from "components/Button";
import InputField from "components/InputField";
import { useTranslation } from "react-i18next";
import { dateToString } from "utils/DateFunctions";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import { FC, useEffect, useState } from "react";
import { MonitoringType } from "api/MonitoringAPI";
import { orderBy } from "lodash";
import { useLocale } from "contexts/LocaleContext";
import FormLabel from "components/FormLabel";
import { AutocompleteOptions } from "pages/MonitoredVehiclesDashboard/MonitoredVehiclesDashboardFilter";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormMonitoredVehicleFilter = {
  plate: string;
  type: string;
  soundAlert: boolean;
  equipment: string;
  startDate: Date | string | null;
  endDate: Date | string | null;
};

const defaultValues: FormMonitoredVehicleFilter = {
  plate: "",
  type: "",
  soundAlert: false,
  equipment: "",
  startDate: null,
  endDate: null
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormMonitoredVehicleFilter) => void;
};

const MonitoredVehicleFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const [selectedMonitoredReason, setSelectedMonitoredReason] =
    useState<AutocompleteOptions>({
      value: "",
      label: ""
    });
  const { language } = useLocale();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [reasonOptions, setReasonsOptions] = useState<AutocompleteOptions[]>([
    {
      value: "search_and_seizure",
      label: t("MonitoredVehiclesDashboardPage.search_and_seizure")
    },
    {
      value: "intelligence",
      label: t("MonitoredVehiclesDashboardPage.intelligence")
    },
    {
      value: "theft_and_robbery",
      label: t("MonitoredVehiclesDashboardPage.theft_and_robbery")
    },
    {
      value: "documentation",
      label: t("MonitoredVehiclesDashboardPage.documentation")
    },
    {
      value: "automatic_monitoring",
      label: t("MonitoredVehiclesDashboardPage.automatic_monitoring")
    }
  ]);

  const updateAutoCompleteLabel = (options: AutocompleteOptions) => ({
    ...options,
    label: t(`MonitoredVehiclesDashboardPage.${options.value}`)
  });

  useEffect(() => {
    if (open) {
      const newReasons: AutocompleteOptions[] = reasonOptions.map(
        updateAutoCompleteLabel
      );
      setReasonsOptions([...newReasons]);
    }
  }, [language, open]);

  const { control, handleSubmit, reset } = useForm<FormMonitoredVehicleFilter>({
    mode: "onChange",
    defaultValues
  });

  const clearForm = () => {
    reset();
    setSelectedMonitoredReason({
      value: "",
      label: ""
    });
  };

  const changeOptionsValue = (object: AutocompleteOptions) => {
    setSelectedMonitoredReason(object);
  };

  const onSubmit = async (data: FormMonitoredVehicleFilter) => {
    const newData = { ...data };

    newData.type = selectedMonitoredReason.value as MonitoringType;
    newData.startDate = newData.startDate
      ? dateToString(newData.startDate as Date)
      : "";
    newData.endDate = newData.endDate
      ? dateToString(newData.endDate as Date)
      : "";

    onFilter(newData);
    setOpen(false);
  };

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        variant="temporary"
        onClose={() => setOpen(false)}
        sx={{ ".MuiPaper-root": { borderRadius: "0px !important" } }}
      >
        <div style={{ width: isMobile ? "100%" : "376px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "-8px",
              padding: "32px 32px 24px 32px",
              justifyContent: "space-between"
            }}
          >
            <Typography variant="h4" align="left">
              {t("CaptureReportPage.filter")}
            </Typography>
            <IconButton color="primary" onClick={() => setOpen(false)}>
              <X size={24} />
            </IconButton>
          </div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="plate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputField
                        label={t("MonitoringVehicles.plate")}
                        gutter={false}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    align="left"
                    color="primary.light"
                  >
                    {t("DefaultPageLayout.tooltipPlate")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>{t("MonitoringVehicles.type.label")}</FormLabel>
                  <Autocomplete
                    disableClearable
                    filterSelectedOptions
                    openText={t("action.open")}
                    closeText={t("action.close")}
                    options={orderBy(
                      reasonOptions,
                      ["", "label"],
                      [false, "asc"]
                    )}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValues) => {
                      event.preventDefault();
                      changeOptionsValue(newValues);
                    }}
                    renderOption={(props, option) => (
                      <Typography key={option.value} {...props}>
                        {option.label}
                      </Typography>
                    )}
                    noOptionsText={t("form.noOptions")}
                    size="small"
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        label={t("MonitoringVehicles.activationDate")}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePickerField
                        label={t("MonitoringVehicles.expirationDateForm")}
                        field={{ ...field }}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="soundAlert"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            onChange={field.onChange}
                            checked={Boolean(field.value)}
                          />
                        }
                        label={t("MonitoringVehicles.soundAlert").toString()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Content>
            <Footer>
              <Button
                customProps={{
                  color: "primary",
                  variant: "outlined",
                  onClick: clearForm
                }}
              >
                {t("action.clear")}
              </Button>
              <Button
                customProps={{
                  type: "submit"
                }}
              >
                {t("action.filter")}
              </Button>
            </Footer>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default MonitoredVehicleFilter;
