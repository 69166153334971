import {
  Box,
  Grid,
  Dialog,
  useTheme,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import useConvoyDashboardAPI, {
  ConvoyInfo,
  VehicleInfos,
  ConvoyVehicles
} from "api/ConvoyDashboardAPI";
import Pages from "enums/Pages";
import { format } from "date-fns";
import Button from "components/Button";
import { Video, X } from "react-feather";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import Watermark from "components/Watermark";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { RowContainer } from "./ConvoyHistoryTable";
import ImageViewer, { parsePlateCoordinate } from "components/ImageViewer";
import { Table, TableContainer } from "components/Table";
import MonitoringStatus from "components/MonitoringStatus";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";

type MonitoringPlate = {
  plate: string;
  class: string;
  color: string;
  make: string;
  model: string;
};

const defaultVehicle: VehicleInfos = {
  id: "",
  ["date_capture"]: "",
  ["location_name"]: "",
  plate: "",
  image: "",
  ["camera_name"]: "",
  ["plate_coordinate"]: "",
  ["vehicle_class"]: "",
  ["vehicle_color"]: "",
  ["vehicle_make"]: "",
  ["vehicle_model"]: ""
};

type Props = {
  open: boolean;
  monitoringList: string[];
  convoySelected: ConvoyVehicles | null;
  setOpen: (open: boolean) => void;
};

const ConvoyImageViewerDialog: FC<Props> = ({
  open,
  setOpen,
  monitoringList,
  convoySelected
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const ConvoyDashboardAPI = useConvoyDashboardAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstVehicleData, setFirstVehicleData] =
    useState<VehicleInfos>(defaultVehicle);
  const [secondVehicleData, setSecondVehicleData] =
    useState<VehicleInfos>(defaultVehicle);
  const [convoyData, setConvoyData] = useState<ConvoyInfo[][]>([]);
  const [isFullscreenFirstVehicle, setIsFullscreenFirstVehicle] =
    useState(false);
  const [isFullscreenSecondVehicle, setIsFullscreenSecondVehicle] =
    useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const requestData = useCallback(
    async (idConvoy: string) => {
      setIsLoading(true);
      if (!sessionUser?.["customer_id"]) return;
      try {
        const convoyResponse = await ConvoyDashboardAPI.requestInfoConvoy(
          sessionUser.customer_id,
          idConvoy
        );
        setConvoyData(convoyResponse.response.convoy_registers);
        setFirstVehicleData({
          id: convoyResponse.response.convoy_registers[0][0].vehicle_1.id || "",
          ["date_capture"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .date_capture || "",
          ["location_name"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .location_name || "",
          plate:
            convoyResponse.response.convoy_registers[0][0].vehicle_1.plate ||
            "",
          ["camera_name"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .camera_name || "",
          ["plate_coordinate"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .plate_coordinate || "",
          ["vehicle_class"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .vehicle_class || "",
          ["vehicle_color"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .vehicle_color || "",
          ["vehicle_make"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .vehicle_make || "",
          ["vehicle_model"]:
            convoyResponse.response.convoy_registers[0][0].vehicle_1
              .vehicle_model || "",
          image:
            convoyResponse.response.convoy_registers[0][0].vehicle_1.image || ""
        });
        setSecondVehicleData({
          id: convoyResponse.response.convoy_registers[0][1].vehicle_2.id || "",
          ["date_capture"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .date_capture || "",
          ["location_name"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .location_name || "",
          plate:
            convoyResponse.response.convoy_registers[0][1].vehicle_2.plate ||
            "",
          ["camera_name"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .camera_name || "",
          ["vehicle_class"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .vehicle_class || "",
          ["vehicle_color"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .vehicle_color || "",
          ["vehicle_make"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .vehicle_make || "",
          ["vehicle_model"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .vehicle_model || "",
          ["plate_coordinate"]:
            convoyResponse.response.convoy_registers[0][1].vehicle_2
              .plate_coordinate || "",
          image:
            convoyResponse.response.convoy_registers[0][1].vehicle_2.image || ""
        });
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    if (convoySelected && convoySelected?.id_convoy !== "") {
      requestData(convoySelected.id_convoy);
    }
  }, [requestData, convoySelected]);

  const closeDialog = () => {
    if (isFullscreenFirstVehicle || isFullscreenSecondVehicle) {
      setIsFullscreenFirstVehicle(false);
      setIsFullscreenSecondVehicle(false);
    } else {
      setOpen(false);
    }
  };

  const monitoringPlate = (data: MonitoringPlate) => {
    if (monitoringList.includes(data.plate || "")) {
      history.push(`${Pages.MONITORING_VEHICLES}/${data.plate}`);
    } else if (!data.make && !data.model && !data.color) {
      history.push(`${Pages.MONITORING_VEHICLES}/${data.plate}/${data.class}`);
    } else {
      history.push(
        `${Pages.MONITORING_VEHICLES}/${data.plate}/${data.class}/${data.make}/${data.model}/${data.color}`
      );
    }
  };

  return (
    <Dialog
      fullScreen={
        fullScreen || isFullscreenFirstVehicle || isFullscreenSecondVehicle
      }
      maxWidth="lg"
      fullWidth
      open={open}
      aria-labelledby="dialog"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeDialog();
        }
      }}
    >
      <>
        <DialogTitle
          sx={{
            padding:
              isFullscreenFirstVehicle || isFullscreenSecondVehicle
                ? "5px 10px !important"
                : ""
          }}
        >
          {isFullscreenFirstVehicle || isFullscreenSecondVehicle ? (
            <div />
          ) : (
            t("ConvoyDashboardPage.details")
          )}
          <IconButton aria-label="close" onClick={closeDialog}>
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding:
              isFullscreenFirstVehicle || isFullscreenSecondVehicle
                ? "0 !important"
                : ""
          }}
        >
          {isLoading && (
            <Box
              sx={{
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading &&
            firstVehicleData.plate !== "" &&
            secondVehicleData.plate !== "" && (
              <Grid container rowSpacing={1} columnSpacing={2} sx={{ mt: 1 }}>
                <Grid
                  item
                  xs={6}
                  lg={
                    isFullscreenFirstVehicle || isFullscreenSecondVehicle
                      ? 12
                      : 6
                  }
                >
                  <Typography sx={{ mb: 1 }} variant="body1" align="left">
                    <strong>{t("ConvoyDashboardPage.vehicle")} 1</strong>
                  </Typography>
                  <div style={{ marginBottom: "16px" }}>
                    <MonitoringStatus
                      monitoringPlates={monitoringList}
                      plate={firstVehicleData.plate}
                    />
                  </div>
                  <ImageViewer
                    isDialogOpen={open}
                    isFullscreen={isFullscreenFirstVehicle}
                    setIsFullscreen={setIsFullscreenFirstVehicle}
                    img={firstVehicleData.image}
                    id={firstVehicleData.id}
                    cameraId={firstVehicleData.location_name}
                    plateCoordinate={
                      firstVehicleData.plate_coordinate
                        ? parsePlateCoordinate(
                            firstVehicleData.plate_coordinate
                          )
                        : undefined
                    }
                  />
                  <Button
                    customProps={{
                      sx: { mt: 2, width: "100%" },
                      color: "secondary",
                      startIcon: <Video />,
                      onClick: () =>
                        monitoringPlate({
                          plate: firstVehicleData.plate,
                          class: firstVehicleData.vehicle_class,
                          color: firstVehicleData.vehicle_color,
                          make: firstVehicleData.vehicle_make,
                          model: firstVehicleData.vehicle_model
                        })
                    }}
                  >
                    {t("ConvoyDashboardPage.monitoring")}
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{ mb: 3 }}
                  xs={6}
                  lg={
                    isFullscreenFirstVehicle || isFullscreenSecondVehicle
                      ? 12
                      : 6
                  }
                >
                  <Typography sx={{ mb: 1 }} variant="body1" align="left">
                    <strong>{t("ConvoyDashboardPage.vehicle")} 2</strong>
                  </Typography>
                  <div style={{ marginBottom: "16px" }}>
                    <MonitoringStatus
                      monitoringPlates={monitoringList}
                      plate={secondVehicleData.plate}
                    />
                  </div>
                  <ImageViewer
                    isDialogOpen={open}
                    isFullscreen={isFullscreenFirstVehicle}
                    setIsFullscreen={setIsFullscreenFirstVehicle}
                    img={secondVehicleData.image}
                    id={secondVehicleData.id}
                    cameraId={secondVehicleData.location_name}
                    plateCoordinate={
                      secondVehicleData.plate_coordinate
                        ? parsePlateCoordinate(
                            secondVehicleData.plate_coordinate
                          )
                        : undefined
                    }
                  />
                  <Button
                    customProps={{
                      sx: { mt: 2, width: "100%" },
                      color: "secondary",
                      startIcon: <Video />,
                      onClick: () =>
                        monitoringPlate({
                          plate: secondVehicleData.plate,
                          class: secondVehicleData.vehicle_class,
                          color: secondVehicleData.vehicle_color,
                          make: secondVehicleData.vehicle_make,
                          model: secondVehicleData.vehicle_model
                        })
                    }}
                  >
                    {t("ConvoyDashboardPage.monitoring")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} variant="body1" align="left">
                    <strong>{t("ConvoyDashboardPage.convoyInfo")}</strong>
                  </Typography>
                  <Watermark>
                    <TableContainer sx={{ maxHeight: 320 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell key="h0" component="th" align="center">
                              {t("ConvoyDashboardPage.plate")}
                            </TableCell>
                            <TableCell key="h1" component="th" align="center">
                              {t("ConvoyDashboardPage.dateTime")}
                            </TableCell>
                            <TableCell key="h2" component="th" align="center">
                              {t("ConvoyDashboardPage.capturePoint")}
                            </TableCell>
                            <TableCell key="h3" component="th" align="center">
                              {t("ConvoyDashboardPage.cameraId")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {convoyData.length > 0 &&
                            convoyData.map((row, idx) => (
                              <TableRow key={`r0${idx}`}>
                                <TableCell component="td">
                                  <RowContainer>
                                    <div style={{ marginBottom: "8px" }}>
                                      <LinkPlate
                                        plate={
                                          row[0].vehicle_1.plate || EMPTY_VALUE
                                        }
                                        path={Pages.VEHICLE_OVERVIEW}
                                      />
                                    </div>
                                    <div>
                                      <LinkPlate
                                        plate={
                                          row[1].vehicle_2.plate || EMPTY_VALUE
                                        }
                                        path={Pages.VEHICLE_OVERVIEW}
                                      />
                                    </div>
                                  </RowContainer>
                                </TableCell>
                                <TableCell component="td">
                                  <RowContainer>
                                    <div style={{ marginBottom: "8px" }}>
                                      {format(
                                        new Date(row[0].vehicle_1.date_capture),
                                        "dd/MM/yyyy HH:mm:ss"
                                      ) || EMPTY_VALUE}
                                    </div>
                                    <div>
                                      {format(
                                        new Date(row[1].vehicle_2.date_capture),
                                        "dd/MM/yyyy HH:mm:ss"
                                      ) || EMPTY_VALUE}
                                    </div>
                                  </RowContainer>
                                </TableCell>
                                <TableCell component="td">
                                  <RowContainer>
                                    <div style={{ marginBottom: "8px" }}>
                                      {row[0].vehicle_1.location_name ||
                                        EMPTY_VALUE}
                                    </div>
                                    <div>
                                      {row[1].vehicle_2.location_name ||
                                        EMPTY_VALUE}
                                    </div>
                                  </RowContainer>
                                </TableCell>
                                <TableCell component="td">
                                  <RowContainer>
                                    <div style={{ marginBottom: "8px" }}>
                                      {row[0].vehicle_1.camera_name ||
                                        EMPTY_VALUE}
                                    </div>
                                    <div>
                                      {row[1].vehicle_2.camera_name ||
                                        EMPTY_VALUE}
                                    </div>
                                  </RowContainer>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Watermark>
                </Grid>
              </Grid>
            )}
        </DialogContent>
      </>
    </Dialog>
  );
};

export default ConvoyImageViewerDialog;
