import { Box, Grid, Typography } from "@mui/material";
import useUserAPI from "api/UserAPI";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import RegexPatterns from "utils/RegexPatterns";
import PasswordField from "components/PasswordField";
import { Controller, useForm } from "react-hook-form";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import FormDialog from "./FormDialog";
import Crypto from "utils/Crypto";

type MyAccountForm = {
  cpf: string;
  name: string;
  username: string;
  newPassword: string;
  confirmNewPassword: string;
  currentPassword: string;
};

const defaultValues: MyAccountForm = {
  cpf: "",
  name: "",
  username: "",
  newPassword: "",
  confirmNewPassword: "",
  currentPassword: ""
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type UserData = {
  customerIdentifier: string;
  authToken: string;
};

const MyAccountDialog: FC<Props> = ({ open, setOpen }) => {
  const { changeSessionUser, sessionUser } = useAuth();
  const [isUpdatingAccount, setUpdatingAccount] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData>({
    customerIdentifier: "",
    authToken: "-"
  });
  const { getByUsername, update } = useUserAPI();

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const { clearErrors, control, formState, getValues, handleSubmit, reset } =
    useForm<MyAccountForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const closeDialog = () => {
    setOpen(false);
  };

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    const customerId = sessionUser["customer_id"];
    try {
      setUpdatingAccount(true);
      const user = await getByUsername(customerId, sessionUser.username);
      if (user) {
        reset({
          name: user.name,
          username: user.username
          //cpf: user["user_data"] ? user["user_data"].cpf : ""
        });
        setUserData({
          authToken: user.auth_token ?? "-",
          customerIdentifier: user.customer_id
        });
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setUpdatingAccount(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  const onSubmit = async (data: MyAccountForm) => {
    if (!sessionUser?.username) return;
    setUpdatingAccount(true);
    try {
      await update({
        customerId: sessionUser["customer_id"],
        name: data.name,
        username: sessionUser.username,
        oldPassword: data.newPassword === "" ? undefined : data.currentPassword,
        newPassword: data.newPassword === "" ? undefined : data.newPassword
        //cpf: data.cpf
      });
      if (sessionUser) {
        changeSessionUser({
          ...sessionUser,
          name: data.name,
          apiAuthCode: Crypto.encrypt(data.newPassword)
        });
      }
      closeDialog();
      snackNotification.success(t("MyAccountDialog.savedSuccessfully"));
    } catch (error) {
      errorHandler({ error });
    } finally {
      setUpdatingAccount(false);
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isUpdatingAccount}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.confirm")}
      title={t("MyAccountDialog.myAccount")}
    >
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="left" color="textSecondary">
            <strong>{t("MyAccountDialog.identification")}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                customProps={{
                  autoFocus: true,
                  disabled: isUpdatingAccount
                }}
                label={t("MyAccountDialog.name")}
                field={{ ...field }}
                fieldState={fieldState}
                gutter={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            customProps={{
              readOnly: true,
              disabled: true,
              value: userData.customerIdentifier
            }}
            label={t("MyAccountDialog.customerIdentifier")}
            gutter={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            customProps={{
              readOnly: true,
              disabled: true,
              value: userData.authToken
            }}
            label={t("MyAccountDialog.authenticationCode")}
            gutter={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                customProps={{
                  readOnly: true,
                  disabled: true,
                  type: "email"
                }}
                label={t("MyAccountDialog.username")}
                field={{ ...field }}
                fieldState={fieldState}
                gutter={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            customProps={{
              readOnly: true,
              disabled: true,
              value: sessionUser?.apiAuthCode
            }}
            label={t("MyAccountDialog.apiAuthCode")}
            gutter={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5" align="left" color="textSecondary">
              <strong>{t("MyAccountDialog.password")}</strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="textSecondary">
              {t("MyAccountDialog.passwordChangeOptional")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="currentPassword"
            control={control}
            rules={{
              pattern: {
                value: RegexPatterns.PASSWORD,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <PasswordField
                customProps={{
                  disabled: isUpdatingAccount,
                  placeholder: t("MyAccountDialog.currentPasswordHint")
                }}
                label={t("MyAccountDialog.currentPassword")}
                field={{ ...field }}
                fieldState={fieldState}
                gutter={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              pattern: {
                value: RegexPatterns.PASSWORD,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <PasswordField
                customProps={{
                  disabled: isUpdatingAccount
                }}
                label={t("MyAccountDialog.newPassword")}
                field={{ ...field }}
                fieldState={fieldState}
                gutter={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmNewPassword"
            control={control}
            rules={{
              validate(value) {
                const newPassword = getValues("newPassword");
                if (newPassword === "") return true;
                return (
                  value === newPassword ||
                  t("form.passwordsMustMatch").toString()
                );
              }
            }}
            render={({ field, fieldState }) => (
              <PasswordField
                customProps={{
                  disabled: isUpdatingAccount
                }}
                label={t("MyAccountDialog.confirmNewPassword")}
                field={{ ...field }}
                fieldState={fieldState}
                gutter={false}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default MyAccountDialog;
