import {
  defaultValues,
  FormFlowDashboardFilter
} from "pages/FlowDashboard/FlowDashboardFilter";
import { createContext, FC, useContext, useState } from "react";

interface ContextProps {
  setFilterData: (filter: FormFlowDashboardFilter) => void;
  getFilterData: () => FormFlowDashboardFilter;
  filterData: FormFlowDashboardFilter;
}

const FlowDashboardFilterContext = createContext<ContextProps>({
  setFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  getFilterData() {
    throw new Error("setFilterData must be defined.");
  },
  filterData: {
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    ranking: undefined,
    equipments: undefined
  }
});

export const FlowDashboardFilterProvider: FC = ({ children }) => {
  const [filterData, setFilterData] =
    useState<FormFlowDashboardFilter>(defaultValues);

  const getFilterData = (): FormFlowDashboardFilter => filterData;

  return (
    <FlowDashboardFilterContext.Provider
      value={{
        setFilterData,
        getFilterData,
        filterData
      }}
    >
      {children}
    </FlowDashboardFilterContext.Provider>
  );
};

export const useFlowDashboardFilter = (): ContextProps => {
  const context = useContext(FlowDashboardFilterContext);
  if (context === undefined) {
    throw new Error(
      "useFlowDashboardFilter must be used within an FlowDashboardFilterProvider"
    );
  }
  return context;
};
