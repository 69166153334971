import { Box, Tab, Tabs } from "@mui/material";
import { FC, ReactNode, SyntheticEvent, useState } from "react";

type TabContentProps = {
  value: string;
  selectedValue: string;
  children?: ReactNode;
};

const TabContent: FC<TabContentProps> = ({
  value,
  selectedValue,
  children,
  ...props
}) => (
  <div
    role="tabpanel"
    style={{ width: "100%" }}
    hidden={selectedValue !== value}
    id={`simple-tabpanel-${value}`}
    aria-labelledby={`simple-tab-${value}`}
    {...props}
  >
    {selectedValue === value && <Box sx={{ pt: 2 }}>{children}</Box>}
  </div>
);

type Props = {
  tabs: Array<{
    hide?: boolean;
    value: string;
    label: string;
    content: ReactNode;
  }>;
};

const TabPanel: FC<Props> = ({ tabs }) => {
  const [selectedValue, setSelectedValue] = useState(tabs[0].value);

  const handleSelectedValueChange = (
    event: SyntheticEvent,
    newValue: string
  ) => {
    event.preventDefault();
    setSelectedValue(newValue);
  };

  return (
    <>
      <Tabs
        sx={{
          borderBottom: theme => `1px solid${theme.palette.grey["200"]}`,
          pt: 1
        }}
        value={selectedValue}
        onChange={handleSelectedValueChange}
        variant="scrollable"
        textColor="secondary"
        indicatorColor="secondary"
      >
        {tabs
          .filter(t => !t.hide)
          .map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
      </Tabs>
      {tabs
        .filter(t => !t.hide)
        .map(tab => (
          <TabContent
            key={tab.value}
            value={tab.value}
            selectedValue={selectedValue}
          >
            {tab.content}
          </TabContent>
        ))}
    </>
  );
};

export default TabPanel;
