import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "api/API";

export type CameraFilter = {
  ["location_name"]: string;
  ["camera_name"]: string;
};

export type SharedDatas = {
  ["owner_name"]: string;
  owner: string;
  alias: string;
  shared?: string[];
};

export type Camera = {
  ["location_name"]: string;
  ["camera_name"]: string;
  ["serial_number"]: string;
  ["updated_at"]: string;
  ["camera_data"]: {
    ["request_image_unrecognized"]: boolean;
    ["request_image_recognized"]: boolean;
    active: boolean;
    ["serial_number"]: string;
    ["customer_id"]: string;
    side: string;
    vms?: {
      index: string;
      name: string;
    };
    cortex: {
      lane: number;
    };
    ["cconet"]?: {
      ["external_id"]: string;
    };
    ["detecta_sp"]?: {
      ["external_id"]: string;
    };
    ["spia_prf"]?: {
      ["external_id"]: string;
    };
  };
  ["shared_data"]: SharedDatas;
};

export type CamerasPaginated = {
  cameras: Camera[];
  page: number;
  ["total_count"]: number;
  ["total_pages"]: number;
};

type ListPaginatedParams = {
  customerId: string;
  page: number;
  pageSize?: number;
};

type ListAllParams = {
  customerId: string;
};

type SearchParams = {
  customerId: string;
  name: string;
  page?: number;
  pageSize?: number;
};

type GetByNameParams = {
  customerId: string;
  locationName: string;
  cameraName: string;
};

type DeleteParams = {
  customerId: string;
  locationName: string;
  cameraName: string;
};

type CreateParams = {
  customerId: string;
  locationName: string;
  cameraName: string;
  active: boolean;
  side: string;
  serialNumber: string;
  requestImageRecognized: boolean;
  requestImageUnrecognized: boolean;
  vmsCameraName: string;
  vmsIndex: string;
  cortex?: Camera["camera_data"]["cortex"];
  ["cconet"]?: Camera["camera_data"]["cconet"];
  ["detecta_sp"]?: Camera["camera_data"]["detecta_sp"];
  ["spia_prf"]?: Camera["camera_data"]["spia_prf"];
};

type UpdateParams = {
  customerId: string;
  locationName: string;
  oldLocationName: string | undefined;
  cameraName: string;
  oldCameraName: string | undefined;
  active: boolean;
  side: string;
  serialNumber: string;
  requestImageRecognized: boolean;
  requestImageUnrecognized: boolean;
  vmsCameraName: string;
  vmsIndex: string;
  cortex?: Camera["camera_data"]["cortex"];
  ["shared_data"]?: SharedDatas;
  ["cconet"]?: Camera["camera_data"]["cconet"];
  ["detecta_sp"]?: Camera["camera_data"]["detecta_sp"];
  ["spia_prf"]?: Camera["camera_data"]["spia_prf"];
};

type UseCameraAPIType = {
  listPaginated: (
    param: ListPaginatedParams
  ) => Promise<NestedData<CamerasPaginated>>;
  listAll: (param: ListAllParams) => Promise<NestedData<Camera[]>>;
  search: (
    param: SearchParams
  ) => Promise<NestedData<CamerasPaginated | Camera[]>>;
  getByName: (param: GetByNameParams) => Promise<Camera>;
  deleteByName: (param: DeleteParams) => Promise<Success>;
  create: (param: CreateParams) => Promise<Success>;
  update: (param: UpdateParams) => Promise<Success>;
};

const useCameraAPI = (): UseCameraAPIType => {
  const API = useAxios();

  const listPaginated = async ({
    customerId,
    page,
    pageSize
  }: ListPaginatedParams): Promise<NestedData<CamerasPaginated>> => {
    const response: AxiosResponse<NestedData<CamerasPaginated>> =
      await API.instance.post(APIEndpoints.CAMERA_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const listAll = async ({
    customerId
  }: ListAllParams): Promise<NestedData<Camera[]>> => {
    const response: AxiosResponse<NestedData<Camera[]>> =
      await API.instance.post(APIEndpoints.CAMERA_LIST, {
        ["customer_id"]: customerId,
        page: -1
      });
    return response.data;
  };

  const search = async ({
    customerId,
    name,
    page = -1,
    pageSize
  }: SearchParams): Promise<NestedData<CamerasPaginated | Camera[]>> => {
    const response: AxiosResponse<NestedData<CamerasPaginated | Camera[]>> =
      await API.instance.get(APIEndpoints.CAMERA_PARTIAL, {
        params: {
          ["customer_id"]: customerId,
          ["camera_name"]: name,
          page,
          ["page_size"]: pageSize
        }
      });
    return response.data;
  };

  const getByName = async ({
    customerId,
    locationName,
    cameraName
  }: GetByNameParams): Promise<Camera> => {
    const response: AxiosResponse<Camera> = await API.instance.get(
      APIEndpoints.CAMERA,
      {
        params: {
          ["customer_id"]: customerId,
          ["location_name"]: locationName,
          ["camera_name"]: cameraName
        }
      }
    );
    return response.data;
  };

  const deleteByName = async ({
    customerId,
    locationName,
    cameraName
  }: DeleteParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CAMERA_DELETE,
      {
        ["customer_id"]: customerId,
        ["location_name"]: locationName,
        ["camera_name"]: cameraName
      }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    let cortexData = {};
    let cconetData = {
      ["cconet"]: {
        ["external_id"]: ""
      }
    };
    let detectaspData = {
      ["detecta_sp"]: {
        ["external_id"]: ""
      }
    };
    let spiaprfData = {
      ["spia_prf"]: {
        ["external_id"]: ""
      }
    };

    if (data.cortex) {
      cortexData = { cortex: data.cortex };
    }
    if (data["cconet"]) {
      cconetData = { ["cconet"]: data["cconet"] };
    }
    if (data["detecta_sp"]) {
      detectaspData = { ["detecta_sp"]: data["detecta_sp"] };
    }
    if (data["spia_prf"]) {
      spiaprfData = { ["spia_prf"]: data["spia_prf"] };
    }

    const requestBody = {
      ["camera_data"]: {
        ["customer_id"]: data.customerId,
        ["location_name"]: data.locationName,
        ["camera_name"]: data.cameraName,
        active: data.active,
        side: data.side,
        ["serial_number"]: data.serialNumber,
        ["request_image_recognized"]: data.requestImageRecognized,
        ["request_image_unrecognized"]: data.requestImageUnrecognized,
        vms: {
          name: data.vmsCameraName,
          index: data.vmsIndex
        },
        ...cortexData,
        ...cconetData,
        ...detectaspData,
        ...spiaprfData
      }
    };

    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CAMERA_CREATE,
      requestBody
    );

    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    let cortexData = {};
    let cconetData = {
      ["cconet"]: {
        ["external_id"]: ""
      }
    };
    let detectaspData = {
      ["detecta_sp"]: {
        ["external_id"]: ""
      }
    };
    let spiaprfData = {
      ["spia_prf"]: {
        ["external_id"]: ""
      }
    };

    if (data.cortex) {
      cortexData = { cortex: data.cortex };
    }
    if (data["cconet"]) {
      cconetData = { ["cconet"]: data["cconet"] };
    }
    if (data["detecta_sp"]) {
      detectaspData = { ["detecta_sp"]: data["detecta_sp"] };
    }
    if (data["spia_prf"]) {
      spiaprfData = { ["spia_prf"]: data["spia_prf"] };
    }

    const requestBody = {
      ["camera_data"]: {
        ["customer_id"]: data.customerId,
        ["location_name"]: data.locationName,
        ["old_location_name"]: data.oldLocationName,
        ["camera_name"]: data.cameraName,
        ["old_camera_name"]: data.oldCameraName,
        active: data.active,
        side: data.side,
        ["serial_number"]: data.serialNumber,
        ["request_image_recognized"]: data.requestImageRecognized,
        ["request_image_unrecognized"]: data.requestImageUnrecognized,
        vms: {
          name: data.vmsCameraName,
          index: data.vmsIndex
        },
        ...cortexData,
        ...cconetData,
        ...detectaspData,
        ...spiaprfData
      },
      ["shared_data"]: data.shared_data
    };

    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.CAMERA_UPDATE,
      requestBody
    );

    return response.data;
  };

  return {
    listPaginated,
    listAll,
    search,
    getByName,
    create,
    update,
    deleteByName
  };
};

export default useCameraAPI;
