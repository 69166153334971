import {
  Grid,
  styled,
  MenuItem,
  useTheme,
  ToggleButton,
  useMediaQuery,
  ToggleButtonGroup
} from "@mui/material";
import useShareAPI from "api/ShareAPI";
import { KeyValuePair } from "api/API";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import SelectField from "components/SelectField";
import useCameraAPI, { Camera } from "api/CameraAPI";
import { Controller, useForm } from "react-hook-form";
import useLocationAPI from "api/LocationAPI";
import snackNotification from "components/SnackNotification";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import useCustomerAPI, { DataCustomer } from "api/CustomerAPI";
import FormDialog from "components/FormDialog";

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: "100%",
  "& > button": {
    width: "50%"
  }
}));

export type SharedDataForm = {
  sharedCustomerId: string;
  cameraName: string;
  locationName: string;
  type: "camera" | "location";
};

const defaultValues: SharedDataForm = {
  sharedCustomerId: "",
  cameraName: "",
  locationName: "",
  type: "location"
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (sharedData?: SharedDataForm) => void;
};

const SharedDataFormDialog: FC<Props> = ({ open, setOpen, onSave }) => {
  const CustomerAPI = useCustomerAPI();
  const CameraAPI = useCameraAPI();
  const LocationAPI = useLocationAPI();
  const ShareAPI = useShareAPI();
  const theme = useTheme();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [form, setForm] = useState<SharedDataForm>(defaultValues);
  const [locations, setLocations] = useState<string[]>([]);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [customers, setCustomers] = useState<KeyValuePair[]>([]);
  const { errorHandler } = useErrorHandler();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, formState, handleSubmit, reset, setValue, watch, trigger } =
    useForm<SharedDataForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    const customerId = sessionUser["customer_id"];
    setLoading(true);
    try {
      const [locationsResponse, cameraResponse, customerResponse] =
        await Promise.all([
          LocationAPI.listAll(customerId),
          CameraAPI.listAll({ customerId }),
          CustomerAPI.listAll(-1, 10)
        ]);
      const a = customerResponse as DataCustomer;
      setCustomers(
        a.data.map(c => ({
          key: c["customer_id"],
          value: c["customer_name"]
        }))
      );
      setLocations(
        locationsResponse.data
          .filter(location => !location["shared_data"]?.owner)
          .map(location => location["location_name"])
      );
      setCameras(cameraResponse.data);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  }, [reset, sessionUser]);

  useEffect(() => {
    setValue("cameraName", "");
    trigger("cameraName");
  }, [watch("type"), watch("locationName")]);

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(form);
  }, [setValue, form]);

  useEffect(() => {
    if (!open) {
      setForm(defaultValues);
      reset(defaultValues);
    }
  }, [open]);

  const onSubmit = async (data: SharedDataForm) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      setLoading(true);
      if (data.type === "camera") {
        await ShareAPI.createSharedCamera({
          ["customer_id"]: sessionUser["customer_id"],
          ["camera_name"]: data.cameraName,
          ["location_name"]: data.locationName,
          ["shared_customer_id"]: data.sharedCustomerId
        });
      } else {
        await ShareAPI.createSharedLocation({
          ["customer_id"]: sessionUser["customer_id"],
          ["location_name"]: data.locationName,
          ["shared_customer_id"]: data.sharedCustomerId
        });
      }
      snackNotification.success(t("ShareDataPage.sharedDataCreated"));
      onSave(data);
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      maxWidth="sm"
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={!formState.isValid}
      confirmText={t("action.apply")}
      title={t("ShareDataPage.newSharedData")}
      testId="shared-form-filter"
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <Controller
            name="sharedCustomerId"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                data-testid="sharedCustomerId"
                label={t("ShareDataPage.shareWith")}
                disabled={isLoading}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
              >
                <MenuItem value="">{t("ShareDataPage.shareWithHint")}</MenuItem>
                {customers.map(customer => (
                  <MenuItem key={customer.key} value={customer.key}>
                    {customer.value}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <CustomToggleButtonGroup
                {...field}
                data-testid="type-btn"
                size={fullScreen ? "small" : "medium"}
                color="secondary"
                exclusive
                onChange={(_event, type) => {
                  if (type) {
                    field.onChange(type);
                  }
                }}
                sx={{ mb: 3 }}
              >
                <ToggleButton value="location" data-testid="equiment-type">
                  {t("ShareDataPage.equipment")}
                </ToggleButton>
                <ToggleButton value="camera" data-testid="camera-type">
                  {t("ShareDataPage.camera")}
                </ToggleButton>
              </CustomToggleButtonGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} data-testid="locationName">
          <Controller
            name="locationName"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                label={t("ShareDataPage.equipment")}
                required
                disabled={isLoading}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
              >
                <MenuItem value="">{t("ShareDataPage.equipmentHint")}</MenuItem>
                {locations.map(location => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          />
        </Grid>
        {watch("type") === "camera" && (
          <Grid item xs={12} data-testing="cameraName">
            <Controller
              name="cameraName"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field }) => (
                <SelectField
                  {...field}
                  label={t("ShareDataPage.camera")}
                  required
                  disabled={isLoading}
                  onChange={event => {
                    field.onChange(event.target.value as string);
                  }}
                >
                  <MenuItem value="">{t("ShareDataPage.cameraHint")}</MenuItem>
                  {cameras
                    .filter(c => c["location_name"] === watch("locationName"))
                    .map(camera => (
                      <MenuItem
                        key={camera["camera_name"]}
                        value={camera["camera_name"]}
                      >
                        {camera["camera_name"]}
                      </MenuItem>
                    ))}
                </SelectField>
              )}
            />
          </Grid>
        )}
      </Grid>
    </FormDialog>
  );
};

export default SharedDataFormDialog;
