import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import reactUseWebSocket, { ReadyState } from "react-use-websocket";
import { SendMessage } from "react-use-websocket/dist/lib/types";
import { useAuth } from "./AuthContext";
import useAxios from "hooks/useAxios";
import Cookies from "utils/Cookies";

type WebSocketContextValue = {
  sendMessage: SendMessage | null;
  lastMessage: MessageEvent<string> | null;
  readyState: ReadyState;
};

const WebSocketContext = createContext<WebSocketContextValue>({
  sendMessage: null,
  lastMessage: null,
  readyState: ReadyState.CLOSED
});

export const WebSocketProvider: FC = ({ children }) => {
  const { sessionUser, accessToken } = useAuth();
  const API = useAxios();
  const accessTokenCookie = Cookies.get(Cookies.ACCESS_TOKEN)?.toString();
  const [socketUrl, setSocketUrl] = useState(
    `${API.wsURL}?t=${accessTokenCookie}`
  );

  const updateSocketUrl = useCallback(() => {
    if (accessToken) {
      setSocketUrl(`${API.wsURL}?t=${accessToken}`);
    }
  }, [accessToken]);

  const { sendMessage, lastMessage, readyState } = reactUseWebSocket(
    socketUrl,
    {
      shouldReconnect(_event: CloseEvent) {
        return true;
      },
      retryOnError: true,
      reconnectAttempts: 10
    },
    sessionUser !== null && accessToken !== undefined // only connect when true,
  );

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: "Connecting",
  //   [ReadyState.OPEN]: "Open",
  //   [ReadyState.CLOSING]: "Closing",
  //   [ReadyState.CLOSED]: "Closed",
  //   [ReadyState.UNINSTANTIATED]: "Uninstantiated"
  // }[readyState];

  useEffect(() => {
    // console.log(connectionStatus);
    if (
      readyState === ReadyState.CLOSED ||
      readyState === ReadyState.UNINSTANTIATED
    ) {
      updateSocketUrl();
    }
  }, [readyState]);

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage: sendMessage ?? null,
        lastMessage: lastMessage ?? null,
        readyState: readyState ?? ReadyState.CLOSED
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextValue => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error(
      "useWebSocketContext must be used within an WebSocketProvider"
    );
  }
  return context;
};
