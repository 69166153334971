import { FC } from "react";
import { SvgIcon } from "@mui/material";

interface CarProps {
  color?: string;
  dimensions?: string;
}

const Car: FC<CarProps> = ({ color, dimensions }) => (
  <SvgIcon
    sx={{ mt: dimensions ? "5px" : "0px" }}
    viewBox={dimensions ? dimensions : "0 0 18 16"}
  >
    <path
      d="M15.92 1.01C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.29 0.42 2.08 1.01L0 7V15C0 15.55 0.45 16 1 16H2C2.55 16 3 15.55 3 15V14H15V15C15 15.55 15.45 16 16 16H17C17.55 16 18 15.55 18 15V7L15.92 1.01ZM3.5 11C2.67 11 2 10.33 2 9.5C2 8.67 2.67 8 3.5 8C4.33 8 5 8.67 5 9.5C5 10.33 4.33 11 3.5 11ZM14.5 11C13.67 11 13 10.33 13 9.5C13 8.67 13.67 8 14.5 8C15.33 8 16 8.67 16 9.5C16 10.33 15.33 11 14.5 11ZM2 6L3.5 1.5H14.5L16 6H2Z"
      fill={color || "white"}
    />
  </SvgIcon>
);

export default Car;
