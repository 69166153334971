import { useAuth } from "contexts/AuthContext";
import { usePageLocation } from "contexts/PageLocationContext";
import Pages from "enums/Pages";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import InnerPageLayout from "layouts/InnerPageLayout";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SystemIntegrations from "./SystemIntegrations";
import CustomerIntegrations from "./CustomerIntegrations";

const PUMATRONIX_ADM = ""; //"Pumatronix";

const IntegrationsPage: FC = () => {
  const { setPageTitle, setLocation } = usePageLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("windowTitle.integrations"));
    setLocation([
      {
        label: t("menu.settings")
      },
      {
        label: t("menu.system")
      },
      {
        label: t("IntegrationsPage.title"),
        page: Pages.INTEGRATIONS
      }
    ]);
  }, [t, Pages]);

  const { sessionUser } = useAuth();
  const [isPumatronixSessionUser, setPumatronixSessionUser] = useState(
    sessionUser?.level_name === PUMATRONIX_ADM
  );

  useEffect(() => {
    setPumatronixSessionUser(sessionUser?.level_name === PUMATRONIX_ADM);
  }, [sessionUser]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        {isPumatronixSessionUser ? (
          <SystemIntegrations />
        ) : (
          <CustomerIntegrations />
        )}
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default IntegrationsPage;
