import { AxiosResponse } from "axios";
import { Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

type CreateShareLocationParams = {
  ["customer_id"]: string;
  ["location_name"]: string;
  ["shared_customer_id"]: string;
};

type DeleteShareLocationParams = CreateShareLocationParams;

type CreateShareCameraParams = {
  ["customer_id"]: string;
  ["location_name"]: string;
  ["camera_name"]: string;
  ["shared_customer_id"]: string;
};

type DeleteShareCameraParams = CreateShareCameraParams;

type UseShareAPIType = {
  createSharedLocation: (params: CreateShareLocationParams) => Promise<Success>;
  deleteSharedLocation: (params: DeleteShareLocationParams) => Promise<Success>;
  createSharedCamera: (params: CreateShareCameraParams) => Promise<Success>;
  deleteSharedCamera: (params: DeleteShareCameraParams) => Promise<Success>;
};

const useShareAPI = (): UseShareAPIType => {
  const API = useAxios();

  const createSharedLocation = async (
    params: CreateShareLocationParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.SHARE_LOCATION_CREATE,
      params
    );
    return response.data;
  };

  const deleteSharedLocation = async (
    params: DeleteShareLocationParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.SHARE_LOCATION_DELETE,
      params
    );
    return response.data;
  };

  const createSharedCamera = async (
    params: CreateShareCameraParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.SHARE_CAMERA_CREATE,
      params
    );
    return response.data;
  };

  const deleteSharedCamera = async (
    params: DeleteShareCameraParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.SHARE_CAMERA_DELETE,
      params
    );
    return response.data;
  };

  return {
    createSharedLocation,
    deleteSharedLocation,
    createSharedCamera,
    deleteSharedCamera
  };
};

export default useShareAPI;
