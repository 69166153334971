import { FormHelperText, styled } from "@mui/material";

type WrapperProps = {
  fullWidth?: boolean;
};

export const Wrapper = styled("div")<WrapperProps>(({ fullWidth = true }) => ({
  width: fullWidth ? "100%" : "auto",
  "&.gutter": {
    height: "90px"
  }
}));

export const Helper = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.text.secondary
}));

export const Error = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main
}));
