import Pages from "enums/Pages";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserGuideLayout from "./UserGuideLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";

const UserGuidePage: FC = () => {
  const { t } = useTranslation();

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.userGuide"));
    setLocation([
      {
        label: t("menu.userGuide"),
        page: Pages.USER_GUIDE
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <UserGuideLayout />
    </DefaultPageLayout>
  );
};

export default UserGuidePage;
