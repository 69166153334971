import { FC, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  Box,
  MenuItem,
  Skeleton,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import PageSection from "components/PageSection/PageSection";
import SelectField from "components/SelectField";
import { ApexOptions } from "apexcharts";
import { primaryDefaultColors } from "styles/theme";

const Container = styled(Box)(({ theme }) => ({
  height: 464,
  display: "flex",
  padding: "16px",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default
}));

export type HeatmapData = {
  name: string | undefined;
  data: {
    x: string;
    y: number | null;
  }[];
}[];

type Props = {
  dayData: HeatmapData;
  hourData: HeatmapData;
  isFetching: boolean;
};

const CapturePointHeatmap: FC<Props> = ({ dayData, hourData, isFetching }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isByHour, setIsByHour] = useState<string>("hour");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const options: ApexOptions = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 40,
              name: t("MonitoredVehiclesDashboardPage.heatmapTooltipLow"),
              color: "#FF0000"
            },
            {
              from: 41,
              to: 51,
              name: t("MonitoredVehiclesDashboardPage.heatmapTooltipMedium"),
              color: "#FFB200"
            },
            {
              from: 52,
              to: Infinity,
              name: t("MonitoredVehiclesDashboardPage.heatmapTooltipHigh"),
              color: "#00A100"
            }
          ]
        }
      }
    },
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true
      }
    },
    yaxis: {
      labels: {
        show: !isMobile
      }
    }
  };

  return (
    <PageSection
      title={t("MonitoredVehiclesDashboardPage.camPerformace")}
      variant="h5"
      actions={
        <SelectField
          size="small"
          style={{
            marginBottom: 0
          }}
          value={isByHour}
          disabled={isFetching}
          onChange={event => {
            setIsByHour(event.target.value as string);
          }}
        >
          <MenuItem value="hour">
            {t("MonitoredVehiclesDashboardPage.byHour")}
          </MenuItem>
          <MenuItem value="day">
            {t("MonitoredVehiclesDashboardPage.byDay")}
          </MenuItem>
        </SelectField>
      }
    >
      {isFetching && <Skeleton variant="rectangular" height={348} />}
      {!isFetching && hourData.length > 0 && dayData.length > 0 && (
        <>
          {isByHour === "hour" ? (
            <Container>
              <div
                style={{
                  width: "100%"
                }}
              >
                <Chart
                  height={432}
                  type="heatmap"
                  options={options}
                  series={hourData}
                />
              </div>
            </Container>
          ) : (
            <Container>
              <div
                style={{
                  width: "100%"
                }}
              >
                <Chart
                  height={432}
                  type="heatmap"
                  options={options}
                  series={dayData}
                />
              </div>
            </Container>
          )}
        </>
      )}
      {!isFetching && hourData.length === 0 && dayData.length === 0 && (
        <Container sx={{ alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h3" color={primaryDefaultColors.light}>
            {t("MonitoredVehiclesDashboardPage.emptyHeatmap")}
          </Typography>
        </Container>
      )}
    </PageSection>
  );
};

export default CapturePointHeatmap;
