import { Box, Tooltip } from "@mui/material";
import useConvoyDashboardAPI, {
  ConvoyLocation,
  ConvoyVehicles
} from "api/ConvoyDashboardAPI";
import { Map } from "react-feather";
import Button from "components/Button";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import useMonitoringAPI from "api/MonitoringAPI";
import { dateToString } from "utils/DateFunctions";
import ConvoyMap from "./ConvoyBIComponents/ConvoyMap";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FormConvoyDashboardFilter } from "./ConvoyDashboardFilter";
import ConvoyHistoryTable from "./ConvoyBIComponents/ConvoyHistoryTable";
import { useConvoyDashboardFilter } from "./ConvoyDashboardFilterContext";
import ConvoyImageViewerDialog from "./ConvoyBIComponents/ConvoyImageViewerDialog";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

export type InfoCards = {
  label: string;
  value: number;
};

export const defaultConvoy: ConvoyVehicles = {
  ["id_convoy"]: "",
  date: "",
  ["convoy_count"]: "",
  customer: "",
  month: "",
  year: ""
};

type Props = {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
};

const ConvoyBI: FC<Props> = ({ isLoading, setLoading }) => {
  const isMount = useIsMount();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [page, setPage] = useState(1);
  const MonitoringAPI = useMonitoringAPI();
  const { errorHandler } = useErrorHandler();
  const [zoom, setZoom] = useState<number>(3);
  const [totalConvoys, setTotalConvoys] = useState<number>(0);
  const ConvoyDashboardAPI = useConvoyDashboardAPI();
  const [pageSize, setPageSize] = useState<number>(10);
  const [monitorings, setMonitorings] = useState<string[]>([]);
  const [isLoadingMap, setIsLoadingMap] = useState<boolean>(false);
  const { getFilterData, filterData } = useConvoyDashboardFilter();
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [convoyVehicles, setConvoyVehicles] = useState<ConvoyVehicles[]>([]);
  const [convoyLocations, setConvoyLocations] = useState<ConvoyLocation[]>([]);
  const [isConvoyDialogOpen, setConvoyDialogOpen] = useState<boolean>(false);

  const [convoySelected, setConvoySelected] =
    useState<ConvoyVehicles>(defaultConvoy);

  const setConvoyPlates = (items: ConvoyVehicles[]): string[][] => {
    const convoyPlates: string[][] = [];
    items.forEach(item => {
      convoyPlates.push([
        item.id_convoy.split("_")[0],
        item.id_convoy.split("_")[1]
      ]);
    });
    return convoyPlates;
  };

  const requestData = useCallback(
    async (
      filter: FormConvoyDashboardFilter,
      pageValue: number,
      pageSizeValue: number
    ) => {
      setLoading(true);
      if (!sessionUser?.["customer_id"]) return;
      try {
        const filterObject = {
          ["customer_id"]: sessionUser.customer_id,
          ["initial_date"]: dateToString(filter.startDate as Date),
          ["final_date"]: dateToString(filter.endDate as Date),
          period: filter.period,
          plate: filter.plate ? filter.plate : undefined,
          frequency: filter.frequency ? filter.frequency : undefined,
          page: pageValue,
          ["page_size"]: pageSizeValue
        };
        setPage(pageValue);

        const convoyResponse = await ConvoyDashboardAPI.requestConvoys(
          filterObject
        );

        const monitoringPlates: string[] = [];
        if (convoyResponse.registers.items.length > 0) {
          await requestLocationsData(
            filter,
            setConvoyPlates(convoyResponse.registers.items)
          );

          const monitoringResponse = await MonitoringAPI.listAll({
            customerId: sessionUser.customer_id
          });

          if (
            monitoringResponse.data.Items &&
            monitoringResponse.data.Items.length > 0
          ) {
            monitoringResponse.data.Items.forEach(item => {
              monitoringPlates.push(item.plate);
            });
          }
        }

        setConvoyVehicles(convoyResponse.registers.items || []);
        setTotalConvoys(convoyResponse.registers.total_items);
        setMonitorings(monitoringPlates);

        setPaginator({
          totalPages: convoyResponse.registers.total_pages || 0,
          totalItems: convoyResponse.registers.total_items || 0
        });
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
      }
    },
    [sessionUser]
  );

  const requestLocationsData = async (
    filter: FormConvoyDashboardFilter,
    convoys: string[][]
  ) => {
    setIsLoadingMap(true);
    if (!sessionUser?.["customer_id"]) return;
    try {
      const convoyLocationsResponse = await ConvoyDashboardAPI.requestMapConvoy(
        sessionUser.customer_id,
        dateToString(filter.startDate as Date),
        dateToString(filter.endDate as Date),
        convoys
      );

      setConvoyLocations(convoyLocationsResponse.convoy_locations || []);

      if (
        convoys.length === 1 &&
        convoyLocationsResponse.convoy_locations.length > 0
      ) {
        setZoom(12);
      } else {
        setZoom(3);
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingMap(false);
    }
  };

  useEffect(() => {
    requestData(getFilterData(), page, pageSize);
  }, [requestData, pageSize]);

  useEffect(() => {
    if (!isMount) {
      if (getFilterData() === null) {
        requestData(getFilterData(), page, pageSize);
      } else {
        requestData(getFilterData(), 1, pageSize);
      }
    }
  }, [filterData]);

  const openConvoyDialog = (convoy: ConvoyVehicles) => {
    setConvoySelected(convoy);
    setConvoyDialogOpen(true);
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <ConvoyMap
          zoom={zoom}
          isLoading={isLoading || isLoadingMap}
          totalConvoys={totalConvoys}
          convoyLocations={totalConvoys === 0 ? [] : convoyLocations}
        />
      </Box>
      <Box sx={{ mb: 1, textAlign: "end" }}>
        <Button
          customProps={{
            variant: "outlined",
            onClick() {
              requestLocationsData(
                getFilterData(),
                setConvoyPlates(convoyVehicles)
              );
            }
          }}
        >
          <Tooltip title={t("ConvoyDashboardPage.seeAll")}>
            <Map size={16} />
          </Tooltip>
        </Button>
      </Box>
      <Box>
        <ConvoyHistoryTable
          isLoading={isLoading}
          monitoringPlates={monitorings}
          convoyVehicles={convoyVehicles}
          openConvoyDialog={openConvoyDialog}
          setMapContent={row => {
            requestLocationsData(getFilterData(), setConvoyPlates([row]));
            setZoom(12);
          }}
          page={page}
          paginator={paginator}
          onPageChange={pageValue =>
            requestData(getFilterData(), pageValue, pageSize)
          }
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </Box>
      <ConvoyImageViewerDialog
        open={isConvoyDialogOpen}
        monitoringList={monitorings}
        setOpen={setConvoyDialogOpen}
        convoySelected={convoySelected}
      />
    </Box>
  );
};

export default ConvoyBI;
