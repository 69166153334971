import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import Pages from "enums/Pages";
import useInfiniteScroll, {
  InfiniteScrollRef,
  ScrollDirection
} from "react-easy-infinite-scroll-hook";
import { EMPTY_VALUE } from "utils/String";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { FC, useEffect, useState, useCallback } from "react";
import noImage from "assets/image_unavailable.jpg";
import { dateTimeToString } from "utils/DateFunctions";
import { FormFilterConvoys } from "./ConvoyReportFilter";
import { ChevronLeft, ChevronRight } from "react-feather";
import { validateFilter } from "./ConvoyReportPage";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import useConvoyReportAPI, { ConvoyReport } from "api/ConvoyReportAPI";
import { useIsMount } from "hooks/useIsMount";

const ImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "496px",
  borderRadius: "4px 4px 0px 0px",
  backgroundColor: theme.palette.grey["200"],

  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",

  img: {
    height: "496px"
  }
}));

const SlideHeader = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  padding: "4px 4px",
  marginBottom: "0px",
  borderRadius: "0px 0px 4px 4px",
  backgroundColor: theme.palette.secondary.light
}));

const SlideContainer = styled("div")(() => ({
  width: "100%",
  height: "116px",
  display: "flex",
  overflowX: "scroll",
  alignItems: "center",

  img: {
    borderRadius: "4px"
  }
}));

type SelectedConvoyReport = {
  data: ConvoyReport;
  index: number;
};

interface ConvoyReportSlideProps {
  filterParam: FormFilterConvoys;
  pageMode: string;
}

const PAGE_SIZE = 50;

const ConvoyReportSlide: FC<ConvoyReportSlideProps> = ({
  filterParam,
  pageMode
}) => {
  const isMount = useIsMount();
  const history = useHistory();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const ConvoyReportAPI = useConvoyReportAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
  const [reports, setReports] = useState<ConvoyReport[]>([]);
  const [empty, setEmpty] = useState<string>(t("pagination.noItemsInitial"));
  const [selectedReport, setSelectedReport] = useState<SelectedConvoyReport>({
    data: {
      id: "",
      ["date_capture"]: "",
      age: "",
      equipment: "",
      camera: "",
      plate: "",
      ["vehicle_make"]: "",
      ["vehicle_model"]: "",
      ["vehicle_color"]: "",
      ["vehicle_class"]: "",
      ["vehicle_year"]: "",
      image_link: "",
      ["ocr_total_time"]: 0,
      ["plate_color"]: 0,
      ["plate_coordinate"]: ""
    },
    index: 0
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      if (sessionUser && pageMode === "slide") {
        try {
          const reportResponse = await loadReports(
            filterParam,
            page,
            PAGE_SIZE
          );
          if (reportResponse && reportResponse.referential) {
            setReports([reportResponse.referential, ...reportResponse.items]);
            setSelectedReport({
              data: reportResponse.referential,
              index: 0
            });
          }
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, [filterParam, pageMode]);

  const previousCapture = useCallback(() => {
    if (selectedReport.index > 0) {
      setSelectedReport({
        data: reports[selectedReport.index - 1],
        index: selectedReport.index - 1
      });
    }
  }, [selectedReport.index, reports]);

  const nextCapture = useCallback(() => {
    if (selectedReport.index < reports.length - 1) {
      setSelectedReport({
        data: reports[selectedReport.index + 1],
        index: selectedReport.index + 1
      });
    }
  }, [selectedReport.index, reports]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        previousCapture();
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        nextCapture();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [previousCapture, nextCapture]);

  useEffect(() => {
    if (!isMount) {
      setEmpty(t("pagination.noItems"));
    }
  }, [reports, filterParam]);

  const loadReports = async (
    filterParam: FormFilterConvoys,
    pageValue: number,
    pageSizeValue: number
  ) => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    if (!validateFilter(filterParam)) return;
    setIsLoading(true);
    try {
      if (
        !filterParam.plate &&
        history.location.pathname !== Pages.CONVOY_REPORT
      ) {
        history.replace(Pages.CONVOY_REPORT);
      }
      const reportResponse = await ConvoyReportAPI.list({
        ["customer_id"]: customerId,
        plate: filterParam.plate,
        ["referential_date"]: dateTimeToString(
          filterParam.referenceDate as Date,
          filterParam.referenceTime as Date
        ) as string,
        equipment: filterParam.equipment,
        time: filterParam.maxGapMinutes,
        page: pageValue,
        ["page_size"]: pageSizeValue
      });
      return reportResponse.registers;
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const next = async (direction: ScrollDirection) => {
    setIsLoadingNext(true);
    const reportResponse = await loadReports(filterParam, page + 1, PAGE_SIZE);
    if (reportResponse) {
      setReports(prev =>
        direction === "left"
          ? [...reportResponse.items, ...prev]
          : [...prev, ...reportResponse.items]
      );
      setPage(page + 1);
    }
    setIsLoadingNext(false);
  };

  const ref: InfiniteScrollRef<HTMLDivElement> = useInfiniteScroll({
    next,
    columnCount: reports.length,
    hasMore: { right: true },
    scrollThreshold: 0.8
  });

  return (
    <>
      <Box>
        {reports.length > 0 && (
          <>
            <ImageContainer>
              <img
                src={selectedReport.data.image_link || noImage}
                alt="Selected convoy capture"
              />
            </ImageContainer>
            <SlideHeader>
              <IconButton
                sx={{ marginRight: "16px" }}
                onClick={previousCapture}
                disabled={selectedReport.index === 0}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="caption" sx={{ marginRight: "16px" }}>
                {t("ConvoyReportPage.capture")}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginRight: "16px" }}>
                <strong>
                  {selectedReport.index + 1}/{reports.length}
                </strong>
              </Typography>
              <IconButton
                sx={{ marginRight: "32px" }}
                onClick={nextCapture}
                disabled={selectedReport.index === reports.length - 1}
              >
                <ChevronRight />
              </IconButton>
              <Typography variant="caption" sx={{ marginRight: "16px" }}>
                {t("ConvoyReportPage.plate")}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginRight: "16px" }}>
                <strong>{selectedReport.data.plate || EMPTY_VALUE}</strong>
              </Typography>
              <Typography variant="caption" sx={{ marginRight: "16px" }}>
                {t("ConvoyReportPage.gap")}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginRight: "16px" }}>
                <strong>{selectedReport.data.age || EMPTY_VALUE}</strong>
              </Typography>
            </SlideHeader>
            <SlideContainer ref={ref}>
              {reports.map((report: ConvoyReport, idx: number) => (
                <img
                  key={`${report.id}${idx}`}
                  style={{
                    cursor: "pointer",
                    border:
                      selectedReport.data.id === report.id
                        ? "3px solid #0B2BFC"
                        : "0px"
                  }}
                  src={report.image_link || noImage}
                  alt="Convoy capture"
                  width="116px"
                  height="96px"
                  onClick={() =>
                    setSelectedReport({ data: report, index: idx })
                  }
                />
              ))}
              {isLoadingNext && (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "2px",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <CircularProgress sx={{ marginBottom: "2px" }} size={20} />
                  <Typography align="center">
                    {t("waitState.loading")}
                  </Typography>
                </div>
              )}
            </SlideContainer>
          </>
        )}
      </Box>
      {isLoading && reports.length === 0 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            marginTop: "20%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && reports.length === 0 && (
        <Typography align="center">{empty}</Typography>
      )}
    </>
  );
};

export default ConvoyReportSlide;
